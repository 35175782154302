export const ID = 'id';
export const NAME = 'name';
export const STATE = 'state';
export const COMPANY_ID = 'companyId';
export const ASSIGNED_EMPLOYER_ID = 'assignedEmployerId';
export const DESCRIPTION = 'description';
export const EMPLOYER_ID = 'employerId';
export const FACILITY_ID = 'facilityId';
export const REQUIREMENTS = 'requirements';
export const BADGE_REQUIREMENTS = 'badgeRequirements';
export const FACILITY_GROUP_ID = 'facilityGroupId';
export const SHIFT_CATEGORY_ID = 'shiftCategoryId';
export const INVITED_WORKER_IDS = 'invitedWorkerIds';
export const PERMITTED_EVENTS = 'permittedEvents';
export const USE_SMART_RANKING = 'useSmartRanking';
export const GENERAL_DETAILS = 'generalDetails';

export const SHIFTS = 'shifts';
export const SHIFTS_ATTRIBUTES = 'shiftsAttributes';
export const REQUEST_TASKS = 'requestTasks';

//State
export const DRAFT = 'draft';
export const POSTED = 'posted';
export const INVITE_POSTED = 'invite_posted';
export const CANCELLED = 'cancelled';

//ShiftScopeEnum
export const ACTIVE = 'active';
export const DONE = 'done';
export const ARCHIVED = 'archived';
