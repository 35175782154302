import styled from 'styled-components';

export const StyledSignoutContainer = styled.div`
  padding: 10px 5% 0px 5%;
  height: 100%;
  h1 {
    padding-top: 10px;
  }
  span {
    padding-bottom: 20px;
    display: block;
  }
`;
