import { BUTTON_CLASS } from 'common/constants/buttonConstants';
import PropTypes from 'prop-types';

export const buttonColorChooser = (theme, buttonClass) => {
  switch (buttonClass) {
    case BUTTON_CLASS.SUBMIT:
      return theme.colors.name.main_color_1;
    case BUTTON_CLASS.BLUE:
      return theme.colors.name.main_color_1;
    case BUTTON_CLASS.YELLOW:
      return theme.colors.name.yellow;
    case BUTTON_CLASS.CANCEL:
      return theme.colors.name.main_color_4;
    default:
      return '';
  }
};

export const buttonBackgroundColorChooser = (theme, buttonClass) => {
  switch (buttonClass) {
    case BUTTON_CLASS.SUBMIT:
      return theme.colors.name.green;
    case BUTTON_CLASS.BLUE:
      return theme.colors.name.main_color_1;
    case BUTTON_CLASS.YELLOW:
      return theme.colors.name.yellow;
    case BUTTON_CLASS.DARK_BLUE:
      return theme.colors.name.darkblue;
    case BUTTON_CLASS.CANCEL:
    case BUTTON_CLASS.DEFAULT:
    default:
      return '';
  }
};

export const buttonFontColorChooser = (theme, buttonClass) => {
  switch (buttonClass) {
    case BUTTON_CLASS.DARK_BLUE:
      return theme.colors.name.white;
    default:
      return '';
  }
};

export const generateClassNameProp = (size, className, inverted) =>
  `${size} ${className} ${inverted ? ' inverted' : ''}`;

export const buttonPropTypes = {
  // since ChevronButton doesn't use icon ->
  // eslint-disable-next-line react/no-unused-prop-types
  icon: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
  type: PropTypes.string
};

export const buttonDefaultProps = {
  children: '',
  size: 'large',
  className: '',
  disabled: false,
  inverted: false,
  icon: undefined,
  isLoading: false,
  onClick: () => {},
  type: 'button'
};
