import React from 'react';
import PropTypes from 'prop-types';
import * as AUTH from 'common/constants/authConstants';
import get from 'lodash/get';
import Bowser from 'bowser';


const Notifications = ({ employer }) => {
  const employerEmail = employer.email;
  const employerId = employer.id;
  const companyId = employer.company.id;

  const OneSignal = window.OneSignal || [];

  const OneSignalAppId = get(employer, 'company.companyConfig.oneSignalAppId');

  const setupOneSignal = () => {
    OneSignal.push(() => {
      if (!OneSignal.isPushNotificationsSupported()) {
        return;
      }
      OneSignal.init({
        appId: OneSignalAppId,
        autoRegister: true,
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: true
        },
        autoResubscribe: true
      });
    });
  };

  const handleSendTags = (companyId, employerId, employerEmail) => {
    const browser = Bowser.getParser(window.navigator.userAgent);

    OneSignal.push(() => {
      OneSignal.sendTag('companyId', companyId);
      OneSignal.sendTag('employerId', employerId);
      OneSignal.sendTag('employerEmail', employerEmail);
      OneSignal.sendTag('userType', AUTH.EMPLOYER);
      OneSignal.sendTag('browser', browser.getBrowser());
    });
  };

  const handleSetExternalUserIdAndEmail = employerId => {
    OneSignal.push(() => {
      OneSignal.setExternalUserId(employerId);
    });
  };

  setupOneSignal();
  handleSendTags(companyId, employerId, employerEmail);
  handleSetExternalUserIdAndEmail(employerId, employerEmail);

  return <React.Fragment></React.Fragment>;
};

Notifications.propTypes = {
  employer: PropTypes.object.isRequired
};

Notifications.defaultProps = {};

export default Notifications;
