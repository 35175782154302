export const revertEditURLToOrigin = (url) => {
  if (url.includes('/edit')) {
    const parts = url.split('/');
    url = url.replace(parts[parts.length - 2], ':id');
  }
  return url;
};

export const revertDetailURLToOrigin = (url) => {
  if (url.includes('/detail')) {
    const parts = url.split('/');
    url = url.replace(parts[parts.length - 1], ':shiftName');
    url = url.replace(parts[parts.length - 2], ':jobId');
  }
  return url;
};


export const getIdFromEditURL = (url) => {
  let id = '';
  if (url.includes('/edit')) {
    const parts = url.split('/');
    id = parts[parts.length - 2]
  }
  return id;
};

export const getIdFromDetailURL = (url) => {
  let id = '';
  if (url.includes('/detail')) {
    const parts = url.split('/');
    id = parts[parts.length - 2]
  }
  return id;
};

export const getShiftNameFromDetailURL = (url) => {
  let id = '';
  if (url.includes('/detail')) {
    const parts = url.split('/');
    id = parts[parts.length - 1]
  }
  return id;
}

