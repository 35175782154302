import { eventClient, events } from '@opus/web.core.lib.event-tracking';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  apolloClient,
  GET_CURRENT_WORKER_ASSIGNMENT_QUERY,
  VERIFY_EMPLOYER_QUERY
} from 'common/apollo';

import { captureException } from '../common/helpers/captureEvent';
import {
  store,
  computed,
  action,
  observable,
  persist,
  event
} from '../common/mobx.decorator';
import { notifyStore } from './notify.store';
import { EVENTS } from 'common/constants/actionConstants';
import { ONE_DAY } from 'common/constants/weekdays.js';

class ProfileItem {
  @persist @observable id;
  @persist @observable firstName;
  @persist @observable lastName;
  @persist @observable workAuthorized;
}

@store({ persist: true })
class AuthStore {
  @persist @observable token = '';
  @persist @observable expiresAt = 0;
  @persist('object', ProfileItem) @observable profile;

  @observable currentJob = null;

  @computed
  get recruiter() {
    return this.profile?.recruiter;
  }

  @computed
  get authorization() {
    return !this.token || Date.now() > this.expiresAt ? '' : this.token;
  }
  @computed
  get id() {
    return this.authorization && this.profile?.id;
  }

  @computed
  get firstName() {
    return this.profile?.firstName;
  }

  @computed
  get lastName() {
    return this.profile?.lastName;
  }

  @computed
  get fullName() {
    return [this.firstName, this.lastName].join(' ').trim();
  }

  @computed
  get activated() {
    return this.profile?.workAuthorized;
  }

  @computed
  get hasCurrentJob() {
    return !isEmpty(this.currentJob);
  }

  @action
  changeProfile = async profile => {
    if (
      this.profile &&
      profile &&
      this.profile?.id === profile?.id &&
      profile.workAuthorized === true &&
      this.activated !== profile?.workAuthorized
    ) {
      eventClient.logEvent(new events.OnboardingWorkerAuthorizedSuccessEvent());
    }

    this.profile = profile;
  };

  @action
  changeToken = async (token = '', duration = ONE_DAY * 30) => {
    this.token = token;
    this.expiresAt = token ? Date.now() + duration : -1;

    if (token) {
      axios.defaults.headers.common['Auth-Token'] = token;
    } else {
      delete axios.defaults.headers.common['Auth-Token'];
    }

    if (!token) {
      notifyStore.signal.push(() => {
        notifyStore.signal.setExternalUserId(null);
      });
    }
  };

  @action
  verifyToken = async () => {
    if (!this.authorization) {
      await this.changeToken('');
      return;
    }

    try {
      const {
        data: { employerToken }
      } = await apolloClient.query({
        query: VERIFY_EMPLOYER_QUERY,
        variables: { token: this.authorization }
      });
      const { employer } = employerToken;

      this.profile = employer;
      await this.changeToken(employerToken);
      await this.changeProfile(employer);

      return employer;
    } catch (error) {
      captureException('Verify token', error);
      await this.changeToken('');
    }
  };

  @event(EVENTS.authStore.logout)
  logout() {
    this.changeToken('');
    eventClient.setUserId(null);
    eventClient.setUserProperties(null);
  }

  @event('authStore/changeToken.success')
  async fetchCurrentJob({ payload }) {
    if (payload?.payload) {
      const response = await apolloClient.query({
        query: GET_CURRENT_WORKER_ASSIGNMENT_QUERY
      });
      this.currentJob = response?.data?.currentWorkerAssignment;
    }
  }
}

export const authStore = new AuthStore();
