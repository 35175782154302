import get from 'lodash/get';
import {
  LOAD_COMPANY_SETTINGS,
  SAVE_COMPANY_SETTINGS
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';

export default function(state = initialState[storeConstants.COMPANY], action) {
  switch (action.type) {
    case LOAD_COMPANY_SETTINGS.SUCCESS: {
      const company = get(action, 'payload.company', {});
      return { ...company };
    }
    case SAVE_COMPANY_SETTINGS.SUCCESS: {
      const company = get(action, 'payload.updateCompany', {});
      return { ...company };
    }
    case SAVE_COMPANY_SETTINGS.FAILURE:
    case LOAD_COMPANY_SETTINGS.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
