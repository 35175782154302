import get from 'lodash/get';
import { LOAD_WORKER_CONTRACT_BY_ID } from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.WORKER_CONTRACT],
  action
) {
  switch (action.type) {
    case LOAD_WORKER_CONTRACT_BY_ID.SUCCESS: {
      const workerContract = get(action, 'payload.workerContract', []);
      return workerContract;
    }
    case LOAD_WORKER_CONTRACT_BY_ID.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
