import React from 'react';
import PropTypes from 'prop-types';
import { StyledCheckboxContainer } from './styledCustomCheckbox';
import Checkbox from '@material-ui/core/Checkbox';

const CusCheckbox = ({
  value, className, name, onChange, label
}) => (
  <StyledCheckboxContainer>
    <Checkbox
      name={name}
      className={className}
      checked={value}
      onChange={onChange}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
    />{label}
  </StyledCheckboxContainer>
);

CusCheckbox.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
};

CusCheckbox.defaultProps = {
  label: '',
  className: '',
  name: '',
  checked: false,
  onChange: () => { },

};

export default CusCheckbox;
