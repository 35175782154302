import { Header, Modal as SemanticModal } from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal } from './styledModal';
import Backdrop from '@material-ui/core/Backdrop';

const Modal = ({
  trigger,
  open,
  onClose,
  title,
  children,
  actions,
  closeOnDimmerClick,
  className,
  icon,
  basic,
  defaultOpen,
  closeIcon,
  size
}) => (
  <Backdrop open={open} onClick={(event) => {
    event.stopPropagation();
  }}>
    <StyledModal
      open={open}
      defaultOpen={defaultOpen}
      basic={basic}
      onClose={onClose}
      closeOnDimmerClick={closeOnDimmerClick}
      className={className}
      closeIcon={closeIcon}
      trigger={trigger}
      size={size}
    >
      <Header icon={icon} content={title} />
      <SemanticModal.Content>{children}</SemanticModal.Content>
      {actions && <SemanticModal.Actions>{actions}</SemanticModal.Actions>}
    </StyledModal>
  </Backdrop>
);

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  actions: PropTypes.object,
  icon: PropTypes.string,
  basic: PropTypes.bool,
  closeOnDimmerClick: PropTypes.bool,
  className: PropTypes.string,
  defaultOpen: PropTypes.bool,
  trigger: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  closeIcon: PropTypes.bool,
  size: PropTypes.oneOf([
    'mini',
    'tiny',
    'small',
    'medium',
    'large',
    'fullscreen'
  ])
};

Modal.defaultProps = {
  onClose: () => { },
  actions: null,
  children: null,
  title: null,
  icon: undefined,
  basic: false,
  closeOnDimmerClick: true,
  className: '',
  defaultOpen: undefined,
  open: undefined,
  trigger: undefined,
  closeIcon: false,
  size: 'medium'
};

export default Modal;
