import { graphQueryPromise } from './helpers';
import * as FACILITY_GROUP from 'common/constants/facilityGroupConstants';

const facilityGroupFields = [
  FACILITY_GROUP.ID,
  FACILITY_GROUP.NAME,
  FACILITY_GROUP.DESCRIPTION,
  FACILITY_GROUP.COMPANY_ID,
  FACILITY_GROUP.WORKPLACE_IMAGE_URL
];

export const getAllFacilityGroups = companyId => {
  const facility_group_fields = facilityGroupFields.join(',');

  const query = `{
      facilityGroups(companyId: "${companyId}"){
        ${facility_group_fields}
      }
    }`;

  return graphQueryPromise(query);
};
