import { ONSITE_MANAGER } from 'common/constants/employerConstants';
import {
  revertDetailURLToOrigin,
  revertEditURLToOrigin
} from 'common/helpers/urlHelper';
import getText from 'context/language/getText';
import { urlMap } from 'routes/urlMap';

export const breadcrumbsMapping = url => {
  let breadcrumbs = null;
  url = revertEditURLToOrigin(url);
  url = revertDetailURLToOrigin(url);

  const mapping = {
    [urlMap.HOME]: {
      title_1st: getText('pageTitle.dashboard'),
      restrictedRoles: []
    },
    [urlMap.WORKERS_LIST]: {
      title_1st: getText('navbar.workforce'),
      title_2nd: getText('pageTitle.workersList'),
      restrictedRoles: []
    },
    [urlMap.SHIFT_REQUESTS_LIST]: {
      title_1st: getText('navbar.management'),
      title_2nd: getText('pageTitle.shiftRequestsList'),
      restrictedRoles: []
    },
    [urlMap.DRAFT_SHIFT_REQUESTS]: {
      title_1st: getText('navbar.management'),
      title_2nd: getText('pageTitle.draftShiftRequestsList'),
      restrictedRoles: []
    },
    [urlMap.SHIFTS_LIST]: {
      title_1st: getText('navbar.management'),
      title_2nd: getText('pageTitle.shiftsList'),
      restrictedRoles: []
    },
    [urlMap.TASKS_LIST]: {
      title_1st: getText('navbar.management'),
      title_2nd: getText('pageTitle.tasksList'),
      restrictedRoles: []
    },
    [urlMap.NEW_SHIFT]: {
      title_1st: getText('navbar.shifts'),
      title_2nd: getText('pageTitle.newShift'),
      restrictedRoles: []
    },
    [urlMap.EDIT_SHIFT_REQUEST]: {
      title_1st: getText('navbar.shifts'),
      title_2nd: getText('pageTitle.editShift'),
      restrictedRoles: []
    },
    [urlMap.EMPLOYERS_LIST]: {
      title_1st: getText('navbar.employers'),
      restrictedRoles: [ONSITE_MANAGER]
    },
    [urlMap.COMPANY]: {
      title_1st: getText('navbar.company'),
      restrictedRoles: [ONSITE_MANAGER]
    },
    [urlMap.REPORTS]: {
      title_1st: getText('navbar.reports'),
      title_2nd: getText('pageTitle.jobReports'),
      restrictedRoles: []
    },
    [urlMap.DASHBOARD]: {
      title_1st: getText('navbar.reports'),
      title_2nd: getText('pageTitle.dashboard'),
      restrictedRoles: []
    },
    [urlMap.TEAMS_LIST]: {
      title_1st: getText('navbar.workforce'),
      title_2nd: getText('navbar.teams'),
      restrictedRoles: []
    },
    [urlMap.BADGES]: {
      title_1st: getText('navbar.workforce'),
      title_2nd: getText('navbar.badges'),
      restrictedRoles: []
    },
    [urlMap.ONBOARDING]: {
      title_1st: getText('navbar.workforce'),
      title_2nd: getText('navbar.onboarding'),
      restrictedRoles: [ONSITE_MANAGER]
    },
    [urlMap.INTERVIEW_SCHEDULE]: {
      title_1st: getText('navbar.onboarding'),
      title_2nd: getText('navbar.interviewSchedule'),
      restrictedRoles: [ONSITE_MANAGER]
    },
    [urlMap.LOCATIONS]: {
      title_1st: getText('navbar.locations'),
      restrictedRoles: []
    },
    [urlMap.FACILITIES]: {
      title_1st: getText('navbar.facilities'),
      restrictedRoles: [ONSITE_MANAGER]
    },
    [urlMap.MESSAGES]: {
      title_1st: getText('navbar.messages'),
      restrictedRoles: []
    },
    [urlMap.MESSAGE_DETAIL]: {
      title_1st: getText('navbar.messages'),
      restrictedRoles: []
    },
    [urlMap.PROFILE]: {
      title_1st: getText('navbar.account'),
      title_2nd: getText('navbar.profile'),
      restrictedRoles: []
    },
    [urlMap.HELP_AND_FEEDBACK]: {
      title_1st: getText('navbar.account'),
      title_2nd: getText('navbar.helpAndFeedback'),
      restrictedRoles: []
    },
    [urlMap.JOBS]: {
      title_1st: getText('navbar.management'),
      title_2nd: getText('pageTitle.jobs'),
      restrictedRoles: []
    },
    [urlMap.JOB_DETAIL]: {
      title_1st: getText('navbar.jobs'),
      restrictedRoles: []
    }
  };
  breadcrumbs = mapping[url];
  return breadcrumbs;
};
