import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { EVENTS } from 'common/constants/actionConstants';
import { toJS } from '../mobx.decorator';
import { authStore } from 'stores/auth.store.js';
import { eventBus } from 'mobx-event-bus2';
import ApolloLinkTimeout from 'apollo-link-timeout';
import {
  captureException,
  captureFatalException
} from '../helpers/captureEvent';
import {
  getLocalToken,
  getPoliciesToken
} from 'common/helpers/auth/sessionToken';

const timeout = new ApolloLinkTimeout(60000);

const httpLink = createHttpLink({
  uri: `${process.env.PROXY_GRAPHQL_URL}/graphql`,
  fetchOptions: {}
});

const authLink = setContext((_, { headers = {} }) => {
  const token = toJS(authStore.authorization);
  const customHeaders = {
    Cookie: document.cookie,
    'Auth-Token': getLocalToken(),
    OPUS_POLICIES: getPoliciesToken(),
    GraphiQL_Authorization: process.env.PROXY_GRAPHQL_AUTHENTICATION
  };

  if (token) {
    customHeaders['Auth-Token'] = token;
  }
  return { headers: { ...customHeaders, ...headers } };
});

const errorLink = onError(error => {
  const { graphQLErrors } = error;
  if (graphQLErrors?.[0]?.message === 'Invalid Token') {
    eventBus.post(EVENTS.authStore.logout);
  }

  if (graphQLErrors?.[0]?.extensions?.code === 403) {
    eventBus.post(EVENTS.authStore.logout);
  }

  if (graphQLErrors?.[0]?.extensions?.code === 500) {
    captureFatalException('[API Response Error]', error);
  } else {
    captureException('Apollo Client', error);
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

export const apolloClient = new ApolloClient({
  link: timeout.concat(authLink.concat(errorLink.concat(httpLink))),
  cache: new InMemoryCache({ addTypename: false }),
  defaultOptions: defaultOptions
});
