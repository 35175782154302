// company settings middleware
import {
  LOAD_COMPANY_SETTINGS,
  SAVE_COMPANY_SETTINGS
} from 'redux/actions/actionTypes';
import { getCompanySettings, postCompanySettings } from 'api/companyInfo';
import {
  loadCompanySuccess,
  loadCompanyFailure,
  saveCompanySuccess,
  saveCompanyFailure
} from 'redux/actions';
import { apiPromise } from './apiPromise';

export const companySettings = store => next => action => {
  switch (action.type) {
    case LOAD_COMPANY_SETTINGS.REQUEST: {
      next(action);
      const { id } = action.payload;
      return apiPromise(
        store,
        action,
        getCompanySettings,
        [id],
        loadCompanySuccess,
        loadCompanyFailure
      );
    }
    case SAVE_COMPANY_SETTINGS.REQUEST: {
      const { values } = action.payload;
      next(action);
      return apiPromise(
        store,
        action,
        postCompanySettings,
        [values],
        saveCompanySuccess,
        saveCompanyFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default companySettings;
