import colors from './colors';

const buttons = {
  primary: {
    default: {
      font: colors.name.white,
      background: 'rgba(102,36,132,.9)', //original: '#2536BA'
      border: 'transparent'
    },
    hovered: {
      background: '#2536ba85'
    },
    focused: {
      background: '#2536ba85'
    },
    disabled: {
      font: '#c8c9c7',
      background: '#97999b'
    },
  },
  secondary: {
    default: {
      font: colors.name.white,
      background: colors.name.main_color_4,
      border: 'black'
    },
    hovered: {
      font: colors.name.black,
      background: 'rgba(229, 229, 229, 0.2)'
    },
    focused: {
      background: 'rgba(229, 229, 229, 0.2)'
    },
    disabled: {
      font: '#c8c9c7',
      border: '#c8c9c7',
      background: 'rgba(247, 248, 249, 0.3)'
    },
    inverted: {
      font: 'white',
      border: 'white'
    }
  },
  tertiary: {
    default: {
      font: colors.name.main_color_1,
      background: colors.name.white
    },
    hovered: {
      font: colors.name.main_color_1,
      background: colors.name.sectiondarkgrey
    },
    focused: {
      font: 'white',
      background: '#f78400bf'
    },
    disabled: {
      font: 'white',
      background: '#fbc1a1'
    },
    inverted: {
      font: 'white'
    }
  },
  chevron: {
    default: {
      font: '#4a4a4a',
      border: '#c8c9c7',
      background: '#f7f8f9',
    },
    hovered: {
      background: '#c5c6c7'
    },
    focused: {
      background: '#c5c6c7'
    },
    disabled: {
      font: '#c8c9c7',
      background: '#f7f8f9'
    },
  }
};
  
export default buttons;
  