const main_color_1 = '#22272A'; //Primary
const main_color_4 = '#fb0a00'; //Secondary
const main_color_3 = '#303436'; //For navigation header selected
const main_color_8 = '#ffffff'; //For font color on button
const sectiongrey = '#f4f4f4';
const main_color_5 = '#E8E8E8'; //For background header sub table
const main_color_6 = '#F0F0F0'; //For background row sub table
const main_color_7 = '#EEEFFA'; //For shawdow of table
const main_color_2 = '#e0e0e0'; //For background carousel

const main_color_9 = '#ebebee'; //Black
const main_color_10 = '#A8ABA8'; //Dark Gray


const yellow = '#FFDC00';
const black = '#071013';
const lightestgrey = '#f9f9f9';
const lightgrey = '#f8f8f8';
const grey = '#aaa';
const darkgrey = '#77878B';
const shadowgrey = '#f0f0f0';

const greyborder = 'rgba(0, 0, 0, 0.26)';
const sectiondarkgrey= '#dcddea';
const red = '#DD1C1A';
const green = '#25ba53';
const orange = '#e5cd4b';
const blue = main_color_1; //Blue ('#2536BA';
const white = '#ffffff';
const lightorange = '#f784008a';
const lightblue = '#E8E8E8';
const brightgrey='#c8c9c7';


const name = {
  lightgrey,
  lightestgrey,
  brightgrey,
  grey,
  darkgrey,
  shadowgrey,
  sectiongrey,
  sectiondarkgrey,
  red,
  yellow,
  blue,
  green,
  black,
  white,
  orange,
  lightorange,
  lightblue,
  greyborder,
  main_color_1,
  main_color_2,
  main_color_3,
  main_color_4,
  main_color_5,
  main_color_6,
  main_color_7,
  main_color_8,
  main_color_9,
  main_color_10
};

const panel = {
  footer: {
    foreground: white,
    background: blue,
  },
  header: {
    foreground: main_color_8,
    background: main_color_1,
  },
  navigation: {
    background_selected: main_color_3,
    foreground: main_color_8,
    foreground_selected: main_color_4,
    border: main_color_4
  },
  main: {
    foreground: black,
    background: lightgrey
  },
  error: {
    foreground: '#333',
    background: 'rgba(215, 52, 0, 0.08)'
  },
};

const button = {
  save: '#65d074',
  default: '#dadada',
  primary: yellow,
  secondary: '#49bbff'
};

const status = {
  done: 'rgb(0, 191, 140)',
  active: 'rgb(229, 194, 0)',
  cancelled: 'rgb(255, 0, 0)',
}

const checkbox = {
  checked: '#006fcf',
  disabledBackground: '#f7f8fa',
  unchecked: '#97999b',
  label: '#53565a',
  labelDisabled: '#1a1a1a',
  labelError: '#d73400'
};

const table = {
  headerRowBackground: white,
  rowHeaderBackground: white,
  highlightRowBackground: white,
  evenRowBackground: white,
  oddRowBackground: '#f7f8f9',
  cellBorderColor: white
};

export default {
  name,
  panel,
  button,
  status,
  checkbox,
  table
};
