import get from 'lodash/get';
import {
  LOAD_MESSAGE_BY_ID,
  MARK_ALL_MESSAGES_AS_READ,
  ADD_NEW_MESSAGE
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.MESSAGE_DETAIL],
  action
) {
  switch (action.type) {
    case LOAD_MESSAGE_BY_ID.SUCCESS: {
      const message_detail = get(action, 'payload.messages', []);
      return [...message_detail];
    }
    case ADD_NEW_MESSAGE.FAILURE:
    case MARK_ALL_MESSAGES_AS_READ.FAILURE:
    case LOAD_MESSAGE_BY_ID.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
