import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getUserAuthentication,
  getIsLoading,
  hidePageContent
} from 'redux/selectors';
import { VERIFY_TOKEN_FINGERPRINT } from 'common/constants/fingerprintConstants';
import FullPageLoader from 'components/common/loaders/FullPageLoader';
import TermOfUseModal from './termOfUseModal';
import { Suspense } from 'react';

const PrivateRoute = ({
  component: Component,
  auth,
  isLoading,
  hideContent,
  ...rest
}) => {
  if (isLoading) return <FullPageLoader />;

  return (
    <Route
      {...rest}
      render={props =>
        auth ? (
          <React.Fragment>
            <Suspense fallback={<FullPageLoader />}>
              {!hideContent && <Component {...props} />}
            </Suspense>
            <TermOfUseModal />
          </React.Fragment>
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const fingerprint = VERIFY_TOKEN_FINGERPRINT;

PrivateRoute.propTypes = {
  location: PropTypes.object.isRequired,
  auth: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: getUserAuthentication(state),
  isLoading: getIsLoading(state)[fingerprint] || false,
  hideContent: hidePageContent(state)
});

export default withRouter(connect(mapStateToProps)(PrivateRoute));
