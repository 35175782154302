import get from 'lodash/get';
import {
  LOAD_ALL_WORKER_ONBOARDINGS,
  SEARCH_WORKER_ONBOARDING,
  CANCEL_WORKER_ONBOARDING
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import { UPDATE_CANCEL_WORKER_ONBOARDINGS_STATE_FINGERPRINT } from 'common/constants/fingerprintConstants';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.ONBOARDINGS],
  action
) {
  switch (action.type) {
    case SEARCH_WORKER_ONBOARDING.SUCCESS: {
      const searchOnboardings = get(action, 'payload.workerOnboardings', []);
      return [...searchOnboardings];
    }
    case LOAD_ALL_WORKER_ONBOARDINGS.SUCCESS: {
      const onboardings = get(action, 'payload.workerOnboardings', []);
      return [...onboardings];
    }
    case CANCEL_WORKER_ONBOARDING.SUCCESS: {
      const updatedWorkerOnboarding = get(
        action,
        'payload.cancelWorkerOnboarding',
        {}
      );
      const onboardings = state.map(item => {
        if (item.id === updatedWorkerOnboarding.id) {
          return updatedWorkerOnboarding;
        }
        return item;
      });

      let fingerprint = get(action, 'meta.fingerprint');
      if (fingerprint === UPDATE_CANCEL_WORKER_ONBOARDINGS_STATE_FINGERPRINT) {
        const message = getText('workerModal.updateWorkerSuccess');
        toast.success(message, { className: 'alert alert-success' });
      }

      return [...onboardings];
    }
    case CANCEL_WORKER_ONBOARDING.FAILURE:
    case LOAD_ALL_WORKER_ONBOARDINGS.FAILURE:
    case SEARCH_WORKER_ONBOARDING.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
