// selectors
import { IMAGE_UPLOAD_FINGERPRINT } from 'common/constants/fingerprintConstants';
import { createSelector } from 'reselect';
import * as storeConstants from 'redux/store/constants';
import forOwn from 'lodash/forOwn';
import get from 'lodash/get';
import { extractCompanyId } from 'common/helpers/helpers';

const pickUser = state => state.user;
export const getUser = createSelector([pickUser], user => user);
export const getCompanyAdmin = createSelector(
  [pickUser],
  user => user.companyAdmin
);

export const getCompanyId = createSelector([pickUser], user =>
  extractCompanyId(user)
);

export const getUserAgreement = createSelector([pickUser], user =>
  get(user, 'employer.agreedToTerms')
);

export const getEmployerId = createSelector([pickUser], user =>
  get(user, 'employer.id')
);

export const getTermsUrl = createSelector([pickUser], user =>
  get(user, 'employer.company.companyConfig.employerTermsOfService.url')
);

export const hidePageContent = createSelector(
  getUserAgreement,
  getTermsUrl,
  (isAgree, termUrl) => !isAgree && termUrl
);

const pickAuth = state =>
  state[storeConstants.AUTH] && state[storeConstants.AUTH].authenticated;
export const getUserAuthentication = createSelector([pickAuth], authd =>
  authd === undefined ? false : authd
);

const pickErrors = state => state.errors;
export const getErrors = createSelector([pickErrors], errors => errors || {});

const pickSuccesses = state => state.successes;
export const getSuccesses = createSelector(
  [pickSuccesses],
  successes => successes || {}
);

const pickLoadMore = state => state.load_more;
export const getLoadMore = createSelector(
  [pickLoadMore],
  load_more => load_more || {}
);

const pickLoadingState = state => state.loading;
export const getIsLoading = createSelector(
  [pickLoadingState],
  loading => loading || {}
);

const getImageUploadState = loadingState => {
  let isAnyImageUploading = false;
  forOwn(loadingState, (value, key) => {
    if (key.indexOf(IMAGE_UPLOAD_FINGERPRINT) > -1 && value === true)
      isAnyImageUploading = true;
  });

  return isAnyImageUploading;
};
export const getIsAnyImageUploading = createSelector(
  [getIsLoading],
  getImageUploadState || false
);

const pickTheme = state => state[storeConstants.THEME] || {};
export const getTheme = createSelector([pickTheme], theme => theme);

const pickUploadedImages = state => state.imageUpload;
export const getUploadedImages = createSelector(
  [pickUploadedImages],
  images => images || {}
);

const pickCurrentUser = state => state.user;
export const getCurrentUser = createSelector(
  [pickCurrentUser],
  user => user || {}
);

// const pickFeatureFlags = state => state[storeConstants.FEATURES];
// export const getFeatureFlags = createSelector([pickFeatureFlags], flags => flags || {});

const pickPagination = state => state[storeConstants.PAGING];
export const getPagination = createSelector([pickPagination], cfg => cfg || {});

const pickOnboardings = state => state.onboardings;
export const getOnboardings = createSelector([pickOnboardings], onboardings =>
  onboardings === undefined ? [] : onboardings
);

const pickWorkers = state => state.workers;
export const getWorkers = createSelector([pickWorkers], workers =>
  workers === undefined ? [] : workers
);

const pickEmployers = state => state.employers;
export const getEmployers = createSelector([pickEmployers], employers =>
  employers === undefined ? [] : employers
);

const pickShifts = state => state.shifts;
export const getShifts = createSelector([pickShifts], shifts =>
  shifts === undefined ? [] : shifts
);

const pickShiftRequests = state => state.shift_requests;
export const getShiftRequests = createSelector(
  [pickShiftRequests],
  shift_requests => (shift_requests === undefined ? [] : shift_requests)
);

const pickRequestTasks = state => state.request_tasks;
export const getRequestTasks = createSelector(
  [pickRequestTasks],
  request_tasks => (request_tasks === undefined ? [] : request_tasks)
);

const pickShiftCategories = state => state.shift_categories;
export const getShiftCategories = createSelector(
  [pickShiftCategories],
  shift_categories => (shift_categories === undefined ? [] : shift_categories)
);

const pickBadges = state => state.badges;
export const getBadges = createSelector([pickBadges], badges =>
  badges === undefined ? [] : badges
);

const pickShiftRequest = state => state.shift_request;
export const getShiftRequest = createSelector(
  [pickShiftRequest],
  shift_request => (shift_request === undefined ? {} : shift_request)
);

const pickJobs = state => state.jobs;
export const getJobs = createSelector([pickJobs], jobs =>
  jobs === undefined ? [] : jobs
);

const pickActiveJobs = state => state.active_jobs;
export const getActiveJobs = createSelector([pickActiveJobs], active_jobs =>
  active_jobs === undefined ? [] : active_jobs
);

const pickWorkerLocations = state => state.worker_locations;
export const getWorkerLocations = createSelector(
  [pickWorkerLocations],
  worker_locations => (worker_locations === undefined ? [] : worker_locations)
);

const pickCompanySettings = state => state.company;
export const getCompanySettings = createSelector(
  [pickCompanySettings],
  company => (company === undefined ? {} : company)
);

const pickImportFiles = state => state.import_files;
export const getImportFiles = createSelector([pickImportFiles], import_files =>
  import_files === undefined ? {} : import_files
);

const pickTeams = state => state.teams;
export const getTeams = createSelector([pickTeams], teams =>
  teams === undefined ? [] : teams
);

const pickLocations = state => state.locations;
export const getLocations = createSelector([pickLocations], locations =>
  locations === undefined ? [] : locations
);

const pickFacilities = state => state.facilities;
export const getFacilities = createSelector([pickFacilities], facilities =>
  facilities === undefined ? [] : facilities
);

const pickFacilityGroups = state => state.facility_groups;
export const getFacilityGroups = createSelector(
  [pickFacilityGroups],
  facility_groups => (facility_groups === undefined ? [] : facility_groups)
);

const pickReports = state => state.reports;
export const getReports = createSelector([pickReports], reports =>
  reports === undefined ? [] : reports
);

const pickMessageThreads = state => state.message_threads;
export const getMessageThreads = createSelector(
  [pickMessageThreads],
  message_threads => (message_threads === undefined ? [] : message_threads)
);

const pickMessageDetail = state => state.message_detail;
export const getMessageDetail = createSelector(
  [pickMessageDetail],
  message_detail => (message_detail === undefined ? [] : message_detail)
);

const pickUpcomingSchedules = state => state.upcoming_schedules;
export const getUpcomingSchedules = createSelector(
  [pickUpcomingSchedules],
  upcoming_schedules =>
    upcoming_schedules === undefined ? [] : upcoming_schedules
);

const pickPastSchedules = state => state.past_schedules;
export const getPastSchedules = createSelector(
  [pickPastSchedules],
  past_schedules => (past_schedules === undefined ? [] : past_schedules)
);

const pickWorkerContract = state => state.worker_contract;
export const getWorkerContract = createSelector(
  [pickWorkerContract],
  worker_contract => (worker_contract === undefined ? {} : worker_contract)
);
