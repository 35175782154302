import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Breadcrumbs from 'modules/breadcrumbs/breadcrumbs';
import Header from 'modules/header/header';
import ContentWrapper from 'modules/contentWrapper/contentWrapper';
import HeaderSignout from 'modules/headerSignout/headerSignout';
import SideBar from 'modules/sidebar/index.js';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { urlMap } from 'routes/urlMap';
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },

  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },

  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
    width: 'calc(100% - 250px)'
  }
}));

const Layout = ({
  showHeader,
  showFooter,
  showBreadcrumbs,
  showSideBar,
  showAccountInfo,
  showHeaderSignout,
  ...props
}) => {
  const classes = useStyles();

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  let renderFullScreen = false;
  const renderFullOne =
    props.location.pathname.startsWith(urlMap.SIGNIN) ||
    props.location.pathname.startsWith(urlMap.FORGOT_PASSWORD) ||
    props.location.pathname.startsWith(urlMap.RESET_PASSWORD) ||
    props.location.pathname.startsWith(urlMap.SIGNUP) ||
    props.location.pathname.startsWith(urlMap.HIREPRIVY_APP_INFO) ||
    props.location.pathname.startsWith(urlMap.OPUS_APP_INFO);

  const renderFullTwo = props.location.pathname.startsWith(urlMap.SIGNOUT);

  if (
    props.location.pathname.startsWith(urlMap.SIGNIN) ||
    props.location.pathname.startsWith(urlMap.FORGOT_PASSWORD) ||
    props.location.pathname.startsWith(urlMap.RESET_PASSWORD) ||
    props.location.pathname.startsWith(urlMap.SIGNUP) ||
    props.location.pathname.startsWith(urlMap.HIREPRIVY_APP_INFO) ||
    props.location.pathname.startsWith(urlMap.OPUS_APP_INFO)
  ) {
    showHeader = false;
    showFooter = false;
    showBreadcrumbs = false;
    showSideBar = false;
    showAccountInfo = false;
    showHeaderSignout = false;
    renderFullScreen = true;
  } else if (props.location.pathname.startsWith(urlMap.SIGNOUT)) {
    showAccountInfo = false;
    showBreadcrumbs = false;
    showHeaderSignout = true;
    showSideBar = false;
    renderFullScreen = true;
  } else {
    renderFullScreen = false;
  }

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  return renderFullOne ? (
    <div className={classes.root}>
      <div className={classes.container}>
        <main className={classes.content}>
          <ContentWrapper renderFullScreen={renderFullScreen} />
        </main>
      </div>
    </div>
  ) : renderFullTwo ? (
    <div className={classes.root}>
      <div className={classes.container}>
        <main className={classes.content}>
          <HeaderSignout
            onOpenNavBarMobile={handleNavBarMobileOpen}
            {...props}
          />
          <ContentWrapper renderFullScreen={renderFullScreen} />
        </main>
      </div>
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.container}>
        <SideBar
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Header onOpenNavBarMobile={handleNavBarMobileOpen} {...props} />
          <Breadcrumbs renderFullScreen={renderFullScreen} />
          <ContentWrapper renderFullScreen={renderFullScreen} />
        </main>
      </div>
    </div>
  );
};

Layout.propTypes = {
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showBreadcrumbs: PropTypes.bool,
  showAccountInfo: PropTypes.bool
};

Layout.defaultProps = {
  showHeader: true,
  showFooter: true,
  showBreadcrumbs: true,
  showAccountInfo: true
};

export default withTheme(withRouter(Layout));
