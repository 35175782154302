import styled from 'styled-components';

export const StyledSignupContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.name.white};
  min-height: 100%;
  width: 100vw;

  .signup-header {
    text-align: center;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.colors.name.main_color_1};
  }
  .password-input-ctrl {
    .MuiOutlinedInput-root {
      border-radius: 24px;
      height: 40px;
    }
    fieldset {
      border-radius: 15px;
      border-color: ${({ theme }) => theme.colors.name.main_color_9};
    }
    &.MuiFormControl-marginNormal {
      margin-top: 0;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      background-color: ${({ theme }) => theme.colors.name.lightgrey};
    }
    .MuiOutlinedInput-input {
      padding-top: 10.5px;
      padding-bottom: 10.5px;
    }
  }
`;
