import { LOAD_ALL_ACTIVE_JOBS } from 'redux/actions/actionTypes';
import { getAllActiveJobs } from 'api/jobs';
import {
  loadAllActiveJobsSuccess,
  loadAllActiveJobsFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const activeJobs = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_ACTIVE_JOBS.REQUEST: {
      next(action);
      const { companyId, locationId } = action.payload;
      return apiPromise(
        store,
        action,
        getAllActiveJobs,
        [companyId, locationId],
        loadAllActiveJobsSuccess,
        loadAllActiveJobsFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default activeJobs;
