export const ID = 'id';
export const NAME= 'name';
export const ASSIGNED_FOR_TEAM = 'assignedForTeam';
export const ASSIGNED_WORKERS = 'assignedWorkers';
export const ASSIGNED_WORKERS_IDS = 'assignedWorkerIds';
export const COMPANY_ID = 'companyId';
export const COMPLETION_PERCENTAGE = 'completionPercentage';
export const CREATED_AT= 'createdAt';
export const UPDATED_AT= 'updatedAt';
export const DESCRIPTION = 'description';
export const EMPLOYER_ID = 'employerId';
export const FACILITY_ID = 'facilityId';
export const FACILITY_GROUP_ID = 'facilityGroupId';
export const SHIFT_REQUEST_ID = 'shiftRequestId';
export const STATUS_COLOR = 'statusColor';
export const TASK_PROGRESSES_COUNT = 'taskProgressesCount';
export const SHIFT_REQUEST_NAME = 'shiftRequestName';
export const PERMITTED_EVENTS = 'permittedEvents';


export const FOR_ALL_WORKERS = 'forAllWorkers';
