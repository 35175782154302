import React, { useState, useEffect } from 'react';
import { StyledSignupContainer } from 'components/auth/styledSignup';
import WorkerForm from 'pages/workersList/workerForm';
import { handleLoadReCaptcha } from './helpers.js';

const Signup = ({ ...props }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    handleLoadReCaptcha(setData);
  }, []);

  return (
    <StyledSignupContainer>
      <WorkerForm forSignup={true} companyConfig={data} {...props} />
    </StyledSignupContainer>
  );
};

export default Signup;
