export const EVENTS = {
  authStore: {
    logout: 'authStore/logout'
  },
  notifyStore: {
    fireError: 'notifyStore/fireError'
  }
};

export const ACTIONS = {
  appStore: {
    init: 'appStore/init'
  },

  authStore: {
    changeToken: 'authStore/changeToken'
  },

  licenseStepStore: {
    handleSubmitLicense: 'licenseStepStore/handleSubmitLicense'
  },

  experienceStepStore: {
    handleSubmitExperiences: 'experienceStepStore/handleSubmitExperiences'
  },

  referenceStepStore: {
    handleSubmitReferences: 'referenceStepStore/handleSubmitReferences'
  }
};
