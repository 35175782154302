export const FILLED = 'filled';
export const ACCEPTED = 'accepted';
export const STARTED = 'started';
export const PAUSED = 'paused';
export const COMPLETED = 'completed';
export const APPROVED = 'approved';
export const CANCELLED = 'cancelled';
export const LATE = 'late';
export const WITHDRAWN = 'withdrawn';
export const NO_SHOW = 'no_show';
export const DRAFT = 'draft';

export const DONE_ST_CL = 'done_status';
export const ACTIVE_ST_CL = 'active_status';
export const CANCELLED_ST_CL = 'cancelled_status';

//Shift Fields
export const ID = 'id';
export const APPROVED_AT = 'approvedAt';
export const BREAK_TIME_DURATION = 'breakTimeDuration';
export const BREAK_TIME_NUMBER = 'breakTimeNumber';
export const CREATED_AT = 'createdAt';
export const CANCELLED_AT = 'cancelledAt';
export const DURATION = 'duration';
export const JOBS_NUMBER = 'jobsNumber';
export const FACILITY_ID = 'facilityId';
export const LOCATION_ID = 'locationId';
export const NOTE = 'note';
export const STATE = 'state';
export const PAY_AMOUNT = 'payAmount';
export const MAXIMUM_PAY_AMOUNT = 'maximumPayAmount';
export const PAYMENT_TYPE = 'paymentType';
export const SHIFT_REQUEST_ID = 'shiftRequestId';
export const START_TIME = 'startTime';
export const START_DATE = 'startDate';
export const END_TIME = 'endTime';
export const JOBS = 'jobs';
export const TIME_ZONE = 'timezone';
export const FILL_RATE = 'fillRate';
export const PERMITTED_EVENTS = 'permittedEvents';
export const IS_SINGLE = 'isSingle';

export const DESTROY = 'destroy';
export const LOCATION = 'location';

export const IS_PAYMENT_IN_RANGE = 'isPaymentInRange';
export const MULTI_DAY = 'multiDay';
export const REPEAT_WEEKLY_ON = 'repeatWeeklyOn';
export const END_AFTER_WEEKS = 'endAfterWeeks';
export const END_AFTER_OCCURRENCES = 'endAfterOccurrences';

export const STATUS_CLASSES = {
  [STARTED]: ACTIVE_ST_CL,
  [ACCEPTED]: ACTIVE_ST_CL,
  [FILLED]: ACTIVE_ST_CL,
  [APPROVED]: DONE_ST_CL,
  [COMPLETED]: DONE_ST_CL,
  [PAUSED]: CANCELLED_ST_CL,
  [LATE]: CANCELLED_ST_CL,
  [CANCELLED]: CANCELLED_ST_CL,
  [WITHDRAWN]: CANCELLED_ST_CL,
  [NO_SHOW]: CANCELLED_ST_CL
};

//Shift State
export const POSTED = 'posted';
export const INVITE_POSTED = 'invite_posted';

//ShiftScopeEnum
export const ACTIVE = 'active';
export const DONE = 'done';
export const ARCHIVED = 'archived';
