import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const WrapperSidebar = styled.div`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1 0 auto;
  z-index: 5;
  position: fixed;
  top: 0px;
  outline: 0px;
  left: 0px;
  border-right: 0px solid rgb(230, 232, 240);
  color: rgb(255, 255, 255);
  width: 250px;
  background-color: ${({ theme }) => theme.colors.name.main_color_1};
`;

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto'
  },
  content: {
    padding: 16
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    padding: 20
  },

  name: {
    marginTop: 10
  },
  divider: {
    marginTop: 20
  },
  navigation: {
    marginTop: 20
  }
}));
