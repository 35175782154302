import React from 'react';

import { MARK_AS_READ_NOTIFICATION } from 'common/apollo/mutations/notification.mutation.js';
import {  useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { calculateDuration } from 'components/common/appNotification/notifications.helper';
import { AvatarWrapper, ContentMessage, CreatedAt, DotStyle, ListNotitication } from './notification.style';




const NotificationItem = ({ notification, setAppNotification ,refetchFetchMore, refetchTotal  }) => {
  const history = useHistory();
  const { id, formattedMessage, url, isRead, createdAt, sender } = notification || {};
  const { avatarUrl, firstName, lastName } = sender || {};
  const [markAsRead] = useMutation(MARK_AS_READ_NOTIFICATION);

  const onClickNotification = async () => {
    await setAppNotification(false);
    await markAsRead({ variables: { markNotificationAsReadId: id } });
    await history.push(`/${url}`);
    await refetchTotal()
    await refetchFetchMore()

  };

  return (
    <ListNotitication onClick={onClickNotification} className="media">
      <React.Fragment>
        <AvatarWrapper round={true} src={avatarUrl} alt={`${firstName} ${lastName}`} name={`${firstName} ${lastName}`}
                       size="40" />
        <div className="media-body align-self-center">

          <ContentMessage
            className="sub-heading mb-0">{formattedMessage}
          </ContentMessage>
          <span className="meta-date">
          <CreatedAt>{calculateDuration(createdAt)}</CreatedAt>
        </span>
        </div>
      </React.Fragment>
      <React.Fragment>
        {
          !isRead && (
            <DotStyle className="dot"></DotStyle>
          )
        }
      </React.Fragment>
    </ListNotitication>


  );
};

export default NotificationItem;
