import styled from 'styled-components';
import { Modal as SemanticModal } from 'semantic-ui-react';

export const StyledModal = styled(SemanticModal)`
  &.ui.modal .ui.header {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.name.main_color_1};
    margin: 0px 21px;
    padding-left: 0px;
    font-weight: bold;
  }
  &.ui.modal {
    display: flex;
    max-height: calc(100% - 64px);
    flex-direction: column;
    margin: 32px;
    position: relative;
    overflow-y: auto;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
      0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  &.ui.inverted.dimmer {
    z-index: 1500;
  }
`;

export default StyledModal;
