import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Checkbox from 'components/common/customCheckbox/checkbox';

const CheckboxInput = ({
  label,
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            {...field} {...props}
          />
        }
        label={label}
        margin="normal"
      />
      {
        touched[field.name] &&
                errors[field.name] && <div className="error">{errors[field.name]}</div>
      }
    </div >
  )
};

export default CheckboxInput;

