import getText from 'context/language/getText';

export const weekdays = [
  getText('days.sunday'),
  getText('days.monday'),
  getText('days.tuesday'),
  getText('days.wednesday'),
  getText('days.thursday'),
  getText('days.friday'),
  getText('days.saturday')
];

export const ONE_DAY = 24 * 60 * 60 * 1000; // ms
export default weekdays;
