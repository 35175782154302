import { gql } from '@apollo/client';

// export const SIGNIN_EMPLOYER_MUTATION = gql`
//   mutation {
//     signInEmployer(
//       $signInValue
//     ) {
//       authToken
//       employer {
//         id
//         lastName
//         phone
//         email
//         lastSignInAt
//         agreedToTerms
//         employerFacilities {
//           facility {
//             id
//           }
//         }
//         company {
//           id
//           name
//           companyConfig {
//             employerTermsOfService {
//               id
//             }
//             beaconConfig
//           }
//         }
//       }
//     }
//   }
// `;


export const CREATE_DIRECT_UPLOAD = gql`
mutation CreateDirectUpload($filename: String!, $byteSize: Int!, $checksum: String!, $contentType: String!) {
  createDirectUpload(filename: $filename, byteSize: $byteSize, checksum: $checksum, contentType: $contentType) {
    blobId
    headers
    signedBlobId
    url
  }
}
`  