import React from 'react';
import PropTypes from 'prop-types';
import { Input as SemanticInput } from 'semantic-ui-react';
import ImageCropper from 'components/common/images/ImageCropper';
import isValidUrl from 'common/helpers/isValidUrl';
import InlineError from 'components/common/form/inputs/inlineInputError';
import {
  StyledInputLabel,
  StyledImagePreview,
  StyledModal,
  StyledCropUploadInputContainer,
  StyledError
} from 'components/common/form/inputs/ImageCropUploadInput/styledImageCropUploadInput';
import { CancelButton, SubmitButton } from 'components/common/buttons/Button';
import { StyledDialogActionsContainer } from 'pages/styledPage';
import getText from 'context/language/getText';
import { FormHelperText } from '@material-ui/core';
import { observer } from 'mobx-react';
import classnames from 'classnames';

@observer
class ImageCropUploadInputBlob extends React.Component {
  constructor(props) {
    super(props);
    this.loadingFetch = this.props.imageUploadStore.handleCreateCredentials;
    this.state = {
      fileUrl: '',
      isUploadingImage: false,
      modalOpen: false,
      image: null,
      invalidFileSize: false,
      crop: {
        x: 0,
        y: 0,
        width: 100,
        aspect: 1
      },
      dataUpload: {}
    };
    this.canvas = document.createElement('canvas');
  }

  state = {
    fileUrl: '',
    modalOpen: false,
    image: null,
    invalidFileSize: false,
    crop: {
      x: 0,
      y: 0,
      width: 100,
      aspect: 1
    }
  };

  onCropComplete = async (crop, pixelCrop) => {
    const croppedImageUrl = await this.getCroppedImg(
      this.imageRef,
      pixelCrop,
      'newFile.jpeg'
    );
    this.setState({ croppedImageUrl });
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.setState({
          image: reader.result,
          crop: {
            x: 0,
            y: 0,
            width: 100,
            aspect: this.props.aspect
          }
        });
      });

      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append('file', e.target.files[0]);

      const invalidFileSize = e.target.files[0].size >= 2 * 1000 * 1000;
      if (invalidFileSize) {
        this.setState({
          invalidFileSize: true,
          modalOpen: false
        });
      } else {
        this.setState({
          imageFormData: formData,
          imageFile: e.target.files[0],
          invalidFileSize: false,
          modalOpen: true,
          crop: {}
        });
      }
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  handleSubmitCrop = async () => {
    const { form, imageUploadStore, signedBlobId } = this.props;
    const { handleCreateCredentials, handleDirectUpload } = imageUploadStore;
    const { imageFile, fileUrl } = this.state;

    new Promise((resolve, reject) => {
      if (this?.props?.setIsLoadingUploadImage) {
        this.props.setIsLoadingUploadImage(true);
      }

      this.canvas.toBlob(file => {
        this.setState({
          isUploadingImage: true
        });
        const fileData = new File([file], 'fileName.jpg', {
          type: 'image/jpeg'
        });

        this.setState({ fileUrl: fileData });
        window.URL.revokeObjectURL(fileUrl);
        handleCreateCredentials(fileData).then(obj => {
          this.setState({ dataUpload: obj });
          handleDirectUpload(obj)
            .then(obj => {
              this.props.setIsLoadingUploadImage(false);
              return obj;
            })
            .then(obj => {
              const img = new Image();
              img.onload = () => {
                form.setFieldValue(signedBlobId, obj?.credentials?.signedBlobId);
              };
              img.src = URL.createObjectURL(imageFile);
            });
        });
        resolve(fileUrl);
      }, 'image/jpeg');
    });

    this.setState({
      modalOpen: false,
      image: null
    });
  };

  handleClose = () => {
    const { field, form, aspect } = this.props;
    const { name } = field;
    const { setFieldValue } = form;

    setFieldValue(name, ''); // or previous name if available in props
    this.setState({
      image: null,
      croppedImageUrl: null,
      crop: {
        x: 0,
        y: 0,
        width: 100,
        aspect
      },
      modalOpen: false
    });
  };

  getCroppedImg = (image, pixelCrop, fileName) => {
    this.canvas.width = pixelCrop.width;
    this.canvas.height = pixelCrop.height;
    const ctx = this.canvas.getContext('2d');
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    //As Base64 string
    const base64Image = this.canvas.toDataURL('image/png');

    return base64Image;
  };

  handleClick = e => {
    e.target.value = null;
  };

  render() {
    const { field, id, label, modalTitle, initialImage, error } = this.props;

    const { value } = field;
    // the value we pass to the input must be a string or undefined
    const sanitizedValue =
      value === undefined || typeof value === 'string' ? value : '';
    const {
      modalOpen,
      image,
      crop,
      croppedImageUrl,
      invalidFileSize,
      isUploadingImage
    } = this.state;
    const showCroppedImage = !!croppedImageUrl;
    const showExistingImage = !!initialImage && !showCroppedImage;
    const existingSource = isValidUrl(initialImage)
      ? initialImage
      : `${process.env.RAILS_HOST}${initialImage}`;

    return (
      <StyledCropUploadInputContainer>
        <div className='image-crop-upload-input row'>
          <div className='col-md-12'>
            <div className='row'>
              {label && (
                <StyledInputLabel className='image-field-label label col-md-3'>
                  {label}
                </StyledInputLabel>
              )}
            </div>
            <div className='row'>
              <SemanticInput
                className={classnames('input', 'col-md-12', { error: !!error })}
                type='file'
                onChange={this.onSelectFile}
                onClick={this.handleClick}
                id={id}
                accept='.jpg,.jpeg,.png'
              />
              <input type='hidden' {...field} value={sanitizedValue} />
            </div>
            <div className='row'>
              <div className='col-md-12' style={{ marginLeft: '14px' }}>
                {error && <FormHelperText error={true}>{error}</FormHelperText>}
              </div>
            </div>
            <div className='row'>
              <p className='col-md-12 image-requirement'>
                {getText('uploadFile.type')}
              </p>
              <InlineError className='error col-md-12' name={field.name} />
            </div>
            <div className='row'>
              {!invalidFileSize && (
                <StyledImagePreview className='preview col-md-12'>
                  {showExistingImage && (
                    <img
                      alt='Crop'
                      className='preview-image'
                      src={existingSource}
                    />
                  )}
                  {showCroppedImage && (
                    <img
                      alt='Crop'
                      className='preview-image'
                      src={croppedImageUrl}
                    />
                  )}
                </StyledImagePreview>
              )}
            </div>
            <div className='row'>
              {invalidFileSize && (
                <StyledError className='col-md-12'>
                  {getText('uploadFile.maxLimit')}
                </StyledError>
              )}
            </div>
            <StyledModal
              className='image-upload-modal'
              open={modalOpen}
              onClose={this.handleClose}
              closeOnDimmerClick={false}
              size='small'
              title={modalTitle}
              actions={
                <StyledDialogActionsContainer>
                  <CancelButton onClick={this.handleClose}>
                    {getText('buttons.cancel')}
                  </CancelButton>
                  <SubmitButton
                    disabled={isUploadingImage}
                    onClick={this.handleSubmitCrop}
                    type='submit'
                    className='crop-image'
                  >
                    {isUploadingImage ? 'Uploading' : getText('buttons.ok')}
                  </SubmitButton>
                </StyledDialogActionsContainer>
              }
            >
              {image && (
                <ImageCropper
                  src={image}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onChange={this.onCropChange}
                  onComplete={this.onCropComplete}
                />
              )}
            </StyledModal>
          </div>
        </div>
      </StyledCropUploadInputContainer>
    );
  }
}

ImageCropUploadInputBlob.propTypes = {
  onSubmitCrop: PropTypes.func.isRequired,
  initialImage: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  uploadedImages: PropTypes.object,
  aspect: PropTypes.number,
  error: PropTypes.number
};

ImageCropUploadInputBlob.defaultProps = {
  initialImage: undefined,
  label: null,
  value: undefined,
  uploadedImages: {},
  aspect: 1
};

export default ImageCropUploadInputBlob;
