export const urlMap = {
  HOME: '/',
  SIGNIN: '/signin',
  SIGNOUT: '/signout',
  RESET_PASSWORD: '/resetpassword',
  FORGOT_PASSWORD: '/forgotpassword',
  REPORTS: '/reports/jobs-reports',
  MESSAGES: '/messages',
  LOCATIONS: '/locations',
  BADGES: '/workforce/badges',
  FACILITIES: '/facilities',
  SHIFT_REQUESTS_LIST: '/management/shiftrequestslist',
  DRAFT_SHIFT_REQUESTS: '/management/draftshiftrequests',
  SHIFTS_LIST: '/management/shiftslist',
  WORKERS_LIST: '/workforce/workerslist',
  WORKERS: '/workers',
  TEAMS_LIST: '/workforce/teamslist',
  EMPLOYERS_LIST: '/employerslist',
  TASKS_LIST: '/management/taskslist',
  NEW_SHIFT: '/newshift',
  EDIT_SHIFT_REQUEST: '/shiftrequest/:id/edit',
  PROFILE: '/account/profile',
  ONBOARDING: '/workforce/onboarding',
  INTERVIEW_SCHEDULE: '/interviewschedule',
  COMPANY: '/company',
  MESSAGE_DETAIL: '/messages/detail/:jobId/:shiftName',
  MESSAGE_THREAD: '/message_threads/:id',
  WORKER_CONTRACT: '/worker_contracts/:id',
  IMPORT_WORKER_FILES: '/import_worker_files/:id',
  IMPORT_EMPLOYER_FILES: '/import_employer_files/:id',
  SHIFT_DETAIL: '/shifts/:id',
  JOBS: '/management/jobs',
  JOB_DETAIL: '/jobs/:id',
  HELP_AND_FEEDBACK: '/account/helpandfeedback',
  SIGNUP: '/signup',
  HIREPRIVY_APP_INFO: '/hireprivyapp',
  OPUS_APP_INFO: '/opusapp',
  DASHBOARD: '/reports/dashboard',
  DASHBOARD2: '/dashboard2',
  MESSAGE_DETAIL_URL: '/messages/detail',
  MANAGEMENT: '/management',
  LIST_REPORTS: '/reports',
  WORKFORCE: '/workforce',
  ACCOUNT: '/account'
};

export const urlConstructor = (urlMapString, params) => {
  try {
    let url = urlMapString;
    const keys = Object.keys(params);
    keys.forEach(key => {
      const paramValue = params[key];
      if (!paramValue) {
        throw new Error(
          `urlConstructionError @ ${urlMapString}, ${key}: ${paramValue}`
        );
      }
      const regex = new RegExp(`[:]${key}`, 'gi');
      url = url.replace(regex, paramValue);
    });
    return url;
  } catch (error) {
    return '/404';
  }
};
