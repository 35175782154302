import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageController from 'routes';
import { getErrors, getIsLoading } from 'redux/selectors';
import { PAGE_ERROR } from 'common/constants/errorConstants';
import Error from 'pages/errorPage/errorPage';

import StyledContentContainer from 'modules/contentArea/styledContentContainer';
import { withRouter } from 'react-router-dom';
import { StyledPage } from 'pages/styledPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FullPageLoader from 'components/common/loaders/FullPageLoader';

const Content = ({ showContent, errors, loading, ...props }) => {
  const hasPageError = Array.isArray(errors[PAGE_ERROR]);
  return (
    <StyledContentContainer className="container-fluid">
      <StyledPage className="col-sm-12">
        {showContent && !hasPageError && !loading && (
          <PageController {...props} />
        )}
        {hasPageError && <Error errors={errors[PAGE_ERROR]} />}
        <ToastContainer hideProgressBar={false} newestOnTop={true} />
        {loading && <FullPageLoader isFullPageLoad={true} />}
      </StyledPage>
    </StyledContentContainer>
  );
};

Content.propTypes = {
  errors: PropTypes.object,
  showContent: PropTypes.bool
};

Content.defaultProps = {
  errors: [],
  showContent: true
};

function mapStateToProps(state) {
  return {
    errors: getErrors(state),
    loading: getIsLoading(state)?.['auth.verifyToken']
  };
}

export default withRouter(connect(mapStateToProps)(Content));
