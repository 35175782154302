// user login middleware

import { LOAD_CURRENT_USER_INFO } from 'redux/actions/actionTypes';
import { getCurrentUserInformation } from 'api/currentUser';
import {
  getCurrentUserInfoSuccess,
  getCurrentUserInfoFailure
} from 'redux/actions';
import { apiPromise } from './apiPromise';

export const currentUser = store => next => action => {
  switch (action.type) {
    case LOAD_CURRENT_USER_INFO.REQUEST: {
      next(action);
      return apiPromise(
        store,
        action,
        getCurrentUserInformation,
        [],
        getCurrentUserInfoSuccess,
        getCurrentUserInfoFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default currentUser;
