import {
  LOAD_ALL_BADGES,
  ADD_NEW_BADGE,
  UPDATE_BADGE
} from 'redux/actions/actionTypes';
import { getAllBadges, addNewBadge, updateBadge } from 'api/badges';
import {
  loadAllBadgesSuccess,
  loadAllBadgesFailure,
  addNewBadgeSuccess,
  addNewBadgeFailure,
  updateBadgeSuccess,
  updateBadgeFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const badges = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_BADGES.REQUEST: {
      next(action);
      const { companyId, offset, limit, search, sort, filter } = action.payload;
      return apiPromise(
        store,
        action,
        getAllBadges,
        [companyId, offset, limit, search, sort, filter],
        loadAllBadgesSuccess,
        loadAllBadgesFailure
      );
    }
    case ADD_NEW_BADGE.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewBadge,
        [values],
        addNewBadgeSuccess,
        addNewBadgeFailure
      );
    }
    case UPDATE_BADGE.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateBadge,
        [values],
        updateBadgeSuccess,
        updateBadgeFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default badges;
