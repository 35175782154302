export const COMPANY_ID = 'id';
export const COMPANY_NAME = 'name';
export const COMPANY_DESCRIPTION = 'description';
export const COMPANY_ADDRESS = 'address';
export const COMPANY_CITY = 'city';
export const COMPANY_ADDRESS_STATE = 'addressState';
export const COMPANY_COUNTRY = 'country';
export const COMPANY_ZIP = 'zip';
export const COMPANY_LOGO_URL = 'logoUrl';
export const COMPANY_MAIN_CONTACT_NAME = 'mainContactName';
export const COMPANY_MAIN_CONTACT_EMAIL = 'mainContactEmail';
export const COMPANY_PHONE = 'phone';
export const COMPANY_LOGO_IMAGE_BASE64 = 'logoImageBase64';
export const COMPANY_LOGO_FILE_NAME = 'logoImageFileName';
export const COMPANY_SIGNED_BLOB = 'logoSignedBlobId';
