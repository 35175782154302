import get from 'lodash/get';
import { LOAD_WORKER_LOCATIONS } from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';

export default function(
  state = initialState[storeConstants.WORKER_LOCATIONS],
  action
) {
  switch (action.type) {
    case LOAD_WORKER_LOCATIONS.SUCCESS: {
      const workerLocations = get(action, 'payload.workerCurrentLocations', []);
      return [...workerLocations];
    }
    default:
      return state;
  }
}
