import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import background_500_error from 'assets/bg_error_500.png';
import img_error from 'assets/img_error.png';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';

export const StyledPage = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding: 0px !important;
  margin: 0px !important;

  *:focus {
    outline: none;
  }
  .ui.button {
    border-radius: 15px;
  }

  //================ orange button ===========//
  .ui.orange.button,
  .ui.orange.buttons .button {
    background-color: ${({ theme }) => theme.colors.name.main_color_4};
    font-weight: normal;
    &:active {
      background-color: ${({ theme }) => theme.colors.name.main_color_4};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.name.lightorange};
    }
  }
  .ui.orange.active.button,
  .ui.orange.button .active.button:active,
  .ui.orange.buttons .active.button,
  .ui.orange.buttons .active.button:active {
    background-color: ${({ theme }) => theme.colors.name.main_color_4};
  }
  .ui.orange.button:focus,
  .ui.orange.buttons .button:focus {
    background-color: ${({ theme }) => theme.colors.name.main_color_4};
  }
  //=================== end ==================//

  //================ blue button ===========//
  .ui.blue.button,
  .ui.blue.buttons .button {
    background-color: ${({ theme }) => theme.colors.name.main_color_1};
    font-weight: bold;
    height: 45px;
    padding: 13px 15px;
    vertical-align: middle;
    &:active {
      background-color: ${({ theme }) => theme.colors.name.main_color_3};
    }
    &:hover {
      background-color: ${({ theme }) => theme.colors.name.lightblue};
    }
  }
  .ui.blue.active.button,
  .ui.blue.button .active.button:active,
  .ui.blue.buttons .active.button,
  .ui.blue.buttons .active.button:active {
    background-color: ${({ theme }) => theme.colors.name.main_color_3};
  }
  .ui.blue.button:focus,
  .ui.blue.buttons .button:focus {
    background-color: ${({ theme }) => theme.colors.name.main_color_3};
  }
  //=================== end ==================//

  //=================== table =================//
  .ui.table {
    padding: 20px;
    font-size: 13px;
    -webkit-box-shadow: 0px 0px 5px 0px
      ${({ theme }) => theme.colors.name.sectiongrey};
    -moz-box-shadow: 0px 0px 5px 0px
      ${({ theme }) => theme.colors.name.sectiongrey};
    box-shadow: 0 0 10px 0 ${({ theme }) => theme.colors.name.sectiongrey};
    border: solid 1px ${({ theme }) => theme.colors.name.sectiongrey};
    border-radius: 15px;
  }
  .ui.table thead th {
    background-color: white;
    color: ${({ theme }) => theme.colors.name.main_color_4};
    font-size: 14px;
  }
  .ui.padded.table th {
    padding: 0px;
  }
  .ui.padded.table th {
    padding-bottom: 10px;
    border-top: none;
  }
  .ui.padded.table td {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    vertical-align: middle;
  }
  .ui.table thead tr:first-child {
    padding-bottom: 10px;
  }
  //=================== end ==================//

  //=================== custom alert =================//
  .Toastify__toast-container {
    width: 320px;
  }
  // .Toastify__toast--default {
  //     background: #fff;
  //     color: #aaa;
  //  }
  //   .Toastify__toast--info {
  //     background: #3498db;
  //  }
  //   .Toastify__toast--success {
  //     background: #07bc0c;
  //  }
  //   .Toastify__toast--warning {
  //     background: #f1c40f;
  //  }
  //   .Toastify__toast--error {
  //     background: #e74c3c;
  //  }
  //=================== end ==================//

  @keyframes slideInUpTiny {
    0% {
      transform: translate3d(0px, 5%, 0px);
    }
    100% {
      transform: none;
    }
  }

  @-webkit-keyframes slideInUpTiny {
    0% {
      transform: translate3d(0px, 5%, 0px);
    }
    100% {
      transform: none;
    }
  }

  .slideInUpTiny {
    animation-name: slideInUpTiny;
  }

  .page-error-container {
    padding: 30px 0;
    position: relative;

    @media screen and (max-width: 575px) {
      padding: 15px 0;
    }
  }

  .page-error-content {
    margin: 0 auto;
    padding: 40px 0;
    width: 380px;
    max-width: 94%;

    @media screen and (max-width: 767px) {
      padding: 15px 0;
    }

    @media screen and (max-width: 575px) {
      padding: 0;
    }
  }

  .error-code {
    color: ${({ theme }) => theme.colors.name.black};
    font-size: 160px;
    text-align: center;
    line-height: 1;
    font-weight: 600;
    text-shadow: 10px 6px 8px rgba(117, 117, 117, 0.8);

    @media screen and (max-width: 991px) {
      font-size: 130px;
    }

    @media screen and (max-width: 767px) {
      font-size: 100px;
    }

    @media screen and (max-width: 575px) {
      font-size: 80px;
    }
  }

  @keyframes zoomInDown {
    from {
      opacity: 0;
      transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    60% {
      opacity: 1;
      transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    }
  }

  .zoomInDown {
    animation-name: zoomInDown;
  }
`;

export const PageSectionWrapper = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.name.sectiongrey};
  border-radius: 10px;
  margin: 0px 5% 20px 5%;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.name.white};
  // -webkit-box-shadow: 0 2px 5px 0  ${({ theme }) =>
    theme.colors.name.greyborder};
  // -moz-box-shadow: 0 2px 5px 0   ${({ theme }) =>
    theme.colors.name.greyborder};
  // box-shadow: 0 2px 5px 0  ${({ theme }) => theme.colors.name.greyborder};
  position: relative;
`;

export const CustomStyledTextField = styled(TextField)`
  fieldset {
    border-radius: 15px;
    border-color: ${({ theme }) => theme.colors.name.main_color_9};
  }
  &.MuiFormControl-marginNormal {
    margin-top: 0;
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 15px;
  }
`;

export const CustomStyledAutocomplete = styled(Autocomplete)`
  fieldset {
    border-radius: 15px;
    border-color: ${({ theme }) => theme.colors.name.main_color_9};
  }
  &.search-filter {
    padding-top: 13px;
    padding-right: 5px;
    .MuiInputBase-root {
      height: 42px;
    }
  }
`;

export const StyledErrorContainer = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.name.red};
  padding: 10px 15px 0px 15px;
`;

export const StyledDialogActionsContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  padding-bottom: 10px;
  .ui.button {
    margin-right: 10px;
    text-transform: uppercase;
    &.last-btn {
      margin-right: 0px;
    }
  }
`;

export const StyledLoader = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: ${props => (props.isFullPageLoad ? '100vh' : '100%')};
`;

export const StyledPageErrorContainer = styled.div`
    height: 100%;
    width: 100%;
    padding: 0 5%;
    background-image: url('${background_500_error}');
    background-repeat: no-repeat;
    .title{
      padding-top: 50px;
      color: ${({ theme }) => theme.colors.name.main_color_4};
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
    }
    .horizonLine{
      margin-top: 20px;
      border-top: 1px solid ${({ theme }) => theme.colors.name.sectiondarkgrey};
    }
    .shoot{
      color: ${({ theme }) => theme.colors.name.main_color_4};
      padding-top: 70px;
      font-size: 30px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
    }
    .errorMsg{
      color: ${({ theme }) => theme.colors.name.main_color_1};
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
    }
    .desription, .errorCode{
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      padding-bottom: 10px;
    }
    .error-img{
      width: 350px;
      height: 350px;
      margin: auto;
      margin-top: 100px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('${img_error}');
    }
    .server_error{
      font-weight: bold;
      color: ${({ theme }) => theme.colors.name.main_color_4};
    }
    .button-container{
      text-align: center;
      padding-top: 20px;
      button{
        background-color: ${({ theme }) => theme.colors.name.main_color_1};
        color: ${({ theme }) => theme.colors.name.main_color_8};;
        padding: 8px 16px;
        font-weight: bold;
        height: 45px;
      }
    }
`;

export const StyledErrorWrapper = styled.div`
  text-align: center;
  width: 30%;
  margin: auto;
  .ui.negative.message {
    white-space: wrap;
    overflow: auto;
  }
`;

export const StyledEmptyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 ${({ theme }) => theme.colors.name.greyborder};
  border: solid 1px ${({ theme }) => theme.colors.name.sectiongrey};
  width: 100%;
  min-height: 300px;
  .empty-content {
    flex: 0 0 20px;
    min-width: 200px;
    text-align: center;
    svg {
      fill: ${({ theme }) => theme.colors.name.black};
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .badge-icon {
    svg {
      width: 20px;
      height: 15px;
      margin-right: 5px;
    }
  }
  .first-add-link {
    color: ${({ theme }) => theme.colors.name.main_color_4};
  }
  a:not([href]):not([tabindex]).first-add-link {
    color: ${({ theme }) => theme.colors.name.main_color_4};
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colors.name.black};
    }
  }

  .no-item-label {
    font-weight: bold;
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.name.black};
  }
`;

export const StyledChip = styled(Chip)`
  &.MuiChip-outlinedPrimary {
    color: ${({ theme }) => theme.colors.name.main_color_1};
    margin-bottom: 10px;
    border: 1px solid ${({ theme }) => theme.colors.name.main_color_1};
  }
  .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.name.main_color_1};
  }
`;

export const StyledBadgesDropdown = styled(Autocomplete)`
  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.colors.name.white}!important;
    border-radius: 15px;
    padding-bottom: 5px !important;
    border: solid 1px ${({ theme }) => theme.colors.name.sectiondarkgrey};
    &:hover {
      background-color: ${({ theme }) => theme.colors.name.white};
    }
  }
  .MuiFilledInput-underline:before {
    border: none;
  }
  .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFilledInput-underline:after {
    border: none;
  }
`;

export const StyledConfirmBadgesDropdown = styled(Autocomplete)`
  .MuiInputBase-root {
    background-color: ${({ theme }) => theme.colors.name.white}!important;
    border: none;
  }
  .MuiFilledInput-underline:before {
    border: none;
  }
  .MuiFilledInput-underline:hover:before {
    border: none;
  }
  .MuiFilledInput-underline:after {
    border: none;
  }
  .MuiInput-underline.Mui-disabled:before {
    border: none;
  }
  .MuiFormLabel-root.Mui-disabled {
    display: none;
  }
  label + .MuiInput-formControl {
    margin-top: 0px;
  }
`;

export const StyledLoadMoreLoader = styled.div`
  text-align: center;
  height: 30px;
  width: 100%;
  .MuiCircularProgress-colorPrimary {
    width: 25px !important;
    height: 25px !important;
  }
`;

export const StyledActionItem = styled.div`
  vertical-align: middle;
  line-height: 30px;
  .deactivate-badge svg {
    fill: ${({ theme }) => theme.colors.name.red};
  }
  .activate-badge svg {
    fill: ${({ theme }) => theme.colors.name.green};
  }
`;
