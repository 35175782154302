const Spanish = {
  home: {
    searchInputPlaceholder: 'Search by the name of jobs',
    typeLabel: 'Worker',
    currentJobLabel: 'Current job',
    currentWorkedHours: 'Current worked hours',
    phoneDialogHeader: 'Contact Phone',
    hours: 'hrs',
    mins: 'mins',
    locationType: 'Location',
    addYourJob: 'Add Your First One.',
    noActiveJobs: 'No Active Jobs.'
  },
  common: {
    save: 'Save',
    cancel: 'Cancel',
    submit: 'Submit',
    yes: 'Yes',
    no: 'No',
    ok: 'OK'
  },
  userInfo: {
    profile: 'Manager Account',
    helpAndFeedback: 'Help and Feedback',
    signout: 'Log out'
  },
  navbar: {
    home: 'Home',
    shifts: 'Shifts',
    reports: 'Reports',
    jobReports: 'Job Reports',
    dashboard: 'Dashboard',
    messages: 'Messages',
    locations: 'Locations',
    facilities: 'Clients',
    workforce: 'Workforce',
    workers: 'Workers',
    jobs: 'Jobs',
    tasks: 'Tasks',
    teams: 'Teams',
    employers: 'Employers',
    onboarding: 'Onboarding',
    interviewSchedule: 'Interview Schedule',
    company: 'Company',
    profile: 'Manager Account'
  },
  forgotPass: {
    submitLabel: 'Send My Code',
    forgotPasswordHeader: 'Forgot Password',
    resetPasswordHeader: 'Reset Password',
    currentPassword: 'Current Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirmar Contraseña',
    yourCodeRequired: 'Your code is required',
    newPasswordRequired: 'New password is required',
    confirmPasswordRequired: 'Se requiere confirmación de contraseña',
    passwordsNotMatch: 'Las contraseñas no coinciden',
    successSubmitForgot:
      'Please check your mailbox for reset password instruction.',
    notificationHeader: 'Notification',
    yourCode: 'Your Code',
    successSubmitReset:
      'Successful password reset! You can now use your new password to login to your account!'
  },
  login: {
    loginButton: 'Sign In',
    forgotLinkLabel: 'Forgot your password?',
    email: 'Email',
    password: 'Contraseña',
    rememberMe: 'Remember me',
    emailInvalid: 'El correo electrónico es invalido',
    emailRequired: 'Correo electronico es requerido',
    passwordRequired: 'Se requiere contraseña',
    passwordAtleast6Chars: 'La contraseña debe tener al menos 6 caracteres',
    userPlaceholder: 'user@yourdomain.com',
    pwPlaceholder: 'Password123',
    carousel_header_1: 'Build and retain a workforce quickly',
    carousel_description_1: 'Mobile based onboarding in an easy-to-use process',
    carousel_header_2: 'Schedule jobs as needed',
    carousel_description_2:
      'Convenient scheduling with options for single or multiday shifts and more',
    carousel_header_3: 'Fill jobs in seconds',
    carousel_description_3:
      "Smart ranking systems that'll notify the right workers in an instant",
    carousel_header_4: 'Keep track of all on-site job activities and workers',
    carousel_description_4:
      'Geo-tracking for all activities and realtime ccommunication with workers',
    carousel_header_5: 'See your job reports instantly',
    carousel_description_5:
      'Extensive job reports with auto-calculated hours worked and more'
  },
  terms: {
    agree_title: 'Terms and Conditions',
    agree_checkbox_label: 'I have read and agree to the Terms',
    agree_button_label: 'I Agree'
  },
  logout: {
    label: 'Signed out',
    message: 'Thanks for spending some quality time with our website.',
    signinAgainBtn: 'Sign in again'
  },
  signup: {
    header: 'Apply Now',
    submitBtn: 'Solicitar',
    agreement: 'Al hacer clic en Aplicar, acepta nuestra ',
    signin: 'Registrarse',
    success: 'Éxito !',
    thankyouMessage: 'Gracias por aplicar con nosotros.',
    note:
      '¡Buen trabajo! Hemos recibido tu solicitud. Siguiente paso, NO OLVIDE revisar su correo electrónico para obtener sus credenciales de inicio de sesión y completar el proceso de incorporación.',
    and: ' y ',
    privacyPolicy: 'Política de privacidad',
    introduceApp: 'Escanee QRCode para instalar la aplicación',
    passwordValidate:
      'La contraseña debe tener al menos 6 caracteres e incluir un número y una letra.',
    termOfUse: 'Condiciones de uso'
  },
  shiftRequestsList: {
    shifts: 'Shifts',
    draftShifts: 'Draft Shifts',
    newShiftRequest: 'New Shift',
    newDraftShiftRequest: 'New Draft Shift',
    posted: 'Posted',
    draft: 'Draft'
  },
  pageTitle: {
    home: 'Home',
    workersList: 'Workers',
    employersList: 'Employers',
    shiftsList: 'Shifts',
    shiftRequestsList: 'Job Orders',
    draftShiftRequestsList: 'Draft Job Orders',
    tasksList: 'Tasks',
    shiftsImport: 'Shifts Import',
    newShift: 'New Shift',
    newDraftShift: 'New Draft Shift',
    editShift: 'Edit Shift',
    editDraftShift: 'Edit Draft Shift',
    company: 'Company',
    teams: 'Teams',
    locations: 'Locations',
    badges: 'Badges',
    reports: 'Reports',
    jobReports: 'Job Reports',
    dashboard: 'Dashboard',
    messages: 'Messages',
    facilities: 'Clients',
    onboarding: 'Onboarding Workers',
    profile: 'Manager Account',
    interviewschedule: 'Interview Schedule',
    helpandfeedback: 'Help and Feedback',
    jobs: 'Jobs'
  },
  dashboard: {
    totalClients: 'Total Clients',
    totalWorkersWorking: 'Total Workers Working',
    totalPositionsOpen: 'Total Positions Open',
    totalWorkersPerClients: 'Total Workers per Clients',
    onboardingSubmissions: 'Onboarding Submissions',
    week: 'Week',
    month: 'Month',
    quarter: 'Quarter',
    year: 'Year',
    clients: 'Clients',
    workers: 'Workers',
    onboardingStep: 'Onboarding Step'
  },
  tasksList: {
    header: {
      taskName: 'Task Name',
      shiftName: 'Shift Name',
      completedPercentage: '% Complete',
      report: '# Report',
      reportDate: 'Report Date',
      note: 'Note',
      email: 'Email',
      phone: 'Phone',
      workerName: 'Worker Name',
      actions: 'Actions'
    },
    viewProgressModal: {
      header: 'Task Progress Detail',
      assignedWorker: 'Assigned Worker',
      image: 'Image',
      completionPercentage: 'Completion Percentage (%)',
      note: 'Note'
    },
    cancelTaskSuccess: 'The task was canceled successfully',
    addNewRequestTaskSuccess: 'The new request task was created successfully.',
    emptyProgresses: 'Task Progress is empty',
    addYourFirstTask: 'Add Your First Task.',
    confirmContent: 'Are you sure you want to cancel this task?'
  },
  workerList: {
    firstName: 'First Name',
    lastName: 'Last Name',
    fullName: 'Full Name',
    title: 'Title',
    facility: 'Client',
    avatar: 'Avatar',
    email: 'Email',
    phone: 'Phone',
    zipcode: 'Zipcode',
    badges: 'Qualifications',
    lastWorked: 'Last Worked',
    lastLogin: 'Last Login',
    badgesColumn: 'Badges',
    ssnColumn: 'SSN',
    actions: 'Actions',
    importedAt: 'Imported At',
    importBtn: 'Import',
    importWorkers: 'Import Workers',
    importTemplate: 'Import Template File',
    importFileStatusHeader: 'Imported File Status',
    importResult: {
      status: 'Status: ',
      added: 'Number of added row: ',
      updated: 'Number of updated row: ',
      count: 'Total of row: ',
      error: 'Errors: '
    },
    importNotice: 'Please upload your csv file to import workers.',
    importSuccess:
      'Your file has been updated to the server, we are processing this file. Please check back later.',
    searchInputPlaceholder: 'Name, phone or SSN of worker',
    resendInvitesBtn: 'Resend Invites',
    resendModalHeader: 'Resend Invites',
    resendModalContent:
      'This will resend the Invitation text to items which were selected.',
    resendModalConfirm: 'Resend Invites',
    resendModalCancel: 'Cancel',
    resendInvitesWorkersSuccess: 'Invites were sent to workers successfully',
    emptyResendModalContent:
      'Please select at least one item to send the invitation.',
    noWorkers: 'No Workers.',
    addYourFirstWorker: 'Add Your First Worker.',
    goToWorkerList: 'Go to workers page to add your workers.',
    confirmBlockWorker: 'Are you sure you want to block the worker?',
    blockWorker: 'Block Worker',
    blockWorkerSuccess: 'The worker was blocked successfully',
    noteRequired: 'Note is required.',
    blockInCompany: 'Block in the company.',
    blockWithFacilities: 'Block with clients:',
    chooseFacilitiesForBlock: 'Please choose clients below for blocking',
    note: 'Note',
    notePlaceholder: 'Please enter the reason for blocking the worker.'
  },
  onboarding: {
    fullName: 'Full Name',
    email: 'Email',
    phone: 'Phone',
    ssn: 'SSN',
    state: 'State',
    actions: 'Actions',
    currentStep: 'Current Step',
    noWorkerOnboarding: 'No Worker Onboarding.',
    searchInputPlaceholder: 'Search worker onboarding',
    confirmContent: 'Are you sure you want to cancel the worker onboarding?',
    cancelWorkerSuccess: 'The worker onboarding was rejected successfully',
    interviewSchedules: 'Interview Schedules',
    jotFormIFrame: 'Worker Contract'
  },
  interviewSchedules: {
    upcomingSchedules: 'Upcoming Schedules',
    pastSchedules: 'Past Schedules',
    fullName: 'Full Name',
    phone: 'Phone',
    email: 'Email',
    meetingLink: 'Meeting Link',
    interviewDate: 'Interview Date',
    state: 'State',
    actions: 'Actions',
    noSchedules: 'No interview schedule.',
    completeConfirmContent:
      'Are you sure you want to pass the inteview of this worker?',
    passSuccess: 'The interview of the worker was evaluated is pass',
    failSuccess: 'The interview of worker was evaluated is failed',
    failConfirmContent:
      'Are you sure you want to fail the inteview of this worker?'
  },
  reports: {
    details: 'Details',
    adjustedHoursText: 'Adjusted Hrs worked / Hrs required',
    hoursText: 'Hrs worked / Hrs required',
    hours: 'hrs',
    logTime: 'Log Time',
    activities: 'Activities',
    timestamp: 'Timestamp',
    hrsWorked: 'Hrs Worked',
    note: 'Note',
    rating: 'Rating',
    adjustedWorkedHours: 'Adjusted Worked Hours',
    notePlaceHolder: 'Enter your note here.',
    adjustHoursPlaceHolder: 'Adjust hours here.',
    adjustedPay: '$ Adjusted Pay',
    adjustedPayPlaceHolder: 'Enter the adjusted pay for the worker',
    noReports: 'No Reports.',
    yourReport: 'Your first report will be generated when jobs get started.',
    header: {
      avatar: 'Avatar',
      message: 'Message',
      fullName: 'Full Name',
      email: 'Email',
      phone: 'Phone',
      numbCompletedShifts: '# of Completed Shifts',
      numbCompletedTasks: '# Completed Tasks',
      totalWorkedHrs: 'Worked Hrs',
      distance: 'Distance',
      shiftName: 'Shift Name',
      location: 'Location',
      workedHrs: 'Worked Hrs',
      jobName: 'Job Name',
      date: 'Date',
      pay: '$ Pay',
      rating: 'Rating'
    }
  },
  employerList: {
    importBtn: 'Import',
    importEmployers: 'Import Employers',
    importTemplate: 'Import Template',
    searchInputPlaceholder: 'Name or phone of employer',
    resendInvitesBtn: 'Resend Invites',
    resendInvitesEmployersSuccess:
      'Invites were sent to employers successfully',
    importNotice: 'Please upload your csv file to import employers.'
  },
  teamsList: {
    header: {
      name: 'Name',
      numberOfMembers: '# of Members',
      actions: 'Action'
    },
    addYourFirstTeam: 'Add Your First Team.',
    noTeams: 'No Teams.'
  },
  badgesList: {
    badgeName: 'Badge Name',
    description: 'Description',
    createdDate: 'Created Date',
    status: 'Status',
    active: 'Active',
    actions: 'Actions',
    addYourFirstBadge: 'Add Your First Badge.',
    noBadges: 'No Badges.',
    addBadgeHeader: 'Add New Badge',
    updateBadgeHeader: 'Update Badge',
    requiredName: 'Name is required',
    requiredDescription: 'Description is required',
    confirmContent: 'Are you sure you want to remove this badge?',
    selectChildren: 'Select on badge children',
    emptyBadge: 'Empty badge'
  },
  searchFilter: {
    workerName: 'Name',
    employerName: 'Name',
    email: 'Email',
    phone: 'Phone',
    ssn: 'SSN',
    filter: 'Filter'
  },
  locationsList: {
    searchInputPlaceholder: 'Name of location',
    name: 'Name',
    address: 'Address',
    city: 'City',
    zipCode: 'Zip Code',
    state: 'State',
    phone: 'Phone',
    action: 'Action',
    addYourFirstLocation: 'Add Your First Location.',
    noLocations: 'No Locations.'
  },
  facilitiesList: {
    searchInputPlaceholder: "Client's name",
    name: 'Name',
    description: 'Description',
    facilityGroupName: 'Client Group Name',
    action: 'Action',
    confirmContent: 'Are you sure you want to deactivate this client?',
    addYourFirstFacility: 'Add Your First Client.',
    noFacilities: 'No Clients.'
  },
  mobileAppInfo: {
    instruction: 'Scan QRCode',
    subInstruction: 'or click icon below to install the OPUS App',
    opusCopyright: '©  OPUS',
    hireprivyCopyright: '©  HirePrivy'
  },
  messages: {
    searchInputPlaceholder: 'Job Name',
    composePlaceholder: 'Type a message',
    landingHeader: 'Communication Messages',
    landingDescription:
      'Search for specific job to start chatting with a worker or go to home, shifts, reports page to see which is available.'
  },
  jobActions: {
    noShow: 'No Show',
    complete: 'Complete',
    pause: 'Pause',
    start: 'Start',
    approve: 'Approve',
    checkIn: 'Check In',
    checkOut: 'Check Out',
    takeBreak: 'Take Break',
    acceptApplication: 'Accept Application',
    cancel: 'Cancel'
  },
  jobStates: {
    accepted: 'Accepted',
    late: 'Late',
    filled: 'Filled',
    started: 'Started',
    paused: 'Paused',
    completed: 'Completed',
    approved: 'Approved',
    cancelled: 'Cancelled',
    noShow: 'No Show',
    withDrawn: 'Withdrawn',
    invited: 'Invited'
  },
  editShift: {
    numbOfWorkers: '# Of Workers',
    payPerWorker: 'Pay Per Worker',
    location: 'Location',
    updateShiftSuccess: 'The shift was updated successfully.',
    timezoneNote:
      'System only allows to change the location with the same time zone.'
  },
  editShiftRequest: {
    updateShiftRequestSuccess: 'The shift request was updated successfully.'
  },
  shiftsList: {
    header: {
      name: 'Name',
      startTime: 'Start Time',
      startDate: 'Start Date',
      messages: 'Messages',
      actions: 'Actions',
      status: 'Status',
      duration: 'Duration',
      shiftName: 'Shift Name',
      timezone: 'Time zone',
      shiftActions: 'Action',
      shiftCategories: 'Shift Categories',
      matchingMethods: 'Matching Methods',
      numbOfDays: '# Of Days',
      numbOfTasks: '# Of Tasks',
      workTime: 'Work Time',
      breakTime: 'Break Time',
      location: 'Location',
      numbOfWorkers: '# Of Workers',
      totalPay: '$ Total Pay',
      payPerWorker: '$ Pay Per Worker',
      pay: '$ Pay',
      rating: 'Rating',
      unreadMessages: 'Unread Messages'
    },
    addYourFirstShift: 'Add Your First Shift.',
    noStartTimeorTimeZone: 'Start Time is Empty',
    noShifts: 'No Shifts.',
    noShiftsFound: 'No Shifts Found.',
    noDraftShiftsFound: 'No Draft Shifts Found.',
    noTasks: 'No Tasks.',
    noJobsFilled: 'No Job Was Filled',
    newShift: 'New Shift',
    searchInputPlaceholder: 'Shift name',
    draftSearchInputPlaceholder: 'Draft shift name',
    updateRequestTaskSuccess: 'The request task was updated successfully.',
    createJobRatingSuccess: 'This job was rated.',
    updateJobRatingSuccess: 'The rating of this job was updated.',
    createJobSuccess: 'The new job was created successfully.',
    cancelShiftSuccess: 'The shift was cancelled successfully.',
    cancelShiftRequestSuccess: 'The shift request was cancelled successfully.',
    cancelShiftRequestConfirm:
      'Are you sure you want to cancel this shift request?',
    confirmContent: 'Are you sure you want to cancel this shift?',
    confirmResendNotification:
      'Are you sure you want to resend notification for this shift?',
    cannotCancelShiftMessage: 'This shift cannot be cancelled.',
    cannotCancelShiftRequestMessage: 'This shift request cannot be cancelled.',
    resendNotification: 'Resend Notification',
    resendNotificationSuccessfully:
      'Notification message is resent successfully.',
    loadMore: 'Loading ...',
    successInfo: {
      phone: 'Phone Number',
      email: 'Email',
      text:
        'Congratulations !!! Created worker successfully. You can invite the worker for jobs.'
    },
    matchingMethods: {
      smartRanking: 'Smart Ranking',
      invitedWorkers: 'Invited Workers'
    },
    tasksListModal: {
      header: 'Task List',
      taskName: 'Task Name',
      percentComplete: '% Complete',
      percentReport: '# of Report',
      action: 'Action'
    },
    inviteWorkerModal: {
      header: 'Invite Worker For Job',
      phoneLabel: 'Phone Number',
      workerAccount: 'Create Account',
      validateInfo: 'Please select one worker',
      searchWorkerPhonePlaceholder:
        'Search worker by name or phone number (Please enter at least 3 characters)',
      workerNotFound: 'Worker not found - You can create a new worker account.'
    },
    addTaskModal: {
      header: 'Add Task',
      name: 'Name',
      description: 'Description *',
      shiftRequest: 'Shift Request *',
      shiftRequestPlaceholder: 'Search shift request by name',
      namePlaceholder: "Enter task's name.",
      forAllWorkers: 'For all workers',
      forAssignedWorkers: 'For assigned workers',
      startTime: 'Start time: ',
      descriptionPlaceholder: "Enter task's description.",
      validateInfo:
        'Please fill out required fields and select at least one worker.'
    },
    editTaskModal: {
      header: 'Edit Task'
    },
    viewTaskModal: {
      header: 'Task Details',
      taskName: 'Task Name',
      shiftName: 'Shift Name',
      description: 'Description',
      assignedWokers: 'Assigned Workers',
      placeholderShiftName: 'Please select one shift.'
    }
  },
  newShift: {
    step1: 'Step 1',
    step2: 'Step 2',
    step3: 'Step 3',
    step4: 'Step 4',
    general: 'General',
    details: 'Details',
    invited_workers: 'Invited Workers',
    smartRanking: 'Smart ranking was selected',
    confirm: 'Confirm',
    isMultidayShift: 'Multi-day shift?',
    multidayShiftEdit: 'Edit Repeat Pattern',
    multidayShiftCreate: 'Create Repeat Pattern',
    useDefaultWorkers:
      'I want to use system-smart ranking to select qualified teams and workers.',
    useInvitedWorkers: 'I want to invite my preferred teams and workers.',
    completeHeader: 'Info',
    messageComplete:
      'The shift request was created by the system. You can check it on shifts list page.',
    shiftListLinkLabel: 'Return to shift list page',
    createSuccess: 'Your shift was created successfully',
    noCategories: 'No Categories.',
    addYourFirstCategories:
      'Contact active administrator to create your shift categories.',
    addCategorySuccess: 'The category was added successfully.',
    timezoneNote:
      'The Start Date and Start Time will be based on time zone of the location which you will select on below.',
    total: 'Total',
    shiftForm: {
      shiftName: 'Shift Name',
      category: 'Category',
      badges: 'Qualifications',
      description: 'Description',
      requirements: 'Requirements',
      slotsNumber: 'Number of workers per shift',
      startTime: 'Start Time',
      payment: 'Payment',
      totalPay: '$ Total Pay per worker',
      paidTime: 'Paid Time',
      breakTime: 'Break Time',
      startDate: 'Start Date',
      addLocation: 'Add Location',
      addFacility: 'Add Client',
      location: 'Location',
      facility: 'Client',
      day: 'Day',
      workers: 'Workers',
      numberWorkers: '# of Workers',
      payType: 'For per',
      payPerDay: '$ Pay per day',
      payPerHour: '$ Pay per hour',
      cost: 'Cost ($)'
    },
    placeholder: {
      shiftName: 'Delivery Job 2020 - Weekend Shift',
      description:
        'What will the worker be doing? Please be as specific as possible.',
      category: 'Choose Category',
      badges: 'Choose qualifications',
      payType: 'Choose',
      slots: 'Number of Workers',
      pay: 'Pay Per Worker',
      startTime: 'Start Time',
      hours: 'Hours',
      hoursRequired: 'Hours *',
      minutes: 'Minutes',
      minutesRequired: 'Minutes *',
      startDate: 'Choose Job Start Date'
    },
    note: {
      generateRepeatOptions: 'General repeats based on option that you want.'
    },
    repeat: {
      deleteAll: 'Delete All',
      restoreAll: 'Restore All',
      deleteRow: 'Delete',
      restoreRow: 'Restore',
      repeatsEveryHeading: 'Repeats',
      endsHeading: 'Ends',
      doesNotRepeat: 'Does not repeat',
      repeatsEvery: 'Repeats every',
      daily: 'Daily',
      weekly: 'Weekly On',
      never: 'Never',
      on: 'On',
      datePlaceholder: 'end date',
      after: 'After',
      weeks: 'Weeks',
      occurrences: 'Occurrences',
      occurrencesNote:
        'Note: You may only schedule up to %max% occurrences for each job',
      sameTooltip:
        "While we can't guarantee you will get the same Wonoloer, this option will help you to attract Wonoloers who prefer longer term engagements",
      payTypeDay: 'day',
      payTypeHour: 'hour'
    },
    repeatOptions: {
      editRepeat: 'Edit Repeat',
      editSchedule: 'Edit Schedule',
      saveSchedule: 'Save Schedule',
      editScheduleNote: 'Note: You may edit each day individually',
      editRepeatNote:
        'Note: Once you set the schedule, you may edit each day individually',
      createRepeat: 'Create Repeat',
      editDays: 'Edit Individual Days',
      backToSchedule: 'Back to Schedule'
    },
    addPreferredModal: {
      addPreferredWorkersBtn: 'Add Preferred Workers',
      addPreferredTeamsBtn: 'Add Preferred Teams',
      addWorkersModalTitle: 'Add Preferred Workers',
      addWorkersModalSubTitle: 'Please select workers for this shift.',
      addTeamsModalTitle: 'Add Preferred Teams',
      addTeamsModalSubTitle: 'Please select teams for this shift.',
      confirmButton: 'Confirm',
      addPreferredTeamsWorkersBtn: 'Add Preferred Teams and Workers',
      addTeamsWorkersModalTitle: 'Add Preferred Teams and Workers',
      searchPlaceholder: 'Search Workers and Teams',
      youHaveChosen: 'You have chosen:'
    },
    categoryModal: {
      header: 'Add Category',
      categoryName: 'Name',
      categoryDescription: 'Description',
      categoryNameRequired: 'Category name is required',
      categoryDescriptionRequired: 'Category description is required',
      addCategorySuccess: 'The category was added successfully.'
    }
  },
  export: {
    exportModalHeader: 'Export Info',
    exportModalContent: 'Please select at least one item to export.',
    columnName: 'Column Name',
    exportSelectionModalHeader: 'Export Selection',
    passwordConfirmHeader: 'Password Confirmation',
    dataAttribute: 'Data Attribute',
    updatedColumnName: 'Export Column',
    note:
      'Please select on columns and update their names which will be exported in csv file.'
  },
  locationModal: {
    addLocationHeader: 'Add Location',
    updateLocationHeader: 'Update Location',
    name: 'Name',
    address: 'Address',
    city: 'City',
    addressState: 'State',
    country: 'Country',
    zipcode: 'Zip Code',
    active: 'Active',
    phone: 'Phone',
    facility: 'Client',
    blankOptionState: 'Please select the Country first *',
    defaultOptionState: 'Select State *',
    defaultOptionCountry: 'Select Country *',
    geofencingRadius: 'Geofencing Radius',
    addLocationSuccess: 'The location was added successfully.',
    updateLocationSuccess: 'The location was updated successfully.',
    deactivateLocationSuccess: 'The location was deactivated successfully.',
    requiredName: 'Name is required',
    requiredAddress: 'Address is required',
    requiredCity: 'City is required',
    requiredCountry: 'Country is required',
    requiredAddressState: 'Address State is required',
    requiredZipcode: 'Zipcode is required',
    showMapHeader: 'Show Location',
    confirmContent: 'Are you sure you want to deactivate this location?'
  },
  badgeModal: {
    addBadgeSuccess: 'The badge was added successfully.',
    updateBadgeSuccess: 'The badge was updated successfully.'
  },
  facilityModal: {
    addFacilityHeader: 'Add Client',
    updateFacilityHeader: 'Update Client',
    name: 'Name',
    description: 'Description',
    imageUrl: 'Workplace Image',
    addFacilitySuccess: 'The client was added successfully.',
    updateFacilitySuccess: 'The dlient was updated successfully.',
    noteforNewShift:
      'Please make sure you have at least one location to create your job orders.',
    deactivateFacilitySuccess: 'The client was deactivated successfully.'
  },
  companyModal: {
    header: 'Update Company'
  },
  teamModal: {
    addTeamHeader: 'Add Team',
    updateTeamHeader: 'Update Team',
    requiredName: 'Team Name is required',
    requiredDescription: 'Description is required',
    atLeastWorkers: 'Please select at least 2 workers',
    cannotRemoveWorker: 'The team needs at least 2 workers',
    name: 'Name',
    description: 'Description',
    share: 'Share',
    confirmContent: 'Are you sure you want to deactivate this team?',
    workerRemoveConfirmContent:
      'Are you sure you want to remove this worker out of the team?',
    deactivateTeamSuccess: 'The team was deactivated successfully.',
    createTeamSuccess: 'The team info was created successfully.',
    updateTeamSuccess: 'The team info was updated successfully.',
    removeWorkerSuccess: 'The worker was remove out of the team successfully.',
    addWorkerSuccess: 'The worker was added into the team successfully.'
  },
  assignBadgesModal: {
    assignBadgesHeader: 'Assign Badges',
    assignMultipleWorkerHeader: 'Assign Badges To Multiple Workers',
    selectBadges: 'Select on Badges',
    note: 'Note',
    badges: 'Badges',
    requiredSelectWorker: 'Please select at least one workers to assign badges'
  },
  workerModal: {
    addWorkerHeader: 'Agregar nuevo trabajador',
    updateWorkerHeader: 'Trabajador de actualización',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    phone: 'Teléfono',
    ssn: 'SSN',
    email: 'Correo electrónico',
    addPhoto: 'Añadir foto',
    requiredFirstName: 'Se requiere el primer nombre',
    requiredLastName: 'Se requiere apellido',
    requiredSSN: 'Se requiere SSN',
    requiredPhone: 'Se requiere teléfono',
    requiredEmail: 'Correo electronico es requerido',
    addWorkerSuccess: 'El trabajador se agregó correctamente.',
    updateWorkerSuccess: 'El trabajador se actualizó correctamente.',
    imageUrl: 'Imagen de Avatar'
  },
  employerModal: {
    addEmployerHeader: 'Add Employer',
    updateEmployerHeader: 'Update Employer',
    firstName: 'First Name',
    lastName: 'Last Name',
    title: 'Title',
    facility: 'Client',
    phone: 'Phone',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    requiredFirstName: 'First name is required',
    requiredLastName: 'Last name is required',
    requiredPhone: 'Phone is required',
    requiredEmail: 'Email is required',
    addEmployerSuccess: 'The employer was added successfully.',
    updateEmployerSuccess: 'The employer was updated successfully.',
    confirmPasswordRequire: 'Confirm Password is required.',
    confirmPasswordMatch: 'Passwords must match.',
    imageUrl: 'Avatar Image'
  },
  info: {
    copyright: '© HirePrivy. All rights reserved.'
  },
  confirmModal: {
    header: 'Confirm Dialog'
  },
  profile: {
    avatar: 'Avatar',
    information: 'Information',
    fullName: 'Full name',
    title: 'Title',
    email: 'Email',
    phone: 'Phone',
    lastLogin: 'Last Login',
    editModalHeader: 'Edit Profile',
    updateProfileSuccess: 'Your account was updated successfully.'
  },
  changePassword: {
    link: 'Change Account Password',
    requiredOldPassword: 'Current password is required'
  },
  companyInfo: {
    mainContactInfo: 'Main Contact Info',
    name: 'Name',
    description: 'Description',
    address: 'Address',
    city: 'City',
    phone: 'Phone',
    addressState: 'State',
    country: 'Country',
    zip: 'Zip',
    mainContactName: 'Contact Name',
    mainContactEmail: 'Contact Email',
    logo: 'Company Logo',
    requiredCompanyName: 'Name is required',
    requiredCompanyDescription: 'Description is required',
    logoCropModalTitle: 'Crop Your Company Logo Picture',
    logoSubmit: 'Set New Company Logo Picture',
    companySuccess: 'Company was updated successfully.',
    cropImageModalTitle: 'Delimitar imagen',
    addLogo: 'Add Logo'
  },
  formErrors: {
    missingFirstName: 'Must have a first name',
    missingLastName: 'Must have a last name',
    missingEmail: 'Must have an email',
    invalidEmail: 'Invalid email',
    missingFacility: 'Must select a client',
    allowSpecialCharacters:
      'Not include special characters (e.g. / or space) for the Shift Name.',
    missingCompanyName: 'Name is required',
    missingName: 'Name is required',
    mustBeNumeric: 'This field must be a number',
    missingZipcode: 'Must have a zipcode',
    missingCreditCardNumber: 'Please enter a credit card number',
    missingCategory: 'Please choose a category',
    shiftName: 'Please enter a shift name',
    shiftDescription: 'Please enter task information',
    shiftRequirements: 'Please enter requirements information',
    forCreateDraft: ' for creating a draft job orders',
    preferredWorkers:
      'Please select at least one of workers from preferred workers or use system-smart ranking',
    repeatPattern:
      'To create multi-shift, please make sure that required fields (*) above were filled.',
    required: 'required',
    missingSlot: 'Number of workers per shift is required.',
    missingCost: 'Min Cost is required.',
    missingMaxCost: 'Max Cost is required.',
    missingPaidTime: 'Paid time is required.',
    missingLocation: 'Location is required.',
    minGreaterMax: 'Min Cost can not be greater than Max Cost.'
  },
  formatErrors: {
    phone: 'Please enter your phone number in the format: (123) 456-7890',
    postal: 'Please enter a 5-digit zip code'
  },
  idleModal: {
    header: 'You Have Been Idle!',
    content: 'You Will Get Timed Out. You want to stay',
    signoutBtn: 'Sign out',
    stayBtn: 'Stay'
  },
  page404: {
    errorMsg: '404 ERROR',
    pageNotFound: 'Oops! Page not found',
    thePageYouSeek: 'The page you seek is not.'
  },
  page500: {
    title: '500 ERROR - INTERNAL SERVER ERROR',
    shoot: 'Shoot',
    errorMsg: 'Well, This is unexpected ...',
    errorCode: 'Error Code:',
    description_1:
      'An error has occurred and we’re working to fix the problem! We’ll be up and running shortly.',
    description_2:
      'If you need immediate help from our customer service team about an ongoing reservation, please call us. If it isn’t an urgent matter, please visit our Help Center for additional information. Thanks for you patience!',
    description_3: 'For urgent situations please call us.',
    goToHome: 'Go Home Page'
  },
  unsupportedBrowser: {
    title: 'Please Update Your Browser',
    uhOh: "Uh oh! It looks like you're using an unsuppported browser.",
    strange:
      'This may cause some things in the portal to look or behave a bit strangely.',
    update:
      'We suggest upgrading to the latest version of your browser before proceeding.'
  },
  loading: {
    pageDidntLoad: 'Error while loading, please refresh your browser.'
  },
  days: {
    day: 'day',
    until: 'until',
    every: 'every',
    weekend: 'weekend',
    weekday: 'weekday',
    sunday: 'sunday',
    monday: 'monday',
    tuesday: 'tuesday',
    wednesday: 'wednesday',
    thursday: 'thursday',
    friday: 'friday',
    saturday: 'saturday'
  },
  buttons: {
    add: 'Agregar',
    edit: 'Edit',
    delete: 'Delete',
    update: 'Actualizar',
    back: 'Back',
    continue: 'Continue',
    block: 'Block',
    unblock: 'Unblock',
    team: 'Team',
    search: 'Search',
    send: 'Send',
    ok: 'OK',
    close: 'Close',
    export: 'Export',
    invite: 'Invite',
    cancel: 'Cancelar',
    upload: 'Upload',
    saveDraft: 'Save As Draft',
    updateShift: 'Update Shift',
    cancelShift: 'Cancel Shift',
    updateDraft: 'Update Draft',
    postShift: 'Post Shift',
    addTask: 'Add Task',
    addWorker: 'Add Worker',
    assign: 'Assign',
    assignBadges: 'Assign Worker Badges',
    editBadge: 'Edit Badge',
    addEmployer: 'Add Employer',
    addLocation: 'Add Location',
    addFacility: 'Add Client',
    addTeam: 'Add Team',
    getDownloadLink: 'Get Download Link',
    confirm: 'Confirm',
    download: 'Download',
    createAccount: 'Create Account',
    adjust: 'Adjust',
    approve: 'Approve'
  }
};

export default Spanish;
