import { gql } from '@apollo/client';

export const GET_EMPLOYER_SUMMARY = gql`
    query {
        employerSummary {
            dateRanges
            shifts {
                fillRate
                newShiftsCount
                recentShifts {
                    id
                    facilityName
                    shiftRequest {
                        name
                    }
                }
            }
            jobs {
                activeJobsCount
                newJobsCount
                highlightJobsCount {
                    noShow
                    late
                    inProgress
                }
            }
            workers {
                activeWorkersCount
                newWorkersCount
                recentNewWorkers {
                    id
                    firstName
                    lastName
                    badges {
                        iconUrl
                    }
                }
            }
            reports(filter: { timePeriod: weekly }) {
                shiftsCompletedCount
                jobsCompletedCount
            }
            onboardingSubmissions(filter: { timePeriod: weekly })
        }
    }
`;

export const GET_EMPLOYER_INIT = gql`
    query {
        employerAppInit {
            totalUnreadNotification
            employer {
                id
                title
                firstName
                company {
                    id
                }
                employerFacilities {
                    facility {
                        id
                        name
                        description
                        workplaceImageUrl
                    }
                }
            }
        }
    }
`;

export const VERIFY_EMPLOYER_QUERY = gql`
    query verifyEmployer($token: String!) {
        employerToken(token: $token) {
            token
            employer {
                id
                avatarUrl
                firstName
                lastName
                email
                phone
                title
                lastSignInAt
                employerFacilities {
                    facility {
                        id
                        name
                        description
                        workplaceImageUrl
                    }
                }
                company {
                    id
                    hasCalendlyIntegration
                    hasHelloSignIntegration
                    companyConfig {
                        importEmployerTemplateUrl
                        importWorkerTemplateUrl
                        phoneSupport
                        oneSignalAppId
                        payRangeJob
                        employerTermsOfService {
                            url
                            version
                        }
                    }
                }
                agreedToTerms
            }
        }
    }
`;
