import getCurrentLocale from 'common/helpers/getCurrentLocale';
import { EN_US, ES_SP } from '../constants';
import USEnglish from './USEnglish';
import Spanish from './spanish';

const defaultLocale = getCurrentLocale();

export const getSupportedLocales = () => [EN_US];

export const getLibrary = (locale = defaultLocale) => {
  let library = null;
  switch (locale) {
    case EN_US:
      library = USEnglish;
      break;
    case ES_SP:
      library = Spanish;
      break;
    default:
      return (library = USEnglish);
  }
  return library;
};
