import 'babel-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import history from 'routes/history';
import App from 'app';
import configureStore from 'redux/store/configureStore';
import { authUserByTokenRequest } from 'redux/actions';
import { getLocalToken } from 'common/helpers/auth/sessionToken';
import { VERIFY_TOKEN_FINGERPRINT } from 'common/constants/fingerprintConstants';
import { getMetaInfoWebByCompany } from 'common/helpers/helpers';
import * as Sentry from '@sentry/react';
import axios from 'axios';

axios.defaults.baseURL = process.env.PROXY_API_URL;
axios.defaults.headers['APP_ID'] = process.env.PROXY_API_APP_ID;
axios.defaults.headers['APP_SECRET'] = process.env.PROXY_API_APP_SECRET;

const sessionToken = getLocalToken();
const store = configureStore();

const { COMPANY_HOST } = process.env;

const fingerprint = VERIFY_TOKEN_FINGERPRINT;
const meta = {
  fingerprint
};
store.dispatch(authUserByTokenRequest({ sessionToken, meta }));

const companyMetaInfo = getMetaInfoWebByCompany(COMPANY_HOST);

render(
  <Sentry.ErrorBoundary fallback={'Error'}>
    <React.Fragment>
      {companyMetaInfo[0]}
      <Provider store={store}>
        <App
          history={history}
          showHeader={true}
          showFooter={false}
          showHeaderSignout={false}
          showNavbar={true}
          showContent={true}
          showSideBar={true}
          theme={companyMetaInfo[1]}
        />
      </Provider>
    </React.Fragment>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
