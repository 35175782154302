import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'components/common/imageLink/imageLink';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getUser } from 'redux/selectors';
import { StyledPadding } from './styledHeaderContainer';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    zIndex: 'initial',
    height: 80
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0
  }
}));

const ResponsiveDrawer = ({
  authenticated,
  globalTheme,
  showNavbar,
  user,
  ...props
}) => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logo = globalTheme.icons.header.logo;
  return (
    <div className={`${classes.root} drawer-root`}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {authenticated && (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          {!authenticated && <StyledPadding />}
          <Logo className="logo" src={logo} />
        </Toolbar>
      </AppBar>
    </div>
  );
};
ResponsiveDrawer.propTypes = {
  container: PropTypes.object,
  user: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

export default connect(mapStateToProps, null)(ResponsiveDrawer);
