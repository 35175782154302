import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const StyledContainer = styled.div`
  svg {
    width: 20px;
    height: 20px;
  }

  .quick-menu {
    .dropdown-menu {
      padding: 5px 0 5px 10px;
      width: 350px;
    }

    & .icon-btn {
      font-size: 30px !important;
      padding: 6px 10px;
    }

    & .icon-alert {
      position: relative;
    }

    .MuiIconButton-label {
      color: ${({ theme }) => theme.colors.name.main_color_1};
    }

    button:focus {
      outline: 0;
    }

    @keyframes pulse {
      from {
        transform: scale3d(1, 1, 1);
      }
      50% {
        transform: scale3d(1.05, 1.05, 1.05);
      }
      to {
        transform: scale3d(1, 1, 1);
      }
    }

    & i.animated.infinite {
      -moz-animation-iteration-count: 50;
      -webkit-animation-iteration-count: 50;
      -o-animation-iteration-count: 50;
      animation-iteration-count: 50;
    }
  }

  .messages-list ul li {
    position: relative;
    padding: 20px 10px 14px;
    border-bottom: 1px solid #E4E4E4;

    & .user-name a {
      color: $app-primary;
    }

    & .sub-heading {
      font-size: 11px;
      margin-bottom: 6px !important;

      &.mb-0 {
        margin-bottom: 0 !important;
      }
    }

    &:last-child {
      border-bottom: 0 none;
    }

    & .jr-btn {
      margin-right: 0 !important;
    }

    & .jr-btn-xs {
      font-size: 11px !important;
      padding: 5px !important;
    }
  }
`;

export const StyledAvatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  border: 1px solid ${({ theme }) => theme.colors.name.darkgrey};
`;

export const StyledDropdownColumnContainer = styled.div`
  .MuiButton-label {
    padding-left: 30px;
  }

  button:focus {
    outline: 0;
  }

  .MuiListItemIcon-root {
    color: red;
    min-width: 45px;
  }
`;

export const StyledGridUserInfoDesktop = styled(Grid)`
  text-align: right;
`;

export const StyledListItemIcon = styled.div`
  min-width: 35px;
  color: ${({ theme }) => theme.colors.name.black};
  fill: currentColor;
  width: 1em;
  height: 1.25em;
  display: inline-block;
  font-size: 1.25rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`;


