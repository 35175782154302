import get from 'lodash/get';
import {
  LOAD_PAST_SCHEDULES,
  UPDATE_SCHEDULE_STATE
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import {
  UPDATE_PASS_PAST_SCHEDULE_FINGERPRINT,
  UPDATE_FAIL_PAST_SCHEDULE_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import getText from 'context/language/getText';

export default function(
  state = initialState[storeConstants.PAST_SCHEDULES],
  action
) {
  switch (action.type) {
    case LOAD_PAST_SCHEDULES.SUCCESS: {
      const upcomingSchedules = get(
        action,
        'payload.pastInterviewSchedules',
        []
      );
      return [...upcomingSchedules];
    }
    case LOAD_PAST_SCHEDULES.FAILURE: {
      const errors = get(action, 'payload.errors', []);
      if (errors.length > 0) {
        toast.error(errors[0].message, { className: 'alert alert-danger' });
        return state;
      }
      break;
    }
    case UPDATE_SCHEDULE_STATE.SUCCESS: {
      const updatedSchedule = get(
        action,
        'payload.updateInterviewScheduleState',
        {}
      );
      const fingerprint = get(action, 'meta.fingerprint');
      const isComingSchedule = get(action, 'meta.isComingSchedule');

      if (!isComingSchedule) {
        const upcomingSchedules = state.map(item => {
          if (item.id === updatedSchedule.id) {
            return updatedSchedule;
          }
          return item;
        });

        let message = '';
        if (fingerprint === UPDATE_PASS_PAST_SCHEDULE_FINGERPRINT)
          message = getText('interviewSchedules.passSuccess');
        if (fingerprint === UPDATE_FAIL_PAST_SCHEDULE_FINGERPRINT)
          message = getText('interviewSchedules.failSuccess');

        toast.success(message, { className: 'alert alert-success' });
        return [...upcomingSchedules];
      }

      return state;
    }
    default:
      return state;
  }
}
