import {
  graphQueryPromise,
  graphMutationPromise,
  handleUpdateString
} from './helpers';
import * as MESSAGE from 'common/constants/messageConstants';
import * as WORKER from 'common/constants/workerConstants';
import * as EMPLOYER from 'common/constants/employerConstants';
import * as MESSAGE_THREAD from 'common/constants/messageThreadConstants';

const messageThreadFields = [
  MESSAGE_THREAD.COMPANY_ID,
  MESSAGE_THREAD.FACILITY_GROUP_ID,
  MESSAGE_THREAD.FACILITY_ID,
  MESSAGE_THREAD.ID,
  MESSAGE_THREAD.LAST_MESSAGE,
  MESSAGE_THREAD.LAST_TIMESTAMP,
  MESSAGE_THREAD.THREAD_ICON_URL,
  MESSAGE_THREAD.OBJECT_ID,
  MESSAGE_THREAD.OBJECT_TYPE,
  MESSAGE_THREAD.SHIFT_NAME,
  MESSAGE_THREAD.UNREAD_MESSAGE_COUNTER
];

const messageFields = [
  MESSAGE.ID,
  MESSAGE.CREATED_AT,
  MESSAGE.JOB_NAME,
  MESSAGE.JOB_START_TIME,
  MESSAGE.ATTACHMENT_URL,
  MESSAGE.MESSAGE,
  MESSAGE.OBJECT_ID,
  MESSAGE.OBJECT_TYPE,
  MESSAGE.STATUS,
  MESSAGE.RECEIVER_ID,
  MESSAGE.RECEIVER_NAME,
  MESSAGE.RECEIVER_TYPE,
  MESSAGE.SENDER_ID,
  MESSAGE.SENDER_NAME,
  MESSAGE.SENDER_TYPE
];

const addMessageFields = [
  MESSAGE.MESSAGE,
  MESSAGE.JOB_ID,
  MESSAGE.ATTACHMENT_BASE64,
  MESSAGE.ATTACHMENT_FILE_NAME,
  MESSAGE.ATTACHMENT_SIGNED_BLOB
];

export const getAllConversations = () => {
  const messageThread_Fields = messageThreadFields.join(',');
  const message_Fields = messageFields.join(',');

  const query = `{
    messageThreads {
      ${messageThread_Fields}
      worker{
        ${WORKER.WORKER_ID}
        ${WORKER.WORKER_FIRST_NAME}
        ${WORKER.WORKER_LAST_NAME}
        ${WORKER.WORKER_AVATAR_URL}
      }
      lastTenMessages{
        ${message_Fields}
        receiver {
          ${EMPLOYER.EMPLOYER_ID}
          ${EMPLOYER.EMPLOYER_FIRST_NAME}
          ${EMPLOYER.EMPLOYER_LAST_NAME}
          ${EMPLOYER.EMPLOYER_AVATAR_URL}
        }
         sender {
          ${WORKER.WORKER_ID}
          ${WORKER.WORKER_FIRST_NAME}
          ${WORKER.WORKER_LAST_NAME}
          ${WORKER.WORKER_AVATAR_URL}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getMessageDetailByMessageThreadId = (messageThreadId, offset) => {
  const message_Fields = messageFields.join(',');

  const query = `{
    messages(filter : { messageThreadId: "${messageThreadId}" }, offset: ${offset})
    {
      ${message_Fields}
      receiver {
        ${EMPLOYER.EMPLOYER_ID}
        ${EMPLOYER.EMPLOYER_FIRST_NAME}
        ${EMPLOYER.EMPLOYER_LAST_NAME}
        ${EMPLOYER.EMPLOYER_AVATAR_URL}
      }
      sender {
        ${WORKER.WORKER_ID}
        ${WORKER.WORKER_FIRST_NAME}
        ${WORKER.WORKER_LAST_NAME}
        ${WORKER.WORKER_AVATAR_URL}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getMessageThreadById = messageThreadId => {
  const messageThread_Fields = messageThreadFields.join(',');

  const query = `{
    messageThread(id: "${messageThreadId}")
    {
      ${messageThread_Fields}
    }
  }`;

  return graphQueryPromise(query);
};

export const addNewMessage = values => {
  const messageThread_Fields = messageThreadFields.join(',');
  const message_Fields = messageFields.join(',');
  const addInfo = handleUpdateString(values, addMessageFields, true);

  const query = `mutation {
    createMessage(${addInfo} ) {
      message{
        ${message_Fields}
        receiver {
          ${WORKER.WORKER_ID}
          ${WORKER.WORKER_FIRST_NAME}
          ${WORKER.WORKER_LAST_NAME}
          ${WORKER.WORKER_AVATAR_URL}
        }
        sender {
          ${EMPLOYER.EMPLOYER_ID}
          ${EMPLOYER.EMPLOYER_FIRST_NAME}
          ${EMPLOYER.EMPLOYER_LAST_NAME}
          ${EMPLOYER.EMPLOYER_AVATAR_URL}
        }
      }
      messageThread{
        ${messageThread_Fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const markAllMessagesAsRead = jobId => {
  const query = `mutation {
    markAllMessagesAsRead(jobId: "${jobId}" ) {
      success
    }
  }`;

  return graphMutationPromise(query);
};
