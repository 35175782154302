import get from 'lodash/get';
import { LOAD_PROGESSES_BY_REQUEST_TASK_ID } from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.TASK_PROGRESSES],
  action
) {
  switch (action.type) {
    case LOAD_PROGESSES_BY_REQUEST_TASK_ID.SUCCESS: {
      const taskProgresses = get(action, 'payload.taskProgresses', []);
      return [...taskProgresses];
    }
    case LOAD_PROGESSES_BY_REQUEST_TASK_ID.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
