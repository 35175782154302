export const ID = 'id';
export const DESCRIPTION = 'description';
export const NAME = 'name';
export const ACTIVE = 'active';
export const WORKPLACE_IMAGE_URL = 'workplaceImageUrl';
export const LOCATIONS_ATTRIBUTES = 'locationsAttributes';

export const FACILITY_GROUP_ID = 'facilityGroupId';
export const FACILITY_WORKPLACE_IMAGE_BASE64 = 'workplaceImageBase64';
export const FACILITY_WORKPLACE_IMAGE_FILENAME = 'workplaceImageFilename';

export const FACILITY_SIGNED_BLOB = 'workplaceImageSignedBlobId';
export const FACILITY_IDS = 'facilityIds';
