import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from 'routes/privateRoute';
import { urlMap } from 'routes/urlMap';

import NotificationRedirect from 'components/common/notificationRedirect/notificationRedirect';
import IdleTimer from 'react-idle-timer';
import IdleTimeOutModal from './idleTimeOutModal';
import Page404 from 'components/error/page404';
import { getUser } from 'redux/selectors';
import { getAvailbleRoute } from './helpers';

const WorkersList = React.lazy(() => import('pages/workersList/workersList'));
const EmployersList = React.lazy(() =>
  import('pages/employersList/employersList')
);
const ShiftList = React.lazy(() => import('pages/shiftList/shiftList'));
const ShiftRequestsList = React.lazy(() =>
  import('pages/shiftRequestsList/shiftRequestsList')
);
const CompanyInfo = React.lazy(() => import('pages/company/companyInfo'));
const NewShift = React.lazy(() => import('pages/newShift/newShift'));
const TeamsList = React.lazy(() => import('pages/teamsList/teamsList'));
const TasksList = React.lazy(() => import('pages/tasksList/tasksList'));
const LocationsList = React.lazy(() =>
  import('pages/locationsList/locationsList')
);
const BadgesList = React.lazy(() => import('pages/badgesList/badgesList'));
const Reports = React.lazy(() => import('pages/reports/reports'));
const Messages = React.lazy(() => import('pages/messages/messages'));
const RedirectMessageThread = React.lazy(() =>
  import('pages/messages/redirectMessageThread')
);
const RedirectShiftDetail = React.lazy(() =>
  import('pages/shiftsList/redirectShiftDetail')
);
const Profile = React.lazy(() => import('pages/profile/profile'));
const FacilitiesList = React.lazy(() =>
  import('pages/facilitiesList/facilitiesList')
);
const Onboarding = React.lazy(() => import('pages/onboarding/onboarding'));
const RedirectOnboarding = React.lazy(() =>
  import('pages/onboarding/redirectOnboarding')
);
const InterviewSchedule = React.lazy(() =>
  import('pages/onboarding/interviewSchedule')
);
const HelpAndFeedback = React.lazy(() => import('pages/helpAndFeedback'));
const JobDetail = React.lazy(() =>
  import('pages/jobPage').then(module => ({
    default: module.JobDetail
  }))
);
const JobList = React.lazy(() =>
  import('pages/jobPage').then(module => ({
    default: module.JobList
  }))
);
const DraftShiftRequestsList = React.lazy(() =>
  import('pages/draftShiftRequestsList/draftShiftRequestsList')
);
const Dashboard = React.lazy(() => import('pages/dashboard/dashboard'));
const WorkerPage = React.lazy(() => import('pages/workers'));

const DashboardPage = React.lazy(() =>
  import('pages/dashboardPage/dashboardPage')
);

class AuthLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 60 * 30,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSignout = this.handleSignout.bind(this);
  }

  _onAction(e) {
    //console.log('user did something', e);
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    //console.log('user is active', e);
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    //console.log('user is idle', e);
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.props.history.push(urlMap.SIGNOUT);
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleSignout() {
    this.setState({ showModal: false });
    this.props.history.push(urlMap.SIGNOUT);
  }

  render() {
    const { user } = this.props;

    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <Switch>
          {/* {getAvailbleRoute(user, urlMap.HOME) && (
            <AuthRoute exact path={urlMap.HOME} component={HomePage} />
          )} */}
          {getAvailbleRoute(user, urlMap.HOME) && (
            <AuthRoute exact path={urlMap.HOME} component={DashboardPage} />
          )}
          {getAvailbleRoute(user, urlMap.WORKERS_LIST) && (
            <AuthRoute
              exact
              path={urlMap.WORKERS_LIST}
              component={WorkersList}
            />
          )}

          {getAvailbleRoute(user, urlMap.WORKERS) && (
            <AuthRoute path={urlMap.WORKERS} component={WorkerPage} />
          )}
          {getAvailbleRoute(user, urlMap.SHIFTS_LIST) && (
            <AuthRoute exact path={urlMap.SHIFTS_LIST} component={ShiftList} />
          )}
          {getAvailbleRoute(user, urlMap.TASKS_LIST) && (
            <AuthRoute exact path={urlMap.TASKS_LIST} component={TasksList} />
          )}
          {getAvailbleRoute(user, urlMap.NEW_SHIFT) && (
            <AuthRoute exact path={urlMap.NEW_SHIFT} component={NewShift} />
          )}
          {getAvailbleRoute(user, urlMap.EDIT_SHIFT_REQUEST) && (
            <AuthRoute
              exact
              path={urlMap.EDIT_SHIFT_REQUEST}
              component={NewShift}
            />
          )}
          {getAvailbleRoute(user, urlMap.TEAMS_LIST) && (
            <AuthRoute exact path={urlMap.TEAMS_LIST} component={TeamsList} />
          )}
          {getAvailbleRoute(user, urlMap.LOCATIONS) && (
            <AuthRoute
              exact
              path={urlMap.LOCATIONS}
              component={LocationsList}
            />
          )}
          {getAvailbleRoute(user, urlMap.BADGES) && (
            <AuthRoute exact path={urlMap.BADGES} component={BadgesList} />
          )}
          {getAvailbleRoute(user, urlMap.REPORTS) && (
            <AuthRoute exact path={urlMap.REPORTS} component={Reports} />
          )}
          {getAvailbleRoute(user, urlMap.DASHBOARD) && (
            <AuthRoute exact path={urlMap.DASHBOARD} component={Dashboard} />
          )}
          {getAvailbleRoute(user, urlMap.MESSAGES) && (
            <AuthRoute exact path={urlMap.MESSAGES} component={Messages} />
          )}
          {getAvailbleRoute(user, urlMap.MESSAGE_DETAIL) && (
            <AuthRoute
              exact
              path={urlMap.MESSAGE_DETAIL}
              component={Messages}
            />
          )}
          {getAvailbleRoute(user, urlMap.MESSAGE_THREAD) && (
            <AuthRoute
              exact
              path={urlMap.MESSAGE_THREAD}
              component={RedirectMessageThread}
            />
          )}
          {getAvailbleRoute(user, urlMap.SHIFT_DETAIL) && (
            <AuthRoute
              exact
              path={urlMap.SHIFT_DETAIL}
              component={RedirectShiftDetail}
            />
          )}
          {getAvailbleRoute(user, urlMap.JOB_DETAIL) && (
            <AuthRoute exact path={urlMap.JOB_DETAIL} component={JobDetail} />
          )}
          {getAvailbleRoute(user, urlMap.WORKER_CONTRACT) && (
            <AuthRoute
              exact
              path={urlMap.WORKER_CONTRACT}
              component={RedirectOnboarding}
            />
          )}
          {getAvailbleRoute(user, urlMap.PROFILE) && (
            <AuthRoute exact path={urlMap.PROFILE} component={Profile} />
          )}
          {getAvailbleRoute(user, urlMap.EMPLOYERS_LIST) && (
            <AuthRoute
              exact
              path={urlMap.EMPLOYERS_LIST}
              component={EmployersList}
            />
          )}
          {getAvailbleRoute(user, urlMap.FACILITIES) && (
            <AuthRoute
              exact
              path={urlMap.FACILITIES}
              component={FacilitiesList}
            />
          )}
          {getAvailbleRoute(user, urlMap.COMPANY) && (
            <AuthRoute exact path={urlMap.COMPANY} component={CompanyInfo} />
          )}
          {getAvailbleRoute(user, urlMap.ONBOARDING) && (
            <AuthRoute exact path={urlMap.ONBOARDING} component={Onboarding} />
          )}
          {getAvailbleRoute(user, urlMap.INTERVIEW_SCHEDULE) && (
            <AuthRoute
              exact
              path={urlMap.INTERVIEW_SCHEDULE}
              component={InterviewSchedule}
            />
          )}
          {getAvailbleRoute(user, urlMap.IMPORT_WORKER_FILES) && (
            <AuthRoute
              exact
              path={urlMap.IMPORT_WORKER_FILES}
              component={NotificationRedirect}
            />
          )}
          {getAvailbleRoute(user, urlMap.IMPORT_EMPLOYER_FILES) && (
            <AuthRoute
              exact
              path={urlMap.IMPORT_EMPLOYER_FILES}
              component={NotificationRedirect}
            />
          )}
          {getAvailbleRoute(user, urlMap.JOBS) && (
            <AuthRoute exact path={urlMap.JOBS} component={JobList} />
          )}
          {getAvailbleRoute(user, urlMap.SHIFT_REQUESTS_LIST) && (
            <AuthRoute
              exact
              path={urlMap.SHIFT_REQUESTS_LIST}
              component={ShiftRequestsList}
            />
          )}
          {getAvailbleRoute(user, urlMap.DRAFT_SHIFT_REQUESTS) && (
            <AuthRoute
              exact
              path={urlMap.DRAFT_SHIFT_REQUESTS}
              component={DraftShiftRequestsList}
            />
          )}
          {getAvailbleRoute(user, urlMap.HELP_AND_FEEDBACK) && (
            <AuthRoute
              exact
              path={urlMap.HELP_AND_FEEDBACK}
              component={HelpAndFeedback}
            />
          )}
          <Route path="*" component={Page404} />
        </Switch>
        <IdleTimeOutModal
          modalIsOpen={this.state.showModal}
          closeHandler={this.handleClose}
          signoutHandler={this.handleSignout}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

export default connect(mapStateToProps)(AuthLayout);
