// API helper and mock functions
import { GraphQLClient } from 'graphql-request';
import { PAGE_ERROR } from 'common/constants/errorConstants';
import includes from 'lodash/includes';
import {
  getLocalToken,
  getPoliciesToken
} from 'common/helpers/auth/sessionToken';
import * as LOCATION from 'common/constants/locationConstants';
import * as TEAM from 'common/constants/teamConstants';
import * as SHIFT from 'common/constants/shiftConstants';
import isEmpty from 'lodash/isEmpty';
import { urlMap } from 'routes/urlMap';

const sessionToken = getLocalToken();
const policiesToken = getPoliciesToken();

// error statuses for which we always want to create page-level errors
const pageErrorStatusCodes = ['unauthorized'];

export const defaultClient = new GraphQLClient(
  `${process.env.PROXY_GRAPHQL_URL}/graphql`,
  { method: 'POST' }
);

export function post(url, data, headers = {}) {
  const body = data instanceof FormData ? data : JSON.stringify(data);

  const options = {
    method: 'POST',
    body,
    headers: {
      Accept: 'application/json',
      'X-Token': sessionToken,
      OPUS_POLICIES: policiesToken,
      ...headers
    }
  };
  return fetch(`${url}`, options).then(response => response.json());
}

export function get(url) {
  const options = {
    method: 'GET'
  };
  return fetch(url, options).then(response => response.json());
}

// Graph QL query wrapper
// includes error state detection
// returns a promise
// @todo: passing tokens via header;
// @todo: set cookie

const graphQueryBase = (client, query, variables = undefined) => {
  // graph QL helpers
  const isEmpty = data => {
    if (typeof data !== 'object') {
      return true;
    }
    const keys = Object.keys(data);
    return data[keys[0]] === null;
  };

  const hasErrors = data => Array.isArray(data.errors);

  return new Promise((resolve, reject) =>
    client
      .request(query, variables)
      .then(data => {
        try {
          if (isEmpty(data) || hasErrors(data)) {
            // throw error
            return reject(data);
          }
          // return data
          return resolve(data);
        } catch (e) {
          return reject(data);
        }
      })
      .catch(error => {
        if (
          window.Sentry &&
          typeof window.Sentry.captureException === 'function'
        ) {
          // Catch all exception & Send it into Sentry
          window.Sentry.withScope(scope => {
            scope.setExtra('data', JSON.stringify(error));
            window.Sentry.captureException(error);
          });
        }

        // return the error in the standard GraphQL format
        try {
          if (
            error.response.errors?.[0]?.extensions?.code === 403 &&
            error.response.errors?.[0]?.extensions?.type === 'unauthorization'
          ) {
            // All "Unauthorized" exception will navigation into signin page
            window.location.href = urlMap.SIGNIN;
          }

          // if our client library returns an error
          if (error.response.status === 500) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject({ errors: ['System Error'], type: PAGE_ERROR });
          }
          // it will have a response object that has a human readable array
          const reducerfn = (acc, e) =>
            acc === true || includes(pageErrorStatusCodes, e.status_code);
          const hasErrorPageError = error.response.errors.reduce(
            reducerfn,
            false
          );
          const pageType = hasErrorPageError ? PAGE_ERROR : 'app_error';
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ errors: error.response.errors, type: pageType });
        } catch (e) {
          // if that response object does not exist, we'll assume
          // it's a page level error and construct our own
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({ errors: [error.message], type: PAGE_ERROR });
        }
      })
  );
};

export const graphQueryPromise = (
  query,
  variables,
  requestHeaders = {},
  client = defaultClient
) => {
  const defaultHeaders = {
    Cookie: document.cookie,
    'Auth-Token': getLocalToken(),
    OPUS_POLICIES: getPoliciesToken(),
    GraphiQL_Authorization: process.env.PROXY_GRAPHQL_AUTHENTICATION
  };
  const headers = {
    ...defaultHeaders,
    ...requestHeaders
  };

  client.setHeaders(headers);
  return graphQueryBase(client, query, variables);
};

export const graphMutationPromise = (
  query,
  variables,
  requestHeaders = {},
  client = defaultClient
) => {
  const defaultHeaders = {
    Cookie: document.cookie,
    'Auth-Token': getLocalToken(),
    OPUS_POLICIES: getPoliciesToken(),
    GraphiQL_Authorization: process.env.PROXY_GRAPHQL_AUTHENTICATION
  };

  const headers = {
    ...defaultHeaders,
    ...requestHeaders
  };

  client.setHeaders(headers);
  return graphQueryBase(client, query, variables);
};

export const quoteStringValue = value => {
  if (typeof value === 'string') {
    return `"${value}"`;
  }
  return value;
};

export const prepAttributes = (fields, expectedShape) => {
  const fieldsWithValues = fields
    .split(',')
    .filter(field => expectedShape[field] !== undefined);

  return fieldsWithValues.map(field => {
    const value = expectedShape[field];
    if (typeof value === 'string') {
      return `${field}:"${expectedShape[field]}"`;
    }
    if (value instanceof Object) {
      const subObj = expectedShape[field];
      const subAttributes = Object.keys(subObj).map(key => {
        if (typeof subObj[key] === 'string') {
          return `${key}: "${subObj[key]}"`;
        }
        return `${key}: ${subObj[key]}`;
      });

      return `${field}: { ${subAttributes.join(', ')} }`;
    }
    return `${field}:${expectedShape[field]}`;
  });
};

export const handleUpdateString = (
  values,
  fields,
  isAddNew = false,
  specialFields = []
) => {
  let output = '';
  if (isAddNew) {
    const index = fields.indexOf('id');
    if (index !== -1) {
      fields.splice(index, 1);
    }
  }
  fields.forEach(field => {
    if (values[field]) {
      if (!isEmpty(specialFields)) {
        const index = specialFields.indexOf(field);
        if (index !== -1) {
          output += `${field} : ${values[field]}, `;
        } else {
          output = getString(field, output, values);
        }
      } else {
        output = getString(field, output, values);
      }
    }
  });
  return output;
};

const getString = (field, output, values) => {
  if (
    field === LOCATION.GEOFENCING_RADIUS ||
    field === TEAM.SHARE ||
    field === SHIFT.JOBS_NUMBER
  ) {
    output += `${field} : ${values[field]}, `;
  } else {
    output += `${field} : "${values[field]}", `;
  }

  return output;
};

export const handleArray = values => {
  let output = '';
  values.forEach(value => {
    output += `"${value}", `;
  });
  return output;
};

export const handleUpdateAttrString = (
  values,
  fields,
  isAddNew = false,
  specialFields = []
) => {
  let output = '';
  if (isAddNew) {
    const index = fields.indexOf('id');
    if (index !== -1) {
      fields.splice(index, 1);
    }
  }
  fields.forEach(field => {
    if (values[field] !== undefined && values[field] !== null) {
      if (!isEmpty(specialFields)) {
        const index = specialFields.indexOf(field);
        if (index !== -1) {
          output += `${field} : ${values[field]}, `;
        } else {
          output = getString(field, output, values);
        }
      } else {
        output = getString(field, output, values);
      }
    }
  });
  return output;
};
