import React from 'react';
import { ErrorMessage } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// @todo: use themeing here
const StyledErrorMessage = styled.div`
  color: red;
`;

const InlineError = ({ name, className }) => (
  <StyledErrorMessage className={className}>
    <ErrorMessage name={name} />
  </StyledErrorMessage>
);

InlineError.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

InlineError.defaultProps = {
  className: ''
};

export default InlineError;
