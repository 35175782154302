import {
  LOAD_ALL_SHIFT_REQUESTS,
  CANCEL_SHIFT_REQUEST,
  UPDATE_SHIFT_REQUEST,
  SEARCH_SHIFT_REQUESTS
} from 'redux/actions/actionTypes';
import {
  getAllShiftRequests,
  cancelShiftRequest,
  updateShiftRequest
} from 'api/shifts';
import {
  cancelShiftRequestSuccess,
  cancelShiftRequestFailure,
  updateShiftRequestSuccess,
  updateShiftRequestFailure,
  loadAllShiftRequestsSuccess,
  loadAllShiftRequestsFailure,
  searchShiftRequestsSuccess,
  searchShiftRequestsFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const shiftRequests = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_SHIFT_REQUESTS.REQUEST: {
      next(action);
      const { companyId, offset, field, order, filterVal } = action.payload;
      return apiPromise(
        store,
        action,
        getAllShiftRequests,
        [companyId, offset, null, filterVal, field, order],
        loadAllShiftRequestsSuccess,
        loadAllShiftRequestsFailure
      );
    }
    case SEARCH_SHIFT_REQUESTS.REQUEST: {
      next(action);
      const { companyId, keywordSearch, filterVal } = action.payload;
      return apiPromise(
        store,
        action,
        getAllShiftRequests,
        [companyId, null, keywordSearch, filterVal, null, null],
        searchShiftRequestsSuccess,
        searchShiftRequestsFailure
      );
    }
    case UPDATE_SHIFT_REQUEST.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateShiftRequest,
        [values],
        updateShiftRequestSuccess,
        updateShiftRequestFailure
      );
    }
    case CANCEL_SHIFT_REQUEST.REQUEST: {
      next(action);
      const { shiftRequestId } = action.payload;
      return apiPromise(
        store,
        action,
        cancelShiftRequest,
        [shiftRequestId],
        cancelShiftRequestSuccess,
        cancelShiftRequestFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default shiftRequests;
