import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
    query Notifications($filter: NotificationFilter, $offset: Int, $limit: Int) {
        notifications(filter: $filter, offset: $offset, limit: $limit) {
            id
            formattedMessage
            url
            isRead
            createdAt
            sender {
                ... on Worker {
                    avatarUrl
                    firstName
                    lastName
                }

                ... on Employer {
                    avatarUrl
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const GET_TOTAL_NOTIFICATIONS = gql`
    query {
        employerAppInit {
            totalUnreadNotification
        }
    }
`;
