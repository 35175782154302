export const EMPLOYER_ID = 'id';
export const EMPLOYER_FIRST_NAME = 'firstName';
export const EMPLOYER_LAST_NAME = 'lastName';
export const EMPLOYER_PHONE = 'phone';
export const EMPLOYER_EMAIL = 'email';
export const EMPLOYER_AVATAR_URL = 'avatarUrl';
export const EMPLOYER_COMPANY_ID = 'companyId';
export const EMPLOYER_PASSWORD = 'password';
export const EMPLOYER_PASSWORD_CONFIRM = 'confirmPassword';
export const EMPLOYER_FACILITY_ID = 'facilityId';
export const EMPLOYER_LAST_SIGN_IN_AT = 'lastSignInAt';
export const EMPLOYER_TITLE = 'title';
export const EMPLOYER_FACILITIES = 'employerFacilities';

export const EMPLOYER_SCOPE = 'scope';

export const EMPLOYER_AVATAR_IMAGE_BASE64 = 'avatarImageBase64';
export const EMPLOYER_AVATAR_IMAGE_FILENAME = 'avatarImageFileName';
export const AVATAR_SIGNED_BLOB_ID = 'avatarSignedBlobId';

export const ADMIN = 'Admin';
export const MANAGER = 'Manager';
export const ONSITE_MANAGER = 'Onsite Manager';

export const ADMIN_VAL = 'admin';
export const MANAGER_VAL = 'manager';
export const ONSITE_MANAGER_VAL = 'onsite_manager';
