export const ID = 'id';
export const MEETING_URL = 'meetingUrl';
export const PERMITTED_EVENTS = 'permittedEvents';
export const PHONE = 'phone';
export const REASON_FOR_CANCELLATION = 'reasonForCancellation';
export const RESCHEDULE_INTERVIEW_URL = 'rescheduleInterviewUrl';
export const STATE = 'state';
export const EVENT_TYPE_NAME = 'eventTypeName';
export const EVENT_START_TIME = 'eventStartTime';
export const EVENT_END_TIME = 'eventEndTime';
export const COMPANY = 'company';
export const CANCELED_BY = 'canceledBy';
export const CANCEL_INTERVIEW_URL = 'cancelInterviewUrl';

//permitted actions
export const CANCEL = 'cancel';
export const COMPLETE = 'complete';
export const FAIL = 'fail';

