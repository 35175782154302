// user-related API definitions

import { graphQueryPromise } from './helpers';

const currentUserFields = [
  'id',
  'customerAdmin',
  'avatarUrl',
  'name',
  'title',
  'phone',
  'email',
  'customer',
  'company',
  'companies',
  'phoneVerifiedAt',
  'companyAdmin',
  'readOnly',
  'firstName',
  'lastName',
];

// eslint-disable-next-line import/prefer-default-export
export const getCurrentUserInformation = () => {
  const fields = currentUserFields.join(',');
  const query = `{
    CurrentUser(id: 1) {
      ${fields}
    }
  }`;
  return graphQueryPromise(query, { rawQuery: true });
};
