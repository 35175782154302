import {
  graphQueryPromise,
  handleUpdateString,
  graphMutationPromise,
  handleArray
} from './helpers';
import * as TEAM from 'common/constants/teamConstants';
import * as WORKER from 'common/constants/workerConstants';
import isEmpty from 'lodash/isEmpty';

const teamFields = [
  TEAM.ID,
  TEAM.NAME,
  TEAM.DESCRIPTION,
  TEAM.EMPLOYER_ID,
  TEAM.SHARE
];

const addTeamFields = [
  TEAM.ID,
  TEAM.NAME,
  TEAM.DESCRIPTION,
  TEAM.SHARE,
  TEAM.FACILITY_ID
];

const updateTeamFields = [TEAM.ID, TEAM.NAME, TEAM.DESCRIPTION, TEAM.SHARE];

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_EMAIL,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE,
  WORKER.WORKER_OVERALL_RATING
];

export const getAllTeams = (
  badgeIds = null,
  offset = 0,
  limit = 20,
  searchString = '',
  sortString = ''
) => {
  const team_fields = teamFields.join(',');
  const worker_fields = workerFields.join(',');

  let query = `{
      teams(offset: ${offset}, limit: ${limit} ${searchString} ${sortString}) {
          ${team_fields}
          workers{
            ${worker_fields}
          }
      }
    }
  `;

  if (!isEmpty(badgeIds)) {
    const badge_ids = handleArray(badgeIds);
    query = `{
      teams(filter: { badgeIds: [${badge_ids}] }, limit: ${limit}, offset: ${offset} ${searchString} ${sortString}  ) {
          ${team_fields}
          workers (badgeIds: [${badge_ids}] ){
            ${worker_fields}
          }
      }
    }
    `;
  }

  return graphQueryPromise(query);
};

export const addNewTeam = values => {
  const fields = teamFields.join(',');
  let workersStr = '';
  if (values[TEAM.WORKER_IDS]) {
    const worker_ids = handleArray(values[TEAM.WORKER_IDS]);
    workersStr = `workerIds: [${worker_ids}]`;
  }

  const updates = handleUpdateString(values, addTeamFields, true);
  const worker_fields = workerFields.join(',');

  const query = `mutation {
    createTeam(${updates} ${workersStr}) {
      ${fields}
      workers{
        ${worker_fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const updateTeam = values => {
  const fields = teamFields.join(',');
  let workersStr = '';
  if (values[TEAM.WORKER_IDS]) {
    const worker_ids = handleArray(values[TEAM.WORKER_IDS]);
    workersStr = `workerIds: [${worker_ids}]`;
  }

  const updates = handleUpdateString(values, updateTeamFields);
  const worker_fields = workerFields.join(',');

  const query = `mutation {
    updateTeam(${updates} ${workersStr}) {
      ${fields}
      workers{
        ${worker_fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const removeTeam = teamId => {
  const query = `mutation {
    destroyTeam(id : "${teamId}") {
      success
    }
  }`;

  return graphMutationPromise(query);
};
