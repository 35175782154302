export const IMAGE_UPLOAD_FINGERPRINT = 'imageUpload';
export const SHOW_WORKERS_FINGERPRINT = 'workers.WorkerList.show';
export const SHOW_JOBS_FINGERPRINT = 'shiftsList.jobs.show';
export const SHOW_EMPLOYERS_FINGERPRINT = 'employer.EmployerList.show';
export const SIGNIN_FINGERPRINT = 'components.auth.signin';
export const SHOW_COMPANY_FINGERPRINT = 'showCompany';
export const EDIT_COMPANY_FINGERPRINT = 'editCompany';
export const VERIFY_TOKEN_FINGERPRINT = 'auth.verifyToken';
export const SHOW_TEAMS_FINGERPRINT = 'teams.teamsList.show';
export const SHOW_LOCATIONS_FINGERPRINT = 'locations.locationsList.show';
export const SHOW_REPORTS_FINGERPRINT = 'showReports';
export const SHOW_MESSAGES_FINGERPRINT = 'messages.show';
export const SHOW_MESSAGE_DETAIL_FINGERPRINT = 'messages.detail.show';
export const ADD_WORKER_FINGERPRINT = 'worker.addNew';
export const ADD_WORKER_ACCOUNT_FINGERPRINT = 'addWorkerAccount';
export const UPDATE_WORKER_FINGERPRINT = 'worker.update';
export const ADD_EMPLOYER_FINGERPRINT = 'employer.addNew';
export const UPDATE_EMPLOYER_FINGERPRINT = 'employer.update';
export const IMPORT_EMPLOYER_FINGERPRINT = 'importEmployerId';
export const IMPORT_WORKER_FINGERPRINT = 'importWorkerId';
export const IMPORT_LOCATION_FINGERPRINT = 'importLocationId';
export const ADD_LOCATION_FINGERPRINT = 'addLocation';
export const ADD_LOCATION_IN_FACILITY_FINGERPRINT = 'addLocationInFacility';
export const UPDATE_LOCATION_FINGERPRINT = 'updateLocation';
export const DEACTIVATE_LOCATION_FINGERPRINT = 'location.deactivate';
export const SHOW_SHIFT_CATEGORIES_FINGERPRINT = 'shiftCategories.show';
export const CREATE_SHIFT_REQUEST_FINGERPRINT = 'createShiftRequest';
export const CREATE_JOB_RATING_FINGERPRINT = 'createJobRating';
export const UPDATE_JOB_RATING_FINGERPRINT = 'updateJobRating';
export const CREATE_JOB_RATING_REPORT_FINGERPRINT = 'createJobRatingReport';
export const UPDATE_JOB_RATING_REPORT_FINGERPRINT = 'updateJobRatingReport';
export const CANCEL_SHIFT_FINGERPRINT = 'cancelShift';
export const UPDATE_JOB_STATE_FINGERPRINT = 'updateJob';
export const SHOW_ACTIVE_JOBS_FINGERPRINT = 'showAllActiveJobs';
export const SHOW_WORKER_LOCATIONS_FINGERPRINT = 'showWorkerLocations';
export const SHOW_FACILITIES_FINGERPRINT = 'facilities.facilitiesList.show';
export const UPDATE_FACILITY_FINGERPRINT = 'facility.update';
export const ADD_FACILITY_FINGERPRINT = 'facility.addNew';
export const DEACTIVATE_FACILITY_FINGERPRINT = 'deactivateFacility';
export const BLOCK_WORKER_FINGERPRINT = 'blockWorker';
export const SHOW_FACILITY_GROUP_FINGERPRINT = 'facilityGroup.show';
export const INVITE_WORKER_FINGERPRINT = 'inviteWorker';
export const UPDATE_REPORT_FINGERPRINT = 'updateReport';
export const ADD_WORKER_FOR_SHIFT_FINGERPRINT = 'addWorkerForShift';
export const ADD_MESSAGE_FINGERPRINT = 'newMessage';
export const FORGOT_PASSWORD_FINGERPRINT = 'forgotPassword';
export const RESET_PASSWORD_FINGERPRINT = 'resetPassword';
export const UPDATE_TEAM_FINGERPRINT = 'updateTeam';
export const ADD_TEAM_FINGERPRINT = 'addTeam';
export const DEACTIVATE_TEAM_FINGERPRINT = 'deactivateTeam';
export const REMOVE_WORKER_OUT_TEAM_FINGERPRINT = 'removeWorkerOutTeam';
export const LOAD_SHIFT_REQUEST_BY_ID_FINGERPRINT = 'loadShiftRequestById';
export const SHOW_SHIFT_REQUESTS_FINGERPRINT = 'shiftRequests.show';
export const SHOW_MORE_SHIFT_REQUESTS_FINGERPRINT = 'shiftRequests.showMore';
export const SEARCH_SHIFT_REQUEST_FINGERPRINT = 'searchShiftRequest';
export const SEARCH_SHIFTS_FINGERPRINT = 'searchShifts';
export const TASK_LIST_LOAD_MORE_FINGERPRINT = 'taskRequests.showMore';
export const UPDATE_SHIFT_FINGERPRINT = 'updateShift';
export const SHOW_BADGES_FINGERPRINT = 'badges.show';
export const UPDATE_SHIFT_REQUEST_FINGERPRINT = 'updateShiftRequest';
export const CANCEL_SHIFT_REQUEST_FINGERPRINT = 'cancelShiftRequest';
export const SHOW_REQUEST_TASKS_FINGERPRINT = 'showRequestTasks';
export const SHOW_MORE_REQUEST_TASKS_FINGERPRINT = 'showMoreRequestTasks';
export const SHOW_TASK_PROGRESSES_FINGERPRINT = 'showTaskProgresses';
export const ADD_REQUEST_TASK_FINGERPRINT = 'addRequestTaskRequest';
export const UPDATE_REQUEST_TASK_FINGERPRINT = 'updateRequestTaskRequest';
export const CANCEL_REQUEST_TASK_FINGERPRINT = 'cancelRequestTaskRequest';
export const CHANGE_PASSWORD_FINGERPRINT = 'changePassword';
export const ADD_SHIFT_CATEGORY_FINGERPRINT = 'addShiftCategory';
export const SHIFT_LIST_LOAD_MORE_FINGERPRINT = 'shiftListLoadMore';
export const SHIFT_REQUEST_LIST_LOAD_MORE_FINGERPRINT =
  'shiftRequestListLoadMore';
export const MARK_ALL_READ_BY_JOB_ID_FINGERPRINT = 'markAllReadByJobId';
export const SHOW_WORKER_ONBOARDINGS_FINGERPRINT = 'showWorkerOnboardings';
export const SEARCH_WORKER_ONBOARDINGS_FINGERPRINT = 'searchWorkerOnboardings';
export const RESEND_INVITES_EMPLOYERS_FINGERPRINT = 'resendInvitesEmployers';
export const RESEND_INVITES_WORKERS_FINGERPRINT = 'resendInvitesWorkers';
export const SHOW_SHIFT_REQUESTS_AFTER_SEARCH_FINGERPRINT =
  'showShiftRequestsAfterSearch';
export const SHOW_SHIFTS_AFTER_SEARCH_FINGERPRINT = 'showShiftsAfterSearch';
export const SHOW_UPCOMING_SCHEDULES_FINGERPRINT = 'showUpcomingSchedules';
export const SHOW_PAST_SCHEDULES_FINGERPRINT = 'showPastSchedules';
export const SHOW_WORKER_CONTRACT_FINGERPRINT = 'showWorkerContract';
export const SHOW_SHIFTS_FINGERPRINT = 'showShifts';
export const SHOW_SHIFT_BY_ID_FINGERPRINT = 'showShiftById';
export const IMPORT_WORKER_STATUS_FINGERPRINT = 'importWorkerStatus';
export const IMPORT_EMPLOYER_STATUS_FINGERPRINT = 'importEmployerStatus';
export const SHOW_MESSAGE_THREAD_DETAIL_FINGERPRINT = 'showMessageThreadDetail';
export const UPDATE_CANCEL_WORKER_ONBOARDINGS_STATE_FINGERPRINT =
  'updateCancelWorkerOnboardingState';
export const UPDATE_PASS_COMING_SCHEDULE_FINGERPRINT =
  'updatePassComingSchedule';
export const UPDATE_FAIL_COMING_SCHEDULE_FINGERPRINT =
  'updateFailComingSchedule';
export const UPDATE_PASS_PAST_SCHEDULE_FINGERPRINT = 'updatePassPastSchedule';
export const UPDATE_FAIL_PAST_SCHEDULE_FINGERPRINT = 'updateFailPastSchedule';
export const SHOW_RECRUIT_FIRST_THEME_FINGERPRINT = 'RecruitFirst';
export const SHOW_KAUFMAN_LYNN_THEME_FINGERPRINT = 'KaufmanLynn';
export const SHOW_OPUS_THEME_FINGERPRINT = 'Opus';
export const SHOW_HIRE_PRIVY_THEME_FINGERPRINT = 'HirePrivy';
export const SHOW_MORE_SHIFTS_FINGERPRINT = 'shiftsShowMore';
export const SHOW_MORE_REPORTS_FINGERPRINT = 'reportsShowMore';
export const SHOW_MORE_WORKERS_FINGERPRINT = 'workersShowMore';
export const SHOW_WORKERS_ON_REPORTS_FINGERPRINT = 'workersOnReportShow';
export const GET_ALL_JOB_FINGERPRINT = 'GET_ALL_JOB_FINGERPRINT';
export const SUBMIT_FEEDBACK_FINGERPRINT = 'submitFeedback';
export const ADD_BADGE_FINGERPRINT = 'addNewBadge';
export const UPDATE_BADGE_FINGERPRINT = 'updateBadge';
export const RESEND_NOTIFICATION_FINGERPRINT = 'resendNotification';

export const CREATE_DRAFT_SHIFT_REQUEST_FINGERPRINT = 'createDraftShiftRequest';
export const UPDATE_DRAFT_SHIFT_REQUEST_FINGERPRINT = 'updateDraftShiftRequest';