import {
  graphMutationPromise,
  graphQueryPromise,
  handleUpdateString
} from './helpers';
import * as AUTH from 'common/constants/authConstants';
import {
  EMPLOYER_ID,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME,
  EMPLOYER_PHONE,
  EMPLOYER_EMAIL,
  EMPLOYER_AVATAR_URL,
  EMPLOYER_TITLE,
  EMPLOYER_LAST_SIGN_IN_AT
} from 'common/constants/employerConstants';
import * as FACILITY from 'common/constants/facilityConstants';
import * as FEEDBACK from 'common/constants/feedbackConstants';

const signinFields = [
  AUTH.AUTH_LOGIN_ID,
  AUTH.AUTH_LOGIN_PASSWORD,
  AUTH.AUTH_RECAPTCHA_TOKEN,
  AUTH.COMPANY_ID
];

const resetPasswordFields = [
  AUTH.TOKEN,
  AUTH.AUTH_LOGIN_PASSWORD,
  AUTH.PASSWORD_CONFIRMATION,
  AUTH.AUTH_RECAPTCHA_TOKEN
];

const changePasswordFields = [
  AUTH.OLD_PASSWORD,
  AUTH.AUTH_LOGIN_PASSWORD,
  AUTH.PASSWORD_CONFIRMATION
];

const employerFields = [
  EMPLOYER_ID,
  EMPLOYER_AVATAR_URL,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME,
  EMPLOYER_EMAIL,
  EMPLOYER_PHONE,
  EMPLOYER_TITLE,
  EMPLOYER_LAST_SIGN_IN_AT
];

const facilityFields = [
  FACILITY.ID,
  FACILITY.NAME,
  FACILITY.DESCRIPTION,
  FACILITY.WORKPLACE_IMAGE_URL
];

const submitFeedbackFields = [
  FEEDBACK.SUBJECT,
  FEEDBACK.COMMENTS,
  FEEDBACK.ATTACHMENT_IMAGE_BASE64,
  FEEDBACK.ATTACHMENT_IMAGE_FILE_NAME,
  FEEDBACK.ATTACHMENT_BLOB_ID
];

export const signinUser = values => {
  const facility_fields = facilityFields.join(',');
  const employer_fields = employerFields.join(',');
  const signinValues = handleUpdateString(values, signinFields);

  const query = `
    mutation {
      signInEmployer(${signinValues}) {
        employer{
          ${employer_fields}
          employerFacilities{
            facility{
              ${facility_fields}
            }
          }
          company {
            id
            hasCalendlyIntegration
            hasHelloSignIntegration
            companyConfig {
              importEmployerTemplateUrl
              importWorkerTemplateUrl
              oneSignalAppId
              payRangeJob
              webOpusPolicies
              phoneSupport
              employerTermsOfService {
                url
                version
              }
            }
          }
          agreedToTerms
        }
        authToken
      }
    }
  `;

  return graphMutationPromise(query);
};

export const verifyToken = token => {
  const facility_fields = facilityFields.join(',');
  const employer_fields = employerFields.join(',');

  const query = `
    query {
      employerToken(token: "${token}"){
          token
          employer {
            ${employer_fields}
            employerFacilities{
              facility{
                ${facility_fields}
              }
            }
            company {
              id
              hasCalendlyIntegration
              hasHelloSignIntegration
              companyConfig {
                importEmployerTemplateUrl
                importWorkerTemplateUrl
                phoneSupport
                oneSignalAppId
                payRangeJob
                employerTermsOfService {
                  url
                  version
                }
              }
            }
            agreedToTerms
        	}
      }
    }
  `;

  return graphQueryPromise(query);
};

export const forgotPassword = email => {
  const query = `mutation {
    sendPasswordResetEmployer(email: "${email}") {
      success
    }
  }`;

  return graphMutationPromise(query);
};

export const resetPassword = values => {
  const updates = handleUpdateString(values, resetPasswordFields);

  const query = `mutation {
    resetPasswordForUser(${updates}) {
      success
    }
  }`;

  return graphMutationPromise(query);
};

export const changePassword = values => {
  const updates = handleUpdateString(values, changePasswordFields);

  const query = `mutation {
    changePasswordForUser(${updates}) {
      success
    }
  }`;

  return graphMutationPromise(query);
};

export const signoutUser = () => {
  const query = `
    mutation {
      signOutEmployer {
        success
      }
    }
  `;

  return graphMutationPromise(query);
};

export const agreeToTerms = id => {
  const query = `
    mutation {
      updateEmployer(id: "${id}", agreedToTerms: true) {
        agreedToTerms
      }
    }
  `;

  return graphMutationPromise(query);
};

export const createFeedbackEmail = values => {
  const feedbackValues = handleUpdateString(values, submitFeedbackFields, true);

  const query = `mutation {
    createFeedbackEmail(${feedbackValues}) {
      success
    }
  }`;

  return graphMutationPromise(query);
};
