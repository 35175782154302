import { ERRORS } from 'redux/store/constants';
import get from 'lodash/get';
import * as types from 'redux/actions/actionTypes';
import initialState from 'redux/reducers/initialState';
import { PAGE_ERROR } from 'common/constants/errorConstants';

export default function(state = initialState[ERRORS], action) {
  const errors = get(action, 'payload.errors', []);
  const fingerprint = get(action, 'meta.fingerprint');

  if (action.type === types.LOCATION_CHANGE) {
    return initialState[ERRORS];
  }

  if (action.type === types.CLEAR_ERROR_STATE) {
    if (state[PAGE_ERROR]) {
      //Incase get 500 internal server error
      return { [PAGE_ERROR]: state[PAGE_ERROR] };
    } else {
      return initialState[ERRORS];
    }
  }

  if (!fingerprint) {
    return state;
  }

  if (errors.length > 0 && fingerprint) {
    const isPageError = get(action, 'payload.type', null) === PAGE_ERROR;

    const nextFingerprint = isPageError ? PAGE_ERROR : fingerprint;
    return {
      ...state,
      [nextFingerprint]: errors
    };
  }

  // if fingerprint and no error
  const newState = Object.assign({}, state);
  delete newState[fingerprint];
  return newState;
}
