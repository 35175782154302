import {
  handleUpdateString,
  graphMutationPromise,
  graphQueryPromise,
  handleArray
} from './helpers';
import * as REQUEST_TASK from 'common/constants/requestTaskConstants';
import * as WORKER from 'common/constants/workerConstants';
import isEmpty from 'lodash/isEmpty';

const requestTaskFields = [
  REQUEST_TASK.ID,
  REQUEST_TASK.NAME,
  REQUEST_TASK.DESCRIPTION,
  REQUEST_TASK.COMPLETION_PERCENTAGE,
  REQUEST_TASK.ASSIGNED_FOR_TEAM,
  REQUEST_TASK.SHIFT_REQUEST_ID,
  REQUEST_TASK.SHIFT_REQUEST_NAME,
  REQUEST_TASK.COMPANY_ID,
  REQUEST_TASK.STATUS_COLOR,
  REQUEST_TASK.TASK_PROGRESSES_COUNT,
  REQUEST_TASK.FOR_ALL_WORKERS,
  REQUEST_TASK.PERMITTED_EVENTS
];

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_EMAIL,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE
];

const addRequestTaskFields = [
  REQUEST_TASK.NAME,
  REQUEST_TASK.DESCRIPTION,
  REQUEST_TASK.SHIFT_REQUEST_ID
];

const updateRequestTaskFields = [
  REQUEST_TASK.ID,
  REQUEST_TASK.NAME,
  REQUEST_TASK.DESCRIPTION
];

export const addNewRequestTask = values => {
  const fields = requestTaskFields.join(',');
  const worker_fields = workerFields.join(',');

  let assignedWorkersStr = '';
  if (
    !isEmpty(values[REQUEST_TASK.ASSIGNED_WORKERS_IDS]) &&
    !values[REQUEST_TASK.FOR_ALL_WORKERS]
  ) {
    const worker_ids = handleArray(values[REQUEST_TASK.ASSIGNED_WORKERS_IDS]);
    assignedWorkersStr = `assignedWorkerIds: [${worker_ids}]`;
  }

  const forAllWorkers = `forAllWorkers: ${
    values[REQUEST_TASK.FOR_ALL_WORKERS]
  }`;
  const updates = handleUpdateString(values, addRequestTaskFields, true);

  const query = `mutation {
      createRequestTask(${updates} ${assignedWorkersStr} ${forAllWorkers}) {
        ${fields}
        assignedWorkers {
          ${worker_fields}
        }
      }
    }`;

  return graphMutationPromise(query);
};

export const updateRequestTask = values => {
  const fields = requestTaskFields.join(',');
  const worker_fields = workerFields.join(',');

  let assignedWorkersStr = '';
  if (
    !isEmpty(values[REQUEST_TASK.ASSIGNED_WORKERS_IDS]) &&
    !values[REQUEST_TASK.FOR_ALL_WORKERS]
  ) {
    const worker_ids = handleArray(values[REQUEST_TASK.ASSIGNED_WORKERS_IDS]);
    assignedWorkersStr = `assignedWorkerIds: [${worker_ids}]`;
  }

  const forAllWorkers = `forAllWorkers: ${
    values[REQUEST_TASK.FOR_ALL_WORKERS]
  }`;
  const updates = handleUpdateString(values, updateRequestTaskFields, false);

  const query = `mutation {
      updateRequestTask(${updates} ${assignedWorkersStr} ${forAllWorkers}) {
        ${fields}
        assignedWorkers {
          ${worker_fields}
        }
      }
    }`;

  return graphMutationPromise(query);
};

export const getAllRequestTasks = offset => {
  const fields = requestTaskFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `{
      requestTasks(offset: ${offset}) {
          ${fields}
          assignedWorkers {
            ${worker_fields}
          }
      }
    }
  `;

  return graphQueryPromise(query);
};

export const removeRequestTask = requestTaskId => {
  const query = `mutation {
    cancelRequestTask(id : "${requestTaskId}") {
      success
    }
  }`;

  return graphMutationPromise(query);
};
