import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { Drawer, Hidden, Paper } from '@material-ui/core';
import Logo from 'components/common/imageLink/imageLink';
import useRouter from 'hooks/useRouter.js';
import { withTheme } from 'styled-components';
import Navigation from '../navigation/index';
import navigationConfig from './navigationConfig';
import { useStyles, WrapperSidebar } from './styledSideBar';

const SideBar = props => {
  const { openMobile, onMobileClose, className, theme, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);
  const logo = theme.icons.header.logo;
  const sidebarContent = (
    <WrapperSidebar className={classes.content}>
      <div className={classes.profile}>
        <Logo className="logo" src={logo} />
      </div>
      <nav className={classes.navigation}>
        {navigationConfig.map((list, index) => (
          <Navigation
            component="div"
            key={index}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </WrapperSidebar>
  );

  return (
    <div>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            {sidebarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          {sidebarContent}
        </Paper>
      </Hidden>
    </div>
  );
};

SideBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default withTheme(SideBar);
