import * as types from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { TOKEN_KEY, WEB_POLICIES } from 'common/constants/authConstants';

export default function(state = initialState[storeConstants.AUTH], action) {
  switch (action.type) {
    case types.USER_LOGIN.SUCCESS:
      const token = action.payload.signInEmployer.authToken;
      const policies =
        action.payload.signInEmployer?.employer?.company?.companyConfig
          ?.webOpusPolicies;

      localStorage.setItem(TOKEN_KEY, token);
      sessionStorage.setItem(WEB_POLICIES, policies);
      return {
        authenticated: true
      };
    case types.AUTH_USER.SUCCESS:
      const returnToken = action.payload.employerToken.token;
      let isValid = false;
      if (returnToken !== null) isValid = !isValid;

      return {
        authenticated: isValid
      };
    case types.AUTH_USER.FAILURE:
      return {
        authenticated: false
      };
    case types.UNAUTH_USER.SUCCESS:
    case types.UNAUTH_USER.FAILURE:
      localStorage.removeItem(TOKEN_KEY);
      return {
        authenticated: false
      };
    default:
      return state;
  }
}
