import get from 'lodash/get';
import {
  LOAD_ALL_JOBS,
  CREATE_JOB_RATING,
  UPDATE_JOB_RATING,
  UPDATE_JOB,
  CREATE_JOB,
  LOAD_JOB_BY_ID
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';
import {
  CREATE_JOB_RATING_FINGERPRINT,
  CREATE_JOB_RATING_REPORT_FINGERPRINT,
  UPDATE_JOB_RATING_FINGERPRINT,
  UPDATE_JOB_RATING_REPORT_FINGERPRINT
} from 'common/constants/fingerprintConstants';

export default function(state = initialState[storeConstants.JOBS], action) {
  switch (action.type) {
    case LOAD_ALL_JOBS.SUCCESS: {
      let jobs = get(action, 'payload.jobs', []);
      return [...jobs];
    }
    case LOAD_JOB_BY_ID.SUCCESS: {
      const job = get(action, 'payload.job', {});
      let jobs = [];
      jobs.push(job);
      return [...jobs];
    }
    case CREATE_JOB_RATING.SUCCESS: {
      const jobRating = get(action, 'payload.createJobRating', {});
      const { rating, note, ratingUserType, job } = jobRating;
      let fingerprint = get(action, 'meta.fingerprint');

      if (
        fingerprint === CREATE_JOB_RATING_REPORT_FINGERPRINT ||
        fingerprint === CREATE_JOB_RATING_FINGERPRINT
      ) {
        let jobs = state.map(jobItem => {
          if (jobItem.id === job.id) {
            const ratingObj = {
              note: note,
              rating: rating,
              ratingUserType: ratingUserType
            };

            jobItem.jobRatings.push(ratingObj);
            return jobItem;
          }
          return jobItem;
        });

        return [...jobs];
      }

      return state;
    }
    case UPDATE_JOB_RATING.SUCCESS: {
      const updatedJobRating = get(action, 'payload.updateJobRating', {});
      const { id, rating, note, ratingUserType, job } = updatedJobRating;
      let fingerprint = get(action, 'meta.fingerprint');

      if (
        fingerprint === UPDATE_JOB_RATING_REPORT_FINGERPRINT ||
        fingerprint === UPDATE_JOB_RATING_FINGERPRINT
      ) {
        let jobs = state.map(jobItem => {
          if (jobItem.id === job.id) {
            const ratingObj = {
              id: id,
              note: note,
              rating: rating,
              ratingUserType: ratingUserType
            };

            jobItem.jobRatings = jobItem.jobRatings.map(ratingItem => {
              if (ratingItem.id === ratingObj.id) return ratingObj;
              return ratingItem;
            });
            return jobItem;
          }
          return jobItem;
        });

        return [...jobs];
      }

      return state;
    }
    case LOAD_ALL_JOBS.FAILURE:
    case LOAD_JOB_BY_ID.FAILURE:
    case CREATE_JOB_RATING.FAILURE:
    case UPDATE_JOB_RATING.FAILURE:
    case UPDATE_JOB.FAILURE:
    case CREATE_JOB.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
