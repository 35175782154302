import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import ImageCropUploadInputContainer from 'components/common/form/inputs/ImageCropUploadInput/container';

const ImageCropUploadField = ({
  name,
  label,
  modalTitle,
  isLoadingUploadImage,
  setIsLoadingUploadImage,
  signedBlobId,
  aspect = 1,
  error,
  isBlob = false
}) => (
  <React.Fragment>
    <Field
      isBlob={isBlob}
      isLoadingUploadImage={isLoadingUploadImage}
      setIsLoadingUploadImage={setIsLoadingUploadImage}
      id={name}
      name={name}
      component={ImageCropUploadInputContainer}
      modalTitle={modalTitle}
      label={label}
      signedBlobId={signedBlobId}
      aspect={aspect}
      error={error}
    />
  </React.Fragment>
);

ImageCropUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  base64FieldName: PropTypes.string,
  fileFieldName: PropTypes.string,
  error: PropTypes.string
};

export default ImageCropUploadField;
