import { gql } from '@apollo/client';
import { DIRECT_UPLOAD_FRAGMENT } from 'common/apollo/apollo.fragment.js';

export const CREATE_DIRECT_UPLOAD_MUTATION = gql`
  mutation createDirectUpload(
    $filename: String!
    $byteSize: Int!
    $checksum: String!
    $contentType: String!
  ) {
    createDirectUpload(
      filename: $filename
      byteSize: $byteSize
      checksum: $checksum
      contentType: $contentType
    ) {
      ...directUploadFragment
    }
  }
  ${DIRECT_UPLOAD_FRAGMENT}
`;
export const DESTROY_FILE_MUTATION = gql`
  mutation destroyFile($id: String!) {
    destroyFile(id: $id) {
      id
      success
    }
  }
`;
