import {
  graphQueryPromise,
  graphMutationPromise,
  handleUpdateString
} from './helpers';
import * as LOCATION from 'common/constants/locationConstants';

const locationFields = [
  LOCATION.ID,
  LOCATION.ACTIVE,
  LOCATION.ADDRESS,
  LOCATION.ADDRESS_STATE,
  LOCATION.CITY,
  LOCATION.FACILITY_ID,
  LOCATION.FACILITY_GROUP_ID,
  LOCATION.GEOFENCING_RADIUS,
  LOCATION.LATITUDE,
  LOCATION.LONGITUDE,
  LOCATION.TIMEZONE,
  LOCATION.NAME,
  LOCATION.PHONE,
  LOCATION.ZIPCODE,
  LOCATION.COUNTRY
];

const addEmployerFields = [
  LOCATION.ID,
  LOCATION.ADDRESS,
  LOCATION.CITY,
  LOCATION.ZIPCODE,
  LOCATION.ADDRESS_STATE,
  LOCATION.NAME,
  LOCATION.COUNTRY,
  LOCATION.ACTIVE,
  LOCATION.PHONE,
  LOCATION.FACILITY_ID,
  LOCATION.LATITUDE,
  LOCATION.LONGITUDE
];

const updateLocationFields = [
  LOCATION.ID,
  LOCATION.ADDRESS,
  LOCATION.CITY,
  LOCATION.ZIPCODE,
  LOCATION.ADDRESS_STATE,
  LOCATION.NAME,
  LOCATION.COUNTRY,
  LOCATION.ACTIVE,
  LOCATION.PHONE,
  LOCATION.LATITUDE,
  LOCATION.LONGITUDE
];

export const getAllLocations = (
  companyId,
  offset = 0,
  limit = 100,
  search = '',
  facilityId = null,
  filter = ''
) => {
  const location_fields = locationFields.join(',');
  const facilityStr = facilityId ? `facilityId: "${facilityId}", ` : '';

  const query = `{
    locations(companyId: "${companyId}",limit: ${limit}, offset: ${offset} ${filter} ${facilityStr} ${search} )
    {
      ${location_fields}
    }
  }`;

  return graphQueryPromise(query);
};

export const addNewLocation = values => {
  const fields = locationFields.join(',');
  const updates = handleUpdateString(values, addEmployerFields, true);

  const radiusVal = values[LOCATION.GEOFENCING_RADIUS]
    ? values[LOCATION.GEOFENCING_RADIUS]
    : null;
  const geofencingRadius = `geofencingRadius: ${radiusVal} `;

  const query = `mutation {
    createLocation(${updates} ${geofencingRadius}) {
      ${fields}
    }
  }`;

  return graphMutationPromise(query);
};

export const updateLocation = values => {
  const fields = locationFields.join(',');
  const updates = handleUpdateString(values, updateLocationFields);

  const radiusVal = values[LOCATION.GEOFENCING_RADIUS]
    ? values[LOCATION.GEOFENCING_RADIUS]
    : null;
  const geofencingRadius = `geofencingRadius: ${radiusVal} `;

  const query = `mutation {
    updateLocation(${updates} ${geofencingRadius}) {
      ${fields}
    }
  }`;

  return graphMutationPromise(query);
};

export const removeLocation = locationId => {
  const query = `mutation {
    updateLocation(id : "${locationId}") {
      success
    }
  }`;

  return graphMutationPromise(query);
};
