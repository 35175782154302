import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import middleware from '../middleware';
import rootReducer from '../reducers';
import history from 'routes/history';

export default function configureStore() {
  const store = createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(routerMiddleware(history), ...middleware)
    )
  );
  return store;
}
