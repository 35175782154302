import {
  RouterStore as BaseRouterStore,
  syncHistoryWithStore
} from 'mobx-react-router';
import { createBrowserHistory } from 'history';

export class RouterStore extends BaseRouterStore {}

export const routerStore = new RouterStore();

export const history = syncHistoryWithStore(
  createBrowserHistory(),
  routerStore
);
