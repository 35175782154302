import animations from './animations';
import colors from './colors';
import icons from './icons';
import buttons from './buttons';
import banners from './banners';

const theme = {
  animations,
  colors,
  icons,
  buttons,
  banners
};

export default theme;
