import React, { useState } from 'react';
import { connect } from 'react-redux';
import { StyledDialogActionsContainer } from 'pages/styledPage';
import Modal from 'components/common/modals/modal';
import { SubmitButton } from 'components/common/buttons/Button';
import Checkbox from 'components/common/customCheckbox/checkbox';
import styled from 'styled-components';
import { getUserAgreement, getTermsUrl } from 'redux/selectors';
import getText from 'context/language/getText';
import { agreeToTermsRequest } from 'redux/actions';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
  iframe {
    width: 100%;
    min-height: 400px;
  }

  ${StyledDialogActionsContainer} {
    margin-top: 20px;
  }
`;

const StyledTermOfUseContent = styled.div`
  text-align: center;
  width: 100%;
  min-height: 400px;
  .MuiCircularProgress-colorPrimary {
    position: absolute;
    top: 40%;
    left: 48%;
  }
`;

const TermOfUseModal = ({ isAgreed, termsUrl, onClickAgree }) => {
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(true);

  if (isAgreed || !termsUrl) {
    return null;
  }

  const hideSpinner = () => {
    setLoading(false);
  };

  return (
    <Modal open title={getText('terms.agree_title')}>
      <Container>
        <StyledTermOfUseContent>
          {loading ? <CircularProgress /> : null}
          <iframe
            frameBorder="0"
            title={getText('terms.agree_title')}
            onLoad={hideSpinner}
            src={termsUrl}
          />
        </StyledTermOfUseContent>
        <Checkbox
          label={getText('terms.agree_checkbox_label')}
          onChange={e => setAgree(e.target.checked)}
        />
        <StyledDialogActionsContainer>
          <SubmitButton
            btnStyle="submit"
            disabled={!agree}
            className="last-btn"
            onClick={onClickAgree}
          >
            {getText('terms.agree_button_label')}
          </SubmitButton>
        </StyledDialogActionsContainer>
      </Container>
    </Modal>
  );
};

const mapStateToProps = state => ({
  isAgreed: getUserAgreement(state),
  termsUrl: getTermsUrl(state)
});

const mapDispatchToProps = dispatch => ({
  onClickAgree: () => dispatch(agreeToTermsRequest())
});

export default connect(mapStateToProps, mapDispatchToProps)(TermOfUseModal);
