import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import { SubmitButton } from 'components/common/buttons/Button';
import getText from 'context/language/getText';
import { forgotPasswordRequest, clearSuccessState } from 'redux/actions';
import { getErrors, getIsLoading, getSuccesses } from 'redux/selectors';
import { Alert, alertTypes } from 'components/common/alerts';
import { FORGOT_PASSWORD_FINGERPRINT } from 'common/constants/fingerprintConstants';
import FullPageLoader from 'components/common/loaders/FullPageLoader';
import { EmailSignInIcon } from 'components/common/icons';
import Grid from '@material-ui/core/Grid';
import Link from 'components/common/links/Link';
import { urlMap } from 'routes/urlMap';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  StyledSignInFormContainer,
  StyledSignInLoadingContainer,
  StyledHeader,
  StyledModalContext
} from 'components/auth/styledSignin';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Modal from 'components/common/modals/modal';
import { StyledDialogActionsContainer } from 'pages/styledPage';
import { AUTH_LOGIN_ID } from 'common/constants/authConstants';

const ConfirmModal = ({ modalIsOpen, closeHandler, saveHandler }) => (
  <Modal
    open={modalIsOpen}
    closeOnDimmerClick
    onClose={closeHandler}
    title={getText('forgotPass.notificationHeader')}
    size="mini"
  >
    <StyledModalContext>
      {getText('forgotPass.successSubmitForgot')}
    </StyledModalContext>
    <StyledDialogActionsContainer>
      <SubmitButton onClick={saveHandler} btnStyle="submit">
        {getText('buttons.ok')}
      </SubmitButton>
    </StyledDialogActionsContainer>
  </Modal>
);

ConfirmModal.propTypes = {
  saveHandler: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired
};

class ForgotPassForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }

  dispatchConfirm = () => {
    this.hideModal();
    this.props.history.push(urlMap.RESET_PASSWORD);
  };

  showModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  hideModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  componentWillUnmount() {
    const { clearSuccessState } = this.props;
    clearSuccessState();
  }

  render() {
    const { forgotPassword, isLoading, errors, successes } = this.props;
    const { modalIsOpen } = this.state;

    if (isLoading)
      return (
        <StyledSignInLoadingContainer>
          <FullPageLoader />
        </StyledSignInLoadingContainer>
      );

    return (
      <StyledSignInFormContainer>
        <Formik
          initialValues={{
            [AUTH_LOGIN_ID]: ''
          }}
          validationSchema={Yup.object().shape({
            [AUTH_LOGIN_ID]: Yup.string()
              .email(getText('login.emailInvalid'))
              .required(getText('login.emailRequired'))
          })}
          onSubmit={(values, actions) => {
            forgotPassword(values.email);
            actions.setSubmitting(false);
          }}
          render={props => {
            if (successes.success) {
              this.showModal();
            }
            return (
              <form onSubmit={props.handleSubmit}>
                <StyledHeader>
                  {getText('forgotPass.forgotPasswordHeader')}
                </StyledHeader>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item className="signin-row">
                    <TextField
                      required
                      label={getText('login.email')}
                      type="email"
                      name={AUTH_LOGIN_ID}
                      margin="normal"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[AUTH_LOGIN_ID]}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailSignInIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                    <div className="error email-error">
                      <ErrorMessage name="email" />
                    </div>
                  </Grid>
                </Grid>
                <SubmitButton className="forgot-password-btn">
                  {getText('forgotPass.submitLabel')}
                </SubmitButton>
                <div className="back-signin">
                  <Link to={urlMap.SIGNIN}>
                    <ArrowBackIosIcon /> {getText('buttons.back')}
                  </Link>
                </div>
                <ConfirmModal
                  modalIsOpen={modalIsOpen}
                  closeHandler={this.hideModal}
                  saveHandler={this.dispatchConfirm}
                />
                {errors.length > 0 && (
                  <Alert type={alertTypes.ERROR} content={errors[0].message} />
                )}
              </form>
            );
          }}
        />
      </StyledSignInFormContainer>
    );
  }
}

ForgotPassForm.propTypes = {
  errors: PropTypes.array,
  isLoading: PropTypes.bool
};

ForgotPassForm.defaultProps = {
  errors: [],
  isLoading: false
};

const fingerprint = FORGOT_PASSWORD_FINGERPRINT;
const meta = {
  fingerprint
};

function mapDispatchToProps(dispatch) {
  return {
    clearSuccessState: () => {
      dispatch(clearSuccessState());
    },
    forgotPassword: email => {
      dispatch(forgotPasswordRequest({ email, meta }));
    }
  };
}

function mapStateToProps(state) {
  return {
    successes: getSuccesses(state)[fingerprint] || false,
    errors: getErrors(state)[fingerprint] || [],
    isLoading: getIsLoading(state)[fingerprint] || false
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassForm);
