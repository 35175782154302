import {
  SEARCH_EMPLOYER_BY_NAME,
  LOAD_ALL_EMPLOYERS,
  ADD_NEW_EMPLOYER,
  UPDATE_EMPLOYER,
  IMPORT_EMPLOYERS,
  RESEND_INVITES,
  LOAD_IMPORT_EMPLOYER_FILE
} from 'redux/actions/actionTypes';
import {
  searchEmployersByName,
  getAllEmployers,
  addNewEmployer,
  updateEmployer,
  importEmployers,
  resendInvites,
  loadImportEmployerFile
} from 'api/employers';
import {
  searchEmployersByNameSuccess,
  searchEmployersByNameFailure,
  loadAllEmployersSuccess,
  loadAllEmployersFailure,
  addNewEmployerSuccess,
  addNewEmployerFailure,
  updateEmployerSuccess,
  updateEmployerFailure,
  importEmployersSuccess,
  importEmployersFailure,
  resendInvitesSuccess,
  resendInvitesFailure,
  loadImportEmployerFileSuccess,
  loadImportEmployerFileFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const employers = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_EMPLOYERS.REQUEST: {
      next(action);
      const {
        companyId,
        offset,
        limit,
        search,
        sort,
        scope,
        fieldSearch
      } = action.payload;
      return apiPromise(
        store,
        action,
        getAllEmployers,
        [companyId, offset, limit, search, sort, scope, fieldSearch],
        loadAllEmployersSuccess,
        loadAllEmployersFailure
      );
    }
    case SEARCH_EMPLOYER_BY_NAME.REQUEST: {
      next(action);
      const { query, type } = action.payload;
      return apiPromise(
        store,
        action,
        searchEmployersByName,
        [query, type],
        searchEmployersByNameSuccess,
        searchEmployersByNameFailure
      );
    }
    case ADD_NEW_EMPLOYER.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewEmployer,
        [values],
        addNewEmployerSuccess,
        addNewEmployerFailure
      );
    }
    case UPDATE_EMPLOYER.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateEmployer,
        [values],
        updateEmployerSuccess,
        updateEmployerFailure
      );
    }
    case IMPORT_EMPLOYERS.REQUEST: {
      next(action);
      const { fileBase64, fileName } = action.payload;
      return apiPromise(
        store,
        action,
        importEmployers,
        [fileBase64, fileName],
        importEmployersSuccess,
        importEmployersFailure
      );
    }
    case RESEND_INVITES.REQUEST: {
      next(action);
      const { userType, userIds } = action.payload;
      return apiPromise(
        store,
        action,
        resendInvites,
        [userType, userIds],
        resendInvitesSuccess,
        resendInvitesFailure
      );
    }
    case LOAD_IMPORT_EMPLOYER_FILE.REQUEST: {
      next(action);
      const { id } = action.payload;
      return apiPromise(
        store,
        action,
        loadImportEmployerFile,
        [id],
        loadImportEmployerFileSuccess,
        loadImportEmployerFileFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default employers;
