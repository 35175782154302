import React from 'react';
import styled from 'styled-components';
import { Button as SemanticButton } from 'semantic-ui-react';
import { buttonColorChooser } from 'components/common/buttons/helpers';

export const StyledButton = styled(({ buttonClass, ...props }) => <SemanticButton {...props} />)`
  &.ui.button {
    background-color: ${props => buttonColorChooser(props.theme, props.buttonClass)};
    color: ${props => props.theme.colors.name.white};
    font-size: 13px;
    font-weight: normal;
    border-radius: 15px;
    margin-top: 8px;
    max-width: 270px;
    padding: 13px 15px;
    font-weight: bold;
    min-width: 110px;
    height: 45px;
    vertical-align: middle;
    margin-right: 15px;
    &:hover{
      background-color: ${({ theme }) => theme.colors.name.lightblue};
    }
    svg.icon{
      fill: ${({ theme }) => theme.colors.name.white};
      width: 20px;
      height: 20px;
      margin-right: 17px;
    }
    .text-wrapper{
      line-height: 20px;
      vertical-align: top;
    }
  }

`;

export default StyledButton;
