import { graphQueryPromise, handleArray } from './helpers';
import * as WORKER_CURRENT_LOCATION from 'common/constants/workerLocationConstants';

const workerLocationFields = [
  WORKER_CURRENT_LOCATION.WORKER_ID,
  WORKER_CURRENT_LOCATION.LONGITUDE,
  WORKER_CURRENT_LOCATION.LATITUDE,
  WORKER_CURRENT_LOCATION.CREATED_AT,
  WORKER_CURRENT_LOCATION.BATTERY_LEVEL
];

export const getWorkerLocationByWorkerId = workerId => {
  const worker_location_fields = workerLocationFields.join(',');

  const query = `{
    workerCurrentLocation(workerId: "${workerId}") {
      ${worker_location_fields}
    }
  }`;

  return graphQueryPromise(query);
};

export const getAllWorkerLocations = workerIds => {
  const worker_location_fields = workerLocationFields.join(',');
  const worker_ids = handleArray(workerIds);

  const query = `{
    workerCurrentLocations(workerIds: [${worker_ids}]) {
      ${worker_location_fields}
    }
  }`;

  return graphQueryPromise(query);
};
