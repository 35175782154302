import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import Themer from 'theme';
import Layout from 'modules/layout';
import '/vendor/style';
import { observer } from 'common/mobx.decorator.js';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'common/apollo';

export const AppWrapper = ({ children, history, theme }) => {
  return (
    <Themer customTheme={theme}>
      <ConnectedRouter history={history}>{children}</ConnectedRouter>
    </Themer>
  );
};

AppWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const App = observer(({ history, theme, ...props }) => {
  return (
    <AppWrapper history={history} theme={theme}>
      <ApolloProvider client={apolloClient}>
        <Layout theme={theme} {...props} />
      </ApolloProvider>
    </AppWrapper>
  );
});

App.propTypes = {
  history: PropTypes.object.isRequired
};

export default App;
