import React from 'react';
import PropTypes from 'prop-types';
import { StyledPage, StyledPageErrorContainer } from 'pages/styledPage';
import getText from 'context/language/getText';
import { urlMap } from 'routes/urlMap';
import Grid from '@material-ui/core/Grid';

const ErrorPage = ({ errors }) => (
  <StyledPage className="col-xs-12">
    <StyledPageErrorContainer>
      <div className="title">{getText('page500.title')}</div>
      <div className="horizonLine" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h4 className="shoot">{getText('page500.shoot')}</h4>
          <p className="errorMsg">{getText('page500.errorMsg')}</p>
          <p className="errorCode">
            {getText('page500.errorCode')}{' '}
            <span className="server_error">{errors}</span>
          </p>
          <p className="desription">{getText('page500.description_1')}</p>
          <p className="desription">{getText('page500.description_2')}</p>
          <p className="desription">{getText('page500.description_3')}</p>
        </Grid>
        <Grid item xs={6}>
          <div className="error-img" />
        </Grid>
      </Grid>
      <div className="horizonLine" />
      <div className="button-container">
        <button
          type="link"
          className="ui button submit"
          onClick={() => {
            window.location = urlMap.HOME;
          }}
        >
          {getText('page500.goToHome')}{' '}
        </button>
      </div>
    </StyledPageErrorContainer>
  </StyledPage>
);

ErrorPage.propTypes = {
  errors: PropTypes.array.isRequired
};

export default ErrorPage;
