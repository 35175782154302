import get from 'lodash/get';
import {
  LOAD_ALL_LOCATIONS,
  ADD_NEW_LOCATION,
  UPDATE_LOCATION,
  REMOVE_LOCATION
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { DEACTIVATE_LOCATION_FINGERPRINT } from 'common/constants/fingerprintConstants';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.LOCATIONS],
  action
) {
  switch (action.type) {
    case LOAD_ALL_LOCATIONS.SUCCESS: {
      const locations = get(action, 'payload.locations', []);
      return [...locations];
    }
    case ADD_NEW_LOCATION.SUCCESS: {
      const newLocation = get(action, 'payload.createLocation', []);
      const locations = state.concat(newLocation);

      toast.success(getText('locationModal.addLocationSuccess'), {
        className: 'alert alert-success'
      });
      return [...locations];
    }
    case UPDATE_LOCATION.SUCCESS: {
      const updatedLocation = get(action, 'payload.updateLocation', []);
      const fingerprint = get(action, 'meta.fingerprint');
      const locations = state.map(item => {
        if (item.id === updatedLocation.id) {
          return updatedLocation;
        }
        return item;
      });

      let message = getText('locationModal.updateLocationSuccess');
      if (fingerprint === DEACTIVATE_LOCATION_FINGERPRINT) {
        message = getText('locationModal.deactivateLocationSuccess');
      }
      toast.success(message, { className: 'alert alert-success' });

      return [...locations];
    }
    case REMOVE_LOCATION.SUCCESS: {
      const success = get(action, 'payload.destroyLocation.success', {});
      if (success) {
        const locationId = get(action, 'meta.locationId', '');
        let updateLocations = state.filter(location => {
          if (location.id === locationId) {
            return false;
          }
          return location;
        });
        return [...updateLocations];
      }
      break;
    }
    case LOAD_ALL_LOCATIONS.FAILURE:
    case ADD_NEW_LOCATION.FAILURE:
    case UPDATE_LOCATION.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
