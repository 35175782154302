import React from 'react';
import PropTypes from 'prop-types';
import { Input as SemanticInput } from 'semantic-ui-react';
import ImageCropper from 'components/common/images/ImageCropper';
import isValidUrl from 'common/helpers/isValidUrl';
import InlineError from 'components/common/form/inputs/inlineInputError';
import {
  StyledInputLabel,
  StyledImagePreview,
  StyledModal,
  StyledCropUploadInputContainer,
  StyledError
} from 'components/common/form/inputs/ImageCropUploadInput/styledImageCropUploadInput';
import { CancelButton, SubmitButton } from 'components/common/buttons/Button';
import { StyledDialogActionsContainer } from 'pages/styledPage';
import getText from 'context/language/getText';
import { FormHelperText } from '@material-ui/core';
import classnames from 'classnames';
class ImageCropUploadInput extends React.Component {
  state = {
    modalOpen: false,
    image: null,
    invalidFileSize: false,
    crop: {
      x: 0,
      y: 0,
      width: 100,
      aspect: 1
    }
  };

  onCropComplete = async (crop, pixelCrop) => {
    const croppedImageUrl = await this.getCroppedImg(
      this.imageRef,
      pixelCrop,
      'newFile.jpeg'
    );
    this.setState({ croppedImageUrl });
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this.setState({
          image: reader.result,
          crop: {
            x: 0,
            y: 0,
            width: 100,
            aspect: this.props.aspect
          }
        });
      });

      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append('file', e.target.files[0]);

      const invalidFileSize = e.target.files[0].size >= 2 * 1000 * 1000;
      if (invalidFileSize) {
        this.setState({
          invalidFileSize: true,
          modalOpen: false
        });
      } else {
        this.setState({
          imageFormData: formData,
          imageFile: e.target.files[0],
          invalidFileSize: false,
          modalOpen: true,
          crop: {}
        });
      }
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  handleSubmitCrop = async () => {
    const { form, fileFieldName, base64FieldName } = this.props;
    const { imageFile, croppedImageUrl } = this.state;

    const img = new Image();
    img.onload = () => {
      form.setFieldValue(fileFieldName, imageFile.name);
      form.setFieldValue(base64FieldName, croppedImageUrl);
      //onSubmitCrop(imageFormData, adjustedX, adjustedY, adjustedWidth, adjustedHeight, name);
    };

    img.src = URL.createObjectURL(imageFile);

    this.setState({
      modalOpen: false,
      image: null
    });
  };

  handleClose = () => {
    const { field, form, aspect } = this.props;
    const { name } = field;
    const { setFieldValue } = form;

    setFieldValue(name, ''); // or previous name if available in props
    this.setState({
      image: null,
      croppedImageUrl: null,
      crop: {
        x: 0,
        y: 0,
        width: 100,
        aspect
      },
      modalOpen: false
    });
  };

  getCroppedImg = (image, pixelCrop, fileName) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    // ctx.fillStyle = "rgb(255,255,255,0.4)";
    // ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    //As Base64 string
    const base64Image = canvas.toDataURL('image/png');
    return base64Image;

    // // As a blob
    // return new Promise((resolve) => {
    //   canvas.toBlob((file) => {
    //     file.name = fileName; /* eslint-disable-line no-param-reassign */
    //     window.URL.revokeObjectURL(this.fileUrl);
    //     this.fileUrl = window.URL.createObjectURL(file);
    //     resolve(this.fileUrl);
    //   }, 'image/jpeg');
    // });
  };

  handleClick = e => {
    e.target.value = null;
  };

  render() {
    const { field, id, label, modalTitle, initialImage, error } = this.props;

    const { value } = field;
    // the value we pass to the input must be a string or undefined
    const sanitizedValue =
      value === undefined || typeof value === 'string' ? value : '';
    const {
      modalOpen,
      image,
      crop,
      croppedImageUrl,
      invalidFileSize
    } = this.state;
    const showCroppedImage = !!croppedImageUrl;
    const showExistingImage = !!initialImage && !showCroppedImage;
    const existingSource = isValidUrl(initialImage)
      ? initialImage
      : `${process.env.RAILS_HOST}${initialImage}`;

    return (
      <StyledCropUploadInputContainer>
        <div className="image-crop-upload-input row">
          <div className="col-md-12">
            <div className="row">
              {label && (
                <StyledInputLabel className="image-field-label label col-md-3">
                  {label}
                </StyledInputLabel>
              )}
            </div>
            <div className="row">
              <SemanticInput
                className={classnames('input', 'col-md-12', { error: !!error })}
                type="file"
                onChange={this.onSelectFile}
                onClick={this.handleClick}
                id={id}
                accept=".jpg,.jpeg,.png"
              />
              <input type="hidden" {...field} value={sanitizedValue} />
            </div>
            <div className="row">
              <div className="col-md-12" style={{ marginLeft: '14px' }}>
                {error && <FormHelperText error={true}>{error}</FormHelperText>}
              </div>
            </div>
            <div className="row">
              <p className="col-md-12 image-requirement">
                {getText('uploadFile.type')}
              </p>
              <InlineError className="error col-md-12" name={field.name} />
            </div>
            <div className="row">
              {!invalidFileSize && (
                <StyledImagePreview className="preview col-md-12">
                  {showExistingImage && (
                    <img
                      alt="Crop"
                      className="preview-image"
                      src={existingSource}
                    />
                  )}
                  {showCroppedImage && (
                    <img
                      alt="Crop"
                      className="preview-image"
                      src={croppedImageUrl}
                    />
                  )}
                </StyledImagePreview>
              )}
            </div>
            <div className="row">
              {invalidFileSize && (
                <StyledError className="col-md-12">
                  {getText('uploadFile.maxLimit')}
                </StyledError>
              )}
            </div>
            <StyledModal
              className="image-upload-modal"
              open={modalOpen}
              onClose={this.handleClose}
              closeOnDimmerClick={false}
              size="small"
              title={modalTitle}
              actions={
                <StyledDialogActionsContainer>
                  <CancelButton onClick={this.handleClose}>
                    {getText('buttons.cancel')}
                  </CancelButton>
                  <SubmitButton
                    onClick={this.handleSubmitCrop}
                    type="submit"
                    className="crop-image"
                  >
                    {getText('buttons.ok')}
                  </SubmitButton>
                </StyledDialogActionsContainer>
              }
            >
              {image && (
                <ImageCropper
                  src={image}
                  crop={crop}
                  onImageLoaded={this.onImageLoaded}
                  onChange={this.onCropChange}
                  onComplete={this.onCropComplete}
                />
              )}
            </StyledModal>
          </div>
        </div>
      </StyledCropUploadInputContainer>
    );
  }
}

ImageCropUploadInput.propTypes = {
  onSubmitCrop: PropTypes.func.isRequired,
  initialImage: PropTypes.string,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  modalTitle: PropTypes.string.isRequired,
  uploadedImages: PropTypes.object,
  aspect: PropTypes.number,
  error: PropTypes.number
};

ImageCropUploadInput.defaultProps = {
  initialImage: undefined,
  label: null,
  value: undefined,
  uploadedImages: {},
  aspect: 1
};

export default ImageCropUploadInput;
