import axios from 'axios';
import { captureException } from 'common/helpers/captureEvent';
import {
  observable,
  action,
  store,
  computed,
  storage,
  persist
} from 'common/mobx.decorator';
import { authStore } from './auth.store';

axios.defaults.baseURL = `${process.env.REACT_APP_PROXY_API_URL}`;
axios.defaults.headers['APP_ID'] = process.env.REACT_APP_PROXY_API_APP_ID;
axios.defaults.headers['APP_SECRET'] =
  process.env.REACT_APP_PROXY_API_APP_SECRET;

axios.interceptors.response.use(undefined, error => {
  captureException('Axios Client', error);
  return Promise.reject(error);
});

@store({ persist: true })
class AppStore {
  constructor() {
    storage.ready(() => {
      this.ready = true;
      if (!this._uniqueId) {
        this._uniqueId = crypto.randomUUID();
      }
    });
  }

  @persist @observable _uniqueId;
  @observable config = null;
  @observable ready = false;

  @observable meta_domain;

  @computed
  get id() {
    // Company Id
    return process.env.REACT_APP_COMPANY_ID;
  }

  @computed
  get name() {
    return 'Opus';
  }

  @computed
  get logo() {
    return `${process.env.PUBLIC_URL}/assets/care/logo.png?version=${process.env.REACT_APP_CI_COMMIT_TAG}`;
  }

  @action
  init = async () => {
    return new Promise(resolve => {
      // wait 1s to make sure all resource loaded
      setTimeout(async () => {
        await authStore.verifyToken();
        resolve();
      }, 1000);
    });
  };
}

export const appStore = new AppStore();
