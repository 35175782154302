export const AUTHOR_ID = 'authorId';
export const AUTHOR_TYPE = 'authorType';
export const COMPANY_ID = 'companyId';
export const STATUS = 'status';
export const RESULT = 'result';

//import result field
export const ADDED = 'added';
export const COUNT = 'count';
export const ERRORS = 'errors';
export const UPDATED = 'updated';
