import get from 'lodash/get';
import {
  LOAD_ALL_REPORTS,
  UPDATE_REPORT,
  LOAD_REPORT_BY_WORKER_ID
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';

export default function(state = initialState[storeConstants.REPORTS], action) {
  switch (action.type) {
    case LOAD_ALL_REPORTS.SUCCESS: {
      const reports = get(action, 'payload.jobReports', []);
      return [...reports];
    }
    case LOAD_REPORT_BY_WORKER_ID.SUCCESS: {
      const reports = get(action, 'payload.jobReports', []);
      return [...reports];
    }
    case UPDATE_REPORT.SUCCESS: {
      return state;
    }
    default:
      return state;
  }
}
