import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton } from './styledButton';

export const BUTTON_CLASS = {
  SUBMIT: 'submit',
  BLUE: 'blue',
  YELLOW: 'yellow',
  CANCEL: 'cancel'
};

const inheritanceButtonPropTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const inheritanceButtonDefaultProps = {
  className: '',
  children: '',
  isLoading: false,
  onClick: () => { },
  disabled: false,
};

export const Button = ({
  type,
  children,
  isLoading,
  onClick,
  className,
  disabled,
  buttonClass
}) => (
  <StyledButton
    className={className}
    type={type}
    loading={isLoading}
    onClick={onClick}
    disabled={disabled}
    buttonClass={buttonClass}
  >
    {children}
  </StyledButton>
);

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  buttonClass: PropTypes.string
};

Button.defaultProps = {
  className: '',
  type: '',
  children: '',
  isLoading: false,
  onClick: () => { },
  disabled: false,
  buttonClass: ''
};

export const SubmitButton = ({
  children, isLoading, onClick, className, disabled
}) => (
  <Button
    type="submit"
    buttonClass={BUTTON_CLASS.SUBMIT}
    isLoading={isLoading}
    onClick={onClick}
    className={className}
    disabled={disabled}
  >
    {children}
  </Button>
);

SubmitButton.propTypes = inheritanceButtonPropTypes;
SubmitButton.defaultProps = inheritanceButtonDefaultProps;

export const YellowButton = ({
  children, isLoading, onClick, className, disabled
}) => (
  <Button
    type="button"
    buttonClass={BUTTON_CLASS.YELLOW}
    isLoading={isLoading}
    onClick={onClick}
    className={className}
    disabled={disabled}
  >
    {children}
  </Button>
);

YellowButton.propTypes = inheritanceButtonPropTypes;
YellowButton.defaultProps = inheritanceButtonDefaultProps;

export const BlueButton = ({
  children, isLoading, onClick, className, disabled
}) => (
  <Button
    type="button"
    buttonClass={BUTTON_CLASS.BLUE}
    isLoading={isLoading}
    onClick={onClick}
    className={className}
    disabled={disabled}
  >
    {children}
  </Button>
);

BlueButton.propTypes = inheritanceButtonPropTypes;
BlueButton.defaultProps = inheritanceButtonDefaultProps;

export const CancelButton = ({
  children, isLoading, onClick, className, disabled
}) => (
  <Button
    type="button"
    buttonClass={BUTTON_CLASS.CANCEL}
    isLoading={isLoading}
    onClick={onClick}
    className={className}
    disabled={disabled}
  >
    {children}
  </Button>
);

CancelButton.propTypes = inheritanceButtonPropTypes;
CancelButton.defaultProps = inheritanceButtonDefaultProps;
