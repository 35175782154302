import React from 'react';
import { Link } from 'react-router-dom';
import { urlMap } from 'routes/urlMap';
import { StyledSignoutContainer } from 'components/auth/styledSignout';
import getText from 'context/language/getText';

class Signout extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <StyledSignoutContainer>
        <h1>{getText('logout.label')}</h1>
        <span>{getText('logout.message')}</span>
        <div>
          <Link to={urlMap.SIGNIN}>{getText('logout.signinAgainBtn')}</Link>
        </div>
      </StyledSignoutContainer>
    );
  }
}


export default Signout;
