import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './userReducer';
import auth from './authReducer';
import errors from './errorReducer';
import loading from './loadingReducer';
import imageUpload from './imageUploadReducer';
import workers from './workersReducer';
import employers from './employersReducer';
import shifts from './shiftsReducer';
import jobs from './jobsReducer';
import company from './companyReducer';
import teams from './teamsReducer';
import locations from './locationsReducer';
import facilities from './facilitiesReducer';
import reports from './reportsReducer';
import message_threads from './messageThreadsReducer';
import message_detail from './messageDetailReducer';
import import_files from './importFilesReducer';
import shift_categories from './shiftCategoriesReducer';
import badges from './badgesReducer';
import shift_request from './shiftRequestReducer';
import shift_requests from './shiftRequestsReducer';
import worker_locations from './workerLocationsReducer';
import worker_contract from './workerContractReducer';
import active_jobs from './activeJobsReducer';
import facility_groups from './facilityGroupsReducer';
import request_tasks from './requestTasksReducer';
import task_progresses from './taskProgressesReducer';
import onboardings from './onboardingsReducer';
import successes from './successReducer';
import load_more from './loadMoreReducer';
import history from 'routes/history';
import upcoming_schedules from './upcomingSchedulesReducer';
import past_schedules from './pastSchedulesReducer';
import * as types from '../actions/actionTypes';

const appReducer = combineReducers({
  router: connectRouter(history),
  user,
  auth,
  successes,
  errors,
  loading,
  imageUpload,
  workers,
  employers,
  shifts,
  jobs,
  company,
  teams,
  locations,
  facilities,
  facility_groups,
  reports,
  message_threads,
  message_detail,
  import_files,
  shift_categories,
  badges,
  shift_request,
  shift_requests,
  worker_locations,
  worker_contract,
  active_jobs,
  request_tasks,
  task_progresses,
  load_more,
  onboardings,
  upcoming_schedules,
  past_schedules
});

// right now, we have no way to set themes, so no need to reduce them
const rootReducer = (state, action) => {
  if (action.type === types.UNAUTH_USER.SUCCESS) {
    return appReducer({ router: state.router }, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
