import styled from 'styled-components';

export const StyleCarouselContainer = styled.div`
  height: 100%;
  .carousel {
    height: 100%;
  }
  .carousel-inner {
    height: 100%;
  }
  .carousel-control-prev {
    width: 50px;
  }
  .carousel-control-next {
    width: 50px;
    justify-content: left;
  }
  .carousel-indicators {
    margin-left: 50px;
    margin-right: 50px;
    justify-content: left;
    .active {
      background-color: ${({ theme }) => theme.colors.name.main_color_4};
    }
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.colors.name.white};
      cursor: pointer;
    }
  }
  .carousel-item {
    height: 100%;
    img {
      height: 100%;
      width: 100%;
    }
    .carousel-caption {
      text-align: left;
      padding-top: 10px;
      padding-bottom: 40px;
      left: 50px;
      right: 50px;
    }
    h3 {
      font-size: 36px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.1;
      letter-spacing: normal;
      text-align: left;
      color: ${({ theme }) => theme.colors.name.main_color_1};
    }
    p {
      height: 20px;
      object-fit: contain;
      font-size: 20px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: #fb0a00;
      color: ${({ theme }) => theme.colors.name.main_color_4};
    }
  }
`;

export const StyledPicture = styled.div`
    background: url('${props => props.image}');
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 100%;
`;
