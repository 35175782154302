import { createAction } from 'redux-actions';
import * as types from './actionTypes';

const actionInputParser = (inputs, returnMeta = false) => {
  if (inputs === undefined) {
    return undefined;
  }
  if (inputs.meta) {
    const { meta, ...rest } = inputs;
    const payload = rest.error === undefined ? rest : rest.error;
    return returnMeta ? meta : payload;
  }
  return returnMeta ? {} : inputs;
};

const payloadCreator = inputs => actionInputParser(inputs, false);
const metaCreator = inputs => actionInputParser(inputs, true);

// ///// auth ///// //
export const signinUserRequest = createAction(
  types.USER_LOGIN.REQUEST,
  payloadCreator,
  metaCreator
);
export const signinUserSuccess = createAction(
  types.USER_LOGIN.SUCCESS,
  payloadCreator,
  metaCreator
);
export const signinUserFailure = createAction(
  types.USER_LOGIN.FAILURE,
  payloadCreator,
  metaCreator
);

export const signoutUserRequest = createAction(
  types.UNAUTH_USER.REQUEST,
  payloadCreator,
  metaCreator
);

export const signoutUserSuccess = createAction(
  types.UNAUTH_USER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const signoutUserFailure = createAction(
  types.UNAUTH_USER.FAILURE,
  payloadCreator,
  metaCreator
);

export const authUserByTokenRequest = createAction(
  types.AUTH_USER.REQUEST,
  payloadCreator,
  metaCreator
);

export const authUserByTokenSuccess = createAction(
  types.AUTH_USER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const authUserByTokenFailure = createAction(
  types.AUTH_USER.FAILURE,
  payloadCreator,
  metaCreator
);

export const forgotPasswordRequest = createAction(
  types.FORGOT_PASSWORD.REQUEST,
  payloadCreator,
  metaCreator
);
export const forgotPasswordSuccess = createAction(
  types.FORGOT_PASSWORD.SUCCESS,
  payloadCreator,
  metaCreator
);
export const forgotPasswordFailure = createAction(
  types.FORGOT_PASSWORD.FAILURE,
  payloadCreator,
  metaCreator
);

export const resetPasswordRequest = createAction(
  types.RESET_PASSWORD.REQUEST,
  payloadCreator,
  metaCreator
);
export const resetPasswordSuccess = createAction(
  types.RESET_PASSWORD.SUCCESS,
  payloadCreator,
  metaCreator
);
export const resetPasswordFailure = createAction(
  types.RESET_PASSWORD.FAILURE,
  payloadCreator,
  metaCreator
);

export const changePasswordRequest = createAction(
  types.CHANGE_PASSWORD.REQUEST,
  payloadCreator,
  metaCreator
);
export const changePasswordSuccess = createAction(
  types.CHANGE_PASSWORD.SUCCESS,
  payloadCreator,
  metaCreator
);
export const changePasswordFailure = createAction(
  types.CHANGE_PASSWORD.FAILURE,
  payloadCreator,
  metaCreator
);

export const agreeToTermsRequest = createAction(
  types.AGREE_TO_TERMS.REQUEST,
  payloadCreator,
  metaCreator
);

export const agreeToTermsSuccess = createAction(
  types.AGREE_TO_TERMS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const agreeToTermsFailure = createAction(
  types.AGREE_TO_TERMS.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// images ///// //
export const uploadCroppedImageRequest = createAction(
  types.UPLOAD_CROPPED_IMAGE.REQUEST,
  payloadCreator,
  metaCreator
);

export const uploadCroppedImageSuccess = createAction(
  types.UPLOAD_CROPPED_IMAGE.SUCCESS,
  payloadCreator,
  metaCreator
);

export const uploadCroppedImageFailure = createAction(
  types.UPLOAD_CROPPED_IMAGE.FAILURE,
  payloadCreator,
  metaCreator
);

// pagination
export const loadPagination = createAction(
  types.LOAD_PAGINATION,
  payloadCreator,
  metaCreator
);

export const configurePagination = createAction(
  types.CONFIGURE_PAGINATION,
  payloadCreator,
  metaCreator
);

// ///// users ///// //
export const getCurrentUserInfo = createAction(
  types.LOAD_CURRENT_USER_INFO.REQUEST,
  payloadCreator,
  metaCreator
);

export const getCurrentUserInfoSuccess = createAction(
  types.LOAD_CURRENT_USER_INFO.SUCCESS,
  payloadCreator,
  metaCreator
);

export const getCurrentUserInfoFailure = createAction(
  types.LOAD_CURRENT_USER_INFO.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// workers list ///// //
export const loadAllWorkersRequest = createAction(
  types.LOAD_ALL_WORKERS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllWorkersSuccess = createAction(
  types.LOAD_ALL_WORKERS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllWorkersFailure = createAction(
  types.LOAD_ALL_WORKERS.FAILURE,
  payloadCreator,
  metaCreator
);

export const searchWorkersByName = createAction(
  types.SEARCH_WORKER_BY_NAME.REQUEST,
  payloadCreator,
  metaCreator
);

export const searchWorkersByNameSuccess = createAction(
  types.SEARCH_WORKER_BY_NAME.SUCCESS,
  payloadCreator,
  metaCreator
);

export const searchWorkersByNameFailure = createAction(
  types.SEARCH_WORKER_BY_NAME.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewWorkerRequest = createAction(
  types.ADD_NEW_WORKER.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewWorkerSuccess = createAction(
  types.ADD_NEW_WORKER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewWorkerFailure = createAction(
  types.ADD_NEW_WORKER.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewWorkerAccountRequest = createAction(
  types.ADD_NEW_WORKER_ACCOUNT.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewWorkerAccountSuccess = createAction(
  types.ADD_NEW_WORKER_ACCOUNT.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewWorkerAccountFailure = createAction(
  types.ADD_NEW_WORKER_ACCOUNT.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateWorkerRequest = createAction(
  types.UPDATE_WORKER.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateWorkerSuccess = createAction(
  types.UPDATE_WORKER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateWorkerFailure = createAction(
  types.UPDATE_WORKER.FAILURE,
  payloadCreator,
  metaCreator
);

export const removeWorkerRequest = createAction(
  types.REMOVE_WORKER.REQUEST,
  payloadCreator,
  metaCreator
);

export const removeWorkerSuccess = createAction(
  types.REMOVE_WORKER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const removeWorkerFailure = createAction(
  types.REMOVE_WORKER.FAILURE,
  payloadCreator,
  metaCreator
);

export const blockWorkerRequest = createAction(
  types.BLOCK_WORKER.REQUEST,
  payloadCreator,
  metaCreator
);

export const blockWorkerSuccess = createAction(
  types.BLOCK_WORKER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const blockWorkerFailure = createAction(
  types.BLOCK_WORKER.FAILURE,
  payloadCreator,
  metaCreator
);


export const importWorkersRequest = createAction(
  types.IMPORT_WORKERS.REQUEST,
  payloadCreator,
  metaCreator
);

export const importWorkersSuccess = createAction(
  types.IMPORT_WORKERS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const importWorkersFailure = createAction(
  types.IMPORT_WORKERS.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadImportWorkerFileRequest = createAction(
  types.LOAD_IMPORT_WORKER_FILE.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadImportWorkerFileSuccess = createAction(
  types.LOAD_IMPORT_WORKER_FILE.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadImportWorkerFileFailure = createAction(
  types.LOAD_IMPORT_WORKER_FILE.FAILURE,
  payloadCreator,
  metaCreator
);

export const clearImportFileState = createAction(
  types.CLEAR_IMPORT_FILE_STATE,
  payloadCreator,
  metaCreator
);

// ///// employers list ///// //
export const loadAllEmployersRequest = createAction(
  types.LOAD_ALL_EMPLOYERS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllEmployersSuccess = createAction(
  types.LOAD_ALL_EMPLOYERS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllEmployersFailure = createAction(
  types.LOAD_ALL_EMPLOYERS.FAILURE,
  payloadCreator,
  metaCreator
);

export const searchEmployersByName = createAction(
  types.SEARCH_EMPLOYER_BY_NAME.REQUEST,
  payloadCreator,
  metaCreator
);

export const searchEmployersByNameSuccess = createAction(
  types.SEARCH_EMPLOYER_BY_NAME.SUCCESS,
  payloadCreator,
  metaCreator
);

export const searchEmployersByNameFailure = createAction(
  types.SEARCH_EMPLOYER_BY_NAME.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewEmployerRequest = createAction(
  types.ADD_NEW_EMPLOYER.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewEmployerSuccess = createAction(
  types.ADD_NEW_EMPLOYER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewEmployerFailure = createAction(
  types.ADD_NEW_EMPLOYER.FAILURE,
  payloadCreator,
  metaCreator
);


export const updateEmployerRequest = createAction(
  types.UPDATE_EMPLOYER.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateEmployerSuccess = createAction(
  types.UPDATE_EMPLOYER.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateEmployerFailure = createAction(
  types.UPDATE_EMPLOYER.FAILURE,
  payloadCreator,
  metaCreator
);

export const importEmployersRequest = createAction(
  types.IMPORT_EMPLOYERS.REQUEST,
  payloadCreator,
  metaCreator
);

export const importEmployersSuccess = createAction(
  types.IMPORT_EMPLOYERS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const importEmployersFailure = createAction(
  types.IMPORT_EMPLOYERS.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadImportEmployerFileRequest = createAction(
  types.LOAD_IMPORT_EMPLOYER_FILE.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadImportEmployerFileSuccess = createAction(
  types.LOAD_IMPORT_EMPLOYER_FILE.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadImportEmployerFileFailure = createAction(
  types.LOAD_IMPORT_EMPLOYER_FILE.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// shifts list ///// //
export const loadAllShifts = createAction(
  types.LOAD_ALL_SHIFTS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllShiftsSuccess = createAction(
  types.LOAD_ALL_SHIFTS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllShiftsFailure = createAction(
  types.LOAD_ALL_SHIFTS.FAILURE,
  payloadCreator,
  metaCreator
);

export const searchShiftRequests = createAction(
  types.SEARCH_SHIFT_REQUESTS.REQUEST,
  payloadCreator,
  metaCreator
);

export const searchShiftRequestsSuccess = createAction(
  types.SEARCH_SHIFT_REQUESTS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const searchShiftRequestsFailure = createAction(
  types.SEARCH_SHIFT_REQUESTS.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadShiftRequestById = createAction(
  types.LOAD_SHIFT_REQUEST_BY_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadShiftRequestByIdSuccess = createAction(
  types.LOAD_SHIFT_REQUEST_BY_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadShiftRequestByIdFailure = createAction(
  types.LOAD_SHIFT_REQUEST_BY_ID.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadShiftById = createAction(
  types.LOAD_SHIFT_BY_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadShiftByIdSuccess = createAction(
  types.LOAD_SHIFT_BY_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadShiftByIdFailure = createAction(
  types.LOAD_SHIFT_BY_ID.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateShiftRequest = createAction(
  types.UPDATE_SHIFT_REQUEST.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateShiftRequestSuccess = createAction(
  types.UPDATE_SHIFT_REQUEST.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateShiftRequestFailure = createAction(
  types.UPDATE_SHIFT_REQUEST.FAILURE,
  payloadCreator,
  metaCreator
);

export const searchShiftsByName = createAction(
  types.SEARCH_SHIFT_BY_NAME.REQUEST,
  payloadCreator,
  metaCreator
);

export const searchShiftsByNameSuccess = createAction(
  types.SEARCH_SHIFT_BY_NAME.SUCCESS,
  payloadCreator,
  metaCreator
);

export const searchShiftsByNameFailure = createAction(
  types.SEARCH_SHIFT_BY_NAME.FAILURE,
  payloadCreator,
  metaCreator
);

export const cancelShift = createAction(
  types.CANCEL_SHIFT.REQUEST,
  payloadCreator,
  metaCreator
);

export const cancelShiftSuccess = createAction(
  types.CANCEL_SHIFT.SUCCESS,
  payloadCreator,
  metaCreator
);

export const cancelShiftFailure = createAction(
  types.CANCEL_SHIFT.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateShift = createAction(
  types.UPDATE_SHIFT.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateShiftSuccess = createAction(
  types.UPDATE_SHIFT.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateShiftFailure = createAction(
  types.UPDATE_SHIFT.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// company settings ///// //
export const loadCompanySettings = createAction(
  types.LOAD_COMPANY_SETTINGS.REQUEST,
  payloadCreator,
  metaCreator
);
export const loadCompanySuccess = createAction(
  types.LOAD_COMPANY_SETTINGS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadCompanyFailure = createAction(
  types.LOAD_COMPANY_SETTINGS.FAILURE,
  payloadCreator,
  metaCreator
);

export const saveCompanySettings = createAction(
  types.SAVE_COMPANY_SETTINGS.REQUEST,
  payloadCreator,
  metaCreator
);

export const saveCompanySuccess = createAction(
  types.SAVE_COMPANY_SETTINGS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const saveCompanyFailure = createAction(
  types.SAVE_COMPANY_SETTINGS.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// teams list ///// //
export const loadAllTeamsRequest = createAction(
  types.LOAD_ALL_TEAMS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllTeamsSuccess = createAction(
  types.LOAD_ALL_TEAMS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllTeamsFailure = createAction(
  types.LOAD_ALL_TEAMS.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewTeamRequest = createAction(
  types.ADD_NEW_TEAM.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewTeamSuccess = createAction(
  types.ADD_NEW_TEAM.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewTeamFailure = createAction(
  types.ADD_NEW_TEAM.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateTeamRequest = createAction(
  types.UPDATE_TEAM.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateTeamSuccess = createAction(
  types.UPDATE_TEAM.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateTeamFailure = createAction(
  types.UPDATE_TEAM.FAILURE,
  payloadCreator,
  metaCreator
);

export const removeTeamRequest = createAction(
  types.REMOVE_TEAM.REQUEST,
  payloadCreator,
  metaCreator
);

export const removeTeamSuccess = createAction(
  types.REMOVE_TEAM.SUCCESS,
  payloadCreator,
  metaCreator
);

export const removeTeamFailure = createAction(
  types.REMOVE_TEAM.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// locations list ///// //
export const loadAllLocationsRequest = createAction(
  types.LOAD_ALL_LOCATIONS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllLocationsSuccess = createAction(
  types.LOAD_ALL_LOCATIONS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllLocationsFailure = createAction(
  types.LOAD_ALL_LOCATIONS.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewLocationRequest = createAction(
  types.ADD_NEW_LOCATION.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewLocationSuccess = createAction(
  types.ADD_NEW_LOCATION.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewLocationFailure = createAction(
  types.ADD_NEW_LOCATION.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateLocationRequest = createAction(
  types.UPDATE_LOCATION.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateLocationSuccess = createAction(
  types.UPDATE_LOCATION.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateLocationFailure = createAction(
  types.UPDATE_LOCATION.FAILURE,
  payloadCreator,
  metaCreator
);

export const removeLocationRequest = createAction(
  types.REMOVE_LOCATION.REQUEST,
  payloadCreator,
  metaCreator
);

export const removeLocationSuccess = createAction(
  types.REMOVE_LOCATION.SUCCESS,
  payloadCreator,
  metaCreator
);

export const removeLocationFailure = createAction(
  types.REMOVE_LOCATION.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// Reports ///// //
export const loadAllReportsRequest = createAction(
  types.LOAD_ALL_REPORTS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllReportsSuccess = createAction(
  types.LOAD_ALL_REPORTS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllReportsFailure = createAction(
  types.LOAD_ALL_REPORTS.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadReportByWorkerId = createAction(
  types.LOAD_REPORT_BY_WORKER_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadReportByWorkerIdSuccess = createAction(
  types.LOAD_REPORT_BY_WORKER_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadReportByWorkerIdFailure = createAction(
  types.LOAD_REPORT_BY_WORKER_ID.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateReportRequest = createAction(
  types.UPDATE_REPORT.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateReportSuccess = createAction(
  types.UPDATE_REPORT.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateReportFailure = createAction(
  types.UPDATE_REPORT.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// Messages ///// //
export const loadMessageThreads = createAction(
  types.LOAD_MESSAGE_CONVERSATIONS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadMessageThreadsSuccess = createAction(
  types.LOAD_MESSAGE_CONVERSATIONS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadMessageThreadsFailure = createAction(
  types.LOAD_MESSAGE_CONVERSATIONS.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadMessageThreadById = createAction(
  types.LOAD_MESSAGE_THREAD_BY_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadMessageThreadByIdSuccess = createAction(
  types.LOAD_MESSAGE_THREAD_BY_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadMessageThreadByIdFailure = createAction(
  types.LOAD_MESSAGE_THREAD_BY_ID.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadMessageDetailById = createAction(
  types.LOAD_MESSAGE_BY_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadMessageDetailByIdSuccess = createAction(
  types.LOAD_MESSAGE_BY_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadMessageDetailByIdFailure = createAction(
  types.LOAD_MESSAGE_BY_ID.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewMessageRequest = createAction(
  types.ADD_NEW_MESSAGE.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewMessageSuccess = createAction(
  types.ADD_NEW_MESSAGE.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewMessageFailure = createAction(
  types.ADD_NEW_MESSAGE.FAILURE,
  payloadCreator,
  metaCreator
);

export const markAllMessagesAsRead = createAction(
  types.MARK_ALL_MESSAGES_AS_READ.REQUEST,
  payloadCreator,
  metaCreator
);

export const markAllMessagesAsReadSuccess = createAction(
  types.MARK_ALL_MESSAGES_AS_READ.SUCCESS,
  payloadCreator,
  metaCreator
);

export const markAllMessagesAsReadFailure = createAction(
  types.MARK_ALL_MESSAGES_AS_READ.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// Reports ///// //
export const loadAllJobsRequest = createAction(
  types.LOAD_ALL_JOBS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllJobsSuccess = createAction(
  types.LOAD_ALL_JOBS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllJobsFailure = createAction(
  types.LOAD_ALL_JOBS.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadJobByIdRequest = createAction(
  types.LOAD_JOB_BY_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadJobByIdSuccess = createAction(
  types.LOAD_JOB_BY_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadJobByIdFailure = createAction(
  types.LOAD_JOB_BY_ID.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// shift categories list ///// //
export const loadAllShiftCategoriesRequest = createAction(
  types.LOAD_ALL_SHIFT_CATEGORIES.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllShiftCategoriesSuccess = createAction(
  types.LOAD_ALL_SHIFT_CATEGORIES.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllShiftCategoriesFailure = createAction(
  types.LOAD_ALL_SHIFT_CATEGORIES.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewCategoryRequest = createAction(
  types.ADD_NEW_CATEGORY.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewCategorySuccess = createAction(
  types.ADD_NEW_CATEGORY.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewCategoryFailure = createAction(
  types.ADD_NEW_CATEGORY.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// badges list ///// //
export const loadAllBadgesRequest = createAction(
  types.LOAD_ALL_BADGES.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllBadgesSuccess = createAction(
  types.LOAD_ALL_BADGES.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllBadgesFailure = createAction(
  types.LOAD_ALL_BADGES.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewBadgeRequest = createAction(
  types.ADD_NEW_BADGE.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewBadgeSuccess = createAction(
  types.ADD_NEW_BADGE.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewBadgeFailure = createAction(
  types.ADD_NEW_BADGE.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateBadgeRequest = createAction(
  types.UPDATE_BADGE.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateBadgeSuccess = createAction(
  types.UPDATE_BADGE.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateBadgeFailure = createAction(
  types.UPDATE_BADGE.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// shift request ///// //

export const loadAllShiftRequests = createAction(
  types.LOAD_ALL_SHIFT_REQUESTS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllShiftRequestsSuccess = createAction(
  types.LOAD_ALL_SHIFT_REQUESTS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllShiftRequestsFailure = createAction(
  types.LOAD_ALL_SHIFT_REQUESTS.FAILURE,
  payloadCreator,
  metaCreator
);

export const cancelShiftRequest = createAction(
  types.CANCEL_SHIFT_REQUEST.REQUEST,
  payloadCreator,
  metaCreator
);

export const cancelShiftRequestSuccess = createAction(
  types.CANCEL_SHIFT_REQUEST.SUCCESS,
  payloadCreator,
  metaCreator
);

export const cancelShiftRequestFailure = createAction(
  types.CANCEL_SHIFT_REQUEST.FAILURE,
  payloadCreator,
  metaCreator
);

export const createShiftRequest = createAction(
  types.CREATE_SHIFT_REQUEST.REQUEST,
  payloadCreator,
  metaCreator
);

export const createShiftRequestSuccess = createAction(
  types.CREATE_SHIFT_REQUEST.SUCCESS,
  payloadCreator,
  metaCreator
);

export const createShiftRequestFailure = createAction(
  types.CREATE_SHIFT_REQUEST.FAILURE,
  payloadCreator,
  metaCreator
);

export const clearShiftRequestsState = createAction(
  types.CLEAR_SHIFT_REQUESTS,
  payloadCreator,
  metaCreator
);

export const clearShiftRequestState = createAction(
  types.CLEAR_SHIFT_REQUEST,
  payloadCreator,
  metaCreator
);

export const clearSuccessState = createAction(
  types.CLEAR_SUCCESS_STATE,
  payloadCreator,
  metaCreator
);

export const clearErrorState = createAction(
  types.CLEAR_ERROR_STATE,
  payloadCreator,
  metaCreator
);

// ///// request task ///// //
export const loadAllRequestTasks = createAction(
  types.LOAD_ALL_REQUEST_TASKS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllRequestTasksSuccess = createAction(
  types.LOAD_ALL_REQUEST_TASKS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllRequestTasksFailure = createAction(
  types.LOAD_ALL_REQUEST_TASKS.FAILURE,
  payloadCreator,
  metaCreator
);

export const createRequestTask = createAction(
  types.CREATE_REQUEST_TASK.REQUEST,
  payloadCreator,
  metaCreator
);

export const createRequestTaskSuccess = createAction(
  types.CREATE_REQUEST_TASK.SUCCESS,
  payloadCreator,
  metaCreator
);

export const createRequestTaskFailure = createAction(
  types.CREATE_REQUEST_TASK.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateRequestTask = createAction(
  types.UPDATE_REQUEST_TASK.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateRequestTaskSuccess = createAction(
  types.UPDATE_REQUEST_TASK.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateRequestTaskFailure = createAction(
  types.UPDATE_REQUEST_TASK.FAILURE,
  payloadCreator,
  metaCreator
);

export const removeRequestTask = createAction(
  types.REMOVE_REQUEST_TASK.REQUEST,
  payloadCreator,
  metaCreator
);

export const removeRequestTaskSuccess = createAction(
  types.REMOVE_REQUEST_TASK.SUCCESS,
  payloadCreator,
  metaCreator
);

export const removeRequestTaskFailure = createAction(
  types.REMOVE_REQUEST_TASK.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// task progress ///// //

export const loadProgressesByRequestTaskId = createAction(
  types.LOAD_PROGESSES_BY_REQUEST_TASK_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadProgressesByRequestTaskIdSuccess = createAction(
  types.LOAD_PROGESSES_BY_REQUEST_TASK_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadProgressesByRequestTaskIdFailure = createAction(
  types.LOAD_PROGESSES_BY_REQUEST_TASK_ID.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// job rating ///// //

export const createJobRatingRequest = createAction(
  types.CREATE_JOB_RATING.REQUEST,
  payloadCreator,
  metaCreator
);

export const createJobRatingSuccess = createAction(
  types.CREATE_JOB_RATING.SUCCESS,
  payloadCreator,
  metaCreator
);

export const createJobRatingFailure = createAction(
  types.CREATE_JOB_RATING.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateJobRatingRequest = createAction(
  types.UPDATE_JOB_RATING.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateJobRatingSuccess = createAction(
  types.UPDATE_JOB_RATING.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateJobRatingFailure = createAction(
  types.UPDATE_JOB_RATING.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateJobRequest = createAction(
  types.UPDATE_JOB.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateJobSuccess = createAction(
  types.UPDATE_JOB.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateJobFailure = createAction(
  types.UPDATE_JOB.FAILURE,
  payloadCreator,
  metaCreator
);

export const createJobRequest = createAction(
  types.CREATE_JOB.REQUEST,
  payloadCreator,
  metaCreator
);

export const createJobSuccess = createAction(
  types.CREATE_JOB.SUCCESS,
  payloadCreator,
  metaCreator
);

export const createJobFailure = createAction(
  types.CREATE_JOB.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// Active Jobs ///// //
export const loadAllActiveJobsRequest = createAction(
  types.LOAD_ALL_ACTIVE_JOBS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllActiveJobsSuccess = createAction(
  types.LOAD_ALL_ACTIVE_JOBS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllActiveJobsFailure = createAction(
  types.LOAD_ALL_ACTIVE_JOBS.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// Worker Locations ///// //
export const loadWorkerLocationsRequest = createAction(
  types.LOAD_WORKER_LOCATIONS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadWorkerLocationsSuccess = createAction(
  types.LOAD_WORKER_LOCATIONS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadWorkerLocationsFailure = createAction(
  types.LOAD_WORKER_LOCATIONS.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// facilities list ///// //
export const loadAllFacilitiesRequest = createAction(
  types.LOAD_ALL_FACILITIES.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllFacilitiesSuccess = createAction(
  types.LOAD_ALL_FACILITIES.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllFacilitiesFailure = createAction(
  types.LOAD_ALL_FACILITIES.FAILURE,
  payloadCreator,
  metaCreator
);

export const addNewFacilityRequest = createAction(
  types.ADD_NEW_FACILITY.REQUEST,
  payloadCreator,
  metaCreator
);

export const addNewFacilitySuccess = createAction(
  types.ADD_NEW_FACILITY.SUCCESS,
  payloadCreator,
  metaCreator
);

export const addNewFacilityFailure = createAction(
  types.ADD_NEW_FACILITY.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateFacilityRequest = createAction(
  types.UPDATE_FACILITY.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateFacilitySuccess = createAction(
  types.UPDATE_FACILITY.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateFacilityFailure = createAction(
  types.UPDATE_FACILITY.FAILURE,
  payloadCreator,
  metaCreator
);

export const removeFacilityRequest = createAction(
  types.REMOVE_FACILITY.REQUEST,
  payloadCreator,
  metaCreator
);

export const removeFacilitySuccess = createAction(
  types.REMOVE_FACILITY.SUCCESS,
  payloadCreator,
  metaCreator
);

export const removeFacilityFailure = createAction(
  types.REMOVE_FACILITY.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// facility groups list ///// //
export const loadAllFacilityGroupsRequest = createAction(
  types.LOAD_ALL_FACILITY_GROUPS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllFacilityGroupsSuccess = createAction(
  types.LOAD_ALL_FACILITY_GROUPS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllFacilityGroupsFailure = createAction(
  types.LOAD_ALL_FACILITY_GROUPS.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// onboarding list ///// //
export const loadAllWorkerOnboardingsRequest = createAction(
  types.LOAD_ALL_WORKER_ONBOARDINGS.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadAllWorkerOnboardingsSuccess = createAction(
  types.LOAD_ALL_WORKER_ONBOARDINGS.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadAllWorkerOnboardingsFailure = createAction(
  types.LOAD_ALL_WORKER_ONBOARDINGS.FAILURE,
  payloadCreator,
  metaCreator
);

export const searchWorkerOnboarding = createAction(
  types.SEARCH_WORKER_ONBOARDING.REQUEST,
  payloadCreator,
  metaCreator
);

export const searchWorkerOnboardingSuccess = createAction(
  types.SEARCH_WORKER_ONBOARDING.SUCCESS,
  payloadCreator,
  metaCreator
);

export const searchWorkerOnboardingFailure = createAction(
  types.SEARCH_WORKER_ONBOARDING.FAILURE,
  payloadCreator,
  metaCreator
);

export const cancelWorkerOnboardingRequest = createAction(
  types.CANCEL_WORKER_ONBOARDING.REQUEST,
  payloadCreator,
  metaCreator
);

export const cancelWorkerOnboardingSuccess = createAction(
  types.CANCEL_WORKER_ONBOARDING.SUCCESS,
  payloadCreator,
  metaCreator
);

export const cancleWorkerOnboardingFailure = createAction(
  types.CANCEL_WORKER_ONBOARDING.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadUpcomingSchedulesRequest = createAction(
  types.LOAD_UPCOMING_SCHEDULES.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadUpcomingSchedulesSuccess = createAction(
  types.LOAD_UPCOMING_SCHEDULES.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadUpcomingSchedulesFailure = createAction(
  types.LOAD_UPCOMING_SCHEDULES.FAILURE,
  payloadCreator,
  metaCreator
);

export const loadPastSchedulesRequest = createAction(
  types.LOAD_PAST_SCHEDULES.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadPastSchedulesSuccess = createAction(
  types.LOAD_PAST_SCHEDULES.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadPastSchedulesFailure = createAction(
  types.LOAD_PAST_SCHEDULES.FAILURE,
  payloadCreator,
  metaCreator
);

export const updateScheduleStateRequest = createAction(
  types.UPDATE_SCHEDULE_STATE.REQUEST,
  payloadCreator,
  metaCreator
);

export const updateScheduleStateSuccess = createAction(
  types.UPDATE_SCHEDULE_STATE.SUCCESS,
  payloadCreator,
  metaCreator
);

export const updateScheduleStateFailure = createAction(
  types.UPDATE_SCHEDULE_STATE.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// invites ///// //

export const resendInvitesRequest = createAction(
  types.RESEND_INVITES.REQUEST,
  payloadCreator,
  metaCreator
);

export const resendInvitesSuccess = createAction(
  types.RESEND_INVITES.SUCCESS,
  payloadCreator,
  metaCreator
);

export const resendInvitesFailure = createAction(
  types.RESEND_INVITES.FAILURE,
  payloadCreator,
  metaCreator
);


export const resendNotificationRequest = createAction(
  types.RESEND_NOTIFICATION.REQUEST,
  payloadCreator,
  metaCreator
);

export const resendNotificationSuccess = createAction(
  types.RESEND_NOTIFICATION.SUCCESS,
  payloadCreator,
  metaCreator
);

export const resendNotificationFailure = createAction(
  types.RESEND_NOTIFICATION.FAILURE,
  payloadCreator,
  metaCreator
);

// ///// Worker Contract ///// //
export const loadWorkerContractById = createAction(
  types.LOAD_WORKER_CONTRACT_BY_ID.REQUEST,
  payloadCreator,
  metaCreator
);

export const loadWorkerContractByIdSuccess = createAction(
  types.LOAD_WORKER_CONTRACT_BY_ID.SUCCESS,
  payloadCreator,
  metaCreator
);

export const loadWorkerContractByIdFailure = createAction(
  types.LOAD_WORKER_CONTRACT_BY_ID.FAILURE,
  payloadCreator,
  metaCreator
);

export const submitFeedbackRequest = createAction(
  types.SUBMIT_FEEDBACK.REQUEST,
  payloadCreator,
  metaCreator
);

export const submitFeedbackSuccess = createAction(
  types.SUBMIT_FEEDBACK.SUCCESS,
  payloadCreator,
  metaCreator
);

export const submitFeedbackFailure = createAction(
  types.SUBMIT_FEEDBACK.FAILURE,
  payloadCreator,
  metaCreator
);
