export const COMPANY_ID = 'companyId';
export const FACILITY_GROUP_ID = 'facilityGroupId';
export const FACILITY_ID = 'facilityId';
export const ID = 'id';
export const LAST_MESSAGE = 'lastMessage';
export const LAST_TEN_MESSAGES = 'lastTenMessages';
export const LAST_TIMESTAMP = 'lastTimestamp';
export const THREAD_ICON_URL = 'threadIconUrl';
export const OBJECT_ID = 'objectId';
export const OBJECT_TYPE = 'objectType';
export const SHIFT_NAME = 'shiftName';
export const UNREAD_MESSAGE_COUNTER = 'unreadMessageCounter';
