import { isEmpty } from 'lodash';
import { store, observable, action, computed } from 'common/mobx.decorator';
import { appStore } from './app.store';
import { routerStore } from './router.store';

@store()
class NotifyStore {
  @observable messages = [];
  @observable doneMessages = {};

  @observable dialogs = [];
  @observable doneDialogs = {};

  @computed
  get waitMessages() {
    return this.messages.filter(message => !this.doneMessages[message.key]);
  }

  @computed
  get waitDialogs() {
    return this.dialogs.filter(dialog => !this.doneDialogs[dialog.key]);
  }

  @computed
  get signal() {
    return window.OneSignal || [];
  }

  @action
  open = async (message, options) => {
    const data = {
      key: `${Date.now()}_${Math.random()}`,
      message,
      options
    };

    if (options.showDialog) {
      this.dialogs.push(data);

      return data.key;
    }

    if (
      this.signal.initialized &&
      routerStore.os?.name !== 'iOS' &&
      Notification?.permission === 'granted'
    ) {
      return this.signal.push(() => {
        this.signal.sendSelfNotification(
          options?.title || appStore.name,
          message,
          options?.url,
          options?.icon
        );
      });
    }

    if (options?.onlyWebPush) {
      return;
    }

    this.messages.push(data);
    return data.key;
  };

  @action
  success = async (message, options) => {
    return this.open(message, { ...options, variant: 'success' });
  };

  @action
  error = async (message, options) => {
    return this.open(message, { ...options, variant: 'error' });
  };

  @action
  info = async (message, options) => {
    return this.open(message, { ...options, variant: 'info' });
  };

  @action
  warning = async (message, options) => {
    return this.open(message, { ...options, variant: 'warning' });
  };

  @action
  closeMessage = async key => {
    this.doneMessages[key] = true;
  };

  @action
  closeDialog = async key => {
    this.doneDialogs[key] = true;
  };

  @action
  initOneSignal = async config => {
    if (!isEmpty(config?.oneSignal)) {
      this.signal.push(() => {
        this.signal.init({
          allowLocalhostAsSecureOrigin: true,
          autoRegister: true,
          autoResubscribe: true,
          persistNotification: false,
          promptOptions: {
            slidedown: {
              enabled: true,
              actionMessage:
                "We'd like to show you notifications for the latest news and updates.",
              acceptButtonText: 'ACCEPT',
              cancelButtonText: 'NO, THANKS'
            }
          },
          welcomeNotification: {
            title: config.name,
            message: 'You are subscribed!'
          },
          notifyButton: {
            enable:
              Notification.permission ===
              'denied' /* Required to use the Subscription Bell */,
            size: 'small' /* One of 'small', 'medium', or 'large' */,
            text: {
              'tip.state.unsubscribed': "You've blocked notifications"
            }
          },
          ...config.oneSignal
        });

        this.signal.sendTag(window.location.host);
        this.signal.setDefaultTitle(config.name);
      });

      if (Notification?.permission === 'default') {
        this.signal.showSlidedownPrompt({ force: true });

        this.signal.on('popoverAllowClick', () => {
          this.signal.showNativePrompt();
        });
      }
    }
  };
}

export const notifyStore = new NotifyStore();
