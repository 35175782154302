import splash_screen_1 from 'assets/hireprivy_splash_screen_1.png';
import splash_screen_2 from 'assets/hireprivy_splash_screen_2.png';
import splash_screen_3 from 'assets/hireprivy_splash_screen_3.png';
import splash_screen_4 from 'assets/hireprivy_splash_screen_4.png';
import splash_screen_5 from 'assets/hireprivy_splash_screen_5.png';

const banners = {
  loginCarousel: {
    splashScreen_1st: splash_screen_1,
    splashScreen_2nd: splash_screen_2,
    splashScreen_3rd: splash_screen_3,
    splashScreen_4th: splash_screen_4,
    splashScreen_5th: splash_screen_5,
  }
};

export default banners;