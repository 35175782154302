export const ID = 'id';
export const ACTIVE = 'active';
export const ICON_URL = 'iconUrl';
export const COMPANY_ID = 'companyId';
export const DESCRIPTION = 'description';
export const NAME = 'name';
export const ICON_BASE64 = 'iconBase64';
export const ICON_FILENAME = 'iconFileName';
export const IS_GROUP = 'isGroup';
export const IS_ONBOARDING_BADGE = 'isOnboardingBadge';
export const WORKER_BADGES = 'workerBadges';
export const ORIGINAL_BADGES = 'originalBadges';
export const ICON_SIGNED_BLOB = 'iconSignedBlobId';

export const WORKER_ID = 'workerId';
export const WORKER_IDS = 'workerIds';
export const CREATED_AT = 'createdAt';
export const HIERARCHY_BADGES_ATTRIBUTE = 'hierarchyBadgesAttributes';

//WorkerBadge
export const EXPIRED_AT = 'expiredAt';
export const NOTE = 'note';

export const BADGE_ID = 'badgeId';
export const CHILD_ID = 'childId';
export const IS_WORKER_ONBOARDING_BADGE = 'isWorkerOnboardingBadge';
