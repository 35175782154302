import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getErrors,
  getIsLoading,
  getUser,
  getSuccesses
} from 'redux/selectors';
import {
  UPDATE_WORKER_FINGERPRINT,
  ADD_WORKER_FINGERPRINT,
  ADD_WORKER_ACCOUNT_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import FullPageLoader from 'components/common/loaders/FullPageLoader';
import {
  StyledWorkerContainer,
  StyledImgContainer,
  StyledSuccessHeader,
  StyledAgreement,
  StyledSignin,
  StyledSuccessWorkerContainer,
  StyledDialogActionsContainer
} from './styledWorkersList';
import * as Yup from 'yup';
import { Formik, ErrorMessage, Field } from 'formik';
import getText from 'context/language/getText';
import Grid from '@material-ui/core/Grid';
import {
  WORKER_ID,
  WORKER_FIRST_NAME,
  WORKER_LAST_NAME,
  WORKER_PHONE,
  WORKER_EMAIL,
  WORKER_COMPANY_ID,
  WORKER_AVATAR_URL,
  AVATAR_SIGNED_BLOB_ID,
  WORKER_PASSWORD,
  WORKER_PASSWORD_CONFIRMATION,
  WORKER_AGREE_TO_TERMS,
  WORKER_RECAPTCHA_TOKEN,
  WORKER_SSN,
  WORKER_MASK_SSN
} from 'common/constants/workerConstants';
import { SubmitButton, CancelButton } from 'components/common/buttons/Button';
import {
  addNewWorkerRequest,
  addNewWorkerAccountRequest,
  updateWorkerRequest,
  clearErrorState,
  clearSuccessState
} from 'redux/actions';
import isEmpty from 'lodash/isEmpty';
import { Alert, alertTypes } from 'components/common/alerts';
import { extractCompanyId } from 'common/helpers/helpers';
import { CustomStyledTextField, StyledErrorContainer } from 'pages/styledPage';
import ImageCropUploadField from 'components/common/form/inputs/ImageCropUploadInput/imageCropUploadField';
import PasswordInput from 'components/common/form/inputs/passwordInput/passwordInput';
import { RECAPTCHA_CLIENT_KEY } from 'components/auth/helpers.js';
import { EN_US, ES_SP } from 'context/language/constants';
import { getUrlParams } from 'common/helpers/getUrlParams';
import { getLibrary } from 'context/language/library';
import successSvg from 'assets/success.svg';
import hireprivyQRSvg from 'assets/hirePrivy/hireprivy-qr.png';
import opusQRSvg from 'assets/opus/opus-qr-app.png';
import MaskInput from 'components/common/form/inputs/maskInput/maskInput';

import { SHOW_HIRE_PRIVY_THEME_FINGERPRINT } from 'common/constants/fingerprintConstants';
const { COMPANY_HOST } = process.env;

const WorkerForm = ({
  errors,
  successes,
  isLoading,
  worker,
  addNew,
  update,
  closeHandler,
  clearErrorState,
  clearSuccessState,
  user,
  forSignup,
  addNewAccount,
  companyConfig,
  history
}) => {
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState(false);
  let lang = EN_US;
  let hasWorkingAuthorization = false;
  if (history?.location?.search) {
    const country = getUrlParams(history.location.search);
    if (country.lang === 'es') lang = ES_SP;
  }
  const langLib = getLibrary(lang);

  let qrImg = opusQRSvg;
  if (COMPANY_HOST === SHOW_HIRE_PRIVY_THEME_FINGERPRINT) {
    qrImg = hireprivyQRSvg;
  }

  let submitLabel = forSignup
    ? getText('signup.submitBtn', langLib)
    : getText('buttons.add', langLib);
  let isAddNew = true;
  let isShowSubmitBtn = true;
  let companyId = null;
  let privacyPolicy = '';
  let termOfUse = '';
  let universalAppUrl = '';

  let validationSchemaObj = Yup.object().shape({
    [WORKER_FIRST_NAME]: Yup.string().required(
      getText('workerModal.requiredFirstName', langLib)
    ),
    [WORKER_LAST_NAME]: Yup.string().required(
      getText('workerModal.requiredLastName', langLib)
    ),
    [WORKER_PHONE]: Yup.string().required(
      getText('workerModal.requiredPhone', langLib)
    ),
    [WORKER_EMAIL]: Yup.string()
      .required(getText('workerModal.requiredEmail', langLib))
      .email(getText('login.emailInvalid', langLib))
  });

  if (forSignup) {
    if (!isEmpty(companyConfig)) {
      companyId = companyConfig.companyId;
      privacyPolicy = companyConfig.privacyPolicyUrl;
      termOfUse = companyConfig.termsOfUseUrl;
      universalAppUrl = companyConfig.universalAppUrl;
    } else {
      isShowSubmitBtn = false;
    }
    validationSchemaObj = Yup.object().shape({
      [WORKER_FIRST_NAME]: Yup.string().required(
        getText('workerModal.requiredFirstName', langLib)
      ),
      [WORKER_LAST_NAME]: Yup.string().required(
        getText('workerModal.requiredLastName', langLib)
      ),
      [WORKER_PHONE]: Yup.string().required(
        getText('workerModal.requiredPhone', langLib)
      ),
      [WORKER_EMAIL]: Yup.string()
        .required(getText('workerModal.requiredEmail', langLib))
        .email(getText('login.emailInvalid', langLib)),
      [WORKER_PASSWORD]: Yup.string()
        .matches(
          /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$/,
          getText('signup.passwordValidate', langLib)
        )
        .required(getText('login.passwordRequired', langLib)),
      [WORKER_PASSWORD_CONFIRMATION]: Yup.string()
        .oneOf(
          [Yup.ref(WORKER_PASSWORD)],
          getText('forgotPass.passwordsNotMatch', langLib)
        )
        .required(getText('forgotPass.confirmPasswordRequired', langLib))
    });
  } else {
    companyId = extractCompanyId(user);
  }

  if (successes[fingerprint_update] || successes[fingerprint_add]) {
    closeHandler();
    window.location.reload();
  }

  if (successes[fingerprint_add_worker_account]) {
    return (
      <StyledSuccessWorkerContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledSuccessHeader>
              <h2 className="success-message">
                {getText('signup.success', langLib)}
              </h2>
            </StyledSuccessHeader>
          </Grid>
          <Grid item xs={12}>
            <span className="thankyou-message">
              {getText('signup.thankyouMessage', langLib)}
            </span>
          </Grid>
          <Grid item xs={12}>
            <StyledImgContainer>
              <img className="img" src={successSvg} alt="" />
              <p className="note-message">{getText('signup.note', langLib)}</p>
              <p className="introduce-app">
                <div>
                  <span class="spacer"></span>
                  <h4>{getText('signup.introduceApp', langLib)}</h4>
                  <span class="spacer"></span>
                </div>
              </p>
              <img className="qr-code" src={qrImg} alt="" />
            </StyledImgContainer>
          </Grid>
        </Grid>
      </StyledSuccessWorkerContainer>
    );
  }

  useEffect(() => {
    // returned function will be called on component unmount
    return () => {
      clearErrorState();
      clearSuccessState();
    };
  }, []);

  let initialWorkerVal = {
    [WORKER_ID]: '',
    [WORKER_FIRST_NAME]: '',
    [WORKER_LAST_NAME]: '',
    [WORKER_PHONE]: '',
    [WORKER_EMAIL]: '',
    [WORKER_PASSWORD]: '',
    [WORKER_PASSWORD_CONFIRMATION]: '',
    [WORKER_AGREE_TO_TERMS]: true,
    [WORKER_SSN]: '',
    [WORKER_COMPANY_ID]: companyId
  };
  if (!isEmpty(worker)) {
    submitLabel = getText('buttons.update', langLib);
    hasWorkingAuthorization = worker.workingAuthorization?.id ? true : false;
    isAddNew = false;
    initialWorkerVal = {
      [WORKER_ID]: worker[WORKER_ID],
      [WORKER_FIRST_NAME]: worker[WORKER_FIRST_NAME],
      [WORKER_LAST_NAME]: worker[WORKER_LAST_NAME],
      [WORKER_PHONE]: worker[[WORKER_PHONE]],
      [WORKER_EMAIL]: worker[WORKER_EMAIL],
      [WORKER_SSN]: worker[WORKER_MASK_SSN],
      [WORKER_COMPANY_ID]: companyId
    };
    validationSchemaObj = Yup.object().shape({
      [WORKER_FIRST_NAME]: Yup.string().required(
        getText('workerModal.requiredFirstName', langLib)
      ),
      [WORKER_LAST_NAME]: Yup.string().required(
        getText('workerModal.requiredLastName', langLib)
      ),
      [WORKER_PHONE]: Yup.string().required(
        getText('workerModal.requiredPhone', langLib)
      ),
      [WORKER_EMAIL]: Yup.string()
        .required(getText('workerModal.requiredEmail', langLib))
        .email(getText('login.emailInvalid', langLib)),
      ...(hasWorkingAuthorization && {
        [WORKER_SSN]: Yup.string()
          .required(getText('workerModal.requiredSSN', langLib))
          .matches(/^\d{3}-?\d{2}-?\d{4}$/, 'SSN must be 9 digits.')
      })
    });
  }

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    const isNumber = !isNaN(userInput);
    if (isNumber) return { value, selection: newState.selection };
    return;
  };

  return (
    <StyledWorkerContainer>
      <Formik
        initialValues={initialWorkerVal}
        validationSchema={validationSchemaObj}
        onSubmit={async (values, actions) => {
          if (!forSignup) {
            if (isAddNew) {
              addNew(values);
            } else {
              let updatedValues = values;
              if (
                updatedValues.socialSecurityNumber &&
                hasWorkingAuthorization
              ) {
                updatedValues.socialSecurityNumber = !updatedValues.socialSecurityNumber.includes(
                  'x'
                )
                  ? updatedValues.socialSecurityNumber
                      .replace(/\)/g, '')
                      .replace(/\(/g, '')
                      .replace(/-/g, '')
                      .replace(/ /g, '')
                  : null;
              }

              update(updatedValues);
            }
          } else {
            window.grecaptcha.ready(() => {
              window.grecaptcha
                .execute(RECAPTCHA_CLIENT_KEY, {
                  action: 'createWorkerAccount'
                })
                .then(token => {
                  let updatedValues = {
                    ...values,
                    [WORKER_RECAPTCHA_TOKEN]: token,
                    [WORKER_COMPANY_ID]: companyId
                  };
                  addNewAccount(updatedValues);
                });
            });
          }

          actions.setSubmitting(false);
        }}
        render={props => (
          <form onSubmit={props.handleSubmit} novalidate="novalidate">
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12}>
                <CustomStyledTextField
                  required
                  className="form-field"
                  variant="outlined"
                  size="small"
                  label={getText('workerModal.firstName', langLib)}
                  type="text"
                  name={WORKER_FIRST_NAME}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values[WORKER_FIRST_NAME]}
                />
                <StyledErrorContainer className="error">
                  <ErrorMessage name={WORKER_FIRST_NAME} />
                </StyledErrorContainer>
              </Grid>
              <Grid item xs={12}>
                <CustomStyledTextField
                  required
                  className="form-field"
                  variant="outlined"
                  size="small"
                  label={getText('workerModal.lastName', langLib)}
                  type="text"
                  name={WORKER_LAST_NAME}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values[WORKER_LAST_NAME]}
                />
                <StyledErrorContainer className="error">
                  <ErrorMessage name={WORKER_LAST_NAME} />
                </StyledErrorContainer>
              </Grid>
              <Grid item xs={12}>
                <CustomStyledTextField
                  required
                  className="form-field"
                  variant="outlined"
                  size="small"
                  label={getText('workerModal.phone', langLib)}
                  type="text"
                  name={WORKER_PHONE}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values[WORKER_PHONE]}
                />
                <StyledErrorContainer className="error">
                  <ErrorMessage name={WORKER_PHONE} />
                </StyledErrorContainer>
              </Grid>
              <Grid item xs={12}>
                <CustomStyledTextField
                  required
                  className="form-field"
                  variant="outlined"
                  size="small"
                  label={getText('workerModal.email', langLib)}
                  type="text"
                  name={WORKER_EMAIL}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values[WORKER_EMAIL]}
                />
                <StyledErrorContainer className="error">
                  <ErrorMessage name={WORKER_EMAIL} />
                </StyledErrorContainer>
              </Grid>
              {!forSignup && !isAddNew && hasWorkingAuthorization && (
                <Grid item xs={12}>
                  <MaskInput
                    mask="***-**-9999"
                    maskChar="#"
                    beforeMaskedValueChange={beforeMaskedValueChange}
                    className="form-field"
                    variant="outlined"
                    size="small"
                    label={getText('workerModal.ssn', langLib)}
                    type="text"
                    required
                    name={WORKER_SSN}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values[WORKER_SSN]}
                  />
                  <StyledErrorContainer className="error">
                    <ErrorMessage name={WORKER_SSN} />
                  </StyledErrorContainer>
                </Grid>
              )}
              {forSignup && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Field
                      name={WORKER_PASSWORD}
                      variant="outlined"
                      label={getText('login.password', langLib)}
                      hideIcon={true}
                      component={PasswordInput}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name={WORKER_PASSWORD_CONFIRMATION}
                      variant="outlined"
                      label={getText('forgotPass.confirmPassword', langLib)}
                      hideIcon={true}
                      component={PasswordInput}
                    />
                  </Grid>
                </React.Fragment>
              )}
              {!forSignup && (
                <Grid item xs={12}>
                  <ImageCropUploadField
                    isBlob={true}
                    setIsLoadingUploadImage={setIsLoadingUploadImage}
                    isLoadingUploadImage={isLoadingUploadImage}
                    signedBlobId={AVATAR_SIGNED_BLOB_ID}
                    name={WORKER_AVATAR_URL}
                    modalTitle={getText(
                      'companyInfo.cropImageModalTitle',
                      langLib
                    )}
                    label={getText('workerModal.imageUrl', langLib)}
                  />
                </Grid>
              )}
            </Grid>
            {forSignup && (
              <StyledAgreement>
                {getText('signup.agreement', langLib)}
                <a
                  href={privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {getText('signup.privacyPolicy', langLib)}
                </a>
                {getText('signup.and', langLib)}
                <a
                  href={termOfUse}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {getText('signup.termOfUse', langLib)}
                </a>
              </StyledAgreement>
            )}
            <StyledDialogActionsContainer>
              {isLoading && <FullPageLoader />}
              {!forSignup && !isLoading && (
                <CancelButton className="right-align" onClick={closeHandler}>
                  {getText('buttons.cancel', langLib)}
                </CancelButton>
              )}
              {!isLoading && (
                <SubmitButton
                  disabled={!isShowSubmitBtn || isLoadingUploadImage}
                  className="worker-submit-btn"
                >
                  {isLoadingUploadImage ? 'Loading' : submitLabel}
                </SubmitButton>
              )}
            </StyledDialogActionsContainer>
            {forSignup && (
              <StyledSignin>
                <a
                  href={universalAppUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  {getText('signup.signin', langLib)}
                </a>
              </StyledSignin>
            )}
            {!forSignup && errors.length > 0 && (
              <Alert type={alertTypes.ERROR} content={errors[0].message} />
            )}
          </form>
        )}
      />
    </StyledWorkerContainer>
  );
};

WorkerForm.propTypes = {
  load: PropTypes.func.isRequired,
  addNew: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  worker: PropTypes.object,
  errors: PropTypes.array,
  isLoading: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  forSignup: PropTypes.bool
};

WorkerForm.defaultProps = {
  errors: [],
  isLoading: false
};

const fingerprint_update = UPDATE_WORKER_FINGERPRINT;
const fingerprint_add = ADD_WORKER_FINGERPRINT;
const fingerprint_add_worker_account = ADD_WORKER_ACCOUNT_FINGERPRINT;

const metaAddWorkerAccount = {
  fingerprint: fingerprint_add_worker_account
};

const metaAdd = {
  fingerprint: fingerprint_add
};

const metaUpdate = {
  fingerprint: fingerprint_update
};

function mapDispatchToProps(dispatch) {
  return {
    clearErrorState: () => {
      dispatch(clearErrorState());
    },
    clearSuccessState: () => {
      dispatch(clearSuccessState());
    },
    addNewAccount: values => {
      dispatch(
        addNewWorkerAccountRequest({ values, meta: metaAddWorkerAccount })
      );
    },
    addNew: values => {
      dispatch(addNewWorkerRequest({ values, meta: metaAdd }));
    },
    update: values => {
      dispatch(updateWorkerRequest({ values, meta: metaUpdate }));
    }
  };
}

function mapStateToProps(state) {
  return {
    user: getUser(state),
    successes: getSuccesses(state),
    errors:
      getErrors(state)[fingerprint_update] ||
      getErrors(state)[fingerprint_add] ||
      getErrors(state)[fingerprint_add_worker_account] ||
      [],
    isLoading:
      getIsLoading(state)[fingerprint_update] ||
      getIsLoading(state)[fingerprint_add] ||
      getIsLoading(state)[fingerprint_add_worker_account] ||
      false
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkerForm);
