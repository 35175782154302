import ReactCrop from 'react-image-crop';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledImageCropper = styled(ReactCrop)`
  &.ReactCrop{
    background-color: transparent;
  }
  .ReactCrop__crop-selection {
  }
`;
