import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Avatar from 'react-avatar';

export const ListItemNotification = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 7px;
  align-items: center;
`;
export const MarkAllRead = styled(Typography)`
  cursor: pointer;
  color: #0086F9;
`;

export const NotificationTotal = styled(Typography)`
  padding: 15px 10px 19px;
  border-bottom: 1px solid #E4E4E4;
  font-weight: bold;

`;


export const ListNotitication = styled.li`
  cursor: pointer;
`;


export const AvatarWrapper = styled(Avatar)`
  margin-right: 10px;
`;

export const ContentMessage = styled.p`
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

export const CreatedAt = styled.small`
  color: #757575
`;

export const DotStyle = styled.div`

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0086F9;
  display: inline-block;


`;
