import styled from 'styled-components';
import Modal from 'components/common/modals/modal';

export const StyledInputLabel = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-bottom: 10px;
`;

export const StyledImagePreview = styled.div`
  margin: 0.5em;

  .preview-image {
    max-width: 100px;
    max-height: 100px;
  }
`;

export const StyledModal = styled(Modal)`
  &.image-upload-modal.ui.modal {
  }
`;

export const StyledCropUploadInputContainer = styled.div`
  .ui.input > input {
    border-radius: 15px;
    border-color: ${({ theme }) => theme.colors.name.main_color_9};
  }
  .image-field-label {
    color: ${({ theme }) => theme.colors.name.main_color_1};
  }
  .image-crop-upload-input {
  }
  .image-requirement{
    padding-top: 10px;
  }
`;

export const StyledError = styled.div`
  color: red;
  font-weight: bold;
`;

