import get from 'lodash/get';
import {
  LOAD_ALL_SHIFT_CATEGORIES,
  ADD_NEW_CATEGORY
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.SHIFT_CATEGORIES],
  action
) {
  switch (action.type) {
    case LOAD_ALL_SHIFT_CATEGORIES.SUCCESS: {
      const shiftCategories = get(action, 'payload.shiftCategories', []);
      return [...shiftCategories];
    }
    case ADD_NEW_CATEGORY.SUCCESS: {
      const newCategory = get(action, 'payload.createShiftCategory', {});
      const categories = state.concat(newCategory);

      toast.success(getText('newShift.categoryModal.addCategorySuccess'), {
        className: 'alert alert-success'
      });
      return [...categories];
    }
    case ADD_NEW_CATEGORY.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
