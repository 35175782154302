import {
  graphQueryPromise,
  handleUpdateString,
  graphMutationPromise,
  handleArray
} from './helpers';
import {
  EMPLOYER_ID,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME,
  EMPLOYER_PHONE,
  EMPLOYER_EMAIL,
  EMPLOYER_AVATAR_URL,
  EMPLOYER_COMPANY_ID,
  EMPLOYER_FACILITY_ID,
  EMPLOYER_LAST_SIGN_IN_AT,
  EMPLOYER_TITLE,
  EMPLOYER_SCOPE,
  AVATAR_SIGNED_BLOB_ID
} from 'common/constants/employerConstants';
import * as FACILITY from 'common/constants/facilityConstants';
import * as IMPORT from 'common/constants/importConstants';
import * as DASHBOARD from 'common/constants/dashboardConstants';

const employerFields = [
  EMPLOYER_ID,
  EMPLOYER_AVATAR_URL,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME,
  EMPLOYER_TITLE,
  EMPLOYER_EMAIL,
  EMPLOYER_PHONE,
  EMPLOYER_LAST_SIGN_IN_AT
];

const createEmployerFields = [
  EMPLOYER_ID,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME,
  EMPLOYER_EMAIL,
  EMPLOYER_PHONE,
  EMPLOYER_COMPANY_ID,
  EMPLOYER_FACILITY_ID,
  EMPLOYER_SCOPE
];

const updateEmployerFields = [
  EMPLOYER_ID,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME,
  EMPLOYER_PHONE,
  EMPLOYER_SCOPE,
  AVATAR_SIGNED_BLOB_ID
];

const facilityFields = [
  FACILITY.ID,
  FACILITY.NAME,
  FACILITY.DESCRIPTION,
  FACILITY.WORKPLACE_IMAGE_URL
];

const importFields = [
  IMPORT.AUTHOR_ID,
  IMPORT.AUTHOR_TYPE,
  IMPORT.COMPANY_ID,
  IMPORT.STATUS
];

const resultFields = [
  IMPORT.ADDED,
  IMPORT.COUNT,
  IMPORT.ERRORS,
  IMPORT.UPDATED
];

const dashboardFields = [
  DASHBOARD.TOTAL_CLIENTS,
  DASHBOARD.TOTAL_WORKERS_WORKING,
  DASHBOARD.TOTAL_POSITIONS_OPEN,
  DASHBOARD.TOTAL_WORKERS_PER_CLIENT,
  DASHBOARD.TOTAL_ONBOARDING_SUBMISSIONS,
  DASHBOARD.DATE_RANGES
];

const dashboardEmployerFields = [
  EMPLOYER_ID,
  EMPLOYER_FIRST_NAME,
  EMPLOYER_LAST_NAME
];

export const getAllEmployers = (
  companyId,
  offset = 0,
  limit = 20,
  search = '',
  sort = '',
  scope = '',
  fieldSearch = ''
) => {
  const fields = employerFields.join(',');
  const facility_fields = facilityFields.join(',');

  const query = `{
    employers(companyId: "${companyId}", offset: ${offset}, limit: ${limit} ${search} ${sort}, filter: { ${scope} ${fieldSearch} } )
    {
      ${fields}
      employerFacilities{
        facility{
          ${facility_fields}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const addNewEmployer = values => {
  const specialFields = [EMPLOYER_SCOPE];
  const fields = employerFields.join(',');
  const facility_fields = facilityFields.join(',');
  const createFields = handleUpdateString(
    values,
    createEmployerFields,
    true,
    specialFields
  );

  const query = `mutation {
    createEmployer(${createFields}) {
      ${fields}
      employerFacilities{
        facility{
          ${facility_fields}
        }
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const updateEmployer = values => {
  const specialFields = [EMPLOYER_SCOPE];
  const fields = employerFields.join(',');
  const facility_fields = facilityFields.join(',');
  const updates = handleUpdateString(
    values,
    updateEmployerFields,
    false,
    specialFields
  );

  const query = `mutation {
    updateEmployer(${updates}) {
      ${fields}
      employerFacilities{
        facility{
          ${facility_fields}
        }
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const searchEmployersByName = search => {
  const fields = employerFields.join(',');
  const nameFields = ['"firstName"', '"lastName"'];
  const searchFieldsStr = nameFields.join(',');
  const query = `{
    filterEmployers(filter: { search: {fields: [${searchFieldsStr}], queryString: "${search}"}}){
      ${fields}
    } 
  }`;

  return graphQueryPromise(query, {}, { rawQuery: true });
};

export const importEmployers = (fileBase64, fileName) => {
  const query = `mutation{
    importEmployer(fileBase64:"${fileBase64}", 
      fileFilename: "${fileName}"){
      success
      id
    }
  }`;

  return graphMutationPromise(query);
};

export const loadImportEmployerFile = id => {
  const import_fields = importFields.join(',');
  const result_fields = resultFields.join(',');

  const query = `{
    importEmployerFile(id: "${id}" )
    {
      ${import_fields}
      result{
        ${result_fields}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const resendInvites = (userType, userIds) => {
  const ids = handleArray(userIds);

  const query = `mutation {
    resendInviteForUsers(userType: ${userType}, ids: [${ids}]) {
      success
    }
  }`;

  return graphMutationPromise(query);
};

export const getEmployerAppInit = (employerId = null) => {
  const employer_fields = dashboardEmployerFields.join(',');
  const dashboard_fields = dashboardFields.join(',');

  const query = `{
    employerAppInit (employerId: "${employerId}" ){
        employer {
          ${employer_fields}
        }
        dashboard {
          ${dashboard_fields}
        }
    }
  }`;

  return graphQueryPromise(query);
};
