import styled from 'styled-components';
import Divider from '@material-ui/core/Divider';

export const StyledHeaderContainer = styled.div`
  display: block;
  align-items: center;
  overflow: hidden;
  .MuiAppBar-colorPrimary {
    background-color: ${({ theme }) => theme.colors.panel.header.background};
    color: ${({ theme }) => theme.colors.panel.header.foreground};
  }
  height: 80px;
  justify-content: space-between;

  .logo {
    z-index: 2;
    white-space: nowrap;
    text-align: center;
    margin: 1.2em 0;
    &:before {
      content: '';
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    .img {
      vertical-align: middle;
      width: ${({ theme }) => theme.icons.header.width};
      height: ${({ theme }) => theme.icons.header.height};
      padding: 5px;
      background-color: ${({ theme }) => theme.icons.header.background};
    }
  }
  .MuiToolbar-gutters {
    padding: 0px 5% 0px 5%;
    @media (max-width: 1279px) {
      padding: 0px;
    }
  }
  .MuiBottomNavigation-root {
    height: 60px;
  }
  .drawer-content {
    height: 80px;
    flex-grow: 1;
  }
  *:focus {
    outline: none;
  }
`;

export const StyledUserContainer = styled.div`
  text-align: center;
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin-top: 10px;
  }
`;

export const StyledPadding = styled.div`
  padding-left: 5%;
  @media (min-width: 1279px) {
    padding: 0px;
  }
`;
