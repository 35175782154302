import get from 'lodash/get';
import { LOAD_ALL_ACTIVE_JOBS } from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.ACTIVE_JOBS],
  action
) {
  switch (action.type) {
    case LOAD_ALL_ACTIVE_JOBS.SUCCESS: {
      const activeJobs = get(action, 'payload.jobs', []);
      return [...activeJobs];
    }
    case LOAD_ALL_ACTIVE_JOBS.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
