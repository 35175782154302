import {
  SEARCH_SHIFT_BY_NAME,
  LOAD_ALL_SHIFTS,
  CANCEL_SHIFT,
  UPDATE_SHIFT,
  LOAD_SHIFT_BY_ID,
  RESEND_NOTIFICATION
} from 'redux/actions/actionTypes';
import {
  getAllShifts,
  cancelShift,
  updateShift,
  getShiftById,
  resendNotification
} from 'api/shifts';
import {
  searchShiftsByNameSuccess,
  searchShiftsByNameFailure,
  loadAllShiftsSuccess,
  loadAllShiftsFailure,
  cancelShiftSuccess,
  cancelShiftFailure,
  updateShiftSuccess,
  updateShiftFailure,
  loadShiftByIdSuccess,
  loadShiftByIdFailure,
  resendNotificationSuccess,
  resendNotificationFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const shifts = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_SHIFTS.REQUEST: {
      next(action);
      const { companyId, offset, scopeFilter, field, order } = action.payload;
      return apiPromise(
        store,
        action,
        getAllShifts,
        [companyId, offset, null, scopeFilter, field, order],
        loadAllShiftsSuccess,
        loadAllShiftsFailure
      );
    }
    case RESEND_NOTIFICATION.REQUEST: {
      next(action);
      const { shiftId } = action.payload;
      return apiPromise(
        store,
        action,
        resendNotification,
        [shiftId],
        resendNotificationSuccess,
        resendNotificationFailure
      );
    }
    case LOAD_SHIFT_BY_ID.REQUEST: {
      next(action);
      const { shiftId } = action.payload;
      return apiPromise(
        store,
        action,
        getShiftById,
        [shiftId],
        loadShiftByIdSuccess,
        loadShiftByIdFailure
      );
    }
    case CANCEL_SHIFT.REQUEST: {
      next(action);
      const { shiftId } = action.payload;
      return apiPromise(
        store,
        action,
        cancelShift,
        [shiftId],
        cancelShiftSuccess,
        cancelShiftFailure
      );
    }
    case UPDATE_SHIFT.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateShift,
        [values],
        updateShiftSuccess,
        updateShiftFailure
      );
    }
    case SEARCH_SHIFT_BY_NAME.REQUEST: {
      next(action);
      const { companyId, keywordSearch, scopeFilter } = action.payload;
      return apiPromise(
        store,
        action,
        getAllShifts,
        [companyId, null, keywordSearch, scopeFilter],
        searchShiftsByNameSuccess,
        searchShiftsByNameFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default shifts;
