import React from 'react';
import InputMask from 'react-input-mask';
import { CustomStyledTextField } from 'pages/styledPage';

const MaskInput = props => (
  <InputMask {...props}>
    {inputProps => <CustomStyledTextField {...inputProps} />}
  </InputMask>
);

export default MaskInput;
