import {
  SEARCH_WORKER_BY_NAME,
  LOAD_ALL_WORKERS,
  ADD_NEW_WORKER,
  ADD_NEW_WORKER_ACCOUNT,
  UPDATE_WORKER,
  BLOCK_WORKER,
  IMPORT_WORKERS,
  LOAD_IMPORT_WORKER_FILE
} from 'redux/actions/actionTypes';
import {
  searchWorkersByName,
  getAllWorkers,
  addNewWorker,
  updateWorker,
  blockWorker,
  importWorkers,
  addNewWorkerAccount,
  loadImportWorkerFile
} from 'api/workers';
import {
  searchWorkersByNameSuccess,
  searchWorkersByNameFailure,
  loadAllWorkersSuccess,
  loadAllWorkersFailure,
  addNewWorkerSuccess,
  addNewWorkerFailure,
  addNewWorkerAccountSuccess,
  addNewWorkerAccountFailure,
  updateWorkerSuccess,
  updateWorkerFailure,
  importWorkersSuccess,
  importWorkersFailure,
  loadImportWorkerFileSuccess,
  loadImportWorkerFileFailure,
  blockWorkerSuccess,
  blockWorkerFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const workers = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_WORKERS.REQUEST: {
      next(action);
      const {
        limit,
        offset,
        companyId,
        getJobReport,
        badgeIds,
        workAuthorized,
        scope,
        fieldSearch,
        blockScope
      } = action.payload;

      return apiPromise(
        store,
        action,
        getAllWorkers,
        [
          companyId,
          getJobReport,
          badgeIds,
          workAuthorized,
          offset,
          limit,
          '',
          '',
          scope,
          fieldSearch,
          blockScope
        ],
        loadAllWorkersSuccess,
        loadAllWorkersFailure
      );
    }
    case SEARCH_WORKER_BY_NAME.REQUEST: {
      next(action);
      const { query, type } = action.payload;
      return apiPromise(
        store,
        action,
        searchWorkersByName,
        [query, type],
        searchWorkersByNameSuccess,
        searchWorkersByNameFailure
      );
    }
    case ADD_NEW_WORKER.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewWorker,
        [values],
        addNewWorkerSuccess,
        addNewWorkerFailure
      );
    }
    case ADD_NEW_WORKER_ACCOUNT.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewWorkerAccount,
        [values],
        addNewWorkerAccountSuccess,
        addNewWorkerAccountFailure
      );
    }
    case UPDATE_WORKER.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateWorker,
        [values],
        updateWorkerSuccess,
        updateWorkerFailure
      );
    }
    case BLOCK_WORKER.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        blockWorker,
        [values],
        blockWorkerSuccess,
        blockWorkerFailure
      );
    }
    case IMPORT_WORKERS.REQUEST: {
      next(action);
      const { fileBase64, fileName } = action.payload;
      return apiPromise(
        store,
        action,
        importWorkers,
        [fileBase64, fileName],
        importWorkersSuccess,
        importWorkersFailure
      );
    }
    case LOAD_IMPORT_WORKER_FILE.REQUEST: {
      next(action);
      const { id } = action.payload;
      return apiPromise(
        store,
        action,
        loadImportWorkerFile,
        [id],
        loadImportWorkerFileSuccess,
        loadImportWorkerFileFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default workers;
