// user login middleware
import {
  USER_LOGIN,
  AUTH_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  UNAUTH_USER,
  AGREE_TO_TERMS,
  SUBMIT_FEEDBACK
} from 'redux/actions/actionTypes';
import {
  signinUser,
  verifyToken,
  forgotPassword,
  resetPassword,
  changePassword,
  signoutUser,
  agreeToTerms,
  createFeedbackEmail
} from 'api/login';
import {
  signinUserSuccess,
  signinUserFailure,
  authUserByTokenSuccess,
  authUserByTokenFailure,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
  changePasswordSuccess,
  changePasswordFailure,
  signoutUserSuccess,
  signoutUserFailure,
  agreeToTermsSuccess,
  agreeToTermsFailure,
  submitFeedbackSuccess,
  submitFeedbackFailure
} from 'redux/actions';
import { apiPromise } from './apiPromise';
import { getEmployerId } from 'redux/selectors';

export const login = store => next => action => {
  switch (action.type) {
    case USER_LOGIN.REQUEST: {
      const values = action.payload;

      next(action);
      return apiPromise(
        store,
        action,
        signinUser,
        [values],
        signinUserSuccess,
        signinUserFailure
      );
    }
    case FORGOT_PASSWORD.REQUEST: {
      next(action);
      const emailVal = action.payload.email;
      return apiPromise(
        store,
        action,
        forgotPassword,
        [emailVal],
        forgotPasswordSuccess,
        forgotPasswordFailure
      );
    }
    case RESET_PASSWORD.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        resetPassword,
        [values],
        resetPasswordSuccess,
        resetPasswordFailure
      );
    }
    case CHANGE_PASSWORD.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        changePassword,
        [values],
        changePasswordSuccess,
        changePasswordFailure
      );
    }
    case UNAUTH_USER.REQUEST: {
      next(action);
      return apiPromise(
        store,
        action,
        signoutUser,
        [],
        signoutUserSuccess,
        signoutUserFailure
      );
    }
    case AUTH_USER.REQUEST: {
      next(action);
      const token = action.payload.sessionToken;
      if (token) {
        return apiPromise(
          store,
          action,
          verifyToken,
          [token],
          authUserByTokenSuccess,
          authUserByTokenFailure
        );
      } else {
        const { meta } = action;
        store.dispatch(authUserByTokenFailure({ error: null, meta }));
      }
      break;
    }
    case AGREE_TO_TERMS.REQUEST: {
      next(action);
      const employerId = getEmployerId(store.getState());

      return apiPromise(
        store,
        action,
        agreeToTerms,
        [employerId],
        agreeToTermsSuccess,
        agreeToTermsFailure
      );
    }
    case SUBMIT_FEEDBACK.REQUEST: {
      next(action);
      const { values } = action.payload;

      return apiPromise(
        store,
        action,
        createFeedbackEmail,
        [values],
        submitFeedbackSuccess,
        submitFeedbackFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default login;
