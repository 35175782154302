import React from 'react';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { EMPLOYER_TITLE } from 'common/constants/employerConstants';
import {
  revertDetailURLToOrigin,
  revertEditURLToOrigin
} from 'common/helpers/urlHelper';
import getText from 'context/language/getText';
import isEmpty from 'lodash/isEmpty';
import AccountInfo from 'modules/accountInfo/accountInfo.js';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUser, getUserAuthentication } from 'redux/selectors';
import { urlMap } from 'routes/urlMap';
import { withTheme } from 'styled-components';
import { breadcrumbsMapping } from './mappingBreadcrumb';
import StyledBreadcrumbsContainer from './styledBreadcrumbs';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  breadcrumbStyle: props => ({
    zIndex: 30,
    position: 'fixed',
    background: 'white',
    width: 'calc(100% - 250px)',
    left: '250px',

    '@media (max-width: 1279px)': {
      left: 0,
      width: '100%',
      top: props.renderFullScreen ? 0 : '62px'
    }
  })
}));

const Breadcrumbs = ({ authenticated, user, className, renderFullScreen }) => {
  const classes = useStyles({ renderFullScreen });
  const renderLevels = user => {
    let url = window.location.pathname;
    url = revertEditURLToOrigin(url);
    url = revertDetailURLToOrigin(url);
    let obj = breadcrumbsMapping(url);

    if (url.startsWith(urlMap.NEW_SHIFT)) {
      const urlParams = new URLSearchParams(window.location.search);
      const draftMode = urlParams.get('draftmode');
      if (draftMode) {
        obj = {
          title_1st: getText('navbar.shifts'),
          title_2nd: getText('pageTitle.newDraftShift'),
          restrictedRoles: []
        };
      }
    }

    if (url.startsWith(urlMap.EDIT_SHIFT_REQUEST)) {
      const urlParams = new URLSearchParams(window.location.search);
      const draftMode = urlParams.get('draftmode');
      if (draftMode) {
        obj = {
          title_1st: getText('navbar.shifts'),
          title_2nd: getText('pageTitle.editDraftShift'),
          restrictedRoles: []
        };
      }
    }

    const currentRole = !isEmpty(user) ? user.employer[EMPLOYER_TITLE] : null;

    if (obj && obj.restrictedRoles.includes(currentRole)) return null;

    return (
      <MaterialBreadcrumbs separator={'/'} aria-label="breadcrumb">
        {obj && (
          <Link color="inherit" href="/">
            {getText('pageTitle.opus')}
          </Link>
        )}
        {obj && obj.title_1st && obj.title_2nd ? (
          <span>{obj.title_1st}</span>
        ) : obj && obj.title_1st && !obj.title_2nd ? (
          <Typography className="primary-text" color="textPrimary">
            {obj.title_1st}
          </Typography>
        ) : (
          undefined
        )}
        {obj && obj.title_2nd && (
          <Typography className="primary-text" color="textPrimary">
            {obj.title_2nd}
          </Typography>
        )}
      </MaterialBreadcrumbs>
    );
  };

  return (
    <StyledBreadcrumbsContainer
      className={clsx(className, 'breadcrumbs', classes.breadcrumbStyle)}
    >
      {authenticated && renderLevels(user)}
      <AccountInfo />
    </StyledBreadcrumbsContainer>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  showNavbar: PropTypes.bool,
  user: PropTypes.object,
  authenticated: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired
};

Breadcrumbs.defaultProps = {
  showNavbar: true,
  className: ''
};

function mapStateToProps(state) {
  return {
    authenticated: getUserAuthentication(state),
    user: getUser(state)
  };
}

export default withTheme(connect(mapStateToProps)(Breadcrumbs));
