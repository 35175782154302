import initialState from './initialState';
import get from 'lodash/get';
import * as storeConstants from '../store/constants';
import {
  IMPORT_WORKERS,
  IMPORT_EMPLOYERS,
  LOAD_IMPORT_EMPLOYER_FILE,
  LOAD_IMPORT_WORKER_FILE,
  CLEAR_IMPORT_FILE_STATE
} from '../actions/actionTypes';
import {
  IMPORT_WORKER_FINGERPRINT,
  IMPORT_EMPLOYER_FINGERPRINT,
  IMPORT_WORKER_STATUS_FINGERPRINT,
  IMPORT_EMPLOYER_STATUS_FINGERPRINT
} from 'common/constants/fingerprintConstants';

export default function(
  state = initialState[storeConstants.IMPORT_FILE],
  action
) {
  switch (action.type) {
    case IMPORT_WORKERS.SUCCESS: {
      const id = get(action, 'payload.importWorker.id', '');
      return {
        ...state,
        [IMPORT_WORKER_FINGERPRINT]: id
      };
    }
    case IMPORT_WORKERS.FAILURE: {
      return {
        ...state,
        [IMPORT_WORKER_FINGERPRINT]: ''
      };
    }
    case IMPORT_EMPLOYERS.SUCCESS: {
      const id = get(action, 'payload.importEmployer.id', '');
      return {
        ...state,
        [IMPORT_EMPLOYER_FINGERPRINT]: id
      };
    }
    case IMPORT_EMPLOYERS.FAILURE: {
      return {
        ...state,
        [IMPORT_EMPLOYER_FINGERPRINT]: ''
      };
    }
    case LOAD_IMPORT_WORKER_FILE.SUCCESS: {
      const importWorkerFile = get(action, 'payload.importWorkerFile', '');
      return {
        ...state,
        [IMPORT_WORKER_STATUS_FINGERPRINT]: importWorkerFile
      };
    }
    case LOAD_IMPORT_EMPLOYER_FILE.SUCCESS: {
      const importEmployerFile = get(action, 'payload.importEmployerFile', '');
      return {
        ...state,
        [IMPORT_EMPLOYER_STATUS_FINGERPRINT]: importEmployerFile
      };
    }
    case CLEAR_IMPORT_FILE_STATE: {
      return {};
    }
    default:
      return state;
  }
}
