import styled from 'styled-components';

export const StyledWorkersList = styled.div`
  width: 100%;
  min-height: 90vh;

  .workers-select-all-cb {
    margin-right: 10px;
    margin-left: 10px;
  }

  .worker-row-cb {
    margin-left: 10px;
  }
`;

export const StyledWorkerContainer = styled.div`
  width: 100%;
  padding-top: 7px;
  .form-field {
    width: 100%;
  }
  .error {
    color: ${({ theme }) => theme.colors.name.lightred};
  }
`;

export const StyledImgContainer = styled.div`
  text-align: center;
  img {
    width: 30%;
    max-width: 200px;
  }
  .qr-code {
    padding-top: 20px;
    width: 30%;
    max-width: 100px;
  }
  p {
    width: 60%;
    max-width: 500px;
    margin: auto;
    &.note-message {
      padding-top: 40px;
      font-size: 16px;
    }
    &.introduce-app {
      display: table;
      div {
        display: table-row;
        line-height: 1.5em;
        font-size: 2em;
        white-space: nowrap;
      }
      h4 {
        font-size: inherit;
        overflow: hidden;
        display: table-cell;
        vertical-align: middle;
        width: 1px;
        table-layout: fixed;
      }
      span.spacer {
        display: table-cell;
      }
      h4 {
        padding: 0 10px;
        font-size: 12px;
        font-weight: normal;
      }
      span.spacer:after {
        display: inline-block;
        width: 100%;
        content: '.';
        font-size: 0;
        color: transparent;
        height: 2px;
        background: ${({ theme }) => theme.colors.name.sectiongrey};
        vertical-align: middle;
        position: relative;
        top: -1px;
      }
      & > a {
        font-size: 0.4em;
        vertical-align: middle;
        background: #25a2a4;
        color: ${({ theme }) => theme.colors.name.sectiongrey};
        text-transform: uppercase;
        font-family: monospace;
        border-radius: 0.5em;
        padding: 0.3em 0.5em;
        text-decoration: none;
      }
      padding-top: 50px;
    }
  }
`;

export const StyledSuccessHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .success-message {
    padding-left: 10px;
  }
  color: ${({ theme }) => theme.colors.name.main_color_1};
`;

export const StyledSuccessWorkerContainer = styled.div`
  text-align: center;
`;

export const StyledAgreement = styled.p`
  text-align: center;
  padding-top: 20px;
`;

export const StyledSignin = styled.p`
  text-align: center;
  padding-top: 40px;
`;

export const StyledDialogActionsContainer = styled.div`
  text-align: center;
  margin-top: 50px;
  padding-bottom: 10px;
  .ui.button {
    text-transform: uppercase;
    margin-right: 0;
  }
  .ui.button.right-align {
    margin-right: 10px;
  }
`;

export const StyledActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  svg {
    fill: ${({ theme }) => theme.colors.name.main_color_1};
  }
  .remove-badge svg {
    fill: ${({ theme }) => theme.colors.name.red};
  }
`;
