export const AUTH_LOGIN_ID = 'email';
export const AUTH_LOGIN_PASSWORD = 'password';
export const TOKEN_KEY = 'w3Token';
export const WEB_POLICIES = 'OpusPolicies';
export const COMPANY_ID = 'companyId';

export const EMPLOYER = 'Employer';
export const WORKER = 'Worker';

export const TOKEN = 'token';
export const PASSWORD_CONFIRMATION = 'passwordConfirmation';
export const OLD_PASSWORD = 'oldPassword';
export const AUTH_RECAPTCHA_TOKEN = 'recaptchaToken';
