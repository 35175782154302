import React from 'react';
//import { Loader } from 'semantic-ui-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledLoader } from 'pages/styledPage';

const FullPageLoader = props => {
  const isFullPageLoad = props?.isFullPageLoad || false;
  return (
    <StyledLoader isFullPageLoad={isFullPageLoad}>
      <CircularProgress />
    </StyledLoader>
  );
};

export default FullPageLoader;
