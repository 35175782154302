import React from 'react';
import {
  getIdFromEditURL,
  getIdFromDetailURL,
  getShiftNameFromDetailURL
} from 'common/helpers/urlHelper';
import * as JOB from 'common/constants/jobConstants';
import getText from 'context/language/getText';
import {
  SHOW_RECRUIT_FIRST_THEME_FINGERPRINT,
  SHOW_KAUFMAN_LYNN_THEME_FINGERPRINT,
  SHOW_OPUS_THEME_FINGERPRINT,
  SHOW_HIRE_PRIVY_THEME_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import { Helmet } from 'react-helmet';
import recruitFirst from 'theme/recruitFirst';
import kaufmanLynn from 'theme/kaufmanLynn';
import opus from 'theme/opus';
import hirePrivy from 'theme/hirePrivy';
import DefaultAvatarUrl from 'assets/default_avatar.png';

export const extractCompanyId = user => {
  const { employer } = user;
  const companyId = employer ? employer.company.id : '';

  return companyId;
};

export const extractIdFromUrl = () => {
  const url = window.location.pathname;

  const parts = url.split('/');
  return parts[parts.length - 1];
};

export const extractIdFromEditUrl = () => {
  const url = window.location.pathname;
  return getIdFromEditURL(url);
};

export const extractJobIdFromUrl = () => {
  const url = window.location.pathname;
  return getIdFromDetailURL(url);
};

export const extractShiftNameFromUrl = () => {
  const url = window.location.pathname;
  return getShiftNameFromDetailURL(url);
};

export const extractJobIdFromLocation = location => {
  return getIdFromDetailURL(location.pathname);
};

export const extractEmployerId = user => {
  const { employer } = user;
  const employerId = employer ? employer.id : '';

  return employerId;
};

export const extractFacilityId = user => {
  const { employer } = user;
  const employerFacility = employer ? employer.employerFacilities[0] : '';
  const facilityId = employerFacility ? employerFacility.facility.id : '';

  return facilityId;
};

export const getWorkerIdsFromActiveJobs = activeJobs => {
  let workerIds = [];
  if (activeJobs) {
    activeJobs.map(job => {
      const { worker } = job;
      const { id } = worker;
      if (id && !workerIds.includes(id)) workerIds.push(id);
      return job;
    });
  }

  return workerIds;
};

export const loadReportGroupByWorkerId = jobs => {
  let reportsGroupByWorker = [];
  let workerIds = [];

  jobs.map(job => {
    const {
      jobReport,
      workerId,
      worker,
      id: jobId,
      unreadMessageCounter
    } = job;

    if (jobReport && workerId) {
      let customJobReport = jobReport;
      customJobReport.jobId = jobId;
      customJobReport.unreadMessageCounter = unreadMessageCounter;

      if (!workerIds.includes(workerId)) {
        workerIds.push(workerId);

        let el = {
          ...worker,
          reports: [customJobReport]
        };
        reportsGroupByWorker.push(el);
      } else {
        reportsGroupByWorker.map(obj => {
          if (obj.id === workerId) {
            obj.reports.push(customJobReport);
          }
          return obj;
        });
      }
    }

    return job;
  });

  return reportsGroupByWorker;
};
export const mapStatusToText = state => {
  let displayText = '';
  switch (state) {
    case JOB.STATE_NO_SHOW:
      displayText = getText('jobStates.noShow');
      break;
    case JOB.STATE_FILLED:
      displayText = getText('jobStates.filled');
      break;
    case JOB.STATE_INVITED:
      displayText = getText('jobStates.invited');
      break;
    case JOB.STATE_LATE:
      displayText = getText('jobStates.late');
      break;
    case JOB.STATE_CANCELLED:
      displayText = getText('jobStates.cancelled');
      break;
    case JOB.STATE_WITHDRAWN:
      displayText = getText('jobStates.withDrawn');
      break;
    case JOB.STATE_STARTED:
      displayText = getText('jobStates.started');
      break;
    case JOB.STATE_PENDING:
      displayText = getText('jobStates.pending');
      break;
    case JOB.STATE_COMPLETED:
      displayText = getText('jobStates.completed');
      break;
    case JOB.STATE_PAUSED:
      displayText = getText('jobStates.paused');
      break;
    case JOB.STATE_APPROVED:
      displayText = getText('jobStates.approved');
      break;
    default:
      state = '';
  }
  return displayText;
};

export const mapActionsToText = (action, getState = false) => {
  let displayText = '';
  let state = '';
  switch (action) {
    case JOB.APPROVE:
      displayText = getText('jobActions.approve');
      state = JOB.STATE_APPROVED;
      break;
    case JOB.NO_SHOW:
      displayText = getText('jobActions.noShow');
      state = JOB.STATE_NO_SHOW;
      break;
    case JOB.CANCEL:
      displayText = getText('jobActions.cancel');
      state = JOB.STATE_CANCELLED;
      break;
    case JOB.START:
      displayText = getText('jobActions.start');
      state = JOB.STATE_STARTED;
      break;
    case JOB.TAKE_BREAK:
      displayText = getText('jobActions.takeBreak');
      state = JOB.STATE_PAUSED;
      break;
    case JOB.CHECK_IN:
      displayText = getText('jobActions.checkIn');
      state = JOB.STATE_STARTED;
      break;
    case JOB.CHECK_OUT:
      displayText = getText('jobActions.checkOut');
      state = JOB.STATE_STARTED;
      break;
    case JOB.COMPLETE:
      displayText = getText('jobActions.complete');
      state = JOB.STATE_COMPLETED;
      break;
    case JOB.ACCEPT_APPLICATION:
      displayText = getText('jobActions.acceptApplication');
      state = JOB.STATE_ACCEPTED;
      break;
    default:
      state = '';
  }
  return getState ? state : displayText;
};

export const getMetaInfoWebByCompany = company => {
  let metaInfo = [null, null];
  const kaufmanLynnInfo = (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Kaufman Lynn</title>
      <meta
        name="description"
        content="Full-service commercial construction specializing in preconstruction, construction management, value engineering, design-build, and general construction."
      />
      <link rel="shortcut icon" href="/kaufmanLynn/favicon.ico" />
      <link rel="manifest" href="/kaufmanLynn/manifest.json" />
    </Helmet>
  );

  const recruitFirstInfo = (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Recruit First</title>
      <meta
        name="description"
        content="At RecruitFirst Hong Kong, the core of what we do stems from knowing that the work we do makes positive changes in someone’s life."
      />
      <link rel="shortcut icon" href="/recruitFirst/favicon.ico" />
      <link rel="manifest" href="/recruitFirst/manifest.json" />
    </Helmet>
  );

  const opusInfo = (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Opus</title>
      <meta name="description" content="Opus portal" />
      <link rel="shortcut icon" href="/opus/favicon.ico" />
      <link rel="manifest" href="/opus/manifest.json" />
    </Helmet>
  );

  const hirePrivyInfo = (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Hire Privy</title>
      <meta name="description" content="Hire Privy portal" />
      <link rel="shortcut icon" href="/hirePrivy/favicon.ico" />
      <link rel="manifest" href="/hirePrivy/manifest.json" />
    </Helmet>
  );

  switch (company) {
    case SHOW_RECRUIT_FIRST_THEME_FINGERPRINT:
      metaInfo = [recruitFirstInfo, recruitFirst];
      break;
    case SHOW_KAUFMAN_LYNN_THEME_FINGERPRINT:
      metaInfo = [kaufmanLynnInfo, kaufmanLynn];
      break;
    case SHOW_OPUS_THEME_FINGERPRINT:
      metaInfo = [opusInfo, opus];
      break;
    case SHOW_HIRE_PRIVY_THEME_FINGERPRINT:
      metaInfo = [hirePrivyInfo, hirePrivy];
      break;
    default:
      metaInfo = [opusInfo, opus];
  }

  return metaInfo;
};

export const getUserAvatarUrl = avatarUrl => {
  let avatarImg = DefaultAvatarUrl;
  if (avatarUrl) avatarImg = avatarUrl;

  return avatarImg;
};
