import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/common/modals/modal';
import { CancelButton, SubmitButton } from 'components/common/buttons/Button';
import getText from 'context/language/getText';

const IdleTimeOutModal = ({ modalIsOpen, closeHandler, signoutHandler }) => (
  <Modal
    open={modalIsOpen}
    onClose={closeHandler}
    title={getText('idleModal.header')}
    size="small"
    actions={(
      <React.Fragment>
        <CancelButton onClick={closeHandler}>{getText('idleModal.stayBtn')}</CancelButton>
        <SubmitButton onClick={signoutHandler} btnStyle="submit">{getText('idleModal.signoutBtn')}</SubmitButton>
      </React.Fragment>
    )}
  >
    {getText('idleModal.content')}
  </Modal>
);

IdleTimeOutModal.propTypes = {
  closeHandler: PropTypes.func,
  modalIsOpen: PropTypes.bool,
  signoutHandler: PropTypes.func,
};


export default IdleTimeOutModal;