import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserAuthentication } from 'redux/selectors';
import { withTheme } from 'styled-components';
import { StyledHeaderContainer } from './styledHeaderContainer';
import DrawerMenu from 'modules/headerSignout/drawerMenuSignout.js';

const HeaderSignout = ({
  authenticated,
  theme,
  className,
  showNavbar,
  ...props
}) => {
  return (
    <StyledHeaderContainer className={`${className} header`}>
      <DrawerMenu
        authenticated={authenticated}
        globalTheme={theme}
        showNavbar={showNavbar}
        {...props}
      />
    </StyledHeaderContainer>
  );
};

HeaderSignout.propTypes = {
  className: PropTypes.string,
  showNavbar: PropTypes.bool,
  authenticated: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired
};

HeaderSignout.defaultProps = {
  showNavbar: true,
  className: ''
};

function mapStateToProps(state) {
  return {
    authenticated: getUserAuthentication(state)
  };
}

export default withTheme(connect(mapStateToProps)(HeaderSignout));
