import get from 'lodash/get';
import {
  SEARCH_WORKER_BY_NAME,
  LOAD_ALL_WORKERS,
  ADD_NEW_WORKER,
  ADD_NEW_WORKER_ACCOUNT,
  UPDATE_WORKER,
  BLOCK_WORKER,
  CREATE_JOB_RATING,
  UPDATE_JOB_RATING
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import {
  CREATE_JOB_RATING_REPORT_FINGERPRINT,
  UPDATE_JOB_RATING_REPORT_FINGERPRINT,
  SHOW_MORE_WORKERS_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import { showErrors } from './helpers';

export default function(state = initialState[storeConstants.WORKERS], action) {
  switch (action.type) {
    case SEARCH_WORKER_BY_NAME.SUCCESS: {
      const workers = get(action, 'payload.filterWorkers', []);
      return [...workers];
    }
    case LOAD_ALL_WORKERS.SUCCESS: {
      const allWorkers = get(action, 'payload.workers', []);
      const fingerprint = get(action, 'meta.fingerprint');
      let isLoadMore = fingerprint === SHOW_MORE_WORKERS_FINGERPRINT;

      const workers = isLoadMore ? state.concat(allWorkers) : allWorkers;

      return [...workers];
    }
    case ADD_NEW_WORKER.SUCCESS: {
      const newWorker = get(action, 'payload.createWorker', []);
      const workers = state.concat(newWorker);

      toast.success(getText('workerModal.addWorkerSuccess'), {
        className: 'alert alert-success'
      });
      return [...workers];
    }
    case UPDATE_WORKER.SUCCESS: {
      const updatedWorker = get(action, 'payload.updateWorker', []);
      const workers = state.map(item => {
        if (item.id === updatedWorker.id) {
          return updatedWorker;
        }
        return item;
      });

      toast.success(getText('workerModal.updateWorkerSuccess'), {
        className: 'alert alert-success'
      });
      return [...workers];
    }
    case CREATE_JOB_RATING.SUCCESS: {
      const jobRating = get(action, 'payload.createJobRating', {});
      const { rating, note, ratingUserType, job } = jobRating;
      let fingerprint = get(action, 'meta.fingerprint');

      if (fingerprint === CREATE_JOB_RATING_REPORT_FINGERPRINT) {
        let workers = state.map(worker => {
          worker.doneJobsJobReports = worker.doneJobsJobReports.map(report => {
            if (report.job.id === job.id) {
              let modifyReport = report;
              const ratingObj = {
                note: note,
                rating: rating,
                ratingUserType: ratingUserType
              };

              modifyReport.job.jobRatings.push(ratingObj);
              return modifyReport;
            }
            return report;
          });
          return worker;
        });

        return [...workers];
      }

      return state;
    }
    case UPDATE_JOB_RATING.SUCCESS: {
      const updatedJobRating = get(action, 'payload.updateJobRating', {});
      const { id, rating, note, ratingUserType, job } = updatedJobRating;
      let fingerprint = get(action, 'meta.fingerprint');

      if (fingerprint === UPDATE_JOB_RATING_REPORT_FINGERPRINT) {
        let workers = state.map(worker => {
          worker.doneJobsJobReports = worker.doneJobsJobReports.map(report => {
            if (report.job.id === job.id) {
              const ratingObj = {
                id: id,
                note: note,
                rating: rating,
                ratingUserType: ratingUserType
              };

              report.job.jobRatings = report.job.jobRatings.map(ratingItem => {
                if (ratingItem.id === ratingObj.id) return ratingObj;
                return ratingItem;
              });
              return report;
            }
            return report;
          });
          return worker;
        });

        return [...workers];
      }

      return state;
    }
    case LOAD_ALL_WORKERS.FAILURE:
    case SEARCH_WORKER_BY_NAME.FAILURE:
    case ADD_NEW_WORKER.FAILURE:
    case ADD_NEW_WORKER_ACCOUNT.FAILURE:
    case BLOCK_WORKER.FAILURE:
    case UPDATE_WORKER.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
