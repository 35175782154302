import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const StyledIconImage = styled.img`
  height: 30px;
  width: ${props => (props.type === 'invoice' ? '33px' : 'inherit')}
`;

export const StyledImportIcon = styled.img`
  float: left;
  padding-right: 5px;
`;

export const StyledEmailSigninIcon = styled.img`
  width: 20px;
`;

export const StyledPassSigninIcon = styled.img`
  width: 20px;
`;

export const StyledCancelIcon = styled.svg`
  fill: ${({ theme }) => theme.colors.name.red};
`;