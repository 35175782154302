// store constants
export const AUTH = 'auth';
export const USER = 'user';
export const ERRORS = 'errors';
export const THEME = 'theme';
export const LOADING = 'loading';
export const IMAGE_UPLOAD = 'image_upload';
export const PAGING = 'pagination';
export const WORKERS = 'workers';
export const EMPLOYERS = 'employers';
export const SHIFTS = 'shifts';
export const JOBS = 'jobs';
export const COMPANY = 'company';
export const TEAMS = 'teams';
export const LOCATIONS = 'locations';
export const REPORTS = 'reports';
export const MESSAGE_THREADS = 'message_threads';
export const MESSAGE_DETAIL = 'message_detail';
export const IMPORT_FILE = 'import_files';
export const SHIFT_CATEGORIES = 'shift_categories';
export const BADGES = 'badges';
export const SHIFT_REQUEST = 'shift_request';
export const SHIFT_REQUESTS = 'shift_requests';
export const WORKER_LOCATIONS = 'worker_locations';
export const WORKER_CONTRACT = 'worker_contract';
export const ACTIVE_JOBS = 'active_jobs';
export const FACILITIES = 'facilities';
export const FACILITY_GROUPS = 'facility_groups';
export const SUCCESSES = 'successes';
export const LOAD_MORE = 'load_more';
export const REQUEST_TASKS = 'request_tasks';
export const TASK_PROGRESSES = 'task_progresses';
export const ONBOARDINGS = 'onboardings';
export const UPCOMING_SCHEDULES = 'upcoming_schedules';
export const PAST_SCHEDULES = 'past_schedules';

