import * as key from '../store/constants';

// initial state
const initialState = {
  [key.AUTH]: {
    authenticated: false
  },
  [key.PAGING]: {},
  [key.USER]: {},
  [key.SUCCESSES]: {},
  [key.ERRORS]: {},
  [key.THEME]: {},
  [key.LOADING]: {},
  [key.IMAGE_UPLOAD]: {},
  [key.WORKERS]: [],
  [key.EMPLOYERS]: [],
  [key.SHIFTS]: [],
  [key.SHIFT_CATEGORIES]: [],
  [key.BADGES]: [],
  [key.JOBS]: [],
  [key.COMPANY]: {},
  [key.TEAMS]: [],
  [key.LOCATIONS]: [],
  [key.FACILITIES]: [],
  [key.FACILITY_GROUPS]: [],
  [key.REPORTS]: [],
  [key.MESSAGE_THREADS]: [],
  [key.MESSAGE_DETAIL]: [],
  [key.IMPORT_FILE]: {},
  [key.SHIFT_REQUESTS]: [],
  [key.SHIFT_REQUEST]: {},
  [key.ACTIVE_JOBS]: [],
  [key.WORKER_LOCATIONS]: [],
  [key.REQUEST_TASKS]: [],
  [key.ONBOARDINGS]: [],
  [key.UPCOMING_SCHEDULES]: [],
  [key.PAST_SCHEDULES]: [],
  [key.TASK_PROGRESSES]: [],
  [key.WORKER_CONTRACT]: {},
  [key.LOAD_MORE]: {}
};

export default initialState;
