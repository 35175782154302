
import { createTracker } from 'redux-segment';
import { logger } from './logger';
import { ravenMiddleWare } from './sentry';
import { imageUpload } from './imageUpload';
import { currentUser } from './currentUser';
import { fingerprinter } from './fingerprinter';
import { login } from './login';
import redirect from './redirect';
import { workers } from './workers';
import { employers } from './employers';
import { shifts } from './shifts';
import { companySettings } from './companySettings';
import { teams } from './teams';
import { locations } from './locations';
import { reports } from './reports';
import { messages } from './messages';
import { jobs } from './jobs';
import { shiftCategories } from './shiftCategories';
import { shiftRequest } from './shiftRequest';
import { activeJobs } from './activeJobs';
import { workerLocations } from './workerLocations';
import { workerContract } from './workerContract';
import { onboardings } from './onboardings';
import { facilities } from './facilities';
import { facilityGroups } from './facilityGroups';
import { shiftRequests } from './shiftRequests';
import { requestTasks } from './requestTasks';
import { taskProgresses } from './taskProgresses';
import { interviewSchedules } from './interviewSchedules';
import { badges } from './badges';

const segmentTracker = createTracker();

// must be before any content activity; in sort order
const setupMiddleware = [
  segmentTracker,
  //pagination,
];

// middleware acting on content; unsorted
const contentMiddleware = [
  imageUpload,
  login,
  redirect,
  currentUser,
  workers,
  employers,
  shifts,
  companySettings,
  teams,
  locations,
  facilities,
  facilityGroups,
  reports,
  messages,
  jobs,
  activeJobs,
  shiftCategories,
  badges,
  shiftRequest,
  shiftRequests,
  workerLocations,
  workerContract,
  requestTasks,
  taskProgresses,
  onboardings,
  interviewSchedules
];

const middlewares = setupMiddleware.concat(contentMiddleware);
const middlewaresProd = middlewares.concat([ravenMiddleWare]);
const middlewaresDev = middlewares.concat([fingerprinter, logger]);

export default (process.env.NODE_ENV === 'development' ? middlewaresDev : middlewaresProd);
