import React from 'react';
import PropTypes from 'prop-types';
import 'react-image-crop/dist/ReactCrop.css';
import {
  StyledImageCropper
} from './styledImageCropper';

const ImageCropper = ({
  src, onChange, crop, onImageLoaded, onComplete, imageRef
}) => src && (
  <StyledImageCropper
    src={src}
    onChange={onChange}
    crop={crop}
    onImageLoaded={onImageLoaded}
    onComplete={onComplete}
    imageRef={imageRef}
  />
);

ImageCropper.propTypes = {
  src: PropTypes.string,
  onChange: PropTypes.func,
  crop: PropTypes.object.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired
};

ImageCropper.defaultProps = {
  src: '',
  onChange: () => { }
};

export default ImageCropper;
