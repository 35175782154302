import get from 'lodash/get';
import {
  LOAD_ALL_REQUEST_TASKS,
  CREATE_REQUEST_TASK,
  REMOVE_REQUEST_TASK
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.REQUEST_TASKS],
  action
) {
  switch (action.type) {
    case LOAD_ALL_REQUEST_TASKS.SUCCESS: {
      const loadedRequestTasks = get(action, 'payload.requestTasks', []);
      const requestTasks = state.concat(loadedRequestTasks);
      return [...requestTasks];
    }
    case REMOVE_REQUEST_TASK.SUCCESS: {
      const success = get(action, 'payload.cancelShiftRequest.success', {});
      toast.success(getText('tasksList.cancelTaskSuccess'), {
        className: 'alert alert-success'
      });

      if (success) {
        const requestTaskId = get(action, 'meta.requestTaskId', '');
        let updateRequestTasks = state.filter(requestTask => {
          if (requestTask.id === requestTaskId) {
            return false;
          }
          return requestTask;
        });

        return [...updateRequestTasks];
      }
      break;
    }
    case REMOVE_REQUEST_TASK.FAILURE:
    case CREATE_REQUEST_TASK.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
