import styled from 'styled-components';

export default styled.div`
  align-items: center;
  display: flex;
  min-height: 53px;
  /* padding: 10px 5% 10px 5%; */
  margin-bottom: 2px;
  padding: 0px 5% 0px 5%;
  justify-content: space-between;
  -webkit-box-shadow: 0px 2px 0px 0px
    ${({ theme }) => theme.colors.name.shadowgrey};
  -moz-box-shadow: 0px 2px 0px 0px
    ${({ theme }) => theme.colors.name.shadowgrey};
  box-shadow: 0px 6px 2px 0px ${({ theme }) => theme.colors.name.shadowgrey};
  svg {
    fill: ${({ theme }) => theme.colors.name.main_color_4};
    margin-right: 10px;
    width: 15px;
    height: 15px;
    vertical-align: middle;
    margin-bottom: 5px;
    &.MuiSvgIcon-fontSizeSmall {
      margin: 0;
    }
  }
  .primary-text {
    color: ${({ theme }) => theme.colors.name.main_color_1};
  }
`;
