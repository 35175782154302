import styled, { css } from 'styled-components';

export const StyledMobileAppInfoContainer = styled.div`
  height: 100%;
  .container-area{
    height: 100%;
    background-color: #ffffff;
  }
  .left-area{
    background-color: #ffffff;
  }
  .right-area{
    height: 100%;
    background-color: ${({ theme }) => theme.colors.name.main_color_1};
  }
  .img-wrapper{
    text-align: center;
  }
`;

export const styledCustomMedia = css`
  width: 70%;
  padding-top: 80px;
  background-size: contain;
`

export const StyledInfoContainer = styled.div`
  text-align: center;
  img{
    padding-top: 40px;
    max-width: 200px;
    max-height: 200px;
  }
  .qr-code{
    padding-top: 20px;
    width: 30%;
    max-width: 100px;
    padding-bottom: 20px;
  }
  .copy-right{
    padding-bottom: 20px;
    font-size: 11px;
    color: ${({ theme }) => theme.colors.name.main_color_1};
  }
  .img-link{
    padding-top: 20px;
    width: 30%;
    max-width: 100px;
  }
  .google-play-link, .apple-store-link {
    padding-top: 10px;
    width: 30%;
    max-width: 100px;
  }
  .sub-instruction{
    color: ${({ theme }) => theme.colors.name.main_color_1};
  }
  p{
    width: 80%;
    max-width: 500px;
    margin: auto;
    &.introduce-app{
      display:table;
      div {
        display:table-row;
        line-height:1.5em;
        font-size:2em;
        white-space:nowrap;
        color: ${({ theme }) => theme.colors.name.main_color_1};
      }
      h4 {
          font-size:inherit;
          overflow:hidden;
          display:table-cell;
          vertical-align:middle;
          width:1px;
          table-layout:fixed;
          color: ${({ theme }) => theme.colors.name.main_color_1};
      }
      span.spacer {
          display:table-cell;
      }
      h4 {
          padding:0 10px;
          font-size: 14px;
          font-weight: normal;
      }
      span.spacer:after {
          display:inline-block;
          width:100%;
          content:".";
          font-size:0;
          color:transparent;
          height:2px;
          background:${({ theme }) => theme.colors.name.sectiongrey};
          vertical-align:middle;
          position:relative;
          top:-1px;
      }
      & > a {
          font-size:.4em;
          vertical-align:middle;
          background:#25a2a4;
          color:${({ theme }) => theme.colors.name.sectiongrey};
          text-transform:uppercase;
          font-family:monospace;
          border-radius:.5em;
          padding:.3em .5em;
          text-decoration:none;
      }
      padding-top: 50px;
    }
  }
`;

export const styledLink = css`
  color: ${({ theme }) => theme.colors.name.main_color_4};
  font-weight: bold;  
`
