import get from 'lodash/get';
import {
  LOAD_ALL_FACILITIES,
  ADD_NEW_FACILITY,
  UPDATE_FACILITY,
  REMOVE_FACILITY
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { DEACTIVATE_FACILITY_FINGERPRINT } from 'common/constants/fingerprintConstants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.FACILITIES],
  action
) {
  switch (action.type) {
    case LOAD_ALL_FACILITIES.SUCCESS: {
      const facilities = get(action, 'payload.facilities', []);
      return [...facilities];
    }
    case ADD_NEW_FACILITY.SUCCESS: {
      const newFacility = get(action, 'payload.createFacility', []);
      const facilities = state.concat(newFacility);

      toast.success(getText('facilityModal.addFacilitySuccess'), {
        className: 'alert alert-success'
      });
      return [...facilities];
    }
    case UPDATE_FACILITY.SUCCESS: {
      const updatedFacility = get(action, 'payload.updateFacility', []);
      const fingerprint = get(action, 'meta.fingerprint');
      const facilities = state.map(item => {
        if (item.id === updatedFacility.id) {
          return updatedFacility;
        }
        return item;
      });

      let message = getText('facilityModal.updateFacilitySuccess');
      if (fingerprint === DEACTIVATE_FACILITY_FINGERPRINT) {
        message = getText('facilityModal.deactivateFacilitySuccess');
      }
      toast.success(message, { className: 'alert alert-success' });

      return [...facilities];
    }
    case REMOVE_FACILITY.SUCCESS: {
      const success = get(action, 'payload.destroyFacility.success', {});
      if (success) {
        const facilityId = get(action, 'meta.facilityId', '');
        let updateFacilities = state.filter(facility => {
          if (facility.id === facilityId) {
            return false;
          }
          return facility;
        });
        return [...updateFacilities];
      }
      break;
    }
    case LOAD_ALL_FACILITIES.FAILURE:
    case REMOVE_FACILITY.FAILURE:
    case ADD_NEW_FACILITY.FAILURE:
    case UPDATE_FACILITY.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
