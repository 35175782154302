import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { PassSignInIcon } from 'components/common/icons';
import { StyledErrorContainer } from 'pages/styledPage';

const PasswordInput = ({
  label,
  field,
  form: { touched, errors },
  hideIcon,
  ...props
}) => {
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false
  });

  let startAdornment = null;
  if (!hideIcon)
    startAdornment = (
      <InputAdornment position="start">
        <PassSignInIcon />
      </InputAdornment>
    );

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div>
      <FormControl
        className="password-input-ctrl"
        fullWidth
        margin="normal"
        size="small"
        variant="outlined"
      >
        <InputLabel htmlFor="adornment-password" required>
          {label}
        </InputLabel>
        <OutlinedInput
          id="adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          {...field}
          {...props}
          startAdornment={startAdornment}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                className="toggle-show"
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {touched[field.name] && errors[field.name] && (
        <StyledErrorContainer>{errors[field.name]}</StyledErrorContainer>
      )}
    </div>
  );
};

export default PasswordInput;
