import initialState from './initialState';
import { SUCCESSES } from '../store/constants';
import * as types from '../actions/actionTypes';
import get from 'lodash/get';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { RESEND_INVITES_EMPLOYERS_FINGERPRINT } from 'common/constants/fingerprintConstants';

export default function(state = initialState[SUCCESSES], action) {
  let fingerprint = null;
  switch (action.type) {
    case types.ADD_NEW_WORKER.SUCCESS: {
      const newWorker = get(action, 'payload.createWorker', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newWorker
      };
    }
    case types.ADD_NEW_WORKER_ACCOUNT.SUCCESS: {
      const newWorkerAccount = get(action, 'payload.createWorkerAccount', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newWorkerAccount
      };
    }
    case types.CREATE_SHIFT_REQUEST.SUCCESS: {
      const newShiftRequest = get(action, 'payload.createShiftRequest', null);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newShiftRequest
      };
    }
    case types.UPDATE_SHIFT_REQUEST.SUCCESS: {
      const updateShiftRequest = get(
        action,
        'payload.updateShiftRequest',
        null
      );
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updateShiftRequest
      };
    }
    case types.UPDATE_WORKER.SUCCESS: {
      const updateWorker = get(action, 'payload.updateWorker', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updateWorker
      };
    }
    case types.BLOCK_WORKER.SUCCESS: {
      const workerBlock = get(action, 'payload.blockWorker', {});
      fingerprint = get(action, 'meta.fingerprint');

      toast.success(getText('workerList.blockWorkerSuccess'), {
        className: 'alert alert-success'
      });

      return {
        ...state,
        [fingerprint]: workerBlock
      };
    }
    case types.ADD_NEW_EMPLOYER.SUCCESS: {
      const newEmployer = get(action, 'payload.createEmployer', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newEmployer
      };
    }
    case types.UPDATE_EMPLOYER.SUCCESS: {
      const updatedEmployer = get(action, 'payload.updateEmployer', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedEmployer
      };
    }
    case types.ADD_NEW_LOCATION.SUCCESS: {
      const newLocation = get(action, 'payload.createLocation', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newLocation
      };
    }
    case types.UPDATE_LOCATION.SUCCESS: {
      const updatedLocation = get(action, 'payload.updateLocation', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedLocation
      };
    }
    case types.ADD_NEW_FACILITY.SUCCESS: {
      const newFacility = get(action, 'payload.createFacility', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newFacility
      };
    }
    case types.ADD_NEW_TEAM.SUCCESS: {
      const newTeam = get(action, 'payload.createTeam', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newTeam
      };
    }
    case types.UPDATE_TEAM.SUCCESS: {
      const updatedTeam = get(action, 'payload.updateTeam', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedTeam
      };
    }
    case types.UPDATE_FACILITY.SUCCESS: {
      const updatedFacility = get(action, 'payload.updateFacility', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedFacility
      };
    }
    case types.CREATE_JOB.SUCCESS: {
      const createdJob = get(action, 'payload.createJob', {});
      let fingerprint = get(action, 'meta.fingerprint');

      let message = getText('shiftsList.createJobSuccess');
      toast.success(message, { className: 'alert alert-success' });

      return {
        ...state,
        [fingerprint]: createdJob
      };
    }
    case types.UPDATE_REPORT.SUCCESS: {
      const updatedJobReport = get(action, 'payload.updateJobReport', {});
      let fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedJobReport
      };
    }
    case types.ADD_NEW_MESSAGE.SUCCESS: {
      const newMessage = get(action, 'payload.createMessage', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newMessage
      };
    }
    case types.RESET_PASSWORD.SUCCESS: {
      const success = get(action, 'payload.resetPasswordForUser', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: success
      };
    }
    case types.CHANGE_PASSWORD.SUCCESS: {
      const success = get(action, 'payload.changePasswordForUser', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: success
      };
    }
    case types.FORGOT_PASSWORD.SUCCESS: {
      const success = get(action, 'payload.sendPasswordResetEmployer', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: success
      };
    }
    case types.SAVE_COMPANY_SETTINGS.SUCCESS: {
      const updateCompany = get(action, 'payload.updateCompany', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updateCompany
      };
    }
    case types.UPDATE_SHIFT.SUCCESS: {
      const updatedShift = get(action, 'payload.updateShift', []);
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedShift
      };
    }
    case types.UPDATE_JOB.SUCCESS: {
      const updatedJob = get(action, 'payload.updateJob', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedJob
      };
    }
    case types.ADD_NEW_BADGE.SUCCESS: {
      const newBadge = get(action, 'payload.createBadge', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: newBadge
      };
    }
    case types.UPDATE_BADGE.SUCCESS: {
      const updatedBadge = get(action, 'payload.updateBadge', {});

      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updatedBadge
      };
    }
    case types.CREATE_REQUEST_TASK.SUCCESS: {
      const newRequestTask = get(action, 'payload.createRequestTask', {});
      fingerprint = get(action, 'meta.fingerprint');

      toast.success(getText('tasksList.addNewRequestTaskSuccess'), {
        className: 'alert alert-success'
      });
      return {
        ...state,
        [fingerprint]: newRequestTask
      };
    }
    case types.UPDATE_REQUEST_TASK.SUCCESS: {
      const updateRequestTask = get(action, 'payload.updateRequestTask', {});
      fingerprint = get(action, 'meta.fingerprint');
      return {
        ...state,
        [fingerprint]: updateRequestTask
      };
    }
    case types.CREATE_JOB_RATING.SUCCESS: {
      const jobRating = get(action, 'payload.createJobRating', {});
      fingerprint = get(action, 'meta.fingerprint');

      toast.success(getText('shiftsList.createJobRatingSuccess'), {
        className: 'alert alert-success'
      });
      return {
        ...state,
        [fingerprint]: jobRating
      };
    }
    case types.ADD_NEW_CATEGORY.SUCCESS: {
      fingerprint = get(action, 'meta.fingerprint');

      return {
        ...state,
        [fingerprint]: { success: true }
      };
    }
    case types.MARK_ALL_MESSAGES_AS_READ.SUCCESS: {
      fingerprint = get(action, 'meta.fingerprint');

      return {
        ...state,
        [fingerprint]: { success: true }
      };
    }
    case types.RESEND_INVITES.SUCCESS: {
      const success = get(action, 'payload.resendInviteForUsers', {});
      let fingerprint = get(action, 'meta.fingerprint');

      const message =
        fingerprint === RESEND_INVITES_EMPLOYERS_FINGERPRINT
          ? getText('employerList.resendInvitesEmployersSuccess')
          : getText('workerList.resendInvitesWorkersSuccess');

      toast.success(message, { className: 'alert alert-success' });

      return {
        ...state,
        [fingerprint]: success
      };
    }
    case types.RESEND_NOTIFICATION.SUCCESS: {
      const success = get(action, 'payload.resendNotification', {});
      let fingerprint = get(action, 'meta.fingerprint');

      const message = getText('shiftsList.resendNotificationSuccessfully');
      toast.success(message, { className: 'alert alert-success' });

      return {
        ...state,
        [fingerprint]: success
      };
    }
    case types.UPDATE_JOB_RATING.SUCCESS: {
      const jobRating = get(action, 'payload.updateJobRating', {});
      fingerprint = get(action, 'meta.fingerprint');

      toast.success(getText('shiftsList.updateJobRatingSuccess'), {
        className: 'alert alert-success'
      });
      return {
        ...state,
        [fingerprint]: jobRating
      };
    }
    case types.SUBMIT_FEEDBACK.SUCCESS: {
      const response = get(action, 'payload.createFeedbackEmail', {});
      fingerprint = get(action, 'meta.fingerprint');

      toast.success(getText('helpAndFeedback.sendEmailSuccess'), {
        className: 'alert alert-success'
      });
      return {
        ...state,
        [fingerprint]: response
      };
    }
    case types.CANCEL_SHIFT_REQUEST.SUCCESS: {
      const successCancelShiftRequest = get(
        action,
        'payload.cancelShiftRequest',
        {}
      );
      fingerprint = get(action, 'meta.fingerprint');

      let message = getText('shiftsList.cancelShiftRequestSuccess');
      toast.success(message, { className: 'alert alert-success' });
      return {
        ...state,
        [fingerprint]: successCancelShiftRequest
      };
    }
    case types.CANCEL_SHIFT.SUCCESS: {
      const successCancelShift = get(action, 'payload.cancelShift', {});
      fingerprint = get(action, 'meta.fingerprint');

      let message = getText('shiftsList.cancelShiftSuccess');
      toast.success(message, { className: 'alert alert-success' });
      return {
        ...state,
        [fingerprint]: successCancelShift
      };
    }
    case types.CLEAR_SUCCESS_STATE: {
      return {};
    }
    default:
      return state;
  }
}
