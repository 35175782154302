// company info APIs

import { graphMutationPromise, post } from './helpers';

export const postAssetMetadata = (metadata) => {
  const mutation = `mutation {
    createAssetMetadata(metadata: {
      ${metadata}
    }) {
      id
    }
  }`;

  return graphMutationPromise(mutation);
};

export const uploadAsset = (formData) => {
  const uploadUrl = '/upload';
  return post(uploadUrl, formData);
};
