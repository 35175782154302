import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uploadCroppedImageRequest } from 'redux/actions';
import { getUploadedImages } from 'redux/selectors';
import { IMAGE_UPLOAD_FINGERPRINT } from 'common/constants/fingerprintConstants';
import ImageCropUploadInput from './ImageCropUploadInput';
import { imageUploadStore } from './ImageCropUpload.store';
import ImageCropUploadInputBlob from './ImageCropUploadInputBlob';

class container extends React.Component {
  state = {
    initialImage: null
  };

  componentDidMount() {
    const { field } = this.props;
    const { value } = field;

    this.setState({
      initialImage: value
    });
  }

  render() {
    const { isBlob } = this.props;
    const { initialImage } = this.state;
    return isBlob ? (
      <ImageCropUploadInputBlob
        imageUploadStore={imageUploadStore}
        {...this.props}
        initialImage={initialImage}
      />
    ) : (
      <ImageCropUploadInput {...this.props} initialImage={initialImage} />
    );
  }
}

container.propTypes = {
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch, ownProps) {
  const { field } = ownProps;
  const { name } = field;
  return {
    onSubmitCrop: (imageData, cropX, cropY, cropWidth, cropHeight) => {
      dispatch(
        uploadCroppedImageRequest({
          imageData,
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          meta: {
            fingerprint: `${IMAGE_UPLOAD_FINGERPRINT}.${name}`
          }
        })
      );
    }
  };
}

function mapStateToProps(state) {
  return {
    uploadedImages: getUploadedImages(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(container);
