import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { getTheme } from 'redux/selectors';
import defaultTheme from './defaults';

export const ThemerHOC = ({ theme, children, customTheme }) => {
  return (
    <ThemeProvider
      theme={{
        ...defaultTheme,
        ...customTheme,
        ...theme
      }}
    >
      {children}
    </ThemeProvider>
  );
};

function mapStateToProps(state) {
  return {
    theme: getTheme(state)
  };
}

export default connect(mapStateToProps)(ThemerHOC);

ThemerHOC.propTypes = {
  children: PropTypes.object.isRequired,
  theme: PropTypes.object
};

ThemerHOC.defaultProps = {
  theme: { colors: {}, logo: {} }
};
