import {
  LOAD_MESSAGE_CONVERSATIONS,
  LOAD_MESSAGE_BY_ID,
  ADD_NEW_MESSAGE,
  MARK_ALL_MESSAGES_AS_READ,
  LOAD_MESSAGE_THREAD_BY_ID
} from 'redux/actions/actionTypes';
import {
  getAllConversations,
  getMessageDetailByMessageThreadId,
  addNewMessage,
  markAllMessagesAsRead,
  getMessageThreadById
} from 'api/messages';
import {
  loadMessageThreadsSuccess,
  loadMessageThreadsFailure,
  loadMessageDetailByIdSuccess,
  loadMessageDetailByIdFailure,
  addNewMessageSuccess,
  addNewMessageFailure,
  markAllMessagesAsReadSuccess,
  markAllMessagesAsReadFailure,
  loadMessageThreadByIdSuccess,
  loadMessageThreadByIdFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const messages = store => next => action => {
  switch (action.type) {
    case LOAD_MESSAGE_CONVERSATIONS.REQUEST: {
      next(action);
      return apiPromise(
        store,
        action,
        getAllConversations,
        [],
        loadMessageThreadsSuccess,
        loadMessageThreadsFailure
      );
    }
    case LOAD_MESSAGE_BY_ID.REQUEST: {
      next(action);
      const { messageThreadId, offset } = action.payload;
      return apiPromise(
        store,
        action,
        getMessageDetailByMessageThreadId,
        [messageThreadId, offset],
        loadMessageDetailByIdSuccess,
        loadMessageDetailByIdFailure
      );
    }
    case LOAD_MESSAGE_THREAD_BY_ID.REQUEST: {
      next(action);
      const { messageThreadId } = action.payload;
      return apiPromise(
        store,
        action,
        getMessageThreadById,
        [messageThreadId],
        loadMessageThreadByIdSuccess,
        loadMessageThreadByIdFailure
      );
    }
    case MARK_ALL_MESSAGES_AS_READ.REQUEST: {
      next(action);
      const { jobId } = action.payload;
      return apiPromise(
        store,
        action,
        markAllMessagesAsRead,
        [jobId],
        markAllMessagesAsReadSuccess,
        markAllMessagesAsReadFailure
      );
    }
    case ADD_NEW_MESSAGE.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewMessage,
        [values],
        addNewMessageSuccess,
        addNewMessageFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default messages;
