import {
  graphMutationPromise,
  graphQueryPromise,
  handleUpdateString
} from './helpers';
import * as JOB from 'common/constants/jobConstants';
import * as JOB_RATINGS from 'common/constants/jobRatingsConstants';
import * as WORKER from 'common/constants/workerConstants';
import * as SHIFT from 'common/constants/shiftConstants';
import * as SHIFT_REQUEST from 'common/constants/shiftRequestConstants';
import * as LOCATION from 'common/constants/locationConstants';
import * as JOB_REPORT from 'common/constants/jobReportConstants';

const jobFields = [
  JOB.ID,
  JOB.CREATED_AT,
  JOB.SHIFT_ID,
  JOB.ACTUAL_WORKING_DURATION,
  JOB.STATE,
  JOB.NOTE,
  JOB.ADJUSTED_PAYMENT,
  JOB.PAUSED_AT,
  JOB.STARTED_AT,
  JOB.FILLED_AT,
  JOB.FACILITY_ID,
  JOB.PERMITTED_EVENTS,
  JOB.WORKER_ID,
  JOB.UNREAD_MESSAGE_COUNTER,
  JOB.MAXIMUM_PAY_AMOUNT,
  JOB.PAY_AMOUNT,
  JOB.ADJUSTED_PAYMENT
];

const jobRatingsFields = [
  JOB_RATINGS.ID,
  JOB_RATINGS.NOTE,
  JOB_RATINGS.RATING,
  JOB_RATINGS.RATING_USER_TYPE
];

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE,
  WORKER.WORKER_EMAIL
];

const activeShiftFields = [SHIFT.ID, SHIFT.DURATION, SHIFT.STATE];

const activeShiftRequestFields = [
  SHIFT_REQUEST.ID,
  SHIFT_REQUEST.NAME,
  SHIFT_REQUEST.STATE
];

const locationFields = [
  LOCATION.ID,
  LOCATION.GEOFENCING_RADIUS,
  LOCATION.LATITUDE,
  LOCATION.LONGITUDE,
  LOCATION.NAME,
  LOCATION.ZIPCODE,
  LOCATION.PHONE,
  LOCATION.CITY,
  LOCATION.ADDRESS,
  LOCATION.ADDRESS_STATE
];

const jobReportFields = [
  JOB_REPORT.ID,
  JOB_REPORT.EMPLOYER_NOTE,
  JOB_REPORT.EMPLOYER_RATING,
  JOB_REPORT.EXPECTED_JOB_DURATION,
  JOB_REPORT.EXPECTED_PAYMENT,
  JOB_REPORT.FINAL_PAYMENT,
  JOB_REPORT.LOCATION_ADDRESS,
  JOB_REPORT.LOCATION_NAME,
  JOB_REPORT.SHIFT_NAME,
  JOB_REPORT.WORKED_HOURS,
  JOB_REPORT.WORKER_ID,
  JOB_REPORT.WORKER_NOTE,
  JOB_REPORT.WORKER_DATE,
  JOB_REPORT.WORKER_RATING,
  JOB_REPORT.TIME_SHEET,
  JOB_REPORT.PERMITTED_EVENTS,
  JOB_REPORT.ADJUSTED_WORKED_HOURS
];

const createJobFields = [JOB.SHIFT_ID, JOB.STATE, JOB.WORKER_ID];

const updateJobFields = [JOB.ID, JOB.EVENT];

export const getAllJobs = (
  companyId,
  filter,
  limit,
  offset,
  search = '',
  sort = '',
  extraArgs = ''
) => {
  const job_fields = jobFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');
  const worker_fields = workerFields.join(',');
  const job_report_fields = jobReportFields.join(',');
  const active_shift_request_fields = activeShiftRequestFields.join(',');

  const query = `{
    jobs(companyId : "${companyId}", limit: ${limit}, offset: ${offset} ${filter} ${search} ${sort} ${extraArgs}) {
      ${job_fields}
      jobRatings {
        ${job_ratings_fields}
      }
      jobReport {
        ${job_report_fields}
      }
      worker {
        ${worker_fields}
      }
      shift {
        ${SHIFT.ID}
        ${SHIFT.PAY_AMOUNT}
        ${SHIFT.MAXIMUM_PAY_AMOUNT}
        ${SHIFT.START_TIME}

        location {
          timezone
        }

        shiftRequest {
          ${active_shift_request_fields}

          shiftCategory {
            id
            name
          }
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getJobsByRange = (companyId, fromDate, toDate) => {
  const job_fields = jobFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');
  const worker_fields = workerFields.join(',');
  const job_report_fields = jobReportFields.join(',');
  const active_shift_request_fields = activeShiftRequestFields.join(',');

  const query = `{
    jobs(companyId : "${companyId}", limit: 1000, filter: { fromDate: "${fromDate}", toDate: "${toDate}"}) {
      ${job_fields}
      jobRatings {
        ${job_ratings_fields}
      }
      jobReport {
        ${job_report_fields}
      }
      worker {
        ${worker_fields}
      }
      shift {
        ${SHIFT.ID}
        ${SHIFT.PAY_AMOUNT}
        ${SHIFT.MAXIMUM_PAY_AMOUNT}
        ${SHIFT.START_TIME}
        ${SHIFT.END_TIME}
        ${SHIFT.TIME_ZONE}

        location {
          timezone
        }

        shiftRequest {
          ${active_shift_request_fields}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getJobsByShiftIdSendBulk = (
  shiftId,
  limit,
  offset,
  search = '',
  sortString
) => {
  const worker_fields = workerFields.join(',');
  const query = `{
    jobs(shiftId : "${shiftId}", limit: ${limit}, offset: ${offset} ${search} , filter:{scope : active} ) {
      worker {
        ${worker_fields}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getJobsByShiftId = (companyId, shiftId) => {
  const job_fields = jobFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');
  const worker_fields = workerFields.join(',');
  const job_report_fields = jobReportFields.join(',');
  const active_shift_request_fields = activeShiftRequestFields.join(',');

  const query = `{
    jobs(companyId : "${companyId}", limit: 1000, shiftId: "${shiftId}") {
      ${job_fields}
      jobRatings {
        ${job_ratings_fields}
      }
      jobReport {
        ${job_report_fields}
      }
      worker {
        ${worker_fields}
      }
      shift {
        ${SHIFT.ID}
        ${SHIFT.PAY_AMOUNT}
        ${SHIFT.MAXIMUM_PAY_AMOUNT}
        ${SHIFT.START_TIME}

        location {
          timezone
        }

        shiftRequest {
          ${active_shift_request_fields}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getJobById = jobId => {
  const job_fields = jobFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');
  const worker_fields = workerFields.join(',');
  const job_report_fields = jobReportFields.join(',');
  const active_shift_request_fields = activeShiftRequestFields.join(',');

  const query = `{
    job(id : "${jobId}") {
      ${job_fields}
      jobRatings {
        ${job_ratings_fields}
      }
      jobReport {
        ${job_report_fields}
      }
      worker {
        ${worker_fields}
      }
      shift {
        ${SHIFT.ID}
        ${SHIFT.PAY_AMOUNT}

        shiftRequest {
          ${active_shift_request_fields}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getAllActiveJobs = (companyId, locationId) => {
  const job_fields = jobFields.join(',');
  const active_shift_fields = activeShiftFields.join(',');
  const active_shift_request_fields = activeShiftRequestFields.join(',');
  const location_fields = locationFields.join(',');
  const worker_fields = workerFields.join(',');
  const job_report_fields = jobReportFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');

  const query = `{
    jobs(companyId: "${companyId}", filter: { locationId: "${locationId}", scope: active }
    ) {
      ${job_fields}
      worker {
        ${worker_fields}
      }
      jobRatings {
        ${job_ratings_fields}
      }
      jobReport {
        ${job_report_fields}
      }
      shift {
        ${active_shift_fields}
        location{
          ${location_fields}
        }
        shiftRequest {
          ${active_shift_request_fields}
        }
      }
    }
  }
  `;

  return graphQueryPromise(query);
};

export const createJobRating = values => {
  const job_ratings_fields = jobRatingsFields.join(',');
  const createJobRatingFields = jobRatingsFields
    .concat(JOB_RATINGS.JOB_ID)
    .concat(JOB_RATINGS.WORKER_ID)
    .concat(JOB_RATINGS.EMPLOYER_ID);
  const updates = handleUpdateString(values, createJobRatingFields, true);

  const query = `mutation {
    createJobRating(${updates}) {
      ${job_ratings_fields}
      job {
        id
        createdAt
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const updateJobRating = values => {
  const job_ratings_fields = jobRatingsFields.join(',');
  const updateJobRatingFields = jobRatingsFields.filter(
    item => item !== JOB_RATINGS.RATING_USER_TYPE
  );
  const updates = handleUpdateString(values, updateJobRatingFields, false);

  const query = `mutation {
      updateJobRating(${updates}) {
        ${job_ratings_fields}
        job {
          id
          createdAt
        }
      }
    }
  `;

  return graphMutationPromise(query);
};

export const updateJob = values => {
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');

  const updates = handleUpdateString(values, updateJobFields, false);

  const query = `mutation {
      updateJob(${updates}) {
        ${job_fields}
        worker{
          ${worker_fields}
        }
        jobRatings {
          ${job_ratings_fields}
        }
      }
    }
  `;

  return graphMutationPromise(query);
};

export const createJob = values => {
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');
  const updates = handleUpdateString(values, createJobFields, true);

  const query = `mutation {
      createJob(${updates}) {
        ${job_fields}
        worker {
          ${worker_fields}
        }
        jobRatings {
          ${job_ratings_fields}
        }
      }
    }
  `;

  return graphMutationPromise(query);
};
