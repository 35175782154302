import {
  LOAD_ALL_REPORTS,
  LOAD_REPORT_BY_WORKER_ID,
  UPDATE_REPORT
} from 'redux/actions/actionTypes';
import { getAllReports, updateReport, getReportByWorkerId } from 'api/reports';
import {
  loadAllReportsSuccess,
  loadAllReportsFailure,
  updateReportSuccess,
  updateReportFailure,
  loadReportByWorkerIdSuccess,
  loadReportByWorkerIdFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const reports = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_REPORTS.REQUEST: {
      next(action);
      return apiPromise(
        store,
        action,
        getAllReports,
        [],
        loadAllReportsSuccess,
        loadAllReportsFailure
      );
    }
    case LOAD_REPORT_BY_WORKER_ID.REQUEST: {
      next(action);
      const { workerId, offset, limit } = action.payload;
      return apiPromise(
        store,
        action,
        getReportByWorkerId,
        [workerId, offset, limit],
        loadReportByWorkerIdSuccess,
        loadReportByWorkerIdFailure
      );
    }
    case UPDATE_REPORT.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateReport,
        [values],
        updateReportSuccess,
        updateReportFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default reports;
