import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUserAuthentication } from 'redux/selectors';
import { withTheme } from 'styled-components';
import DrawerMenu from 'modules/header/drawerMenu.js';
import { StyledHeaderContainer } from './styledHeaderContainer';
import React from 'react';

import { Hidden } from '@material-ui/core';
const Header = ({
  authenticated,
  theme,
  className,
  showNavbar,
  onOpenNavBarMobile,
  ...props
}) => {
  return (
    <Hidden lgUp>
      <StyledHeaderContainer className={`${className} header`}>
        <DrawerMenu
          onOpenNavBarMobile={onOpenNavBarMobile}
          authenticated={authenticated}
          globalTheme={theme}
          showNavbar={showNavbar}
          {...props}
        />
      </StyledHeaderContainer>
    </Hidden>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  showNavbar: PropTypes.bool,
  authenticated: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired
};

Header.defaultProps = {
  showNavbar: true,
  className: ''
};

function mapStateToProps(state) {
  return {
    authenticated: getUserAuthentication(state)
  };
}

export default withTheme(connect(mapStateToProps)(Header));
