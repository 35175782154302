import {
  LOAD_ALL_FACILITIES,
  ADD_NEW_FACILITY,
  UPDATE_FACILITY,
  REMOVE_FACILITY
} from 'redux/actions/actionTypes';
import {
  getAllFacilities,
  addNewFacility,
  updateFacility,
  removeFacility
} from 'api/facilities';
import {
  loadAllFacilitiesSuccess,
  loadAllFacilitiesFailure,
  addNewFacilitySuccess,
  addNewFacilityFailure,
  updateFacilitySuccess,
  updateFacilityFailure,
  removeFacilitySuccess,
  removeFacilityFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const facilities = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_FACILITIES.REQUEST: {
      next(action);
      const { companyId, search, filter } = action.payload;
      return apiPromise(
        store,
        action,
        getAllFacilities,
        [companyId, undefined, undefined, search, filter],
        loadAllFacilitiesSuccess,
        loadAllFacilitiesFailure
      );
    }
    case ADD_NEW_FACILITY.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewFacility,
        [values],
        addNewFacilitySuccess,
        addNewFacilityFailure
      );
    }
    case UPDATE_FACILITY.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateFacility,
        [values],
        updateFacilitySuccess,
        updateFacilityFailure
      );
    }
    case REMOVE_FACILITY.REQUEST: {
      next(action);
      const { facilityId } = action.payload;
      return apiPromise(
        store,
        action,
        removeFacility,
        [facilityId],
        removeFacilitySuccess,
        removeFacilityFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default facilities;
