import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import TextField from '@material-ui/core/TextField';
import PasswordInput from 'components/common/form/inputs/passwordInput/passwordInput';
import { SubmitButton } from 'components/common/buttons/Button';
import Checkbox from 'components/common/form/inputs/checkbox/checkbox';
import getText from 'context/language/getText';
import { signinUserRequest } from 'redux/actions';
import { getErrors, getIsLoading } from 'redux/selectors';
import { Alert, alertTypes } from 'components/common/alerts';
import { SIGNIN_FINGERPRINT } from 'common/constants/fingerprintConstants';
import FullPageLoader from 'components/common/loaders/FullPageLoader';
import { EmailSignInIcon } from 'components/common/icons';
import Grid from '@material-ui/core/Grid';
import Link from 'components/common/links/Link';
import { urlMap } from 'routes/urlMap';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  StyledSignInFormContainer,
  StyledSignInLoadingContainer
} from 'components/auth/styledSignin';
import {
  AUTH_LOGIN_ID,
  AUTH_LOGIN_PASSWORD,
  AUTH_RECAPTCHA_TOKEN,
  COMPANY_ID
} from 'common/constants/authConstants';
import { RECAPTCHA_CLIENT_KEY } from 'components/auth/helpers.js';

const SigninForm = ({ signinUser, isLoading, errors, companyConfig }) => {
  if (isLoading)
    return (
      <StyledSignInLoadingContainer>
        <FullPageLoader />
      </StyledSignInLoadingContainer>
    );

  return (
    <StyledSignInFormContainer>
      <Formik
        initialValues={{
          email: '',
          password: '',
          remember: false
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(getText('login.emailInvalid'))
            .required(getText('login.emailRequired')),
          password: Yup.string()
            .min(6, getText('login.passwordAtleast6Chars'))
            .required(getText('login.passwordRequired'))
        })}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);

          window.grecaptcha.ready(() => {
            window.grecaptcha
              .execute(RECAPTCHA_CLIENT_KEY, {
                action: 'signInEmployer'
              })
              .then(token => {
                const companyId = companyConfig?.companyId;
                signinUser(values.email, values.password, token, companyId);
                actions.setSubmitting(false);
              });
          });
        }}
        render={props => (
          <form onSubmit={props.handleSubmit}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item className="signin-row">
                <TextField
                  required
                  label={getText('login.email')}
                  type="email"
                  name={AUTH_LOGIN_ID}
                  margin="normal"
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  value={props.values.email}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailSignInIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <div className="error email-error">
                  <ErrorMessage name="email" />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item className="signin-row">
                <Field
                  name={AUTH_LOGIN_PASSWORD}
                  variant="outlined"
                  label={getText('login.password')}
                  component={PasswordInput}
                />
              </Grid>
            </Grid>
            <Field
              name="remember"
              className="remember"
              label={getText('login.rememberMe')}
              component={Checkbox}
              onChange={props.handleChange}
              value={props.values.remember}
            />
            <SubmitButton className="signin-submit-btn">
              {getText('login.loginButton')}
            </SubmitButton>
            <div className="forgot-pasword">
              <Link to={urlMap.FORGOT_PASSWORD}>
                {getText('login.forgotLinkLabel')}
              </Link>
            </div>
            {errors.length > 0 && (
              <Alert type={alertTypes.ERROR} content={errors[0].message} />
            )}
          </form>
        )}
      />
    </StyledSignInFormContainer>
  );
};

SigninForm.propTypes = {
  errors: PropTypes.array,
  isLoading: PropTypes.bool
};

SigninForm.defaultProps = {
  errors: [],
  isLoading: false
};

const fingerprint = SIGNIN_FINGERPRINT;
const meta = {
  fingerprint
};

function mapDispatchToProps(dispatch) {
  return {
    signinUser: (email, password, token, companyId) => {
      const value = {
        [AUTH_LOGIN_ID]: email,
        [AUTH_LOGIN_PASSWORD]: password,
        [AUTH_RECAPTCHA_TOKEN]: token,
        [COMPANY_ID]: companyId,
        meta
      };
      dispatch(signinUserRequest(value));
    }
  };
}

function mapStateToProps(state) {
  return {
    errors: getErrors(state)[fingerprint] || [],
    isLoading: getIsLoading(state)[fingerprint] || false
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SigninForm);
