export const WORKER_ID = 'id';
export const WORKER_FIRST_NAME = 'firstName';
export const WORKER_LAST_NAME = 'lastName';
export const WORKER_PHONE = 'phone';
export const WORKER_EMAIL = 'email';
export const WORKER_AVATAR_URL = 'avatarUrl';
export const WORKER_BADGES = 'badges';
export const WORKER_COMPANY_ID = 'companyId';
export const WORKER_WORKING_AUTHORIZATION = 'workingAuthorization';
export const WORKER_JOB_REPORTS = 'jobReports';
export const WORKER_LAST_SIGN_IN_AT = 'lastSignInAt';
export const WORKER_DONE_JOBS_JOB_REPORTS = 'doneJobsJobReports';
export const WORKER_OVERALL_RATING = 'overallRating';
export const WORKER_PASSWORD = 'password';
export const WORKER_PASSWORD_CONFIRMATION = 'passwordConfirmation';
export const WORKER_AGREE_TO_TERMS = 'agreedToTerms';
export const WORKER_RECAPTCHA_TOKEN = 'recaptchaToken';

export const AVATAR_SIGNED_BLOB_ID = 'avatarSignedBlobId';
export const WORKER_AVATAR_IMAGE_BASE64 = 'avatarImageBase64';
export const WORKER_AVATAR_IMAGE_FILENAME = 'avatarImageFileName';
export const WORKER_FULL_SSN = 'fullSocialSecurityNumber';
export const WORKER_MASK_SSN = 'maskingSocialSecurityNumber';
export const WORKER_SSN = 'socialSecurityNumber';

//Worker Block Field
export const BLOCK_WORKER_ID = 'workerId';
export const BLOCK_NOTE = 'note';
export const BLOCK_TYPE = 'blockType';
export const BLOCK_FACILITIES = 'blockFacilities';
