import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query getLocations($companyId: String!, $facilityId: String) {
    locations(
      companyId: $companyId
      facilityId: $facilityId
      limit: 100
      offset: 0
      filter: { active: true }
    ) {
      id
      active
      address
      addressState
      city
      facilityId
      facilityGroupId
      geofencingRadius
      latitude
      longitude
      timezone
      name
      phone
      zipcode
      country
    }
  }
`;

export const GET_WORKERS_LOCATION = gql`
  query getWorkersLocation($workerIds: [String!]!) {
    workerCurrentLocations(workerIds: $workerIds) {
      workerId
      longitude
      latitude
      createdAt
      batteryLevel
    }
  }
`;
