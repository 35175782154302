//WorkerOnboarding

export const COMPANY_ONBOARDING_ID = 'companyOnboardingId';
export const CURRENT_PART_INDEX = 'currentPartIndex';
export const ID = 'id';
export const STATE = 'state';
export const PERMITTED_EVENTS = 'permittedEvents';
export const PENDING_WORKER_ONBOARDING_PART = 'pendingWorkerOnboardingPart';


//WorkerOnboardingPart
export const WORKER_ID= 'workerId';
export const INTEGRATION_OBJECT = 'integrationObject';
export const INTEGRATION = 'integration';
