import {
  SEARCH_WORKER_ONBOARDING,
  LOAD_ALL_WORKER_ONBOARDINGS,
  CANCEL_WORKER_ONBOARDING
} from 'redux/actions/actionTypes';
import {
  getAllWorkerOnboardings,
  cancelWorkerOnboarding
} from 'api/workerOnboarding';
import {
  searchWorkerOnboardingSuccess,
  searchWorkerOnboardingFailure,
  loadAllWorkerOnboardingsSuccess,
  loadAllWorkerOnboardingsFailure,
  cancelWorkerOnboardingSuccess,
  cancleWorkerOnboardingFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const onboardings = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_WORKER_ONBOARDINGS.REQUEST: {
      next(action);
      const {
        companyId,
        offset,
        limit,
        searchString,
        sortString,
        scope,
        fieldSearch
      } = action.payload;

      return apiPromise(
        store,
        action,
        getAllWorkerOnboardings,
        [
          companyId,
          offset,
          limit,
          searchString,
          sortString,
          scope,
          fieldSearch
        ],
        loadAllWorkerOnboardingsSuccess,
        loadAllWorkerOnboardingsFailure
      );
    }
    case SEARCH_WORKER_ONBOARDING.REQUEST: {
      next(action);
      const {
        companyId,
        offset,
        limit,
        searchString,
        sortString,
        scope,
        fieldSearch
      } = action.payload;
      return apiPromise(
        store,
        action,
        getAllWorkerOnboardings,
        [
          companyId,
          offset,
          limit,
          searchString,
          sortString,
          scope,
          fieldSearch
        ],
        searchWorkerOnboardingSuccess,
        searchWorkerOnboardingFailure
      );
    }
    case CANCEL_WORKER_ONBOARDING.REQUEST: {
      next(action);
      const { id } = action.payload;
      return apiPromise(
        store,
        action,
        cancelWorkerOnboarding,
        [id],
        cancelWorkerOnboardingSuccess,
        cancleWorkerOnboardingFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default onboardings;
