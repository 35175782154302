import {
  graphQueryPromise,
  graphMutationPromise,
  handleUpdateString,
  handleArray
} from './helpers';

import * as WORKER from 'common/constants/workerConstants';
import * as JOB_REPORT from 'common/constants/jobReportConstants';
import * as JOB from 'common/constants/jobConstants';
import * as JOB_RATINGS from 'common/constants/jobRatingsConstants';
import * as WORKER_ADDRESS from 'common/constants/workerAddressConstants';
import * as IMPORT from 'common/constants/importConstants';
import * as BADGE from 'common/constants/badgeConstants';
import * as FACILITY from 'common/constants/facilityConstants';
import isEmpty from 'lodash/isEmpty';

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_EMAIL,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE,
  WORKER.WORKER_OVERALL_RATING,
  WORKER.WORKER_LAST_SIGN_IN_AT,
  WORKER.WORKER_FULL_SSN,
  WORKER.WORKER_MASK_SSN
];

const addUpdateWorkerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE,
  WORKER.WORKER_EMAIL,
  WORKER.AVATAR_SIGNED_BLOB_ID
];

const jobReportFields = [
  JOB_REPORT.ID,
  JOB_REPORT.EMPLOYER_NOTE,
  JOB_REPORT.EMPLOYER_RATING,
  JOB_REPORT.EXPECTED_JOB_DURATION,
  JOB_REPORT.EXPECTED_PAYMENT,
  JOB_REPORT.FINAL_PAYMENT,
  JOB_REPORT.LOCATION_ADDRESS,
  JOB_REPORT.LOCATION_NAME,
  JOB_REPORT.SHIFT_NAME,
  JOB_REPORT.WORKED_HOURS,
  JOB_REPORT.WORKER_ID,
  JOB_REPORT.WORKER_NOTE,
  JOB_REPORT.WORKER_DATE,
  JOB_REPORT.WORKER_RATING,
  JOB_REPORT.TIME_SHEET,
  JOB_REPORT.PERMITTED_EVENTS,
  JOB_REPORT.ADJUSTED_WORKED_HOURS
];

const jobFields = [
  JOB.ID,
  JOB.UNREAD_MESSAGE_COUNTER,
  JOB.REQUEST_TASKS_COUNTER,
  JOB.STATE,
  JOB.ADJUSTED_PAYMENT,
  JOB.PAY_AMOUNT,
  JOB.MAXIMUM_PAY_AMOUNT
];

const jobRatingsFields = [
  JOB_RATINGS.ID,
  JOB_RATINGS.NOTE,
  JOB_RATINGS.RATING,
  JOB_RATINGS.RATING_USER_TYPE
];

const workerAddressFields = [WORKER_ADDRESS.ID, WORKER_ADDRESS.ZIPCODE];

const importFields = [
  IMPORT.AUTHOR_ID,
  IMPORT.AUTHOR_TYPE,
  IMPORT.COMPANY_ID,
  IMPORT.STATUS
];

const resultFields = [
  IMPORT.ADDED,
  IMPORT.COUNT,
  IMPORT.ERRORS,
  IMPORT.UPDATED
];

const badgesFields = [
  BADGE.ID,
  BADGE.NAME,
  BADGE.DESCRIPTION,
  BADGE.ICON_URL,
  BADGE.ACTIVE,
  BADGE.IS_GROUP,
  BADGE.IS_ONBOARDING_BADGE
];

const workerBadgesFields = [
  BADGE.ID,
  BADGE.EXPIRED_AT,
  BADGE.NOTE,
  BADGE.BADGE_ID,
  BADGE.IS_WORKER_ONBOARDING_BADGE
];

const blockWorkerFields = [
  WORKER.BLOCK_WORKER_ID,
  FACILITY.FACILITY_GROUP_ID,
  WORKER.BLOCK_NOTE
];

const facilityFields = [
  FACILITY.ID,
  FACILITY.NAME,
  FACILITY.DESCRIPTION,
  FACILITY.ACTIVE
];

export const getAllWorkers = (
  companyId,
  getJobReport = false,
  badgeIds = null,
  workAuthorized = null,
  offset = 0,
  limit = 20,
  searchString = '',
  sortString = '',
  scope = 'active',
  fieldSearch = '',
  blockScope = ''
) => {
  const fields = workerFields.join(',');
  const address_fields = workerAddressFields.join(',');
  const badges_field = badgesFields.join(',');
  const worker_badges_field = workerBadgesFields.join(',');
  const filter_scope = scope !== null ? `, scope: ${scope}` : '';
  const facility_fields = facilityFields.join(',');

  let query = `{
    workers(companyId: "${companyId}", limit: ${limit}, offset: ${offset}, filter: { workAuthorized: ${workAuthorized} ${filter_scope} ${fieldSearch} ${blockScope} } ${searchString} ${sortString} )
    {
      ${fields}
      workerAddresses{
        ${address_fields}
      }
      badges{
        ${badges_field}
        children{
          id
        }
      }
      workerBadges{
        badge{
          ${badges_field}
        }
        ${worker_badges_field}
      }
      workingAuthorization{
        id
      }
      blockedFacility{
        facilityIds{
          ${facility_fields}
        }
        note
      }
    }
  }`;

  if (!isEmpty(badgeIds)) {
    const badge_ids = handleArray(badgeIds);
    query = `{
      workers(companyId: "${companyId}", filter: { badgeIds: [${badge_ids}], workAuthorized: ${workAuthorized} ${filter_scope} ${fieldSearch} ${blockScope} }, limit: ${limit}, offset: ${offset} ${searchString} ${sortString})
      {
        ${fields}
        workerAddresses{
          ${address_fields}
        }
      }
    }`;
  }

  if (getJobReport) {
    const haveJobReport = 'have_job_reports';
    const job_report_fields = jobReportFields.join(',');
    const job_fields = jobFields.join(',');
    const job_ratings_fields = jobRatingsFields.join(',');
    const address_fields = workerAddressFields.join(',');

    query = `{
      workers(companyId: "${companyId}", filter: { extraFilter:  ${haveJobReport} ${filter_scope} ${fieldSearch} ${blockScope}}, limit: ${limit}, offset: ${offset} )
      {
        ${fields}
        workerAddresses{
          ${address_fields}
        }
        doneJobsJobReports {
          ${job_report_fields}
          job{
            ${job_fields}
            jobRatings {
              ${job_ratings_fields}
            }
          }
        }
      }
    }`;
  }

  return graphQueryPromise(query);
};

export const getWorkerContract = workerId => {
  const query = `{
    candidateProfile(workerId: "${workerId}") {
      workerContract {
        jotFormPdfFiles {
          id
          filename
          fileUrl
        }

        workerSignatureUrl
        workerSignedAt
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getCandidateProfile = id => {
  const fields = workerFields.join(',');
  const badges_field = badgesFields.join(',');

  const query = `{
    candidateProfile(workerId: "${id}") {
      workerContract {
        jotFormPdfFiles {
          id
          filename
          fileUrl
          isPreview
        }

        workerSignatureUrl
        workerSignedAt

        interviewerSignatureUrl
        interviewerSignedAt
      }
      workerOnboarding {
        id
        currentPartIndex
        state
        pendingWorkerOnboardingPart {
          id
          remindWorkerActionLabel
          state
          integrationObject
          integration {
            ... on WorkingAuthorization {
              id
            }
            ... on InterviewSchedule {
                id
                state
                permittedEvents
                rescheduleInterviewUrl
                meetingUrl
                state
                eventStartTime
            }

            ... on WorkerContract {
              id
              interviewerSignatureUrl
              interviewerSignedAt
              state
              workerSignatureUrl
              workerSignedAt
              jotFormPdfFiles {
                id
                filename
                fileUrl
                isPreview
              }
            }
          }
        }
      }
      dashboard 
      worker {
        ${fields}
        workAuthorized
        employerNote
        workerAddresses{
          id
          country
          state
          city
          street
          zipcode
          aptNumber
        }
        badges{
          ${badges_field}
          children{
            id
          }
        }
        
        workingAuthorization {
          id
          dateOfBirth
          socialSecurityNumber
          idPhotoUrl
        }        
      }
    }
  }`;

  return graphQueryPromise(query);
};
export const getWorker = id => {
  const fields = workerFields.join(',');
  const badges_field = badgesFields.join(',');

  const query = `{
    worker(id: "${id}") {
      ${fields}
      workAuthorized
      workerAddresses{
        id
        country
        state
        city
        street
        zipcode
        aptNumber
      }
      badges{
        ${badges_field}
        children{
          id
        }
      }
      
      workingAuthorization {
        id
        dateOfBirth
        socialSecurityNumber
      }

      
    }
  }`;

  return graphQueryPromise(query);
};

export const addNewWorker = values => {
  const fields = workerFields.join(',');
  const address_fields = workerAddressFields.join(',');
  const createWorkerFields = addUpdateWorkerFields.concat(
    WORKER.WORKER_COMPANY_ID
  );
  const updates = handleUpdateString(values, createWorkerFields, true);

  const query = `mutation {
    createWorker(${updates}) {
      ${fields}
      workerAddresses{
        ${address_fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const addNewWorkerAccount = values => {
  const fields = workerFields.join(',');
  const specialFields = [WORKER.WORKER_AGREE_TO_TERMS];
  const createWorkerFields = addUpdateWorkerFields.concat(
    WORKER.WORKER_COMPANY_ID,
    WORKER.WORKER_PASSWORD,
    WORKER.WORKER_PASSWORD_CONFIRMATION,
    WORKER.WORKER_AGREE_TO_TERMS,
    WORKER.WORKER_RECAPTCHA_TOKEN
  );
  const updates = handleUpdateString(
    values,
    createWorkerFields,
    true,
    specialFields
  );

  const query = `mutation {
    createWorkerAccount(${updates}) {
      authToken
      worker{
        ${fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const updateWorkerNote = (workerId, note) => {
  const fields = workerFields.join(',');

  const mutation = `mutation {
    updateWorker(id: "${workerId}", employerNote: "${note}") {
      ${fields}
    }
  }`;

  return graphMutationPromise(mutation);
};

export const updateWorker = values => {
  const fields = workerFields.join(',');
  const address_fields = workerAddressFields.join(',');
  const updateWorkerFields = addUpdateWorkerFields;
  const updates = handleUpdateString(values, updateWorkerFields);

  let workingAuthorization = '';
  if (values[WORKER.WORKER_SSN])
    workingAuthorization = `, workingAuthorizationAttributes: { 
    socialSecurityNumber: "${values[WORKER.WORKER_SSN]}" 
  }`;

  const mutation = `mutation {
    updateWorker(${updates} ${workingAuthorization}) {
      ${fields}
      workerAddresses{
        ${address_fields}
      }
    }
  }`;

  return graphMutationPromise(mutation);
};

export const searchWorkersByName = search => {
  const fields = workerFields.join(',');
  const address_fields = workerAddressFields.join(',');
  const nameFields = ['"firstName"', '"lastName"'];
  const searchFieldsStr = nameFields.join(',');
  const query = `{
    filterWorkers(filter: { search: {fields: [${searchFieldsStr}], queryString: "${search}"}}){
      ${fields}
      workerAddresses{
        ${address_fields}
      }
    } 
  }`;

  return graphQueryPromise(query, {}, { rawQuery: true });
};

export const importWorkers = (fileBase64, fileName) => {
  const query = `mutation{
    importWorker(fileBase64:"${fileBase64}", 
      fileFilename: "${fileName}"){
      success
      id
    }
  }`;

  return graphMutationPromise(query);
};

export const loadImportWorkerFile = id => {
  const import_fields = importFields.join(',');
  const result_fields = resultFields.join(',');

  const query = `{
    importWorkerFile(id: "${id}" )
    {
      ${import_fields}
      result{
        ${result_fields}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const blockWorker = values => {
  const updates = handleUpdateString(values, blockWorkerFields);

  let blockFacilityIds = '';
  if (!isEmpty(values[FACILITY.FACILITY_IDS])) {
    let output = '';
    values[FACILITY.FACILITY_IDS].map((obj, index) => {
      const facilityId = obj.id;
      output += `"${facilityId}",`;

      return null;
    });
    blockFacilityIds = `, facilityIds: [${output}]`;
  }

  const mutation = `mutation {
    createWorkerBlock(${updates} ${blockFacilityIds}) {
      id
      workerId
      employerId
    }
  }`;

  return graphMutationPromise(mutation);
};

export const exportWorkers = (password, workerIds, attributesMapping) => {
  const passwordStr = !isEmpty(workerIds) ? `password: "${password}", ` : '';
  let workerIdsStr = '';
  let mappingStr = '';

  if (!isEmpty(workerIds)) {
    let output = '';
    workerIds.map((obj, index) => {
      const workerId = obj.id;
      output += `"${workerId}",`;
      return null;
    });
    workerIdsStr = ` workerIds: [${output}], `;
  }

  if (!isEmpty(attributesMapping)) {
    let output = '';
    attributesMapping.map((obj, index) => {
      output += `{ key : "${obj.key}", label: "${obj.label}" },`;
      return null;
    });
    mappingStr = ` attributesMapping: [${output}], `;
  }

  const mutation = `mutation {
    exportWorkers( ${passwordStr} ${workerIdsStr} ${mappingStr}) {
      message
    }
  }`;

  return graphMutationPromise(mutation);
};
