import {
  graphQueryPromise,
  graphMutationPromise,
  handleUpdateString
} from './helpers';
import * as JOB_REPORT from 'common/constants/jobReportConstants';
import * as JOB from 'common/constants/jobConstants';
import * as JOB_RATINGS from 'common/constants/jobRatingsConstants';

const reportsFields = [
  JOB_REPORT.ID,
  JOB_REPORT.EMPLOYER_NOTE,
  JOB_REPORT.EMPLOYER_RATING,
  JOB_REPORT.EXPECTED_JOB_DURATION,
  JOB_REPORT.EXPECTED_PAYMENT,
  JOB_REPORT.FINAL_PAYMENT,
  JOB_REPORT.LOCATION_ADDRESS,
  JOB_REPORT.LOCATION_NAME,
  JOB_REPORT.SHIFT_NAME,
  JOB_REPORT.WORKED_HOURS,
  JOB_REPORT.WORKER_ID,
  JOB_REPORT.WORKER_NOTE,
  JOB_REPORT.WORKER_RATING,
  JOB_REPORT.WORKER_DATE,
  JOB_REPORT.TIME_SHEET,
  JOB_REPORT.PERMITTED_EVENTS,
  JOB_REPORT.ADJUSTED_WORKED_HOURS,
  JOB_REPORT.STATE
];

const updateReportFields = [
  JOB_REPORT.ID,
  JOB_REPORT.EMPLOYER_NOTE,
  JOB_REPORT.STATE,
  JOB_REPORT.ADJUSTED_WORKED_HOURS,
  JOB_REPORT.ADJUSTED_PAYMENT
];

const jobFields = [
  JOB.ID,
  JOB.UNREAD_MESSAGE_COUNTER,
  JOB.REQUEST_TASKS_COUNTER,
  JOB.STATE,
  JOB.ADJUSTED_PAYMENT,
  JOB.MAXIMUM_PAY_AMOUNT,
  JOB.PAY_AMOUNT
];

const jobRatingsFields = [
  JOB_RATINGS.ID,
  JOB_RATINGS.NOTE,
  JOB_RATINGS.RATING,
  JOB_RATINGS.RATING_USER_TYPE
];

export const getAllReports = () => {
  const fields = reportsFields.join(',');

  const query = `{
    reports()
    {
      ${fields}
    }
  }`;

  return graphQueryPromise(query);
};

export const getReportByWorkerId = (workerId, offset = 0, limit = 20) => {
  const job_report_fields = reportsFields.join(',');
  const job_fields = jobFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');

  const query = `{
    jobReports(workerId: "${workerId}", limit: ${limit}, offset: ${offset} ) {
      ${job_report_fields}
      job{
        ${job_fields}
        jobRatings {
          ${job_ratings_fields}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const updateReport = values => {
  const fields = reportsFields.join(',');
  const specialField = [
    JOB_REPORT.ADJUSTED_WORKED_HOURS,
    JOB_REPORT.STATE,
    JOB_REPORT.ADJUSTED_PAYMENT
  ];
  const updates = handleUpdateString(
    values,
    updateReportFields,
    false,
    specialField
  );

  const query = `mutation {
    updateJobReport(${updates}) {
      ${fields}
    }
  }`;

  return graphMutationPromise(query);
};
