import headerLogo from 'assets/hirePrivy/logo-2.png';
import signInLogo from 'assets/hirePrivy/logo-1.png';


const icons = {
  header: {
    logo: headerLogo,
    width: '180px',
    height: '60px',
    background: '',
  },
  signIn:{
    logo: signInLogo,
    width: '',
    height: '',
  }
};

export default icons;
