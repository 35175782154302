import React from 'react';
import NotificationItem from './notificationItem';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { MARK_ALL_READ_NOTIFICATION } from 'common/apollo/mutations/notification.mutation';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ListItemNotification, MarkAllRead, NotificationTotal } from './notification.style';

const AppNotification = ({ notifications, nextFunc, hasMore, setAppNotification, totalUnreadNotification , refetchTotal , refetchFetchMore ,dataNotifications , setNotifications}) => {

  const [markAllAsRead] = useMutation(MARK_ALL_READ_NOTIFICATION );
  const markAllAsReadMessage = async () => {
    await markAllAsRead();
    await  refetchTotal()
    await refetchFetchMore()
    await window.location.reload()

  };
  return (

    <InfiniteScroll
      height={300}
      className={'messages-list scrollbar'}
      dataLength={notifications?.length}
      next={() => nextFunc()}
      hasMore={hasMore}
      loader={<p style={{fontSize:'12px'}}>Loading...</p>}
    >
      <NotificationTotal
        noWrap>Notifications
        ({totalUnreadNotification || 0})</NotificationTotal>
      <ListItemNotification
        className="media">
        <Typography
          style={{ fontWeight: 'bold' }}
          noWrap>Today</Typography>
        <MarkAllRead
          onClick={markAllAsReadMessage}
          noWrap>Mark all as read</MarkAllRead>
      </ListItemNotification>
      <ul className="list-unstyled">
        { notifications?.map((notification, index) => (
          <NotificationItem  key={index} refetchTotal={refetchTotal} refetchFetchMore={refetchFetchMore} setAppNotification={setAppNotification}
                            notification={notification} />
        ))}
      </ul>

    </InfiniteScroll>

  );
};

export default AppNotification;
