import {
  graphQueryPromise,
  handleUpdateString,
  graphMutationPromise
} from './helpers';
import * as FACILITY from 'common/constants/facilityConstants';
import * as FACILITY_GROUP from 'common/constants/facilityGroupConstants';
import isEmpty from 'lodash/isEmpty';

const facilityFields = [
  FACILITY.ID,
  FACILITY.NAME,
  FACILITY.DESCRIPTION,
  FACILITY.ACTIVE,
  FACILITY.WORKPLACE_IMAGE_URL
];

const facilityGroupFields = [
  FACILITY_GROUP.ID,
  FACILITY_GROUP.NAME,
  FACILITY_GROUP.DESCRIPTION,
  FACILITY_GROUP.WORKPLACE_IMAGE_URL
];

const addFacilityFields = [
  FACILITY.ID,
  FACILITY.NAME,
  FACILITY.ACTIVE,
  FACILITY.DESCRIPTION,
  FACILITY.FACILITY_GROUP_ID,
  FACILITY.FACILITY_SIGNED_BLOB
];

const updateFacilityFields = [
  FACILITY.ID,
  FACILITY.NAME,
  FACILITY.ACTIVE,
  FACILITY.DESCRIPTION,
  FACILITY.FACILITY_SIGNED_BLOB
];

export const getAllFacilities = (
  companyId,
  offset = 0,
  limit = 100,
  search = '',
  filter = ''
) => {
  const facility_fields = facilityFields.join(',');
  const facility_group_fields = facilityGroupFields.join(',');

  const query = `{
    facilities(companyId: "${companyId}", offset: ${offset}, limit: ${limit} ${search} ${filter})
    {
      ${facility_fields}
      facilityGroup{
        ${facility_group_fields}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const addNewFacility = values => {
  const fields = facilityFields.join(',');
  const facility_group_fields = facilityGroupFields.join(',');
  const updates = handleUpdateString(values, addFacilityFields, true);

  let locationsAttributes = '';
  if (!isEmpty(values[FACILITY.LOCATIONS_ATTRIBUTES])) {
    let output = '';
    values[FACILITY.LOCATIONS_ATTRIBUTES].map((obj, index) => {
      const id = obj.id ? `id: "${obj.id}", ` : '';
      const address = `address: "${obj.address}", `;
      const city = `city: "${obj.city}", `;
      const zipcode = `zipcode: "${obj.zipcode}", `;
      const addressState = `addressState: "${obj.addressState}", `;
      const name = `name: "${obj.name}", `;
      const country = `country: "${obj.country}", `;
      const geofencingRadius = obj.geofencingRadius
        ? `geofencingRadius: ${obj.geofencingRadius}, `
        : '';
      const phone = obj.phone ? `phone: "${obj.phone}", ` : '';

      output +=
        '{' +
        id +
        address +
        city +
        zipcode +
        addressState +
        name +
        country +
        geofencingRadius +
        phone +
        '},';

      return null;
    });
    locationsAttributes = `locationsAttributes: [${output}]`;
  }

  const query = `mutation {
    createFacility(${updates} ${locationsAttributes}) {
      ${fields}
      facilityGroup{
        ${facility_group_fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const updateFacility = values => {
  const fields = facilityFields.join(',');
  const facility_group_fields = facilityGroupFields.join(',');
  const updates = handleUpdateString(values, updateFacilityFields);

  const query = `mutation {
    updateFacility(${updates}) {
      ${fields}
      facilityGroup{
        ${facility_group_fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const removeFacility = facilityId => {
  const query = `mutation {
    destroyFacility(id : "${facilityId}") {
      success
    }
  }`;

  return graphMutationPromise(query);
};
