import {
  EMPLOYER_TITLE,
  ADMIN,
  ONSITE_MANAGER,
  MANAGER
} from 'common/constants/employerConstants';
import { urlMap } from 'routes/urlMap';
import isEmpty from 'lodash/isEmpty';

const IS_ADMIN = 'isAdmin';
const IS_MANAGER = 'isManager';
const IS_ONSITE_MANAGER = 'isOnsiteManager';

export const getEmployerRole = user => {
  let role = IS_ONSITE_MANAGER;

  if (!isEmpty(user) && !isEmpty(user.employer)) {
    switch (user.employer[EMPLOYER_TITLE]) {
      case ADMIN:
        role = IS_ADMIN;
        break;
      case MANAGER:
        role = IS_MANAGER;
        break;
      case ONSITE_MANAGER:
        role = IS_ONSITE_MANAGER;
        break;
      default:
        role = IS_ONSITE_MANAGER;
    }
  }

  return role;
};

export const getAvailbleRoute = (user, url) => {
  let canAccess = false;
  const role = getEmployerRole(user);

  const accessObjects = {
    [urlMap.DASHBOARD2]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.HOME]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.WORKERS_LIST]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.WORKERS]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.DRAFT_SHIFT_REQUESTS]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.SHIFT_REQUESTS_LIST]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.SHIFTS_LIST]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.TASKS_LIST]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.NEW_SHIFT]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.EDIT_SHIFT_REQUEST]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.TEAMS_LIST]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.LOCATIONS]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.BADGES]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.REPORTS]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.DASHBOARD]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: false,
      [IS_ONSITE_MANAGER]: false
    },
    [urlMap.MESSAGES]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.MESSAGE_THREAD]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.MESSAGE_DETAIL]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.SHIFT_DETAIL]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.JOB_DETAIL]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.WORKER_CONTRACT]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.PROFILE]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.EMPLOYERS_LIST]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: false
    },
    [urlMap.FACILITIES]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: false,
      [IS_ONSITE_MANAGER]: false
    },
    [urlMap.COMPANY]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: false,
      [IS_ONSITE_MANAGER]: false
    },
    [urlMap.ONBOARDING]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: false,
      [IS_ONSITE_MANAGER]: false
    },
    [urlMap.INTERVIEW_SCHEDULE]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: false,
      [IS_ONSITE_MANAGER]: false
    },
    [urlMap.IMPORT_WORKER_FILES]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.IMPORT_EMPLOYER_FILES]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.JOBS]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    },
    [urlMap.HELP_AND_FEEDBACK]: {
      [IS_ADMIN]: true,
      [IS_MANAGER]: true,
      [IS_ONSITE_MANAGER]: true
    }
  };

  canAccess = accessObjects[url][role];

  return canAccess;
};
