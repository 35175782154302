import {
  LOAD_UPCOMING_SCHEDULES,
  LOAD_PAST_SCHEDULES,
  UPDATE_SCHEDULE_STATE
} from 'redux/actions/actionTypes';
import {
  getUpcomingInterviewSchedule,
  getPastInterviewSchedule,
  updateInterviewScheduleState
} from 'api/workerOnboarding';
import {
  loadUpcomingSchedulesSuccess,
  loadUpcomingSchedulesFailure,
  loadPastSchedulesSuccess,
  loadPastSchedulesFailure,
  updateScheduleStateSuccess,
  updateScheduleStateFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const interviewSchedules = store => next => action => {
  switch (action.type) {
    case LOAD_UPCOMING_SCHEDULES.REQUEST: {
      next(action);

      return apiPromise(
        store,
        action,
        getUpcomingInterviewSchedule,
        [],
        loadUpcomingSchedulesSuccess,
        loadUpcomingSchedulesFailure
      );
    }
    case LOAD_PAST_SCHEDULES.REQUEST: {
      next(action);

      return apiPromise(
        store,
        action,
        getPastInterviewSchedule,
        [],
        loadPastSchedulesSuccess,
        loadPastSchedulesFailure
      );
    }
    case UPDATE_SCHEDULE_STATE.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateInterviewScheduleState,
        [values],
        updateScheduleStateSuccess,
        updateScheduleStateFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default interviewSchedules;
