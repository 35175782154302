import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from 'routes/privateRoute';
import Signin from 'components/auth/signin';
import Signout from 'components/auth/signout';
import Signup from 'components/auth/signup';
import MobileAppInfo from 'pages/mobileAppInfo';
import { urlMap } from 'routes/urlMap';
import AuthLayout from './authLayout';
const DashboardPage = React.lazy(() => import('pages/dashboardPage'));

const PageController = ({ ...props }) => (
  <Switch>
    <AuthRoute exact path={urlMap.HOME} component={DashboardPage} />
    <Route path={urlMap.SIGNIN} component={() => <Signin {...props} />} />
    <Route
      path={urlMap.FORGOT_PASSWORD}
      component={() => <Signin {...props} />}
    />
    <Route
      path={urlMap.RESET_PASSWORD}
      component={() => <Signin {...props} />}
    />
    <Route path={urlMap.SIGNUP} component={Signup} />
    <Route path={urlMap.HIREPRIVY_APP_INFO} component={MobileAppInfo} />
    <Route path={urlMap.OPUS_APP_INFO} component={MobileAppInfo} />
    <Route path={urlMap.SIGNOUT} component={() => <Signout {...props} />} />
    <AuthLayout {...props}></AuthLayout>
  </Switch>
);

export default PageController;
