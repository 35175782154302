import {
  LOAD_ALL_JOBS,
  LOAD_JOB_BY_ID,
  CREATE_JOB_RATING,
  UPDATE_JOB_RATING,
  UPDATE_JOB,
  CREATE_JOB
} from 'redux/actions/actionTypes';
import {
  getAllJobs,
  createJobRating,
  updateJobRating,
  updateJob,
  createJob,
  getJobById
} from 'api/jobs';
import {
  loadAllJobsSuccess,
  loadAllJobsFailure,
  createJobRatingSuccess,
  createJobRatingFailure,
  updateJobRatingSuccess,
  updateJobRatingFailure,
  updateJobSuccess,
  updateJobFailure,
  createJobSuccess,
  createJobFailure,
  loadJobByIdSuccess,
  loadJobByIdFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const jobs = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_JOBS.REQUEST: {
      next(action);
      const {
        companyId,
        limit,
        offset,
        filter,
        keywordSearch
      } = action.payload;
      return apiPromise(
        store,
        action,
        getAllJobs,
        [companyId, filter, limit, offset, keywordSearch],
        loadAllJobsSuccess,
        loadAllJobsFailure
      );
    }
    case LOAD_JOB_BY_ID.REQUEST: {
      next(action);
      const { jobId } = action.payload;
      return apiPromise(
        store,
        action,
        getJobById,
        [jobId],
        loadJobByIdSuccess,
        loadJobByIdFailure
      );
    }
    case CREATE_JOB_RATING.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        createJobRating,
        [values],
        createJobRatingSuccess,
        createJobRatingFailure
      );
    }
    case UPDATE_JOB_RATING.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateJobRating,
        [values],
        updateJobRatingSuccess,
        updateJobRatingFailure
      );
    }
    case UPDATE_JOB.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateJob,
        [values],
        updateJobSuccess,
        updateJobFailure
      );
    }
    case CREATE_JOB.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        createJob,
        [values],
        createJobSuccess,
        createJobFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default jobs;
