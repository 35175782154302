// import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { IMAGE_UPLOAD } from '../store/constants';
import * as types from '../actions/actionTypes';

export default function(state = initialState[IMAGE_UPLOAD], action) {
  switch (action.type) {
    case types.LOCATION_CHANGE: {
      return {};
    }
    case types.UPLOAD_CROPPED_IMAGE.REQUEST: {
      const { meta } = action;

      return {
        ...state,
        [meta.fingerprint]: {}
      };
    }
    case types.UPLOAD_CROPPED_IMAGE.SUCCESS: {
      const { meta, payload } = action;

      return {
        ...state,
        [meta.fingerprint]: {
          ...payload
        }
      };
    }
    case types.UPLOAD_CROPPED_IMAGE.FAILURE: {
      const { meta } = action;

      return {
        ...state,
        [meta.fingerprint]: {}
      };
    }
    default:
      return state;
  }
}
