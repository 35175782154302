import {
  LOAD_ALL_REQUEST_TASKS,
  CREATE_REQUEST_TASK,
  UPDATE_REQUEST_TASK,
  REMOVE_REQUEST_TASK
} from 'redux/actions/actionTypes';
import {
  getAllRequestTasks,
  addNewRequestTask,
  updateRequestTask,
  removeRequestTask
} from 'api/requestTasks';
import {
  createRequestTaskSuccess,
  createRequestTaskFailure,
  updateRequestTaskSuccess,
  updateRequestTaskFailure,
  loadAllRequestTasksSuccess,
  loadAllRequestTasksFailure,
  removeRequestTaskSuccess,
  removeRequestTaskFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const requestTasks = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_REQUEST_TASKS.REQUEST: {
      next(action);
      const { offset } = action.payload;
      return apiPromise(
        store,
        action,
        getAllRequestTasks,
        [offset],
        loadAllRequestTasksSuccess,
        loadAllRequestTasksFailure
      );
    }
    case CREATE_REQUEST_TASK.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewRequestTask,
        [values],
        createRequestTaskSuccess,
        createRequestTaskFailure
      );
    }
    case UPDATE_REQUEST_TASK.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateRequestTask,
        [values],
        updateRequestTaskSuccess,
        updateRequestTaskFailure
      );
    }
    case REMOVE_REQUEST_TASK.REQUEST: {
      next(action);
      const { requestTaskId } = action.payload;
      return apiPromise(
        store,
        action,
        removeRequestTask,
        [requestTaskId],
        removeRequestTaskSuccess,
        removeRequestTaskFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default requestTasks;
