export const ID = 'id';
export const ACTIVE = 'active';
export const ADDRESS = 'address';
export const ADDRESS_STATE = 'addressState';
export const CITY = 'city';
export const FACILITY_ID = 'facilityId';
export const FACILITY_GROUP_ID = 'facilityGroupId';
export const GEOFENCING_RADIUS = 'geofencingRadius';
export const LATITUDE = 'latitude';
export const LONGITUDE = 'longitude';
export const NAME = 'name';
export const PHONE = 'phone';
export const ZIPCODE = 'zipcode';
export const COUNTRY = 'country';
export const TIMEZONE = 'timezone';
export const CONVERT_KILOMETER_TO_MILE = 0.62;
export const DISTANCE_UNIT = {
  miles: 'miles',
  kilometer: 'kilometer'
};

export const MIN_GEO = 0.16;
export const MAX_GEO = 31.07;
export const CONVERT_MET_TO_MILE = 0.0006213;
