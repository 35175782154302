// company settings middleware
import { UPLOAD_CROPPED_IMAGE } from 'redux/actions/actionTypes';
import uploadCroppedImage from 'api/uploadCroppedImage';
import {
  uploadCroppedImageSuccess,
  uploadCroppedImageFailure
} from 'redux/actions';
import { apiPromise } from './apiPromise';

export const imageUpload = store => next => action => {
  switch (action.type) {
    case UPLOAD_CROPPED_IMAGE.REQUEST: {
      next(action);
      const { imageData, cropX, cropY, cropWidth, cropHeight } = action.payload;

      return apiPromise(
        store,
        action,
        uploadCroppedImage,
        [imageData, cropX, cropY, cropWidth, cropHeight],
        uploadCroppedImageSuccess,
        uploadCroppedImageFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default imageUpload;
