import React from 'react';
import getText from 'context/language/getText';
import PropTypes from 'prop-types';
import {
  StyledAvatar,
  StyledListItemIcon,
  StyledDropdownColumnContainer
} from './styledAccountInfo';
import DefaultAvatarUrl from 'assets/default_avatar.png';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Typography from '@material-ui/core/Typography';
import { urlMap } from 'routes/urlMap';
import isEmpty from 'lodash/isEmpty';
import { dashboardStore } from 'pages/dashboardPage/stores/dashboard.stores.js';
import {
  ContactUserIcon,
  QuestionMarkIcon,
  SignOutIcon
} from 'components/common/icons';
import { observer } from 'common/mobx.decorator';
import { useHistory } from 'react-router-dom';
import { signoutUserRequest } from 'redux/actions';
import { connect } from 'react-redux';

const AccountDropdown = observer(({ user, handleDrawerToggle, ...props }) => {
  const history = useHistory();
  const { avatarUrl } = user;
  const { reset } = dashboardStore;
  let imageUrl = avatarUrl ? avatarUrl : DefaultAvatarUrl;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const navigateProfile = () => {
    if (!isEmpty(props)) {
      history.push(urlMap.PROFILE);
      handleClose();
    }
  };

  const navigateHelps = () => {
    if (!isEmpty(props)) {
      history.push(urlMap.HELP_AND_FEEDBACK);
      handleClose();
    }
  };

  const navigateSignout = async () => {

    if (!isEmpty(props)) {
      await props?.logout()
      await reset();
     await history.push(urlMap.SIGNOUT);
    await  localStorage.clear();
     await handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    handleDrawerToggle();
  };

  return (
    <StyledDropdownColumnContainer>
      <Button
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <StyledAvatar src={imageUrl}></StyledAvatar>
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <MenuItem onClick={navigateProfile}>
          <StyledListItemIcon>
            <ContactUserIcon />
          </StyledListItemIcon>
          <Typography variant="inherit" noWrap>
            {getText('userInfo.profile')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={navigateHelps}>
          <StyledListItemIcon>
            <QuestionMarkIcon />
          </StyledListItemIcon>
          <Typography variant="inherit" noWrap>
            {getText('userInfo.helpAndFeedback')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={navigateSignout}>
          <StyledListItemIcon>
            <SignOutIcon />
          </StyledListItemIcon>
          <Typography variant="inherit" noWrap>
            {getText('userInfo.signout')}
          </Typography>
        </MenuItem>
      </Menu>
    </StyledDropdownColumnContainer>
  );
});

AccountDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  handleDrawerToggle: PropTypes.func
};

AccountDropdown.defaultProps = {
  handleDrawerToggle: () => {}
};



function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(signoutUserRequest());
    }
  };
}


export default connect(null, mapDispatchToProps)(AccountDropdown);


