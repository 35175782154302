import {
  LOAD_ALL_TEAMS,
  ADD_NEW_TEAM,
  UPDATE_TEAM,
  REMOVE_TEAM
} from 'redux/actions/actionTypes';
import { getAllTeams, addNewTeam, updateTeam, removeTeam } from 'api/teams';
import {
  loadAllTeamsSuccess,
  loadAllTeamsFailure,
  addNewTeamSuccess,
  addNewTeamFailure,
  updateTeamSuccess,
  updateTeamFailure,
  removeTeamSuccess,
  removeTeamFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const teams = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_TEAMS.REQUEST: {
      next(action);
      const { badgeIds } = action.payload;
      return apiPromise(
        store,
        action,
        getAllTeams,
        [badgeIds],
        loadAllTeamsSuccess,
        loadAllTeamsFailure
      );
    }
    case ADD_NEW_TEAM.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewTeam,
        [values],
        addNewTeamSuccess,
        addNewTeamFailure
      );
    }
    case UPDATE_TEAM.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateTeam,
        [values],
        updateTeamSuccess,
        updateTeamFailure
      );
    }
    case REMOVE_TEAM.REQUEST: {
      next(action);
      const { teamId } = action.payload;
      return apiPromise(
        store,
        action,
        removeTeam,
        [teamId],
        removeTeamSuccess,
        removeTeamFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default teams;
