export const ID = 'id';
export const EMPLOYER_NOTE = 'employerNote';
export const EMPLOYER_RATING = 'employerRating';
export const EXPECTED_JOB_DURATION = 'expectedJobDuration';
export const EXPECTED_PAYMENT = 'expectedPayment';
export const FINAL_PAYMENT = 'finalPayment';
export const LOCATION_ADDRESS = 'locationAddress';
export const LOCATION_NAME = 'locationName';
export const SHIFT_NAME = 'shiftName';
export const WORKED_HOURS = 'workedHours';
export const WORKER_ID = 'workerId';
export const WORKER_NOTE = 'workerNote';
export const WORKER_RATING = 'workerRating';
export const WORKER_DATE = 'workedDate';
export const TIME_SHEET = 'timesheet';
export const PERMITTED_EVENTS = 'permittedEvents';
export const ADJUSTED_WORKED_HOURS = 'adjustedWorkedHours';
export const ADJUSTED_PAYMENT = 'adjustedPayment';
export const STATE = 'state';

export const APPROVED_STATE = 'approved';

// Permitted actions of report
export const APPROVE = 'approve';
export const ADJUST = 'adjust';
export const RATE = 'rate';
