import {
  CREATE_SHIFT_REQUEST,
  LOAD_SHIFT_REQUEST_BY_ID
} from 'redux/actions/actionTypes';
import { createShiftRequest, getShiftRequestById } from 'api/shifts';
import {
  createShiftRequestSuccess,
  createShiftRequestFailure,
  loadShiftRequestByIdSuccess,
  loadShiftRequestByIdFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const shiftRequest = store => next => action => {
  switch (action.type) {
    case CREATE_SHIFT_REQUEST.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        createShiftRequest,
        [values],
        createShiftRequestSuccess,
        createShiftRequestFailure
      );
    }
    case LOAD_SHIFT_REQUEST_BY_ID.REQUEST: {
      next(action);
      const { shiftRequestId } = action.payload;
      return apiPromise(
        store,
        action,
        getShiftRequestById,
        [shiftRequestId],
        loadShiftRequestByIdSuccess,
        loadShiftRequestByIdFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default shiftRequest;
