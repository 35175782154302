import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import PasswordInput from 'components/common/form/inputs/passwordInput/passwordInput';
import { SubmitButton } from 'components/common/buttons/Button';
import getText from 'context/language/getText';
import { resetPasswordRequest, clearSuccessState } from 'redux/actions';
import { getErrors, getIsLoading, getSuccesses } from 'redux/selectors';
import { Alert, alertTypes } from 'components/common/alerts';
import { RESET_PASSWORD_FINGERPRINT } from 'common/constants/fingerprintConstants';
import FullPageLoader from 'components/common/loaders/FullPageLoader';
import Grid from '@material-ui/core/Grid';
import Link from 'components/common/links/Link';
import { urlMap } from 'routes/urlMap';
import {
  StyledSignInFormContainer,
  StyledSignInLoadingContainer,
  StyledHeader,
  StyledModalContext
} from 'components/auth/styledSignin';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { YourCodeIcon } from 'components/common/icons';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as AUTH from 'common/constants/authConstants';
import Modal from 'components/common/modals/modal';
import { StyledDialogActionsContainer } from 'pages/styledPage';
import { RECAPTCHA_CLIENT_KEY } from 'components/auth/helpers.js';

const ConfirmModal = ({ modalIsOpen, closeHandler, saveHandler }) => (
  <Modal
    open={modalIsOpen}
    closeOnDimmerClick
    onClose={closeHandler}
    title={getText('forgotPass.notificationHeader')}
    size="mini"
  >
    <StyledModalContext>
      {getText('forgotPass.successSubmitReset')}
    </StyledModalContext>
    <StyledDialogActionsContainer>
      <SubmitButton onClick={saveHandler} btnStyle="submit">
        {getText('buttons.ok')}
      </SubmitButton>
    </StyledDialogActionsContainer>
  </Modal>
);

class ResetPassForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
  }

  dispatchUpdateConfirm = () => {
    this.hideModal();
    this.props.history.push(urlMap.SIGNIN);
  };

  showModal = () => {
    this.setState({
      modalIsOpen: true
    });
  };

  hideModal = () => {
    this.setState({
      modalIsOpen: false
    });
  };

  componentWillUnmount() {
    const { clearSuccessState } = this.props;
    clearSuccessState();
  }

  render() {
    const { resetPassword, isLoading, errors, successes } = this.props;

    const { modalIsOpen } = this.state;

    if (isLoading)
      return (
        <StyledSignInLoadingContainer>
          <FullPageLoader />
        </StyledSignInLoadingContainer>
      );

    return (
      <StyledSignInFormContainer>
        <Formik
          initialValues={{
            [AUTH.TOKEN]: '',
            [AUTH.AUTH_LOGIN_PASSWORD]: '',
            [AUTH.PASSWORD_CONFIRMATION]: ''
          }}
          validationSchema={Yup.object().shape({
            [AUTH.TOKEN]: Yup.string().required(
              getText('forgotPass.yourCodeRequired')
            ),
            [AUTH.AUTH_LOGIN_PASSWORD]: Yup.string()
              .min(6, getText('login.passwordAtleast6Chars'))
              .required(getText('forgotPass.newPasswordRequired')),
            [AUTH.PASSWORD_CONFIRMATION]: Yup.string()
              .oneOf(
                [Yup.ref(AUTH.AUTH_LOGIN_PASSWORD)],
                getText('forgotPass.passwordsNotMatch')
              )
              .required(getText('forgotPass.confirmPasswordRequired'))
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);

            window.grecaptcha.ready(() => {
              window.grecaptcha
                .execute(RECAPTCHA_CLIENT_KEY, {
                  action: 'resetPasswordForUser'
                })
                .then(token => {
                  let updatedValues = {
                    [AUTH.AUTH_RECAPTCHA_TOKEN]: token,
                    ...values
                  };

                  resetPassword(updatedValues);
                  actions.setSubmitting(false);
                });
            });
          }}
          render={props => {
            if (successes.success) {
              this.showModal();
            }
            return (
              <form onSubmit={props.handleSubmit}>
                <StyledHeader>
                  {getText('forgotPass.resetPasswordHeader')}
                </StyledHeader>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item className="signin-row">
                    <TextField
                      required
                      variant="outlined"
                      size="small"
                      label={getText('forgotPass.yourCode')}
                      type="text"
                      margin="normal"
                      name={AUTH.TOKEN}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[AUTH.TOKEN]}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <YourCodeIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                    <div className="error code-error">
                      <ErrorMessage name={AUTH.TOKEN} />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item className="signin-row">
                    <Field
                      name={AUTH.AUTH_LOGIN_PASSWORD}
                      variant="outlined"
                      label={getText('forgotPass.newPassword')}
                      component={PasswordInput}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item className="signin-row">
                    <Field
                      name={AUTH.PASSWORD_CONFIRMATION}
                      variant="outlined"
                      label={getText('forgotPass.confirmPassword')}
                      component={PasswordInput}
                    />
                  </Grid>
                </Grid>
                <SubmitButton className="reset-password-btn">
                  {getText('buttons.update')}
                </SubmitButton>
                <ConfirmModal
                  modalIsOpen={modalIsOpen}
                  closeHandler={this.hideModal}
                  saveHandler={this.dispatchUpdateConfirm}
                />
                <div className="back-signin">
                  <Link to={urlMap.SIGNIN}>
                    <ArrowBackIosIcon /> {getText('buttons.back')}
                  </Link>
                </div>
                {errors.length > 0 && (
                  <Alert type={alertTypes.ERROR} content={errors[0].message} />
                )}
              </form>
            );
          }}
        />
      </StyledSignInFormContainer>
    );
  }
}

ResetPassForm.propTypes = {
  errors: PropTypes.array,
  isLoading: PropTypes.bool
};

ResetPassForm.defaultProps = {
  errors: [],
  isLoading: false
};

const fingerprint = RESET_PASSWORD_FINGERPRINT;
const meta = {
  fingerprint
};

function mapDispatchToProps(dispatch) {
  return {
    clearSuccessState: () => {
      dispatch(clearSuccessState());
    },
    resetPassword: values => {
      dispatch(resetPasswordRequest({ values, meta }));
    }
  };
}

function mapStateToProps(state) {
  return {
    successes: getSuccesses(state)[fingerprint] || false,
    errors: getErrors(state)[fingerprint] || [],
    isLoading: getIsLoading(state)[fingerprint] || false
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassForm);
