import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { urlMap } from 'routes/urlMap';
import get from 'lodash/get';
import { getUserAuthentication } from 'redux/selectors';
import getText from 'context/language/getText';
import { StyledSigninContainer } from 'components/auth/styledSignin';
import SigninForm from './signinForm';
import { withTheme } from 'styled-components';
import SimpleCarousel from 'components/common/carousel';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import ForgotPassForm from './forgotPassForm';
import ResetPassForm from './resetPassForm';
import { handleLoadReCaptcha } from 'components/auth/helpers.js';
import { SHOW_HIRE_PRIVY_THEME_FINGERPRINT } from 'common/constants/fingerprintConstants';
const { COMPANY_HOST } = process.env;

const Signin = ({ isAuthenticated, location, theme, ...props }) => {
  const [data, setData] = useState(null);

  let copyrightText = getText('info.copyright');
  if (COMPANY_HOST === SHOW_HIRE_PRIVY_THEME_FINGERPRINT) {
    copyrightText = getText('info.hireprivyCopyright');
  }

  useEffect(() => {
    handleLoadReCaptcha(setData);
  }, []);

  let renderForm = <SigninForm companyConfig={data} />;

  if (location.pathname === urlMap.FORGOT_PASSWORD)
    renderForm = <ForgotPassForm {...props} />;
  if (location.pathname === urlMap.RESET_PASSWORD)
    renderForm = <ResetPassForm {...props} />;

  if (isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: get(location, 'state.from.pathname', urlMap.HOME),
          state: { referrer: location.pathname }
        }}
      />
    );
  }

  const logo = theme.icons.signIn.logo;

  return (
    <StyledSigninContainer className="row">
      <Grid container>
        <Grid item xs={12} md={6} className="signin-form-area">
          <div className="signin-form-wrapper">
            <div className="img-container logo-container">
              <img className="img" src={logo} alt="" />
            </div>
            {renderForm}
          </div>
          <div className="copy-right">
            <span>{copyrightText}</span>
          </div>
        </Grid>
        <Hidden smDown>
          <Grid item md={6} className="banner-area">
            <SimpleCarousel theme={theme} />
          </Grid>
        </Hidden>
      </Grid>
    </StyledSigninContainer>
  );
};

Signin.propTypes = {
  theme: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
};

Signin.defaultProps = {};

function mapStateToProps(state) {
  return {
    isAuthenticated: getUserAuthentication(state)
  };
}

export default withTheme(connect(mapStateToProps)(Signin));
