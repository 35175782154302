import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUser } from 'redux/selectors';
import {
  StyledMobileAppInfoContainer,
  styledCustomMedia,
  StyledInfoContainer
} from './styledMobileAppInfo';
import getText from 'context/language/getText';
import { Grid, Box, Link } from '@material-ui/core';
import { urlMap } from 'routes/urlMap';
import HireprivyImg from 'assets/hirePrivy/hireprivyMobileInfo.png';
import OpusImg from 'assets/opus/opusMobileInfo.png';
import hireprivyQRSvg from 'assets/hirePrivy/hireprivy-qr.png';
import opusQRSvg from 'assets/opus/opus-qr-app.png';
import hireprivyLogo from 'assets/hirePrivy/logo-1.png';
import opusLogo from 'assets/opus/logo-opus.png';
import appleLink from 'assets/apple.png';
import googleLink from 'assets/google.png';

const OPUS_INFO = {
  logo: opusLogo,
  qrImg: opusQRSvg,
  mobileImg: OpusImg,
  googlePlay: 'https://play.google.com/store/apps/details?id=com.mylong.opus',
  appleStore: 'https://apps.apple.com/us/app/opus-plus/id1535370222',
  copyright: getText('mobileAppInfo.opusCopyright')
};

const HIREPRIVY_INFO = {
  logo: hireprivyLogo,
  qrImg: hireprivyQRSvg,
  mobileImg: HireprivyImg,
  googlePlay:
    'https://play.google.com/store/apps/details?id=com.mylong.hireprivy',
  appleStore: 'https://apps.apple.com/us/app/hire-privy/id1532682212',
  copyright: getText('mobileAppInfo.hireprivyCopyright')
};

class MobileAppInfo extends Component {
  constructor(props) {
    super(props);

    let info = OPUS_INFO;
    if (window.location.pathname.startsWith(urlMap.HIREPRIVY_APP_INFO))
      info = HIREPRIVY_INFO;
    this.state = { info };
  }

  render() {
    const { info } = this.state;
    const { mobileImg, qrImg, logo, copyright, googlePlay, appleStore } = info;

    return (
      <StyledMobileAppInfoContainer>
        <Grid container alignItems="flex-start" className="container-area">
          <Grid item xs={12} md={6} className="left-area">
            <StyledInfoContainer>
              <Grid container>
                <Grid item xs={12}></Grid>
                <Grid item xs={12}>
                  <img className="img" src={logo} alt="" />
                  <p className="introduce-app">
                    <div>
                      <span class="spacer"></span>
                      <h4>{getText('mobileAppInfo.instruction')}</h4>
                      <span class="spacer"></span>
                    </div>
                  </p>
                  <div className="sub-instruction">
                    {getText('mobileAppInfo.subInstruction')}
                  </div>
                  <img className="qr-code" src={qrImg} alt="" />
                  <div>
                    <Link className="google-play-link" href={googlePlay}>
                      <img className="img-link" src={googleLink} alt="" />
                    </Link>
                  </div>
                  <div>
                    <Link className="apple-store-link" href={appleStore}>
                      <img className="img-link" src={appleLink} alt="" />
                    </Link>
                  </div>
                  <p className="introduce-app">
                    <div>
                      <span class="spacer"></span>
                    </div>
                  </p>
                  <div className="copy-right">{copyright}</div>
                </Grid>
              </Grid>
            </StyledInfoContainer>
          </Grid>
          <Grid item xs={12} md={6} className="right-area">
            <Box
              className="img-wrapper"
              display={{
                xs: 'block',
                sm: 'block',
                md: 'block',
                lg: 'block',
                xl: 'block'
              }}
            >
              <img
                css={styledCustomMedia}
                alt="mobile application"
                src={mobileImg}
              />
            </Box>
          </Grid>
        </Grid>
      </StyledMobileAppInfoContainer>
    );
  }
}

MobileAppInfo.propTypes = {
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

export default connect(mapStateToProps, null)(MobileAppInfo);
