import get from 'lodash/get';
import {
  LOAD_UPCOMING_SCHEDULES,
  UPDATE_SCHEDULE_STATE
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import {
  UPDATE_PASS_COMING_SCHEDULE_FINGERPRINT,
  UPDATE_FAIL_COMING_SCHEDULE_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.UPCOMING_SCHEDULES],
  action
) {
  switch (action.type) {
    case LOAD_UPCOMING_SCHEDULES.SUCCESS: {
      const upcomingSchedules = get(
        action,
        'payload.upcomingInterviewSchedules',
        []
      );
      return [...upcomingSchedules];
    }
    case LOAD_UPCOMING_SCHEDULES.FAILURE: {
      showErrors(action);
      return state;
    }
    case UPDATE_SCHEDULE_STATE.SUCCESS: {
      const updatedSchedule = get(
        action,
        'payload.updateInterviewScheduleState',
        {}
      );
      const fingerprint = get(action, 'meta.fingerprint');
      const isComingSchedule = get(action, 'meta.isComingSchedule');

      if (isComingSchedule) {
        const upcomingSchedules = state.map(item => {
          if (item.id === updatedSchedule.id) {
            return updatedSchedule;
          }
          return item;
        });

        let message = '';
        if (fingerprint === UPDATE_PASS_COMING_SCHEDULE_FINGERPRINT)
          message = getText('interviewSchedules.passSuccess');
        if (fingerprint === UPDATE_FAIL_COMING_SCHEDULE_FINGERPRINT)
          message = getText('interviewSchedules.failSuccess');

        toast.success(message, { className: 'alert alert-success' });
        return [...upcomingSchedules];
      }
      return state;
    }
    default:
      return state;
  }
}
