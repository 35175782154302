import {
  LOAD_ALL_SHIFT_CATEGORIES,
  ADD_NEW_CATEGORY
} from 'redux/actions/actionTypes';
import { getAllShiftCategories, addNewCategory } from 'api/shiftCategories';
import {
  loadAllShiftCategoriesSuccess,
  loadAllShiftCategoriesFailure,
  addNewCategorySuccess,
  addNewCategoryFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const shiftCategories = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_SHIFT_CATEGORIES.REQUEST: {
      next(action);
      const { companyId } = action.payload;
      return apiPromise(
        store,
        action,
        getAllShiftCategories,
        [companyId],
        loadAllShiftCategoriesSuccess,
        loadAllShiftCategoriesFailure
      );
    }
    case ADD_NEW_CATEGORY.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewCategory,
        [values],
        addNewCategorySuccess,
        addNewCategoryFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default shiftCategories;
