// action types
class AsyncActionType {
  constructor(base) {
    this.base = base;
    this.REQUEST = `${base}_REQUEST`;
    this.SUCCESS = `${base}_SUCCESS`;
    this.FAILURE = `${base}_FAILURE`;
  }
}

// connecte router actions
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

// PAGINATION
export const CONFIGURE_PAGINATION = 'SET_PAGINATION';
export const LOAD_PAGINATION = 'LOAD_PAGINATION';

// login
export const AUTH_USER = new AsyncActionType('AUTH_USER'); //auth user by token
export const UNAUTH_USER = new AsyncActionType('UNAUTH_USER'); // log out
export const USER_LOGIN = new AsyncActionType('USER_LOGIN'); // login request

export const FORGOT_PASSWORD = new AsyncActionType('FORGOT_PASSWORD');
export const RESET_PASSWORD = new AsyncActionType('RESET_PASSWORD');
export const CHANGE_PASSWORD = new AsyncActionType('CHANGE_PASSWORD');
export const AGREE_TO_TERMS = new AsyncActionType('AGREE_TO_TERMS');
// images
export const UPLOAD_CROPPED_IMAGE = new AsyncActionType('UPLOAD_CROPPED_IMAGE');

// user info
export const LOAD_CURRENT_USER_INFO = new AsyncActionType(
  'LOAD_CURRENT_USER_INFO'
);

// workers list
export const SEARCH_WORKER_BY_NAME = new AsyncActionType('SEARCH_WORKER_BY_NAME');
export const LOAD_ALL_WORKERS = new AsyncActionType('LOAD_ALL_WORKERS');
export const ADD_NEW_WORKER = new AsyncActionType('ADD_NEW_WORKER');
export const UPDATE_WORKER = new AsyncActionType('UPDATE_WORKER');
export const REMOVE_WORKER = new AsyncActionType('REMOVE_WORKER');
export const IMPORT_WORKERS = new AsyncActionType('IMPORT_WORKERS');
export const ADD_NEW_WORKER_ACCOUNT = new AsyncActionType('ADD_NEW_WORKER_ACCOUNT');
export const LOAD_IMPORT_WORKER_FILE = new AsyncActionType('LOAD_IMPORT_WORKER_FILE');
export const BLOCK_WORKER = new AsyncActionType('BLOCK_WORKER');

// employers list
export const SEARCH_EMPLOYER_BY_NAME = new AsyncActionType(
  'SEARCH_EMPLOYER_BY_NAME'
);
export const LOAD_ALL_EMPLOYERS = new AsyncActionType('LOAD_ALL_EMPLOYERS');

export const ADD_NEW_EMPLOYER = new AsyncActionType('ADD_NEW_EMPLOYER');
export const UPDATE_EMPLOYER = new AsyncActionType('UPDATE_EMPLOYER');
export const IMPORT_EMPLOYERS = new AsyncActionType('IMPORT_EMPLOYERS');
export const LOAD_IMPORT_EMPLOYER_FILE = new AsyncActionType(
  'LOAD_IMPORT_EMPLOYER_FILE'
);

// shifts list
export const SEARCH_SHIFT_BY_NAME = new AsyncActionType('SEARCH_SHIFT_BY_NAME');
export const LOAD_ALL_SHIFTS = new AsyncActionType('LOAD_ALL_SHIFTS');
export const CANCEL_SHIFT = new AsyncActionType('CANCEL_SHIFT');
export const UPDATE_SHIFT = new AsyncActionType('UPDATE_SHIFT');

// company settings
export const LOAD_COMPANY_SETTINGS = new AsyncActionType(
  'LOAD_COMPANY_SETTINGS'
);
export const SAVE_COMPANY_SETTINGS = new AsyncActionType(
  'SAVE_COMPANY_SETTINGS'
);

// teams list
export const LOAD_ALL_TEAMS = new AsyncActionType('LOAD_ALL_TEAMS');
export const ADD_NEW_TEAM = new AsyncActionType('ADD_NEW_TEAM');
export const UPDATE_TEAM = new AsyncActionType('UPDATE_TEAM');
export const REMOVE_TEAM = new AsyncActionType('REMOVE_TEAM');

// locations list
export const SEARCH_LOCATIONS = new AsyncActionType('SEARCH_LOCATIONS');
export const LOAD_ALL_LOCATIONS = new AsyncActionType('LOAD_ALL_LOCATIONS');

export const ADD_NEW_LOCATION = new AsyncActionType('ADD_NEW_LOCATION');
export const UPDATE_LOCATION = new AsyncActionType('UPDATE_LOCATION');
export const REMOVE_LOCATION = new AsyncActionType('REMOVE_LOATION');

// reports
export const LOAD_ALL_REPORTS = new AsyncActionType('LOAD_ALL_REPORTS');
export const LOAD_REPORT_BY_WORKER_ID = new AsyncActionType(
  'LOAD_REPORT_BY_WORKER_ID'
);
export const UPDATE_REPORT = new AsyncActionType('UPDATE_REPORT');

// messages
export const LOAD_MESSAGE_CONVERSATIONS = new AsyncActionType(
  'LOAD_MESSAGE_CONVERSATIONS'
);
export const LOAD_MESSAGE_BY_ID = new AsyncActionType('LOAD_MESSAGE_BY_ID');
export const ADD_NEW_MESSAGE = new AsyncActionType('ADD_NEW_MESSAGE');
export const MARK_ALL_MESSAGES_AS_READ = new AsyncActionType(
  'MARK_ALL_MESSAGES_AS_READ'
);
export const LOAD_MESSAGE_THREAD_BY_ID = new AsyncActionType(
  'LOAD_MESSAGE_THREAD_BY_ID'
);

// jobs
export const LOAD_ALL_JOBS = new AsyncActionType('LOAD_ALL_JOBS');
export const LOAD_JOB_BY_ID = new AsyncActionType('LOAD_JOB_BY_ID');
export const UPDATE_JOB = new AsyncActionType('UPDATE_JOB');
export const CREATE_JOB = new AsyncActionType('CREATE_JOB');

// shift categories
export const LOAD_ALL_SHIFT_CATEGORIES = new AsyncActionType(
  'LOAD_ALL_SHIFT_CATEGORIES'
);
export const ADD_NEW_CATEGORY = new AsyncActionType('ADD_NEW_CATEGORY');

// badges
export const LOAD_ALL_BADGES = new AsyncActionType('LOAD_ALL_BADGES');
export const ADD_NEW_BADGE = new AsyncActionType('ADD_NEW_BADGE');
export const UPDATE_BADGE = new AsyncActionType('UPDATE_BADGE');

// shift request
export const LOAD_ALL_SHIFT_REQUESTS = new AsyncActionType(
  'LOAD_ALL_SHIFT_REQUESTS'
);
export const CREATE_SHIFT_REQUEST = new AsyncActionType('CREATE_SHIFT_REQUEST');
export const CANCEL_SHIFT_REQUEST = new AsyncActionType('CANCEL_SHIFT_REQUEST');
export const UPDATE_SHIFT_REQUEST = new AsyncActionType('UPDATE_SHIFT_REQUEST');
export const SEARCH_SHIFT_REQUESTS = new AsyncActionType(
  'SEARCH_SHIFT_REQUESTS'
);
export const LOAD_SHIFT_REQUEST_BY_ID = new AsyncActionType(
  'LOAD_SHIFT_REQUEST_BY_ID'
);

export const CLEAR_SHIFT_REQUESTS = 'CLEAR_SHIFT_REQUESTS';
export const CLEAR_SHIFT_REQUEST = 'CLEAR_SHIFT_REQUEST';
export const CLEAR_SUCCESS_STATE = 'CLEAR_SUCCESS_STATE';
export const CLEAR_ERROR_STATE = 'CLEAR_ERROR_STATE';
export const CLEAR_IMPORT_FILE_STATE = 'CLEAR_IMPORT_FILE_STATE';

// shift
export const LOAD_SHIFT_BY_ID = new AsyncActionType('LOAD_SHIFT_BY_ID');

// job rating
export const CREATE_JOB_RATING = new AsyncActionType('CREATE_JOB_RATING');
export const UPDATE_JOB_RATING = new AsyncActionType('UPDATE_JOB_RATING');

// request tasks list
export const LOAD_ALL_REQUEST_TASKS = new AsyncActionType(
  'LOAD_ALL_REQUEST_TASKS'
);
export const CREATE_REQUEST_TASK = new AsyncActionType('CREATE_REQUEST_TASK');
export const UPDATE_REQUEST_TASK = new AsyncActionType('UPDATE_REQUEST_TASK');
export const REMOVE_REQUEST_TASK = new AsyncActionType('REMOVE_REQUEST_TASK');

// task progresses list
export const LOAD_PROGESSES_BY_REQUEST_TASK_ID = new AsyncActionType(
  'LOAD_PROGESSES_BY_REQUEST_TASK_ID'
);

// active jobs
export const LOAD_ALL_ACTIVE_JOBS = new AsyncActionType('LOAD_ALL_ACTIVE_JOBS');
export const LOAD_WORKER_LOCATIONS = new AsyncActionType(
  'LOAD_WORKER_LOCATIONS'
);

// facilities list
export const LOAD_ALL_FACILITIES = new AsyncActionType('LOAD_ALL_FACILITIES');

export const ADD_NEW_FACILITY = new AsyncActionType('ADD_NEW_FACILITY');
export const UPDATE_FACILITY = new AsyncActionType('UPDATE_FACILITY');
export const REMOVE_FACILITY = new AsyncActionType('REMOVE_FACILITY');

// facility group list

export const LOAD_ALL_FACILITY_GROUPS = new AsyncActionType(
  'LOAD_ALL_FACILITY_GROUPS'
);

// onboarding list
export const LOAD_ALL_WORKER_ONBOARDINGS = new AsyncActionType(
  'LOAD_ALL_WORKER_ONBOARDINGS'
);
export const SEARCH_WORKER_ONBOARDING = new AsyncActionType(
  'SEARCH_WORKER_ONBOARDING'
);
export const CANCEL_WORKER_ONBOARDING = new AsyncActionType(
  'CANCEL_WORKER_ONBOARDING'
);

export const LOAD_UPCOMING_SCHEDULES = new AsyncActionType(
  'LOAD_UPCOMING_SCHEDULES'
);
export const LOAD_PAST_SCHEDULES = new AsyncActionType('LOAD_PAST_SCHEDULES');
export const UPDATE_SCHEDULE_STATE = new AsyncActionType(
  'UPDATE_SCHEDULE_STATE'
);

// resend invites
export const RESEND_INVITES = new AsyncActionType('RESEND_INVITES');
export const RESEND_NOTIFICATION = new AsyncActionType('RESEND_NOTIFICATION');

// worker contract
export const LOAD_WORKER_CONTRACT_BY_ID = new AsyncActionType(
  'LOAD_WORKER_CONTRACT_BY_ID'
);

export const SUBMIT_FEEDBACK = new AsyncActionType('SUBMIT_FEEDBACK');

