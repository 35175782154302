export const ID = 'id';
export const CREATED_AT = 'createdAt';
export const SHIFT_ID = 'shiftId';
export const ACTUAL_WORKING_DURATION = 'actualWorkingDuration';
export const STATE = 'state';
export const NOTE = 'note';
export const ADJUSTED_PAYMENT = 'adjustedPayment';
export const PAUSED_AT = 'pausedAt';
export const STARTED_AT = 'startedAt';
export const FILLED_AT = 'filledAt';
export const FACILITY_ID = 'facilityId';
export const PERMITTED_EVENTS = 'permittedEvents';
export const WORKER_ID = 'workerId';
export const UNREAD_MESSAGE_COUNTER = 'unreadMessageCounter';
export const REQUEST_TASKS_COUNTER = 'requestTasksCounter';

export const PAY_AMOUNT = 'payAmount';
export const MAXIMUM_PAY_AMOUNT = 'maximumPayAmount';


export const JOB_RATINGS = 'jobRatings';
export const EVENT = 'event';

//JOB ACTIONS
export const APPROVE = 'approve';
export const CANCEL = 'cancel';
export const NO_SHOW = 'no_show';
export const CHECK_IN = 'check_in';
export const CHECK_OUT = 'check_out';
export const TAKE_BREAK = 'take_break';
export const ACCEPT_APPLICATION = 'accept_application';
export const START = 'start';
export const COMPLETE = 'complete';

//JOB STATE
export const STATE_FILLED = 'filled';
export const STATE_INVITED = 'invited';
export const STATE_PENDING = 'pending';
export const STATE_STARTED = 'started';
export const STATE_PAUSED = 'paused';
export const STATE_COMPLETED = 'completed';
export const STATE_APPROVED = 'approved';
export const STATE_CANCELLED = 'cancelled';
export const STATE_NO_SHOW = 'no_show';
export const STATE_WITHDRAWN = 'withdrawn';
export const STATE_LATE = 'late';

export const CAN_RATE = 'rate';

export const STATE_ACCEPTED = 'accepted';
export const STATE_INVITED_FILLED = 'invited_filled';



