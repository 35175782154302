import React, { Component } from 'react';
import { Carousel, CarouselCaption, CarouselControl, CarouselIndicators, CarouselItem } from 'reactstrap';
import { StyleCarouselContainer, StyledPicture } from './styled';
import getText from 'context/language/getText';

class SimpleCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      items: []
    };

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }


  componentDidMount() {
    const { theme } = this.props;

    const initialItems = [
      {
        id: 1,
        altText: getText('login.carousel_header_1'),
        caption: getText('login.carousel_description_1'),
        src: theme.banners.loginCarousel.splashScreen_1st
      },
      {
        id: 2,
        altText: getText('login.carousel_header_2'),
        caption: getText('login.carousel_description_2'),
        src: theme.banners.loginCarousel.splashScreen_2nd
      }, {
        id: 3,
        altText: getText('login.carousel_header_3'),
        caption: getText('login.carousel_description_3'),
        src: theme.banners.loginCarousel.splashScreen_3rd
      },
      {
        id: 4,
        altText: getText('login.carousel_header_4'),
        caption: getText('login.carousel_description_4'),
        src: theme.banners.loginCarousel.splashScreen_4th
      },
      {
        id: 5,
        altText: getText('login.carousel_header_5'),
        caption: getText('login.carousel_description_5'),
        src: theme.banners.loginCarousel.splashScreen_5th
      }
    ];

    this.setState({
      items: initialItems
    });
  }


  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex, items } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}>
          <StyledPicture image={item.src} />
          <CarouselCaption className="text-danger" captionText={item.altText} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    return (<StyleCarouselContainer>
      <Carousel
        activeIndex={activeIndex}
        next={this.next}
        previous={this.previous}>
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
        {slides}
        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
      </Carousel>
    </StyleCarouselContainer>
    );
  }
}

export default SimpleCarousel;
