import { LOAD_WORKER_CONTRACT_BY_ID } from 'redux/actions/actionTypes';
import { getWorkerContract } from 'api/workerOnboarding';
import {
  loadWorkerContractByIdSuccess,
  loadWorkerContractByIdFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const workerContract = store => next => action => {
  switch (action.type) {
    case LOAD_WORKER_CONTRACT_BY_ID.REQUEST: {
      next(action);
      const { id } = action.payload;
      return apiPromise(
        store,
        action,
        getWorkerContract,
        [id],
        loadWorkerContractByIdSuccess,
        loadWorkerContractByIdFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default workerContract;
