// user login middleware

import { LOCATION_CHANGE } from 'redux/actions/actionTypes';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import { getUrlParams } from 'common/helpers/getUrlParams';

const redirect = store => next => action => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      const locationPayload = action.payload;
      const query = locationPayload.location.search;
      if (!query) return next(action);
      const queryVars = getUrlParams(query);
      const token = get(queryVars, 'token', null);
      if (token) localStorage.token = token;

      const newPath = get(queryVars, 'path', null);
      const desiredPath = `../../${newPath}`;

      if (!newPath) return next(action);
      store.dispatch(push(desiredPath));
      return null;
    }

    default:
      next(action);
      return null;
  }
};

export default redirect;
