import styled from 'styled-components';

export const StyledSigninContainer = styled.div`
  min-height: 100%;
  height: 100vh;
  .signin-form-area {
    min-height: 100%;
    position: relative;
    background-color: ${({ theme }) => theme.colors.name.white};
  }
  .banner-area {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-color: ${({ theme }) => theme.colors.name.main_color_2};
  }
  .MuiFormControl-root {
    display: block;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .signin-form-wrapper {
    width: 35%;
    margin: auto;
    padding-top: 50px;
    @media (max-width: 600px) {
      width: 90%;
      padding: 0px 5px;
    }
  }
  .logo-container {
    padding: 15% 20px;
  }
  .img-container {
    img {
      width: 100%;
    }
  }
  .ui.button {
    &.signin-submit-btn,
    &.forgot-password-btn,
    &.reset-password-btn {
      margin-top: 50px;
      border-radius: 15px;
      height: 45px;
      min-width: 100%;
      text-transform: uppercase;
    }
  }
  .copy-right {
    font-weight: bold;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    height: 13px;

    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #323554;
  }
  .forgot-pasword {
    display: block;
    text-align: center;
    padding-top: 50px;
  }
  .back-signin {
    display: block;
    text-align: left;
    padding-top: 50px;
    svg {
      font-size: 11px;
    }
  }
  .signin-row {
    width: 100%;
    .MuiOutlinedInput-root {
      height: 45px;
      border-radius: 15px;
    }
    .MuiInputLabel-outlined.MuiInputLabel-shrink {
      background-color: ${({ theme }) => theme.colors.name.lightgrey};
    }
    svg {
      fill: ${({ theme }) => theme.colors.name.main_color_1};
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px
        ${({ theme }) => theme.colors.name.lightgrey} inset !important;
    }
  }
  .signin-icon {
    padding-bottom: 10px !important;
  }
  .MuiFormControlLabel-root {
    padding-top: 10px;
    padding-left: 10px;
  }
  .MuiIconButton-root.toggle-show {
    padding: 0px;
  }
`;

export const StyledSignInFormContainer = styled.div`
  min-height: 400px;
`;

export const StyledSignInLoadingContainer = styled.div`
  height: 300px;
`;

export const StyledHeader = styled.h3`
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  padding-top: 20px;
`;

export const StyledModalContext = styled.div`
  text-align: center;
`;
