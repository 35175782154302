import { LOAD_ALL_FACILITY_GROUPS } from 'redux/actions/actionTypes';
import { getAllFacilityGroups } from 'api/facilityGroups';
import {
  loadAllFacilityGroupsSuccess,
  loadAllFacilityGroupsFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const facilityGroups = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_FACILITY_GROUPS.REQUEST: {
      next(action);
      const { companyId } = action.payload;
      return apiPromise(
        store,
        action,
        getAllFacilityGroups,
        [companyId],
        loadAllFacilityGroupsSuccess,
        loadAllFacilityGroupsFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default facilityGroups;
