import {
  graphQueryPromise,
  handleUpdateString,
  graphMutationPromise
} from './helpers';
import * as SHIFT from 'common/constants/shiftConstants';
import * as SHIFT_REQUEST from 'common/constants/shiftRequestConstants';
import * as LOCATION from 'common/constants/locationConstants';
import * as REQUEST_TASK from 'common/constants/requestTaskConstants';
import * as JOB from 'common/constants/jobConstants';
import * as WORKER from 'common/constants/workerConstants';
import * as JOB_RATINGS from 'common/constants/jobRatingsConstants';
import isEmpty from 'lodash/isEmpty';

const locationFields = [
  LOCATION.ID,
  LOCATION.ACTIVE,
  LOCATION.ADDRESS,
  LOCATION.ADDRESS_STATE,
  LOCATION.CITY,
  LOCATION.FACILITY_ID,
  LOCATION.FACILITY_GROUP_ID,
  LOCATION.GEOFENCING_RADIUS,
  LOCATION.LATITUDE,
  LOCATION.LONGITUDE,
  LOCATION.NAME,
  LOCATION.PHONE,
  LOCATION.ZIPCODE,
  LOCATION.TIMEZONE,
  LOCATION.COUNTRY
];

const shiftFields = [
  SHIFT.ID,
  SHIFT.APPROVED_AT,
  SHIFT.BREAK_TIME_DURATION,
  SHIFT.BREAK_TIME_NUMBER,
  SHIFT.CREATED_AT,
  SHIFT.CANCELLED_AT,
  SHIFT.DURATION,
  SHIFT.JOBS_NUMBER,
  SHIFT.FACILITY_ID,
  SHIFT.NOTE,
  SHIFT.STATE,
  SHIFT.PAY_AMOUNT,
  SHIFT.MAXIMUM_PAY_AMOUNT,
  SHIFT.PAYMENT_TYPE,
  SHIFT.START_TIME,
  SHIFT.END_TIME,
  SHIFT.SHIFT_REQUEST_ID,
  SHIFT.FILL_RATE,
  SHIFT.PERMITTED_EVENTS,
  SHIFT.TIME_ZONE
];

const shiftRequestFields = [
  SHIFT_REQUEST.ID,
  SHIFT_REQUEST.NAME,
  SHIFT_REQUEST.STATE,
  SHIFT_REQUEST.COMPANY_ID,
  SHIFT_REQUEST.ASSIGNED_EMPLOYER_ID,
  SHIFT_REQUEST.INVITED_WORKER_IDS,
  SHIFT_REQUEST.DESCRIPTION,
  SHIFT_REQUEST.REQUIREMENTS,
  SHIFT_REQUEST.BADGE_REQUIREMENTS,
  SHIFT_REQUEST.EMPLOYER_ID,
  SHIFT_REQUEST.FACILITY_ID,
  SHIFT_REQUEST.FACILITY_GROUP_ID,
  SHIFT_REQUEST.SHIFT_CATEGORY_ID,
  SHIFT_REQUEST.PERMITTED_EVENTS,
  SHIFT_REQUEST.GENERAL_DETAILS
];

const shiftRequestFields_New = [
  SHIFT_REQUEST.NAME,
  SHIFT_REQUEST.DESCRIPTION,
  SHIFT_REQUEST.REQUIREMENTS,
  SHIFT_REQUEST.FACILITY_ID,
  SHIFT_REQUEST.SHIFT_CATEGORY_ID,
  SHIFT_REQUEST.EMPLOYER_ID,
  SHIFT_REQUEST.ASSIGNED_EMPLOYER_ID,
  SHIFT_REQUEST.STATE
];

const shiftFields_Update = [
  SHIFT.ID,
  SHIFT.DURATION,
  SHIFT.BREAK_TIME_DURATION,
  SHIFT.PAY_AMOUNT,
  SHIFT.MAXIMUM_PAY_AMOUNT,
  SHIFT.PAYMENT_TYPE,
  SHIFT.BREAK_TIME_NUMBER,
  SHIFT.START_TIME,
  SHIFT.JOBS_NUMBER,
  SHIFT.LOCATION_ID
];

const shiftRequestFields_DraftUpdate = [
  SHIFT_REQUEST.ID,
  SHIFT_REQUEST.NAME,
  SHIFT_REQUEST.STATE,
  SHIFT_REQUEST.DESCRIPTION,
  SHIFT_REQUEST.REQUIREMENTS,
  SHIFT_REQUEST.SHIFT_CATEGORY_ID,
  SHIFT_REQUEST.FACILITY_ID
];

const requestTask = [
  REQUEST_TASK.ID,
  REQUEST_TASK.NAME,
  REQUEST_TASK.DESCRIPTION,
  REQUEST_TASK.COMPLETION_PERCENTAGE,
  REQUEST_TASK.ASSIGNED_FOR_TEAM,
  REQUEST_TASK.SHIFT_REQUEST_ID,
  REQUEST_TASK.COMPANY_ID,
  REQUEST_TASK.STATUS_COLOR,
  REQUEST_TASK.TASK_PROGRESSES_COUNT,
  REQUEST_TASK.FOR_ALL_WORKERS
];

const jobFields = [
  JOB.ID,
  JOB.CREATED_AT,
  JOB.SHIFT_ID,
  JOB.ACTUAL_WORKING_DURATION,
  JOB.STATE,
  JOB.NOTE,
  JOB.ADJUSTED_PAYMENT,
  JOB.PAUSED_AT,
  JOB.STARTED_AT,
  JOB.FILLED_AT,
  JOB.FACILITY_ID,
  JOB.PERMITTED_EVENTS,
  JOB.UNREAD_MESSAGE_COUNTER
];

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_EMAIL,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE
];

const jobRatingsFields = [
  JOB_RATINGS.ID,
  JOB_RATINGS.NOTE,
  JOB_RATINGS.RATING,
  JOB_RATINGS.RATING_USER_TYPE
];

export const searchShiftsByName = search => {
  const fields = '';
  const query = `{
    allUsers(filter: { q: "${search}", type: "Worker" }) {
      ${fields}
    }
  }`;
  return graphQueryPromise(query);
};

export const getShifts = (companyId, startTimeFrom, startTimeTo) => {
  const query = `{
    shifts (companyId: "${companyId}",  limit: 1000, filter: { startTimeFrom: "${startTimeFrom}", startTimeTo: "${startTimeTo}" , scope: calendar_view } ) {
      ${shiftFields.join(',')}
      shiftRequest {
        ${shiftRequestFields.join(',')}
      }
      location {
        ${locationFields.join(',')}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getAllShifts = (
  companyId,
  offset,
  keywordSearch = null,
  scopeFilter = null,
  field = null,
  order = null
) => {
  const shift_fields = shiftFields.join(',');
  const shiftRequest_fields = shiftRequestFields.join(',');
  const location_fields = locationFields.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');

  let offsetVal = '';
  if (offset) offsetVal = `, offset : ${offset}`;

  let keywordVal = '';
  if (keywordSearch === '') {
    keywordVal = ', keywordSearch : ""';
  } else {
    if (keywordSearch !== null) {
      keywordVal = `, keywordSearch : "${keywordSearch}"`;
    }
  }

  let filterVal = '';
  if (scopeFilter) {
    filterVal = `, filter: { scope: ${scopeFilter} } `;
  }

  let sortVal = '';
  if (field) {
    sortVal = `, sortBy: { field: ${field}, order: ${order} } `;
  }

  const query = `{
    shifts (companyId : "${companyId}" ${offsetVal} ${keywordVal} ${sortVal} ${filterVal}) {
      ${shift_fields}
      shiftRequest {
        ${shiftRequest_fields}
      }
      location{
        ${location_fields}
      }
      jobs{
        ${job_fields}
        worker{
          ${worker_fields}
        }
        jobRatings {
          ${job_ratings_fields}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getRankingWorkers = shiftRequestId => {
  const query = `{
    shiftRequestRankingWorkers(shiftRequestId: "${shiftRequestId}") {
      workerId
      workerName
      notification
      rankingScore
      permittedEvents
    }
  }`;

  return graphQueryPromise(query);
};

export const resendNotificationRankingWorker = (shiftRequestId, workerId) => {
  const mutation = `mutation {
    resendNotificationRankingWorker(shiftRequestId: "${shiftRequestId}", workerId: "${workerId}") {
      success
    }
  }`;

  return graphMutationPromise(mutation);
};

export const getAllShiftRequests = (
  companyId,
  offset,
  keywordSearch = null,
  filterVal = '',
  field = null,
  order = null
) => {
  const shiftRequest_fields = shiftRequestFields.join(',');
  const shift_fields = shiftFields.join(',');
  const location_fields = locationFields.join(',');
  const requestTask_fields = requestTask.join(',');
  const job_ratings_fields = jobRatingsFields.join(',');
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');

  let offsetVal = '';
  if (offset) offsetVal = `, offset : ${offset}`;

  let keywordVal = '';
  if (keywordSearch === '') {
    keywordVal = ', keywordSearch : ""';
  } else {
    if (keywordSearch !== null) {
      keywordVal = `, keywordSearch : "${keywordSearch}"`;
    }
  }

  let sortVal = '';
  if (field) {
    sortVal = `, sortBy: { field: ${field}, order: ${order} } `;
  }

  const query = `{
    shiftRequests (companyId : "${companyId}" ${offsetVal} ${keywordVal} ${filterVal} ${sortVal} ) {
      ${shiftRequest_fields}
      requestTasks{
        ${requestTask_fields}
        assignedWorkers{
          ${worker_fields}
        }
      }
      shifts {
        ${shift_fields}
        location{
          ${location_fields}
        }
        jobs{
          ${job_fields}
          worker{
            ${worker_fields}
          }
          jobRatings {
            ${job_ratings_fields}
          }
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getShiftRequestById = shiftRequestId => {
  const shift_fields = shiftFields.join(',');
  const shiftRequest_fields = shiftRequestFields.join(',');
  const location_fields = locationFields.join(',');
  const requestTask_fields = requestTask.join(',');
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `{
    shiftRequest (id : "${shiftRequestId}") {
      ${shiftRequest_fields}
      requestTasks{
        ${requestTask_fields}
      }
      shifts {
        ${shift_fields}
        location{
          ${location_fields}
        }
        jobs{
          ${job_fields}
          worker{
            ${worker_fields}
          }
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getShiftById = shiftId => {
  const shift_fields = shiftFields.join(',');
  const location_fields = locationFields.join(',');
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');
  const shift_request_fields = [SHIFT_REQUEST.ID, SHIFT_REQUEST.NAME].join(',');
  const job_ratings_fields = jobRatingsFields.join(',');

  const query = `{
    shift(id : "${shiftId}") {
      ${shift_fields}
      shiftRequest{
        ${shift_request_fields}
      }
      location{
        ${location_fields}
      }
      jobs{
        ${job_fields}
        worker{
          ${worker_fields}
        }
        jobRatings {
          ${job_ratings_fields}
        }
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const createShiftRequest = values => {
  const shiftRequest_fields = shiftRequestFields_New
    .concat(SHIFT_REQUEST.ID)
    .join(',');
  const specialField = [SHIFT_REQUEST.DESCRIPTION, SHIFT_REQUEST.REQUIREMENTS];
  const shiftRequest_updated_fields = handleUpdateString(
    values,
    shiftRequestFields_New,
    true,
    specialField
  );

  const shift_fields = shiftFields.join(',');
  const location_fields = locationFields.join(',');
  const requestTask_fields = requestTask.join(',');
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');

  const badgeRequirementsVal = !isEmpty(
    values[SHIFT_REQUEST.BADGE_REQUIREMENTS]
  )
    ? `badgeRequirements: ${values[SHIFT_REQUEST.BADGE_REQUIREMENTS]}, `
    : '';
  const shiftVal = !isEmpty(values[SHIFT_REQUEST.SHIFTS])
    ? `shifts: ${values[SHIFT_REQUEST.SHIFTS]} , `
    : '';
  const generalDetailsVal = values[SHIFT_REQUEST.GENERAL_DETAILS]
    ? `generalDetails:  { ${values[SHIFT_REQUEST.GENERAL_DETAILS]} }, `
    : '';

  const useSmartRankingVal = values[SHIFT_REQUEST.USE_SMART_RANKING]
    ? `useSmartRanking: ${values[SHIFT_REQUEST.USE_SMART_RANKING]}, `
    : '';

  const invitedWorkerIdsVal =
    !values[SHIFT_REQUEST.USE_SMART_RANKING] &&
    values[SHIFT_REQUEST.INVITED_WORKER_IDS]
      ? `invitedWorkerIds: ${values[SHIFT_REQUEST.INVITED_WORKER_IDS]}, `
      : '';

  const mutation = `
    mutation {
      createShiftRequest( 
        ${shiftRequest_updated_fields} ${badgeRequirementsVal} ${generalDetailsVal} ${invitedWorkerIdsVal} ${shiftVal} ${useSmartRankingVal}
      ) {
          ${shiftRequest_fields}
          requestTasks{
            ${requestTask_fields}
          }
          shifts {
            ${shift_fields}
            location{
              ${location_fields}
            }
            jobs{
              ${job_fields}
              worker{
                ${worker_fields}
              }
            }
          }
      }
    }
  `;

  return graphMutationPromise(mutation);
};

export const cancelShift = shiftId => {
  const muatation = `
    mutation{
      cancelShift(shiftIds: ["${shiftId}"]){
        success
      } 
    } 
  `;

  return graphMutationPromise(muatation);
};

export const resendNotification = shiftId => {
  const muatation = `
    mutation{
      resendNotification(shiftId: "${shiftId}"){
        success
      } 
    } 
  `;

  return graphMutationPromise(muatation);
};

export const updateShiftRequest = values => {
  const specialField = [
    SHIFT_REQUEST.DESCRIPTION,
    SHIFT_REQUEST.REQUIREMENTS,
    SHIFT_REQUEST.INVITED_WORKER_IDS
  ];
  const updates = handleUpdateString(
    values,
    shiftRequestFields_DraftUpdate,
    false,
    specialField
  );
  const shiftRequest_fields = shiftRequestFields.join(',');
  const shift_fields = shiftFields.join(',');
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');
  const location_fields = locationFields.join(',');
  const requestTask_fields = requestTask.join(',');

  const badgeRequirementsVal = !isEmpty(
    values[SHIFT_REQUEST.BADGE_REQUIREMENTS]
  )
    ? `badgeRequirements: ${values[SHIFT_REQUEST.BADGE_REQUIREMENTS]}, `
    : 'badgeRequirements: [],';
  const shiftAttributesVal = !isEmpty(values[SHIFT_REQUEST.SHIFTS_ATTRIBUTES])
    ? `shiftsAttributes: ${values[SHIFT_REQUEST.SHIFTS_ATTRIBUTES]} , `
    : '';
  const generalDetailsVal = values[SHIFT_REQUEST.GENERAL_DETAILS]
    ? `generalDetails:  { ${values[SHIFT_REQUEST.GENERAL_DETAILS]} }, `
    : '';

  const useSmartRankingVal = values[SHIFT_REQUEST.USE_SMART_RANKING]
    ? `useSmartRanking: ${values[SHIFT_REQUEST.USE_SMART_RANKING]}, `
    : '';

  const invitedWorkerIdsVal =
    !values[SHIFT_REQUEST.USE_SMART_RANKING] &&
    values[SHIFT_REQUEST.INVITED_WORKER_IDS]
      ? `invitedWorkerIds: ${values[SHIFT_REQUEST.INVITED_WORKER_IDS]}, `
      : '';

  const mutation = `mutation {
    updateShiftRequest(
      ${updates} ${badgeRequirementsVal} ${shiftAttributesVal} ${generalDetailsVal} ${useSmartRankingVal} ${invitedWorkerIdsVal}
      ) {
      ${shiftRequest_fields}
      requestTasks{
        ${requestTask_fields}
      }
      shifts {
        ${shift_fields}
        location{
          ${location_fields}
        }
        jobs{
          ${job_fields}
          worker{
            ${worker_fields}
          }
        }
      }
    }
  }`;

  return graphMutationPromise(mutation);
};

export const updateShift = values => {
  const shift_fields = shiftFields.join(',');
  const specialField = [SHIFT.PAY_AMOUNT, SHIFT.MAXIMUM_PAY_AMOUNT];

  const updates = handleUpdateString(
    values,
    shiftFields_Update,
    false,
    specialField
  );
  const job_fields = jobFields.join(',');
  const worker_fields = workerFields.join(',');
  const location_fields = locationFields.join(',');

  const query = `mutation {
    updateShift(${updates}) {
      ${shift_fields}
      location{
        ${location_fields}
      }
      jobs{
        ${job_fields}
        worker{
          ${worker_fields}
        }
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const cancelShiftRequest = shiftRequestId => {
  const query = `
    mutation{
      cancelShiftRequest(id: ["${shiftRequestId}"]){
        success
      } 
    } 
  `;

  return graphMutationPromise(query);
};
