import * as types from '../actions/actionTypes';
import initialState from './initialState';
import { USER } from '../store/constants';
import get from 'lodash/get';
import defaultsDeep from 'lodash/defaultsDeep';

export default function(state = initialState[USER], action) {
  switch (action.type) {
    case types.USER_LOGIN.SUCCESS:
      return {
        ...state,
        ...action.payload.signInEmployer
      };
    case types.USER_LOGIN.FAILURE:
      return {
        ...state,
        ...action.payload
      };
    case types.AUTH_USER.SUCCESS:
      const user = action.payload.employerToken;

      return {
        ...state,
        ...user
      };
    case types.LOAD_CURRENT_USER_INFO.SUCCESS:
      return {
        ...state,
        ...action.payload.currentUser
      };
    case types.UPDATE_EMPLOYER.SUCCESS: {
      const updatedEmployer = get(action, 'payload.updateEmployer', []);
      if (updatedEmployer.id === state.employer.id) {
        const employer = {
          ...state.employer,
          ...updatedEmployer
        };
        let result = state;
        result.employer = employer;

        return result;
      } else {
        return state;
      }
    }
    case types.AGREE_TO_TERMS.SUCCESS: {
      return defaultsDeep({ employer: { agreedToTerms: true } }, state);
    }
    case types.UNAUTH_USER.SUCCESS:
    case types.UNAUTH_USER.FAILURE:
      // blank out the user if they have logged out
      return {};
    default:
      return state;
  }
}
