export const ID = 'id';
export const CREATED_AT = 'createdAt';
export const ATTACHMENT_URL = 'attachmentUrl';
export const JOB_NAME = 'jobName';
export const JOB_START_TIME = 'jobStartTime';
export const JOB_TIME_ZONE = 'jobTimezone';
export const MESSAGE = 'message';
export const OBJECT_ID = 'objectId';
export const OBJECT_TYPE = 'objectType';
export const RECEIVER_ID = 'receiverId';
export const RECEIVER_NAME = 'receiverName';
export const RECEIVER_TYPE = 'receiverType';
export const SENDER_ID = 'senderId';
export const SENDER_NAME = 'senderName';
export const SENDER_TYPE = 'senderType';
export const STATUS = 'status';

export const JOB_ID = 'jobId';
export const COMPANY_ID = 'companyId';
export const RECEIVER = 'receiver';
export const SENDER = 'senderId';

export const ATTACHMENT_BASE64 = 'attachmentBase64';
export const ATTACHMENT_FILE_NAME = 'attachmentFilename';

export const ATTACHMENT_SIGNED_BLOB = 'attachmentSignedBlobId';
