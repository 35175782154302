import get from 'lodash/get';
import {
  LOAD_ALL_BADGES,
  ADD_NEW_BADGE,
  UPDATE_BADGE
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(state = initialState[storeConstants.BADGES], action) {
  switch (action.type) {
    case LOAD_ALL_BADGES.SUCCESS: {
      const badges = get(action, 'payload.badges', []);
      return [...badges];
    }
    case ADD_NEW_BADGE.SUCCESS: {
      toast.success(getText('badgeModal.addBadgeSuccess'), {
        className: 'alert alert-success'
      });
      return [...state];
    }
    case UPDATE_BADGE.SUCCESS: {
      toast.success(getText('badgeModal.updateBadgeSuccess'), {
        className: 'alert alert-success'
      });
      return [...state];
    }
    case UPDATE_BADGE.FAILURE:
    case ADD_NEW_BADGE.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
