import { gql } from '@apollo/client';

export const GET_ACTIVE_JOBS = gql`
  query getActiveJobs(
    $locationId: String
    $keywordSearch: String
    $limit: Int
    $offset: Int
  ) {
    employerSummary {
      jobs(
        filter: { keywordSearch: $keywordSearch, locationId: $locationId }
        offset: $offset
        limit: $limit
      ) {
        activeJobs {
          id
          createdAt
          shiftId
          actualWorkingDuration
          state
          note
          adjustedPayment
          pausedAt
          startedAt
          filledAt
          facilityId
          permittedEvents
          workerId
          unreadMessageCounter
          maximumPayAmount
          payAmount
          adjustedPayment
          worker {
            id
            avatarUrl
            firstName
            lastName
            phone
            email
          }
          jobRatings {
            id
            note
            rating
            ratingUserType
          }
          jobReport {
            id
            employerNote
            employerRating
            expectedJobDuration
            expectedPayment
            finalPayment
            locationAddress
            locationName
            shiftName
            workedHours
            workerId
            workerNote
            workedDate
            workerRating
            timesheet
            permittedEvents
            adjustedWorkedHours
          }
          shift {
            id
            duration
            state
            location {
              id
              geofencingRadius
              latitude
              longitude
              name
              zipcode
              phone
              city
              address
              addressState
            }
            shiftRequest {
              id
              name
              state
            }
          }
        }
      }
    }
  }
`;
