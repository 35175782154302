import get from 'lodash/get';
import {
  LOAD_MESSAGE_CONVERSATIONS,
  LOAD_MESSAGE_THREAD_BY_ID,
  MARK_ALL_MESSAGES_AS_READ
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.MESSAGE_THREADS],
  action
) {
  switch (action.type) {
    case LOAD_MESSAGE_CONVERSATIONS.SUCCESS: {
      const messageThreads = get(action, 'payload.messageThreads', []);
      return [...messageThreads];
    }
    case LOAD_MESSAGE_THREAD_BY_ID.SUCCESS: {
      const messageThread = get(action, 'payload.messageThread', {});
      const messsageThreads = [].concat(messageThread);
      return [...messsageThreads];
    }
    case MARK_ALL_MESSAGES_AS_READ.SUCCESS: {
      let jobId = get(action, 'meta.jobId');

      let updatedMesssageThreads = state.map(messsageThread => {
        if (
          messsageThread.objectId === jobId &&
          messsageThread.objectType === 'Job'
        ) {
          let updateMessageThread = messsageThread;
          updateMessageThread.unreadMessageCounter = 0;
          return updateMessageThread;
        }
        return messsageThread;
      });

      return [...updatedMesssageThreads];
    }
    case LOAD_MESSAGE_THREAD_BY_ID.FAILURE:
    case LOAD_MESSAGE_CONVERSATIONS.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
