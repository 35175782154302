import {
  graphQueryPromise,
  graphMutationPromise,
  handleUpdateString
} from './helpers';
import * as WORKER from 'common/constants/workerConstants';
import * as WORKER_ONBOARDING from 'common/constants/workerOnboardingConstants';
import * as INTERVIEW_SCHEDULE from 'common/constants/interviewScheduleConstants';
import * as CALENDLY_INTEGRATION from 'common/constants/calendlyIntegrationConstants';
import * as WORKER_CONTRACT from 'common/constants/workerContractConstants';

const workerOnboardingFields = [
  WORKER_ONBOARDING.COMPANY_ONBOARDING_ID,
  WORKER_ONBOARDING.CURRENT_PART_INDEX,
  WORKER_ONBOARDING.ID,
  WORKER_ONBOARDING.STATE,
  WORKER_ONBOARDING.PERMITTED_EVENTS
];

const workerOnboardingPartFields = [
  WORKER_ONBOARDING.WORKER_ID,
  WORKER_ONBOARDING.ID,
  WORKER_ONBOARDING.STATE,
  WORKER_ONBOARDING.INTEGRATION_OBJECT
];

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_EMAIL,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE,
  WORKER.WORKER_FULL_SSN,
  WORKER.WORKER_MASK_SSN
];

const interviewScheduleFields = [
  INTERVIEW_SCHEDULE.ID,
  INTERVIEW_SCHEDULE.MEETING_URL,
  INTERVIEW_SCHEDULE.PERMITTED_EVENTS,
  INTERVIEW_SCHEDULE.PHONE,
  INTERVIEW_SCHEDULE.REASON_FOR_CANCELLATION,
  INTERVIEW_SCHEDULE.RESCHEDULE_INTERVIEW_URL,
  INTERVIEW_SCHEDULE.STATE,
  INTERVIEW_SCHEDULE.EVENT_TYPE_NAME,
  INTERVIEW_SCHEDULE.EVENT_START_TIME,
  INTERVIEW_SCHEDULE.EVENT_END_TIME,
  INTERVIEW_SCHEDULE.CANCELED_BY,
  INTERVIEW_SCHEDULE.CANCEL_INTERVIEW_URL
];

const interviewSchedule_UpdateFields = [
  WORKER_ONBOARDING.ID,
  WORKER_ONBOARDING.STATE
];

const calendlyIntegrationFields = [CALENDLY_INTEGRATION.ID];

const workerContractFields = [
  WORKER_CONTRACT.ID,
  WORKER_CONTRACT.INTERVIEWER_SIGNATURE_URL
];

export const getWorkerOnboarding = workerId => {
  const query = `{
    workerOnboarding(id: "${workerId}") {
      id
      currentPartIndex
      pendingWorkerOnboardingPart {
        id
        state
      }

      permittedEvents
      state
    }
  }`;

  return graphQueryPromise(query);
};

export const getAllWorkerOnboardings = (
  companyId,
  offset = 0,
  limit = 20,
  searchString = '',
  sortString = '',
  scope = '',
  fieldSearch = ''
) => {
  const onboarding_fields = workerOnboardingFields.join(',');
  const onboarding_part_fields = workerOnboardingPartFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `{
    workerOnboardings(companyId: "${companyId}", offset: ${offset}, limit: ${limit} ${searchString} ${sortString} , filter: { ${scope} ${fieldSearch} }  )
    {
      ${onboarding_fields}
      pendingWorkerOnboardingPart{
        ${onboarding_part_fields}
        integration{
          ... on WorkerContract {
            id
            state
          }
        }
      }
      worker{
        ${worker_fields}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getUpcomingInterviewSchedule = () => {
  const intervview_schedule_fields = interviewScheduleFields.join(',');
  const calendly_integration_fields = calendlyIntegrationFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `{
    upcomingInterviewSchedules
    {
      ${intervview_schedule_fields}
      calendlyIntegration{
        ${calendly_integration_fields}
      }
      worker{
        ${worker_fields}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const getPastInterviewSchedule = () => {
  const intervview_schedule_fields = interviewScheduleFields.join(',');
  const calendly_integration_fields = calendlyIntegrationFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `{
    pastInterviewSchedules
    {
      ${intervview_schedule_fields}
      calendlyIntegration{
        ${calendly_integration_fields}
      }
      worker{
        ${worker_fields}
      }
    }
  }`;

  return graphQueryPromise(query);
};

export const cancelWorkerOnboarding = id => {
  const onboarding_fields = workerOnboardingFields.join(',');
  const onboarding_part_fields = workerOnboardingPartFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `mutation {
    cancelWorkerOnboarding(id: "${id}") {
      ${onboarding_fields}
      pendingWorkerOnboardingPart{
        ${onboarding_part_fields}
        integration{
          ... on WorkerContract {
            id
            state
          }
        }
      }
      worker{
        ${worker_fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const updateInterviewScheduleState = values => {
  const updates = handleUpdateString(values, interviewSchedule_UpdateFields);
  const intervview_schedule_fields = interviewScheduleFields.join(',');
  const calendly_integration_fields = calendlyIntegrationFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `mutation {
    updateInterviewScheduleState(${updates}) {
      ${intervview_schedule_fields}
      calendlyIntegration{
        ${calendly_integration_fields}
      }
      worker{
        ${worker_fields}
      }
    }
  }`;

  return graphMutationPromise(query);
};

export const getWorkerContract = id => {
  const worker_contract_fields = workerContractFields.join(',');

  const query = `{
    workerContract(id: "${id}" )
    {
      ${worker_contract_fields}
    }
  }`;

  return graphQueryPromise(query);
};

export const updateWorkerOnboardingPart = (id, data) => {
  const mutation = `
    mutation($id: String!, $integrationAttributes: JSON
      ) {
      updateWorkerOnboardingPart(
        id: $id
        integrationAttributes: $integrationAttributes
      ) {
        id
        state
      }
    }
  `;

  return graphMutationPromise(mutation, {
    id,
    integrationAttributes: data
  });
};

export const updateWorkingAuthorization = variables => {
  const mutation = `
    mutation($id: String!
      $socialSecurityNumber: String,
      $dateOfBirth: String,
      $legalRightToWork: Boolean,
      $idPhotoBase64: String) {
        updateWorkingAuthorization(
          id: $id, 
          socialSecurityNumber: $socialSecurityNumber, 
          dateOfBirth: $dateOfBirth, 
          legalRightToWork: $legalRightToWork, 
          idPhotoBase64: $idPhotoBase64) {
            id
        }
      }
  `;

  return graphMutationPromise(mutation, variables);
};

export const remindWorkerOnboardingPart = id => {
  const mutation = `
    mutation($id: String!) {
        remindWorkerOnboardingPart(id: $id) {
        id
        state
      }
    }
  `;

  return graphMutationPromise(mutation, {
    id
  });
};
