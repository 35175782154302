import { LOAD_PROGESSES_BY_REQUEST_TASK_ID } from 'redux/actions/actionTypes';
import { getProgressesByRequestTaskId } from 'api/taskProgresses';
import {
  loadProgressesByRequestTaskIdSuccess,
  loadProgressesByRequestTaskIdFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const taskProgresses = store => next => action => {
  switch (action.type) {
    case LOAD_PROGESSES_BY_REQUEST_TASK_ID.REQUEST: {
      next(action);
      const { requestTaskId } = action.payload;
      return apiPromise(
        store,
        action,
        getProgressesByRequestTaskId,
        [requestTaskId],
        loadProgressesByRequestTaskIdSuccess,
        loadProgressesByRequestTaskIdFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default taskProgresses;
