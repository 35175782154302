import React from 'react';
import PropTypes from 'prop-types';
import { urlMap } from 'routes/urlMap';
import FullPageLoader from 'components/common/loaders/FullPageLoader';
import { getUrlFromNotiForImport } from 'common/helpers/getUrlParams';
import { extractIdFromUrl } from 'common/helpers/helpers';

class NotificationRedirect extends React.Component {
  componentDidMount() {
    let url = getUrlFromNotiForImport(this.props.history.location);
    let mainUrl = '';

    switch (url) {
      case urlMap.IMPORT_WORKER_FILES:
        mainUrl = urlMap.WORKERS_LIST;
        break;
      case urlMap.IMPORT_EMPLOYER_FILES:
        mainUrl = urlMap.EMPLOYERS_LIST;
        break;
      default:
        mainUrl = urlMap.HOME;
    }

    const id = extractIdFromUrl();
    const searchParams = new URLSearchParams();
    searchParams.append('importFileId', id);
    searchParams.toString();

    const desLink =
      mainUrl !== urlMap.HOME
        ? mainUrl + '?' + searchParams.toString()
        : mainUrl;
    setTimeout(() => {
      window.location.href = desLink;
    }, 2000);
  }

  render() {
    return <FullPageLoader />;
  }
}

NotificationRedirect.propTypes = {
  isLoading: PropTypes.bool.isRequired
};

export default NotificationRedirect;
