import get from 'lodash/get';
import { LOAD_ALL_FACILITY_GROUPS } from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.FACILITY_GROUPS],
  action
) {
  switch (action.type) {
    case LOAD_ALL_FACILITY_GROUPS.SUCCESS: {
      const facilityGroups = get(action, 'payload.facilityGroups', []);
      return [...facilityGroups];
    }
    case LOAD_ALL_FACILITY_GROUPS.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
