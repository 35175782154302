import initialState from './initialState';
import get from 'lodash/get';
import * as storeConstants from '../store/constants';
import {
  CREATE_SHIFT_REQUEST,
  CLEAR_SHIFT_REQUEST,
  LOAD_SHIFT_REQUEST_BY_ID
} from '../actions/actionTypes';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.SHIFT_REQUEST],
  action
) {
  switch (action.type) {
    case LOAD_SHIFT_REQUEST_BY_ID.SUCCESS: {
      const shiftRequest = get(action, 'payload.shiftRequest', null);
      const { meta } = action;
      return {
        ...state,
        [meta.fingerprint]: shiftRequest
      };
    }
    case CLEAR_SHIFT_REQUEST: {
      return {};
    }
    case LOAD_SHIFT_REQUEST_BY_ID.FAILURE:
    case CREATE_SHIFT_REQUEST.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
