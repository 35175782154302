import styled from 'styled-components';

export const StyledCheckboxContainer = styled.div`
    .MuiCheckbox-colorSecondary.Mui-checked{
        color: ${({ theme }) => theme.colors.name.main_color_1};
    }
`;

export const StyledContainer = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    min-height: 20px;
    margin-left: 5px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: ${({ theme }) => theme.colors.name.white};
        border: 1px solid ${({ theme }) => theme.colors.name.sectiondarkgrey};
        border-radius: 5px;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: ${({ theme }) => theme.colors.name.sectiongrey};
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .checkmark {
        background-color: ${({ theme }) => theme.colors.name.main_color_1};
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    & input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    & .checkmark:after {
        left: 7px;
        top: 3px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }      
`;