import headerLogo from 'assets/kaufmanLynn/KL-logo.png';
import colors from './colors';

const icons = {
  header: {
    logo: headerLogo,
    width: 'auto',
    height: '70px',
    background: colors.name.white
  },
  signIn:{
    logo: headerLogo,
    width: '',
    height: '',
  }
};

export default icons;