/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */

import { MANAGER, ONSITE_MANAGER } from 'common/constants/employerConstants';
import {
  CompanyNavIcon,
  EmployersNavIcon,
  FacilitiesNavIcon,
  HomeNavIcon,
  JobsNavIcon,
  LocationsNavIcon,
  MessagesNavIcon,
  ReportsNavIcon,
  WorkforceNavIcon
} from 'components/common/icons';
import getText from 'context/language/getText';
import { urlMap } from 'routes/urlMap';

export default [
  {
    pages: [
      {
        href: urlMap.HOME,
        title: getText('navbar.home'),
        icon: HomeNavIcon,
        restrictedRoles: []
      },
      {
        href: urlMap.MANAGEMENT,
        title: getText('navbar.management'),
        icon: JobsNavIcon,
        children: [
          {
            href: urlMap.SHIFT_REQUESTS_LIST,
            title: getText('navbar.shiftRequests'),

            restrictedRoles: []
          },
          {
            href: urlMap.SHIFTS_LIST,
            title: getText('navbar.shifts'),

            restrictedRoles: []
          },
          {
            href: urlMap.JOBS,
            title: getText('navbar.jobs'),

            restrictedRoles: []
          },
          {
            href: urlMap.TASKS_LIST,
            title: getText('navbar.tasks'),

            restrictedRoles: []
          },
          {
            href: urlMap.DRAFT_SHIFT_REQUESTS,
            title: getText('navbar.draftShiftRequests'),

            restrictedRoles: []
          }
        ]
      },
      {
        href: urlMap.LIST_REPORTS,
        title: getText('navbar.reports'),
        icon: ReportsNavIcon,
        restrictedRoles: [],
        children: [
          {
            href: urlMap.REPORTS,
            title: getText('navbar.jobReports'),

            restrictedRoles: []
          },
          {
            href: urlMap.DASHBOARD,
            title: getText('navbar.dashboard'),

            restrictedRoles: [MANAGER, ONSITE_MANAGER]
          }
        ]
      },
      {
        href: urlMap.MESSAGES,
        title: getText('navbar.messages'),
        icon: MessagesNavIcon,
        restrictedRoles: []
      },
      {
        href: urlMap.WORKFORCE,
        title: getText('navbar.workforce'),
        icon: WorkforceNavIcon,
        children: [
          {
            href: urlMap.WORKERS_LIST,
            title: getText('navbar.workers'),

            restrictedRoles: []
          },
          {
            href: urlMap.TEAMS_LIST,
            title: getText('navbar.teams'),

            restrictedRoles: []
          },
          {
            href: urlMap.BADGES,
            title: getText('navbar.badges'),

            restrictedRoles: []
          },
          {
            href: urlMap.ONBOARDING,
            title: getText('navbar.onboarding'),

            restrictedRoles: [MANAGER, ONSITE_MANAGER]
          }
        ]
      },
      {
        href: urlMap.EMPLOYERS_LIST,
        title: getText('navbar.employers'),
        icon: EmployersNavIcon,
        restrictedRoles: [ONSITE_MANAGER]
      },
      {
        href: urlMap.LOCATIONS,
        title: getText('navbar.locations'),
        icon: LocationsNavIcon,
        restrictedRoles: []
      },
      {
        href: urlMap.FACILITIES,
        title: getText('navbar.facilities'),
        icon: FacilitiesNavIcon,
        restrictedRoles: [MANAGER, ONSITE_MANAGER]
      },
      {
        href: urlMap.COMPANY,
        title: getText('navbar.company'),
        icon: CompanyNavIcon,
        restrictedRoles: [MANAGER, ONSITE_MANAGER]
      }
    ]
  }
];
