import get from 'lodash/get';
import {
  SEARCH_SHIFT_BY_NAME,
  LOAD_ALL_SHIFTS,
  LOAD_SHIFT_BY_ID,
  RESEND_NOTIFICATION
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { showErrors } from './helpers';
import { SHOW_MORE_SHIFTS_FINGERPRINT } from 'common/constants/fingerprintConstants';

export default function(state = initialState[storeConstants.SHIFTS], action) {
  switch (action.type) {
    case SEARCH_SHIFT_BY_NAME.SUCCESS: {
      const shifts = get(action, 'payload.shifts', []);
      return [...shifts];
    }
    case LOAD_ALL_SHIFTS.SUCCESS: {
      const allShifts = get(action, 'payload.shifts', []);
      const fingerprint = get(action, 'meta.fingerprint');
      let isLoadMore = fingerprint === SHOW_MORE_SHIFTS_FINGERPRINT;

      const shifts = isLoadMore ? state.concat(allShifts) : allShifts;
      return [...shifts];
    }
    case LOAD_SHIFT_BY_ID.SUCCESS: {
      const shift = get(action, 'payload.shift', {});
      let shifts = [];
      shifts.push(shift);
      return [...shifts];
    }
    case RESEND_NOTIFICATION.FAILURE:
    case LOAD_SHIFT_BY_ID.FAILURE:
    case LOAD_ALL_SHIFTS.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
