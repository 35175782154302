/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse, Typography } from '@material-ui/core';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import styled from 'styled-components';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { getUserAuthentication, getUser } from 'redux/selectors';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { EMPLOYER_TITLE } from 'common/constants/employerConstants';
import { observer } from 'common/mobx.decorator';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

export const WrapperListItem = styled(ListItem)`
  button {
    outline: none !important;
  }
  .MuiCollapse-wrapperInner {
    .title-item {
      text-transform: capitalize;
    }
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 30px !important;
  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
`;

const useStyles = makeStyles(theme => ({
  title: {
    marginLeft: 5
  },

  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: '#E4E4E4',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '28px',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    marginTop: '5px',
    borderRadius: 16,
    letterSpacing: 0,
    width: '100%',
    border: 'none',
    outline: 'none',
    '&:active': {
      outline: 'none',
      border: 'none'
    },
    '&:hover': {
      outline: 'none',
      border: 'none',
      borderRadius: 16,
      background: 'rgba(255, 255, 255, 0.2)',
      color: 'white',
      '& svg': {
        fill: 'white'
      }
    }
  },
  buttonLeaf: {
    marginTop: '5px',
    color: '#E4E4E4',
    padding: '10px 8px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '28px',
    justifyContent: 'flex-start',
    borderRadius: 16,
    letterSpacing: 0,
    border: 'none',
    outline: 'none',
    width: '100%',
    '&:active': {
      border: 'none',
      outline: 'none'
    },
    '&:hover': {
      borderRadius: 16,
      border: 'none',
      outline: 'none',
      background: 'rgba(255, 255, 255, 0.2)',
      color: 'white',
      '& svg': {
        fill: 'white'
      }
    },
    '&.depth-0': {
      fontWeight: 400
    }
  },
  icon: {
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  active: {
    color: '#FF3D3E',
    fontWeight: 400,
    '& svg': {
      fill: '#FF3D3E'
    }
  }
}));

const NavigationListItem = observer(props => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    user,
    authenticated,
    restrictedRoles,
    ...rest
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft
  };
  const currentRole = !isEmpty(user) ? user.employer[EMPLOYER_TITLE] : null;
  if (children) {
    return (
      !restrictedRoles?.includes(currentRole) &&
      authenticated && (
        <WrapperListItem
          {...rest}
          className={clsx(classes.item, className)}
          disableGutters
        >
          <Button
            className={classes.button}
            onClick={handleToggle}
            style={style}
          >
            {Icon && (
              <StyledListItemIcon>
                <Icon className={classes.icon} />
              </StyledListItemIcon>
            )}
            <Typography className={clsx(classes.title, 'title-item')}>
              {title}
            </Typography>
            {open ? (
              <ExpandLessIcon className={classes.expandIcon} color="inherit" />
            ) : (
              <ChevronRightIcon
                className={classes.expandIcon}
                color="inherit"
              />
            )}
          </Button>
          <Collapse in={open}>{children}</Collapse>
        </WrapperListItem>
      )
    );
  } else {
    return (
      !restrictedRoles?.includes(currentRole) &&
      authenticated && (
        <WrapperListItem
          {...rest}
          className={clsx(classes.itemLeaf, className)}
          disableGutters
        >
          <Button
            activeClassName={classes.active}
            className={clsx(classes.buttonLeaf, `depth-${depth}`)}
            component={CustomRouterLink}
            exact
            style={style}
            to={href}
          >
            {Icon && (
              <StyledListItemIcon>
                <Icon className={classes.icon} />
              </StyledListItemIcon>
            )}
            <span className={clsx(classes.title, 'title-item')}> {title}</span>
            {Label && (
              <span className={classes.label}>
                <Label />
              </span>
            )}
          </Button>
        </WrapperListItem>
      )
    );
  }
});

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false
};

function mapStateToProps(state) {
  return {
    authenticated: getUserAuthentication(state),
    user: getUser(state)
  };
}

export default connect(mapStateToProps)(NavigationListItem);
