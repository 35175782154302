import { LOAD_WORKER_LOCATIONS } from 'redux/actions/actionTypes';
import { getAllWorkerLocations } from 'api/workerLocations';
import {
  loadWorkerLocationsSuccess,
  loadWorkerLocationsFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const workerLocations = store => next => action => {
  switch (action.type) {
    case LOAD_WORKER_LOCATIONS.REQUEST: {
      next(action);
      const { workerIds } = action.payload;
      return apiPromise(
        store,
        action,
        getAllWorkerLocations,
        [workerIds],
        loadWorkerLocationsSuccess,
        loadWorkerLocationsFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default workerLocations;
