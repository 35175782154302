import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'components/common/imageLink/imageLink';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { getUser } from 'redux/selectors';
import { StyledPadding } from './styledHeaderContainer';
import clsx from 'clsx';

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    zIndex: 100,
    height: 65,
    boxSizing: 'border-box',
    transition: 'all 0.3s ease'
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0
  },
  logoWrapper: {
    position: 'absolute',
    left: 'calc(50% - 77px)'
  }
}));

const ResponsiveDrawer = ({
  authenticated,
  globalTheme,
  showNavbar,
  user,
  onOpenNavBarMobile,
  ...props
}) => {
  const classes = useStyles();

  const logo = globalTheme.icons.header.logo;

  return (
    <div className={'drawer-root'}>
      <AppBar position="fixed" className={clsx(classes.appBar, 'Cmt-header')}>
        <Toolbar>
          {authenticated && (
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={onOpenNavBarMobile}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
          )}
          {!authenticated && <StyledPadding />}

          <Logo className={clsx(classes.logoWrapper, 'logo')} src={logo} />
        </Toolbar>
      </AppBar>
    </div>
  );
};
ResponsiveDrawer.propTypes = {
  container: PropTypes.object,
  user: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

export default connect(mapStateToProps, null)(ResponsiveDrawer);
