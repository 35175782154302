import get from 'lodash/get';
import {
  LOAD_ALL_SHIFT_REQUESTS,
  LOAD_ALL_SHIFTS,
  LOAD_ALL_REQUEST_TASKS,
  LOAD_REPORT_BY_WORKER_ID,
  LOAD_ALL_WORKERS
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import {
  SHIFT_REQUEST_LIST_LOAD_MORE_FINGERPRINT,
  SHIFT_LIST_LOAD_MORE_FINGERPRINT,
  TASK_LIST_LOAD_MORE_FINGERPRINT,
  SHOW_WORKERS_ON_REPORTS_FINGERPRINT,
  SHOW_MORE_WORKERS_FINGERPRINT,
  SHOW_MORE_REPORTS_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import isEmpty from 'lodash/isEmpty';

export default function(
  state = initialState[storeConstants.LOAD_MORE],
  action
) {
  switch (action.type) {
    case LOAD_ALL_SHIFT_REQUESTS.SUCCESS: {
      const shiftRequests = get(action, 'payload.shiftRequests', []);
      let isLoadMore = isEmpty(shiftRequests) ? false : true;

      return {
        ...state,
        [SHIFT_REQUEST_LIST_LOAD_MORE_FINGERPRINT]: isLoadMore
      };
    }
    case LOAD_ALL_SHIFTS.SUCCESS: {
      const shifts = get(action, 'payload.shifts', []);
      let isLoadMore = isEmpty(shifts) ? false : true;

      return {
        ...state,
        [SHIFT_LIST_LOAD_MORE_FINGERPRINT]: isLoadMore
      };
    }
    case LOAD_ALL_REQUEST_TASKS.SUCCESS: {
      const requestTasks = get(action, 'payload.requestTasks', []);
      let isLoadMore = isEmpty(requestTasks) ? false : true;

      return {
        ...state,
        [TASK_LIST_LOAD_MORE_FINGERPRINT]: isLoadMore
      };
    }
    case LOAD_ALL_WORKERS.SUCCESS: {
      const fingerprint = get(action, 'meta.fingerprint');
      let isJobReport = fingerprint === SHOW_WORKERS_ON_REPORTS_FINGERPRINT;

      const workers = get(action, 'payload.workers', []);
      let isLoadMore = false;
      if (!isEmpty(workers) && isJobReport) isLoadMore = true;

      return {
        ...state,
        [SHOW_MORE_WORKERS_FINGERPRINT]: isLoadMore
      };
    }
    case LOAD_REPORT_BY_WORKER_ID.SUCCESS: {
      const reports = get(action, 'payload.reports', []);
      let isLoadMore = isEmpty(reports) ? false : true;

      return {
        ...state,
        [SHOW_MORE_REPORTS_FINGERPRINT]: isLoadMore
      };
    }
    default:
      return state;
  }
}
