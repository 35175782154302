import get from 'lodash/get';
import { postAssetMetadata, uploadAsset } from './uploadAsset';


const uploadCroppedImage = async (
  imageFormData,
  cropX,
  cropY,
  cropWidth,
  cropHeight
) => {
  const imageMetadata = `cropDimensions: {
      x: ${cropX},
      y: ${cropY},
      width: ${cropWidth},
      height: ${cropHeight}
    }`;

  const assetMetaData = await postAssetMetadata(imageMetadata);
  const imageId = get(assetMetaData, 'createAssetMetadata.id');
  imageFormData.append('id', imageId);

  return uploadAsset(imageFormData);
};

export default uploadCroppedImage;
