import { gql } from '@apollo/client';

export const GET_FACILITIES_QUERY = gql`
  query Facilities($companyId: String, $filter: FacilityFilters, $limit: Int) {
    facilities(companyId: $companyId, filter: $filter, limit: $limit) {
      id
      name
      description
      active
      workplaceImageUrl
      facilityGroup {
        id
        name
        description
        workplaceImageUrl
      }
    }
  }
`;

export const GET_FACILITIES_GROUP_QUERY = gql`
  query FacilityGroups($facilityGroupId: String!) {
    facilityGroups(companyId: $facilityGroupId) {
      id
      name
      description
      companyId
      workplaceImageUrl
    }
  }
`;
