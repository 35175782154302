import { apolloClient } from 'common/apollo/index.js';
import { CREATE_DIRECT_UPLOAD } from 'common/apollo/mutations/employer.mutation.js';
import { captureException } from 'common/helpers/captureEvent.js';
import { calculateChecksum, directUpload } from 'common/helpers/fileHelper.js';
import { action, observable, store } from 'common/mobx.decorator';
@store()
class ImageUploadStore {
  @observable uploadImage = null;
  @observable commonImageData = null;
  @observable signedBlobIdData = '';
  @action
  changeUploadImage = uploadImage => {
    if (this.uploadImage === uploadImage) {
      return;
    }
    this.uploadImage = uploadImage;
  };
  @action
  handleDirectUpload = async value => {
    await directUpload(
      value.credentials.url,
      JSON.parse(value.credentials.headers),
      value.file
    );

    return value;
  };

  @action
  handleCreateCredentials = async imageValue => {
    try {
      const file = imageValue;
      const checksum = await calculateChecksum(file);
      const { data } = await apolloClient.mutate({
        mutation: CREATE_DIRECT_UPLOAD,
        variables: {
          filename: file.name,
          byteSize: file.size,
          checksum: checksum,
          contentType: file.type
        }
      });

      const obj = {
        ...imageValue,
        file: file,
        credentials: data.createDirectUpload,
        isUploaded: false
      };

      // await this.handleDirectUpload(obj);
      this.commonImageData = await {
        ...obj,
        filename: file.name,
        byteSize: file.size,
        checksum: checksum,
        contentType: file.type
      };
      this.signedBlobIdData = await data?.createDirectUpload?.signedBlobId;
      this.uploadImage = await obj;
      return obj;
    } catch (error) {
      captureException('Upload image', error);
    }
  };
}

export const imageUploadStore = new ImageUploadStore();
