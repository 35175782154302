import React from 'react';
import ContentArea from 'modules/contentArea/contentArea';
import { withTheme } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapperContent: props => ({
    marginTop: props.renderFullScreen ? 0 : '60px',
    paddingLeft: props.renderFullScreen ? 0 : '250px',
    '@media (max-width: 1279px)': {
      marginTop: props.renderFullScreen ? 0 : '40px',
      paddingLeft: '0'
    }
  })
}));

const ContentWrapper = props => {
  const renderFullScreen = props?.renderFullScreen;
  const classes = useStyles({ renderFullScreen });
  return (
    <div className={classes.wrapperContent}>
      <ContentArea {...props} />
    </div>
  );
};

export default withTheme(ContentWrapper);
