import {
  LOAD_ALL_LOCATIONS,
  ADD_NEW_LOCATION,
  UPDATE_LOCATION,
  REMOVE_LOCATION
} from 'redux/actions/actionTypes';
import {
  getAllLocations,
  addNewLocation,
  updateLocation,
  removeLocation
} from 'api/locations';
import {
  loadAllLocationsSuccess,
  loadAllLocationsFailure,
  addNewLocationSuccess,
  addNewLocationFailure,
  updateLocationSuccess,
  updateLocationFailure,
  removeLocationSuccess,
  removeLocationFailure
} from 'redux/actions';
import { apiPromise } from 'redux/middleware/apiPromise';

export const locations = store => next => action => {
  switch (action.type) {
    case LOAD_ALL_LOCATIONS.REQUEST: {
      next(action);
      const { companyId, search, filter } = action.payload;
      return apiPromise(
        store,
        action,
        getAllLocations,
        [companyId, 0, 100, search, null, filter],
        loadAllLocationsSuccess,
        loadAllLocationsFailure
      );
    }
    case ADD_NEW_LOCATION.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        addNewLocation,
        [values],
        addNewLocationSuccess,
        addNewLocationFailure
      );
    }
    case UPDATE_LOCATION.REQUEST: {
      next(action);
      const { values } = action.payload;
      return apiPromise(
        store,
        action,
        updateLocation,
        [values],
        updateLocationSuccess,
        updateLocationFailure
      );
    }
    case REMOVE_LOCATION.REQUEST: {
      next(action);
      const { locationId } = action.payload;
      return apiPromise(
        store,
        action,
        removeLocation,
        [locationId],
        removeLocationSuccess,
        removeLocationFailure
      );
    }
    default:
      next(action);
      return Promise.resolve();
  }
};

export default locations;
