import get from 'lodash/get';
import {
  SEARCH_EMPLOYER_BY_NAME,
  LOAD_ALL_EMPLOYERS,
  ADD_NEW_EMPLOYER,
  UPDATE_EMPLOYER,
  RESEND_INVITES
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(
  state = initialState[storeConstants.EMPLOYERS],
  action
) {
  let employers = [];
  switch (action.type) {
    case SEARCH_EMPLOYER_BY_NAME.SUCCESS: {
      employers = get(action, 'payload.filterEmployers', []);
      return [...employers];
    }
    case LOAD_ALL_EMPLOYERS.SUCCESS: {
      employers = get(action, 'payload.employers', []);
      return [...employers];
    }
    case ADD_NEW_EMPLOYER.SUCCESS: {
      const newEmployer = get(action, 'payload.createEmployer', []);
      employers = state.concat(newEmployer);

      toast.success(getText('employerModal.addEmployerSuccess'), {
        className: 'alert alert-success'
      });
      return [...employers];
    }
    case UPDATE_EMPLOYER.SUCCESS: {
      const updatedEmployer = get(action, 'payload.updateEmployer', []);

      employers = state.map(item => {
        if (item.id === updatedEmployer.id) {
          return updatedEmployer;
        }
        return item;
      });

      toast.success(getText('employerModal.updateEmployerSuccess'), {
        className: 'alert alert-success'
      });
      return [...employers];
    }
    case RESEND_INVITES.FAILURE:
    case SEARCH_EMPLOYER_BY_NAME.FAILURE:
    case LOAD_ALL_EMPLOYERS.FAILURE:
    case ADD_NEW_EMPLOYER.FAILURE:
    case UPDATE_EMPLOYER.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
