import {
  graphQueryPromise,
  handleUpdateString,
  graphMutationPromise
} from './helpers';
import * as BADGE from 'common/constants/badgeConstants';
import * as WORKER from 'common/constants/workerConstants';
import isEmpty from 'lodash/isEmpty';

const badgesFields = [
  BADGE.ID,
  BADGE.NAME,
  BADGE.DESCRIPTION,
  BADGE.ICON_URL,
  BADGE.CREATED_AT,
  BADGE.ACTIVE,
  BADGE.IS_GROUP,
  BADGE.IS_ONBOARDING_BADGE
];

const addbadgeFields = [
  BADGE.COMPANY_ID,
  BADGE.NAME,
  BADGE.DESCRIPTION,
  BADGE.ICON_BASE64,
  BADGE.ICON_FILENAME,
  BADGE.ICON_SIGNED_BLOB
];

const updatebadgeFields = [
  BADGE.ID,
  BADGE.NAME,
  BADGE.DESCRIPTION,
  BADGE.ICON_BASE64,
  BADGE.ICON_FILENAME,
  BADGE.ACTIVE,
  BADGE.ICON_SIGNED_BLOB
];

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_EMAIL,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE,
  WORKER.WORKER_OVERALL_RATING,
  WORKER.WORKER_LAST_SIGN_IN_AT
];

const hierarchyBadgesFieds = [BADGE.ID, BADGE.BADGE_ID, BADGE.CHILD_ID];

export const getAllBadges = (
  companyId,
  offset = 0,
  limit = 20,
  search = '',
  sort = '',
  filter = ''
) => {
  const badges_Fields = badgesFields.join(',');
  const hierarchy_Badges_Fields = hierarchyBadgesFieds.join(',');

  const query = `{
        badges(companyId: "${companyId}", offset: ${offset}, limit: ${limit} ${search} ${sort} ${filter} )
        {
            ${badges_Fields}
            children{
              ${badges_Fields}
            }
            hierarchyBadges{
              ${hierarchy_Badges_Fields}
            }
        }
    }`;

  return graphQueryPromise(query);
};

export const addNewBadge = values => {
  const badges_Fields = badgesFields.join(',');
  const addNew = handleUpdateString(values, addbadgeFields, true);

  let hierarchyBadgesAttributes = '';
  if (!isEmpty(values[BADGE.HIERARCHY_BADGES_ATTRIBUTE])) {
    let output = '';
    values[BADGE.HIERARCHY_BADGES_ATTRIBUTE].map((obj, index) => {
      const childId = obj.childId;
      output += `{ childId : "${childId}" },`;

      return null;
    });
    hierarchyBadgesAttributes = `hierarchyBadgesAttributes: [${output}]`;
  }

  const mutation = `mutation {
      createBadge(${addNew} ${hierarchyBadgesAttributes}) {
        ${badges_Fields}
      }
    }`;

  return graphMutationPromise(mutation);
};

export const updateBadge = values => {
  const badges_Fields = badgesFields.join(',');
  const specialField = [BADGE.ACTIVE];
  const update = handleUpdateString(
    values,
    updatebadgeFields,
    false,
    specialField
  );

  let hierarchyBadgesAttributes = '';
  if (!isEmpty(values[BADGE.HIERARCHY_BADGES_ATTRIBUTE])) {
    let output = '';
    values[BADGE.HIERARCHY_BADGES_ATTRIBUTE].map((obj, index) => {
      const idStr = obj.id ? `id: "${obj.id}", ` : '';
      const childIdStr = `childId: "${obj.childId}", `;
      const destroyStr = obj.destroy ? `_destroy : ${obj.destroy}, ` : '';

      output += '{' + idStr + childIdStr + destroyStr + '},';

      return null;
    });
    hierarchyBadgesAttributes = `hierarchyBadgesAttributes: [${output}]`;
  }

  const mutation = `mutation {
      updateBadge(${update} ${hierarchyBadgesAttributes}) {
        ${badges_Fields}
      }
    }`;

  return graphMutationPromise(mutation);
};

export const assignWorkerBadge = values => {
  const workers_Fields = workerFields.join(',');
  const badges_Fields = badgesFields.join(',');
  const workerInfo = `workerId: "${values[BADGE.WORKER_ID]}",`;

  let workerBadgesAttributes = '';
  if (!isEmpty(values[BADGE.WORKER_BADGES])) {
    let output = '';
    values[BADGE.WORKER_BADGES].map((obj, index) => {
      const idStr = `id: "${obj.id}", `;
      const destroyStr = obj.destroy ? `_destroy : ${obj.destroy}, ` : '';
      const badgeIdStr = `badgeId: "${obj.badgeId}", `;
      const noteStr = !obj.destroy ? `note: "${obj.note}", ` : '';
      const expiredAtStr = !obj.destroy ? `expiredAt: "${obj.expiredAt}" ` : '';

      output +=
        '{' + idStr + badgeIdStr + noteStr + destroyStr + expiredAtStr + '},';

      return null;
    });
    workerBadgesAttributes = `workerBadgesAttributes: [${output}]`;
  }

  const mutation = `mutation {
        assignWorkerBadge(${workerInfo} ${workerBadgesAttributes}) {
          ${workers_Fields}
          badges{
            ${badges_Fields}
          }
        }
      } `;

  return graphMutationPromise(mutation);
};

export const assignMultiWorkerBadge = values => {
  let workerIds = '';
  if (!isEmpty(values[BADGE.WORKER_IDS])) {
    values[BADGE.WORKER_IDS].map((obj, index) => {
      workerIds += `"${obj.id}", `;
      return null;
    });
  }
  const workerInfo = `workerIds: [${workerIds}], `;

  let workerBadgesAttributes = '';
  if (!isEmpty(values[BADGE.WORKER_BADGES])) {
    let output = '';
    values[BADGE.WORKER_BADGES].map((obj, index) => {
      const badgeId = obj.badgeId;
      const note = obj.note;
      const expiredAt = obj.expiredAt;

      output +=
        `{
        badgeId: "${badgeId}", ` +
        `note: "${note}", ` +
        `expiredAt: "${expiredAt}"
      }, `;

      return null;
    });
    workerBadgesAttributes = `workerBadgesAttributes: [${output}]`;
  }

  const mutation = `mutation {
        assignMultiWorkerBadge(${workerInfo} ${workerBadgesAttributes}) {
          success
          message
        }
      } `;

  return graphMutationPromise(mutation);
};
