import { store, observable, action } from 'common/mobx.decorator';
import { apolloClient } from 'common/apollo/index.js';
import {
  GET_EMPLOYER_SUMMARY,
  GET_EMPLOYER_INIT
} from 'common/apollo/queries/employer.query.js';
import {
  GET_LOCATIONS,
  GET_WORKERS_LOCATION
} from 'common/apollo/queries/location.query.js';
import { GET_FACILITIES_QUERY } from 'common/apollo/queries/facility.query.js';
import { GET_ACTIVE_JOBS } from 'common/apollo/queries/job.query.js';

@store()
class DashboardStore {
  @observable employerSummary = {};
  @observable shifts = {};
  @observable jobs = {};
  @observable workers = {};
  @observable reports = {};
  @observable onboardingSubmissions = [];
  @observable locations = null;
  @observable workerLocations = null;
  @observable activeJobs = null;
  @observable employeInit = null;
  @observable dateRanges = null;
  @observable facilities = null;

  @action
  reset = () => {
    this.employerSummary = {};
    this.shifts = {};
    this.jobs = {};
    this.workers = {};
    this.reports = {};
    this.onboardingSubmissions = [];
    this.locations = null;
    this.workerLocations = null;
    this.activeJobs = [];
    this.employeInit = null;
    this.dateRanges = null;
    this.facilities = null;
  };

  @action
  fetchDashboardInfo = async () => {
    const response = await apolloClient.query({
      query: GET_EMPLOYER_SUMMARY
    });

    this.employerSummary = response?.data?.employerSummary;
    this.shifts = response?.data?.employerSummary?.shifts;
    this.jobs = response?.data?.employerSummary?.jobs;
    this.workers = response?.data?.employerSummary?.workers;
    this.reports = response?.data?.employerSummary?.reports;
    this.dateRanges = response?.data?.employerSummary?.dateRanges;
    this.onboardingSubmissions =
      response?.data?.employerSummary?.onboardingSubmissions;
    return response.data?.employerSummary;
  };

  @action
  fetchLocations = async id => {
    const response = await apolloClient.query({
      query: GET_LOCATIONS,
      variables: {
        companyId: this.employeInit?.company?.id,
        facilityId: id
      }
    });

    this.locations = response?.data?.locations;
  };

  @action
  fetchWorkerLocations = async workerIds => {
    const response = await apolloClient.query({
      query: GET_WORKERS_LOCATION,
      variables: {
        workerIds: workerIds
      }
    });
    this.workerLocations = response?.data?.workerCurrentLocations;
  };

  @action
  fetchActiveJobs = async data => {
    const { locationId, searchTerm } = data;
    const response = await apolloClient.query({
      query: GET_ACTIVE_JOBS,
      variables: {
        locationId: locationId,
        keywordSearch: searchTerm,
        limit: 100,
        offset: 0
      }
    });
    this.activeJobs = response?.data?.employerSummary?.jobs?.activeJobs;
  };

  @action
  fetchEmployeInit = async () => {
    const response = await apolloClient.query({
      query: GET_EMPLOYER_INIT
    });
    this.employeInit = response?.data?.employerAppInit?.employer;
  };
  @action
  fetchFacilities = async () => {
    const response = await apolloClient.query({
      query: GET_FACILITIES_QUERY,
      variables: {
        companyId: this.employeInit?.company?.id,
        limit: 100,
        filter: {
          active: true
        }
      }
    });
    this.facilities = response?.data?.facilities;
  };
}

export const dashboardStore = new DashboardStore();
