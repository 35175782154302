import get from 'lodash/get';
import {
  LOAD_ALL_SHIFT_REQUESTS,
  CANCEL_SHIFT_REQUEST,
  UPDATE_SHIFT,
  CREATE_SHIFT_REQUEST,
  CANCEL_SHIFT,
  CREATE_JOB,
  UPDATE_SHIFT_REQUEST,
  UPDATE_REQUEST_TASK,
  SEARCH_SHIFT_REQUESTS,
  CLEAR_SHIFT_REQUESTS,
  CREATE_JOB_RATING,
  UPDATE_JOB_RATING
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import * as SHIFT from 'common/constants/shiftConstants';
import * as SHIFT_REQUEST from 'common/constants/shiftRequestConstants';
import getText from 'context/language/getText';
import { toast } from 'react-toastify';
import {
  CANCEL_SHIFT_REQUEST_FINGERPRINT,
  SHOW_MORE_SHIFT_REQUESTS_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import isEmpty from 'lodash/isEmpty';
import { showErrors } from './helpers';

export default (
  state = initialState[storeConstants.SHIFT_REQUESTS],
  action
) => {
  switch (action.type) {
    case LOAD_ALL_SHIFT_REQUESTS.SUCCESS: {
      const allShiftRequests = get(action, 'payload.shiftRequests', []);
      const fingerprint = get(action, 'meta.fingerprint');
      const isLoadMore = fingerprint === SHOW_MORE_SHIFT_REQUESTS_FINGERPRINT;

      const shiftRequests = isLoadMore
        ? state.concat(allShiftRequests)
        : allShiftRequests;
      return [...shiftRequests];
    }
    case LOAD_ALL_SHIFT_REQUESTS.FAILURE: {
      return state;
    }
    case SEARCH_SHIFT_REQUESTS.SUCCESS: {
      const searchShiftRequests = get(action, 'payload.shiftRequests', []);
      return searchShiftRequests;
    }
    case CREATE_SHIFT_REQUEST.SUCCESS: {
      toast.success(getText('newShift.createSuccess'), {
        className: 'alert alert-success'
      });

      const newShiftRequest = get(action, 'payload.createShiftRequest', null);
      const shiftRequests = state.concat(newShiftRequest);
      const uniqShiftRequests = shiftRequests.filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i
      );

      return [...uniqShiftRequests];
    }
    case UPDATE_SHIFT_REQUEST.SUCCESS: {
      const updatedShiftRequest = get(action, 'payload.updateShiftRequest', {});

      const shiftRequests = state.map(shiftRequest => {
        if (shiftRequest.id === updatedShiftRequest[SHIFT_REQUEST.ID]) {
          return updatedShiftRequest;
        }
        return shiftRequest;
      });

      const fingerprint = get(action, 'meta.fingerprint');
      let message = getText('editShiftRequest.updateShiftRequestSuccess');
      if (fingerprint === CANCEL_SHIFT_REQUEST_FINGERPRINT) {
        message = getText('shiftsList.cancelShiftRequestSuccess');
      }
      toast.success(message, { className: 'alert alert-success' });

      return [...shiftRequests];
    }
    case UPDATE_SHIFT.SUCCESS: {
      const updatedShift = get(action, 'payload.updateShift', {});

      const shiftRequests = state.map(shiftRequest => {
        if (shiftRequest.id === updatedShift[SHIFT.SHIFT_REQUEST_ID]) {
          let updatedShiftRequest = shiftRequest;
          const updatedShifts = shiftRequest.shifts.map(shift => {
            if (shift.id === updatedShift[SHIFT.ID]) {
              return updatedShift;
            }
            return shift;
          });
          updatedShiftRequest.shifts = updatedShifts;

          return updatedShiftRequest;
        }
        return shiftRequest;
      });

      let message = getText('editShift.updateShiftSuccess');
      toast.success(message, { className: 'alert alert-success' });

      return [...shiftRequests];
    }
    case UPDATE_REQUEST_TASK.SUCCESS: {
      const updatedRequestTask = get(action, 'payload.updateRequestTask', {});
      let message = getText('shiftsList.updateRequestTaskSuccess');

      let updateShiftRequests = state.map(shiftRequest => {
        if (shiftRequest.id === updatedRequestTask.shiftRequestId) {
          let updateShiftRq = shiftRequest;

          updateShiftRq[SHIFT_REQUEST.REQUEST_TASKS] = shiftRequest[
            SHIFT_REQUEST.REQUEST_TASKS
          ].map(requestTask => {
            if (requestTask.id === updatedRequestTask.id) {
              return updatedRequestTask;
            }
            return requestTask;
          });
          return updateShiftRq;
        }
        return shiftRequest;
      });

      toast.success(message, { className: 'alert alert-success' });

      return [...updateShiftRequests];
    }
    case CREATE_JOB.SUCCESS: {
      const createdJob = get(action, 'payload.createJob', {});
      let shiftRequestId = get(action, 'meta.shiftRequestId', '');
      let shiftId = get(action, 'meta.shiftId', '');

      const shiftRequests = state.map(shiftRequest => {
        if (shiftRequest.id === shiftRequestId) {
          let updatedShiftRequest = shiftRequest;
          const updatedShifts = shiftRequest.shifts.map(shift => {
            if (shift.id === shiftId) {
              let updatedShift = shift;
              updatedShift.jobs = !shift.jobs
                ? [createdJob]
                : shift.job.concat(createdJob);
              return updatedShift;
            }
            return shift;
          });
          updatedShiftRequest.shifts = updatedShifts;

          return updatedShiftRequest;
        }
        return shiftRequest;
      });

      return [...shiftRequests];
    }
    case CREATE_JOB_RATING.SUCCESS: {
      const jobRating = get(action, 'payload.createJobRating', {});
      const { id, rating, note, ratingUserType, job } = jobRating;

      let shiftRequestId = get(action, 'meta.shiftRequestId', '');
      let shiftId = get(action, 'meta.shiftId', '');

      let shiftRequests = state.map(shiftRequest => {
        if (shiftRequest.id === shiftRequestId) {
          let modifyShiftRequest = shiftRequest;
          modifyShiftRequest.shifts = modifyShiftRequest.shifts.map(shift => {
            if (shift.id === shiftId) {
              shift.jobs = shift.jobs.map(item => {
                if (item.id === job.id) {
                  let modifyItem = item;
                  const ratingObj = {
                    id: id,
                    note: note,
                    rating: rating,
                    ratingUserType: ratingUserType
                  };

                  if (!isEmpty(modifyItem.jobRatings)) {
                    modifyItem.jobRatings.push(ratingObj);
                  } else {
                    modifyItem.jobRatings = [ratingObj];
                  }

                  return modifyItem;
                }
                return item;
              });
            }
            return shift;
          });
          return modifyShiftRequest;
        }
        return shiftRequest;
      });

      return [...shiftRequests];
    }
    case UPDATE_JOB_RATING.SUCCESS: {
      const updatedJobRating = get(action, 'payload.updateJobRating', {});
      const { id, rating, note, ratingUserType, job } = updatedJobRating;

      let shiftRequestId = get(action, 'meta.shiftRequestId');
      let shiftId = get(action, 'meta.shiftId');

      let shiftRequests = state.map(shiftRequest => {
        if (shiftRequest.id === shiftRequestId) {
          let modifyShiftRequest = shiftRequest;
          modifyShiftRequest.shifts = modifyShiftRequest.shifts.map(shift => {
            if (shift.id === shiftId) {
              shift.jobs = shift.jobs.map(item => {
                if (item.id === job.id) {
                  let modifyItem = item;

                  const ratingObj = {
                    id: id,
                    note: note,
                    rating: rating,
                    ratingUserType: ratingUserType
                  };

                  modifyItem.jobRatings = modifyItem.jobRatings.map(
                    ratingItem => {
                      if (ratingItem.id === ratingObj.id) return ratingObj;
                      return ratingItem;
                    }
                  );

                  return modifyItem;
                }
                return item;
              });
            }
            return shift;
          });
          return modifyShiftRequest;
        }
        return shiftRequest;
      });

      return [...shiftRequests];
    }
    case CREATE_JOB_RATING.FAILURE:
    case UPDATE_JOB_RATING.FAILURE:
    case UPDATE_SHIFT.FAILURE:
    case CANCEL_SHIFT.FAILURE:
    case SEARCH_SHIFT_REQUESTS.FAILURE:
    case UPDATE_SHIFT_REQUEST.FAILURE:
    case CANCEL_SHIFT_REQUEST.FAILURE: {
      showErrors(action);
      return state;
    }
    case CLEAR_SHIFT_REQUESTS: {
      return initialState[storeConstants.SHIFT_REQUESTS];
    }
    default:
      return state;
  }
};
