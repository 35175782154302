import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AppNotification from 'components/common/appNotification/';
import Notifications from 'components/common/notifications/notifications';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getUser } from 'redux/selectors';
import AccountDropdown from './accountDropdown';
import { GET_NOTIFICATIONS, GET_TOTAL_NOTIFICATIONS } from 'common/apollo/queries/notification.query';
import { useQuery } from '@apollo/client';
import { StyledContainer, StyledGridUserInfoDesktop } from './styledAccountInfo';
import styled from 'styled-components';

export const WrapperContent = styled.div`
  background: #FF3D3E;
  border: 2px solid #FFFFFF;
  border-radius: 50%;
 width: 20px;
  height: 20px;
text-align: center;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  padding : 10px;
  position: absolute;
  left: 23px;
`

export const WrapperIcon = styled(IconButton)`
 position: relative;
`


const AccountInfo = ({ user, ...props }) => {
  const { employer } = user;
  const [appNotification, setAppNotification] = useState(false);



  const [notifications, setNotifications] = useState([]);

  const [offset, setOffset] = useState(0);

  const [hasMore, setHasMore] = useState(true);

  const { data ,refetch : refetchTotal } = useQuery(GET_TOTAL_NOTIFICATIONS );

  const { fetchMore , data : dataNotifications, refetch : refetchFetchMore } = useQuery(GET_NOTIFICATIONS);

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification);
    setNotifications(dataNotifications.notifications)
  };


  const getNotifications = async () => {
    setOffset((prevOffset) => prevOffset + 12);

    const { data } = await fetchMore({
      variables: { limit: 20, offset: offset }
    });
    data.notifications.length === 0 && setHasMore(!hasMore);
    setNotifications((prev) => [...prev, ...data.notifications]);
  };

  useEffect(() => {
    getNotifications();
  }, []);
  const totalUnreadNotification = data?.employerAppInit?.totalUnreadNotification;


  return (
    <StyledContainer>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
        spacing={2}
      >
        <StyledGridUserInfoDesktop item xs={6}>
          {employer && (
            <Dropdown
              className="quick-menu"
              isOpen={appNotification}
              toggle={onAppNotificationSelect}
            >
              <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown"
              >
                <WrapperIcon className="icon-btn">
                  <i className="zmdi zmdi-notifications-none icon-alert" />
                  {
                    totalUnreadNotification > 0 &&(
                      <WrapperContent>
                        {totalUnreadNotification}
                      </WrapperContent>
                    )
                  }

                </WrapperIcon>
              </DropdownToggle>

              <DropdownMenu right>
                <AppNotification
                  dataNotifications={dataNotifications}
                  setNotifications={setNotifications}
                  refetchTotal={refetchTotal}
                  refetchFetchMore={refetchFetchMore}
                  totalUnreadNotification={totalUnreadNotification}
                  nextFunc={() => getNotifications()}
                  setAppNotification={setAppNotification} hasMore={hasMore}
                  notifications={notifications} />
              </DropdownMenu>
            </Dropdown>
          )}
        </StyledGridUserInfoDesktop>
        {employer && <Notifications  employer={employer} />}

        <StyledGridUserInfoDesktop    style={{
          margin : '4px 0'
        }} item xs={6}>
          {employer && <AccountDropdown    user={employer} {...props} />}
        </StyledGridUserInfoDesktop>
      </Grid>
    </StyledContainer>
  );
};

AccountInfo.propTypes = {
  user: PropTypes.object
};

AccountInfo.defaultProps = {};

function mapStateToProps(state) {
  return {
    user: getUser(state)
  };
}

export default connect(mapStateToProps, null)(AccountInfo);

