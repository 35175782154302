import get from 'lodash/get';
import {
  LOAD_ALL_TEAMS,
  ADD_NEW_TEAM,
  UPDATE_TEAM,
  REMOVE_TEAM
} from '../actions/actionTypes';
import initialState from './initialState';
import * as storeConstants from '../store/constants';
import {
  DEACTIVATE_TEAM_FINGERPRINT,
  UPDATE_TEAM_FINGERPRINT,
  REMOVE_WORKER_OUT_TEAM_FINGERPRINT
} from 'common/constants/fingerprintConstants';
import { toast } from 'react-toastify';
import getText from 'context/language/getText';
import { showErrors } from './helpers';

export default function(state = initialState[storeConstants.TEAMS], action) {
  switch (action.type) {
    case LOAD_ALL_TEAMS.SUCCESS: {
      const teams = get(action, 'payload.teams', []);
      return [...teams];
    }
    case ADD_NEW_TEAM.SUCCESS: {
      const newTeam = get(action, 'payload.createTeam', []);
      const teams = state.concat(newTeam);

      toast.success(getText('teamModal.createTeamSuccess'), {
        className: 'alert alert-success'
      });
      return [...teams];
    }
    case UPDATE_TEAM.SUCCESS: {
      const updateTeam = get(action, 'payload.updateTeam', []);

      const { meta } = action;
      const teams = state.map(item => {
        if (item.id === updateTeam.id) {
          return updateTeam;
        }
        return item;
      });

      let message = '';
      switch (meta.fingerprint) {
        case DEACTIVATE_TEAM_FINGERPRINT:
          message = getText('teamModal.deactivateTeamSuccess');
          break;
        case UPDATE_TEAM_FINGERPRINT:
          message = getText('teamModal.updateTeamSuccess');
          break;
        case REMOVE_WORKER_OUT_TEAM_FINGERPRINT:
          message = getText('teamModal.removeWorkerSuccess');
          break;
        default:
          message = '';
      }

      toast.success(message, { className: 'alert alert-success' });

      return [...teams];
    }
    case REMOVE_TEAM.SUCCESS: {
      const success = get(action, 'payload.destroyTeam.success', {});
      if (success) {
        const teamId = get(action, 'meta.teamId', '');
        let updateTeams = state.filter(team => {
          if (team.id === teamId) {
            return false;
          }
          return team;
        });

        toast.success(getText('teamModal.removeWorkerSuccess'), {
          className: 'alert alert-success'
        });
        return [...updateTeams];
      }
      break;
    }
    case LOAD_ALL_TEAMS.FAILURE:
    case ADD_NEW_TEAM.FAILURE:
    case UPDATE_TEAM.FAILURE:
    case REMOVE_TEAM.FAILURE: {
      showErrors(action);
      return state;
    }
    default:
      return state;
  }
}
