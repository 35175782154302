import { gql } from '@apollo/client';

export const MARK_AS_READ_NOTIFICATION = gql`
    mutation MarkNotificationAsRead($markNotificationAsReadId: String!) {
        markNotificationAsRead(id: $markNotificationAsReadId) {
            success
        }
    }
`;

export const MARK_ALL_READ_NOTIFICATION = gql`
    mutation MarkAllNotificationsAsRead {
        markAllNotificationsAsRead {
            success
        }
    }
`;
export const SEND_BULK_MESSAGE_MUTATION = gql`
    mutation NotifyToWorkersShift($shiftId: String!, $workerIds: [String!]!, $content: String!) {
        notifyToWorkersShift(shiftId: $shiftId, workerIds: $workerIds, content: $content) {
            success
        }
    }
`;