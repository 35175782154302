
import headerLogo from 'assets/opus/logo-white-opus.png';
import signInLogo from 'assets/opus/logo-opus.png';

const icons = {
  header: {
    logo: headerLogo,
    width: '160px',
    height: '43px',
    background: '',
  },
  signIn:{
    logo: signInLogo,
    width: '',
    height: '',
  }
};

export default icons;
