import {
  graphQueryPromise,
  graphMutationPromise,
  handleUpdateString
} from './helpers';
import * as CATEGORY from 'common/constants/shiftCategoryConstants';

const shiftCategoriesFields = [
  CATEGORY.ID,
  CATEGORY.NAME,
  CATEGORY.DESCRIPTION,
  CATEGORY.COMPANY_ID
];

const addShiftCategoriesFields = [
  CATEGORY.NAME,
  CATEGORY.DESCRIPTION,
  CATEGORY.COMPANY_ID
];

export const getAllShiftCategories = companyId => {
  const shiftCategories_Fields = shiftCategoriesFields.join(',');

  const query = `{
        shiftCategories(companyId: "${companyId}" )
        {
            ${shiftCategories_Fields}
        }
    }`;

  return graphQueryPromise(query);
};

export const addNewCategory = values => {
  const shiftCategories_Fields = shiftCategoriesFields.join(',');
  const addNew = handleUpdateString(values, addShiftCategoriesFields, true);

  const query = `mutation {
      createShiftCategory(${addNew} ) {
        ${shiftCategories_Fields}
      }
    }`;

  return graphMutationPromise(query);
};
