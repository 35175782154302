const main_color_1 = '#e31e23'; //'#6f1989'; //Blue ( original: '#2E339B')
const main_color_2 = '#29326A'; //Dark Blue
const main_color_3 = ''; //Blue ( original: '#2536BA')
const main_color_4 = '#071013'; //'#ff4a02'; //Orange
const main_color_5 = '#E55972'; //Pink
const main_color_6 = '#FF5A10'; //Dark Orange
const main_color_7 = '#DBDEFF'; //Gray
const main_color_8 = '#F8F8F8'; //White
const main_color_9 = '#323554'; //Black
const main_color_10 = '#A8ABA8'; //Dark Gray


const yellow = '#FFDC00';
const black = '#071013';
const lightestgrey = '#f9f9f9';
const lightgrey = '#f8f8f8';
const grey = '#aaa';
const darkgrey = '#77878B';
const shadowgrey = '#f0f0f0';
const sectiongrey = '#EEEFFA';
const sectiondarkgrey= '#dcddea';
const red = '#DD1C1A';
const green = '#25ba53';
const blue = main_color_1; //Blue ('#2536BA';
const white = '#ffffff';
const lightorange = '#f784008a';
const lightblue = '#2536ba85';
const brightgrey='#c8c9c7';


const name = {
  lightgrey,
  lightestgrey,
  brightgrey,
  grey,
  darkgrey,
  shadowgrey,
  sectiongrey,
  sectiondarkgrey,
  red,
  yellow,
  blue,
  green,
  black,
  white,
  lightorange,
  lightblue,
  main_color_1,
  main_color_2,
  main_color_3,
  main_color_4,
  main_color_5,
  main_color_6,
  main_color_7,
  main_color_8,
  main_color_9,
  main_color_10
};

const panel = {
  footer: {
    foreground: white,
    background: blue,
  },
  header: {
    foreground: main_color_8,
    background: main_color_1,
  },
  navigation: {
    background_selected: main_color_4,
    foreground: main_color_8,
    foreground_selected: '#ffffff',
    border: main_color_4
  },
  main: {
    foreground: black,
    background: lightgrey
  },
  error: {
    foreground: '#333',
    background: 'rgba(215, 52, 0, 0.08)'
  },
};

const button = {
  save: '#65d074',
  default: '#dadada',
  primary: yellow,
  secondary: '#49bbff'
};

const status = {
  done: 'rgb(0, 191, 140)',
  active: 'rgb(229, 194, 0)',
  cancelled: 'rgb(255, 0, 0)',
}

const checkbox = {
  checked: '#006fcf',
  disabledBackground: '#f7f8fa',
  unchecked: '#97999b',
  label: '#53565a',
  labelDisabled: '#1a1a1a',
  labelError: '#d73400'
};

const table = {
  headerRowBackground: white,
  rowHeaderBackground: white,
  highlightRowBackground: white,
  evenRowBackground: white,
  oddRowBackground: '#f7f8f9',
  cellBorderColor: white
};

export default {
  name,
  panel,
  button,
  status,
  checkbox,
  table
};
