import {
  graphQueryPromise,
  graphMutationPromise,
  handleUpdateAttrString
} from './helpers';
import * as COMPANY from 'common/constants/companyConstants';

const companyFields = [
  COMPANY.COMPANY_ID,
  COMPANY.COMPANY_NAME,
  COMPANY.COMPANY_DESCRIPTION,
  COMPANY.COMPANY_ADDRESS,
  COMPANY.COMPANY_CITY,
  COMPANY.COMPANY_ADDRESS_STATE,
  COMPANY.COMPANY_COUNTRY,
  COMPANY.COMPANY_ZIP,
  COMPANY.COMPANY_LOGO_URL,
  COMPANY.COMPANY_MAIN_CONTACT_NAME,
  COMPANY.COMPANY_MAIN_CONTACT_EMAIL,
  COMPANY.COMPANY_PHONE
];

const updateCompanyFields = [
  COMPANY.COMPANY_ID,
  COMPANY.COMPANY_NAME,
  COMPANY.COMPANY_DESCRIPTION,
  COMPANY.COMPANY_ADDRESS,
  COMPANY.COMPANY_CITY,
  COMPANY.COMPANY_ADDRESS_STATE,
  COMPANY.COMPANY_COUNTRY,
  COMPANY.COMPANY_ZIP,
  COMPANY.COMPANY_MAIN_CONTACT_NAME,
  COMPANY.COMPANY_MAIN_CONTACT_EMAIL,
  COMPANY.COMPANY_PHONE,
  COMPANY.COMPANY_LOGO_IMAGE_BASE64,
  COMPANY.COMPANY_LOGO_FILE_NAME,
  COMPANY.COMPANY_SIGNED_BLOB
];

export const getCompanySettings = id => {
  const fields = companyFields.join(',');
  const query = `
        {
            company(id: "${id}") {
                ${fields}
            }
        }
    `;

  return graphQueryPromise(query);
};
export const postCompanySettings = values => {
  const fields = companyFields.join(',');
  const updates = handleUpdateAttrString(values, updateCompanyFields);

  const query = `mutation {
        updateCompany(
            ${updates}
        ){
            ${fields}
        }
    }`;

  return graphMutationPromise(query);
};
