import { graphQueryPromise } from './helpers';
import * as TASK_PROGRESS from 'common/constants/taskProgressConstants';
import * as WORKER from 'common/constants/workerConstants';

const taskProgressFields = [
  TASK_PROGRESS.ID,
  TASK_PROGRESS.COMPLETION_PERCENTAGE,
  TASK_PROGRESS.NOTE,
  TASK_PROGRESS.REQUEST_TASK_ID,
  TASK_PROGRESS.TASK_PROGRESSE_IMAGE_URL,
  TASK_PROGRESS.CREATED_AT
];

const workerFields = [
  WORKER.WORKER_ID,
  WORKER.WORKER_EMAIL,
  WORKER.WORKER_AVATAR_URL,
  WORKER.WORKER_FIRST_NAME,
  WORKER.WORKER_LAST_NAME,
  WORKER.WORKER_PHONE
];

export const getProgressesByRequestTaskId = requestTaskId => {
  const fields = taskProgressFields.join(',');
  const worker_fields = workerFields.join(',');

  const query = `{
      taskProgresses(requestTaskId: "${requestTaskId}") {
          ${fields}
          ${TASK_PROGRESS.WORKER} {
            ${worker_fields}
          }
      }
    }
  `;

  return graphQueryPromise(query);
};
