
import headerLogo from 'assets/recruitFirst/logo-white-recruitfirst.png';
import signInLogo from 'assets/recruitFirst/logo-recruitfirst.png';

const icons = {
  header: {
    logo: headerLogo,
    width: '230px',
    height: '46px',
    background: '',
  },
  signIn:{
    logo: signInLogo,
    width: '',
    height: '',
  }
};

export default icons;
